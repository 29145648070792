import { Box, Grid, Typography } from '@mui/material'
import {
  borderLineColor,
  couponColor,
  headingColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import { LocalShipping } from '@mui/icons-material'

function DeliveryMethod({ shipType, delivery }) {
  return (
    <Box
    sx={{ backgroundColor: whiteColor }}
      border={'1px solid ' + borderLineColor}
      borderRadius={2.2}
      px={1.85}
      pt={1.85}
      my={3}
    >
      <Typography
        variant='h5'
        component='h3'
        fontSize={{ xs: 14, md: 15 }}
        color={headingColor}
        pb={1.8}
        borderBottom={'1px solid ' + borderLineColor}
        textAlign='right'
      >
        Delivery Method
      </Typography>

      <Grid container spacing={1} py={1.8} alignItems='center'>
        <Grid item>
          <LocalShipping />
        </Grid>

        <Grid item>
          <Typography
            variant='subtitle1'
            component='p'
            fontSize={{ xs: 14, md: 15 }}
            color={couponColor}
            sx={{ textTransform: 'capitalize' }}
          >
            {/* Regular Delivery (2-5 days) {shipType} */}
            {delivery}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DeliveryMethod
