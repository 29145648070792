import { Box, Typography, Tabs, Tab } from '@mui/material'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import {
  dashboardNavColor,
  primaryColor,
  stepperInactiveColor,
  breadcrumbBgColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import { Link } from 'react-router-dom'

function DashboardNavbar({ active }) {
  const businessName = sessionStorage.getItem('businessName')

  return (
    <>
      <Box
        sx={{
          backgroundColor: stepperInactiveColor,
          position: 'sticky',
          top: 240,
          display: { xs: 'none', md: 'block' },
        }}
        borderRadius={1}
        p={2}
      >
        <Box
          display='flex'
          alignItems='center'
          px={1}
          py={0.8}
          component={Link}
          // to='/Dashboard'
          to={`/${businessName}/Dashboard`}
          color={active === 'dashboard' ? primaryColor : dashboardNavColor}
          sx={{ cursor: 'pointer' }}
        >
          <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} />
          <Typography
            color={active === 'dashboard' ? primaryColor : dashboardNavColor}
            fontSize='14px'
            fontWeight={active === 'dashboard' ? 600 : 400}
          >
            Dashboard
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          px={1}
          py={0.8}
          component={Link}
          // to="/AllOrders"
          to={`/${businessName}/AllOrders`}
          color={active === 'allOrders' ? primaryColor : dashboardNavColor}
          sx={{ cursor: 'pointer' }}
        >
          <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} />
          <Typography
            color={active === 'allOrders' ? primaryColor : dashboardNavColor}
            fontSize='14px'
            fontWeight={active === 'allOrders' ? 600 : 400}
          >
            All Orders
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          px={3}
          py={0.8}
          component={Link}
          // to="/OpenOrders"
          to={`/${businessName}/OpenOrders`}
          color={active === 'openOrders' ? primaryColor : dashboardNavColor}
          sx={{ cursor: 'pointer' }}
        >
          <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} />
          <Typography
            color={active === 'openOrders' ? primaryColor : dashboardNavColor}
            fontSize='14px'
            fontWeight={active === 'openOrders' ? 600 : 400}
          >
            Open Orders
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          px={3}
          py={0.8}
          component={Link}
          // to="/CompletedOrders"
          to={`/${businessName}/CompletedOrders`}
          color={active === 'completedOrders' ? primaryColor : dashboardNavColor}
          sx={{ cursor: 'pointer' }}
        >
          <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} />
          <Typography
            color={
              active === 'completedOrders' ? primaryColor : dashboardNavColor
            }
            fontSize='14px'
            fontWeight={active === 'completedOrders' ? 600 : 400}
          >
            Completed Orders
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          px={3}
          py={0.8}
          component={Link}
          // to="/CanceledOrders"
          to={`/${businessName}/CanceledOrders`}
          color={active === 'canceledOrders' ? primaryColor : dashboardNavColor}
          sx={{ cursor: 'pointer' }}
        >
          <ChevronRightRoundedIcon sx={{ mr: '8px', fontSize: '20px' }} />
          <Typography
            color={
              active === 'canceledOrders' ? primaryColor : dashboardNavColor
            }
            fontSize='14px'
            fontWeight={active === 'canceledOrders' ? 600 : 400}
          >
            Cancelled Orders
          </Typography>
        </Box>
        {/* <Box
        display="flex"
        alignItems="center"
        px={3}
        py={1}
        component={Link}
        // to="/CanceledOrders"
        to={`/OtherOrders/${businessId}`}
        color={active == "otherOrders" ? primaryColor : dashboardNavColor}
        sx={{ cursor: "pointer" }}
      >
        <ChevronRightRoundedIcon sx={{ mr: "8px", fontSize: "20px" }} />
        <Typography
          color={active == "otherOrders" ? primaryColor : dashboardNavColor}
          fontWeight={active == "otherOrders" ? 600 : 400}
        >
          Other Orders
        </Typography> 
      </Box> */}
      </Box>

      <Box display={{ xs: 'block', md: 'none' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={active === 'dashboard' ? 0 : 1}
            // onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label='basic tabs example'
          >
            <Tab
              label='Dashboard'
              sx={{ textTransform: 'none' }}
              component={Link}
              to={`/${businessName}/dashboard`}
            />
            <Tab
              label='All Orders'
              sx={{ textTransform: 'none' }}
              component={Link}
              to={`/${businessName}/allOrders`}
            />
          </Tabs>
        </Box>

        {active === 'dashboard' ? null : (
          <Box mt={1.75} display='flex' alignItems='center'>
            <Box
              sx={styles.btnStyle(active === 'openOrders')}
              component={Link}
              to={`/${businessName}/openOrders`}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontWeight={400}
                color={active === 'openOrders' ? whiteColor : dashboardNavColor}
              >
                Open
              </Typography>
            </Box>
            <Box
              sx={styles.btnStyle(active === 'completedOrders')}
              component={Link}
              to={`/${businessName}/completedOrders`}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontWeight={400}
                color={
                  active === 'completedOrders' ? whiteColor : dashboardNavColor
                }
              >
                Completed
              </Typography>
            </Box>
            <Box
              sx={styles.btnStyle(active === 'canceledOrders')}
              component={Link}
              to={`/${businessName}/canceledOrders`}
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontWeight={400}
                color={
                  active === 'canceledOrders' ? whiteColor : dashboardNavColor
                }
              >
                Cancelled
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default DashboardNavbar

const styles = {
  btnStyle: (isActive) => ({
    backgroundColor: isActive ? primaryColor : breadcrumbBgColor,
    padding: '2px 15px',
    borderRadius: '4px',
    mr: 1.2,
    cursor: 'pointer',
  }),
}
