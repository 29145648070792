import { getApiInstance } from '../../ApiInstance'

export const addActicity = async (data) => {
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      `activity-command-side/api/v1/activity/addActivity`,
      data
    )
    console.log('ADD_ACTIVITY:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}