import { Box, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AffiliateProgramsContent from './Affiliates/AffiliateProgramsContent'

import { useLocation, useParams } from 'react-router-dom'
import { getCartDetails } from 'redux/addToCart/AddToCartSlice'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import { getUserName } from 'utils/authHelpers'
import TopComponent from './TopComponent'
import {
  getPendingPaymentsDetails,
  getSettledPaymentsDetails,
} from 'redux/affiliate/AffiliateSlice'

function AffiliatePrograms() {
  const dispatch = useDispatch()
  const { businessName } = useParams()

  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const businessIdData = sessionStorage.getItem('businessId')

  const { businessId } = useSelector((state) => state.rootSlice)
  const { commercialDetails } = useSelector((state) => state.product)
  const { cartDetails } = useSelector((state) => state.cart)
  const { isUserLogin } = useSelector((state) => state.auth)
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem('businessName')
    if (businessName !== localBusinessName) {
      sessionStorage.setItem('cartData', 'null')
    }
    dispatch(getBusinessIdDetails(businessName))
    sessionStorage.setItem('businessName', businessName)
  }

  useEffect(() => {
    getBusinessData()
  }, [businessName])

  useEffect(() => {
    if (businessIdData !== null) {
      if (!commercialDetails) {
        dispatch(getCommercialDetails())
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId))
        }
      }
    }

    return () => {}
  }, [businessId])

  // Fetch Payment Details
  const fetchPendingPayments = (pendingParams) => {
    dispatch(getPendingPaymentsDetails(pendingParams))
  }

  const fetchSettledPayments = (settledParams) => {
    dispatch(getSettledPaymentsDetails(settledParams))
  }

  useEffect(() => {
    if (isUserLogin) {
      fetchPendingPayments({
        pageNo: 1,
        status: 'pending',
        month: 24,
      })

      fetchSettledPayments({
        pageNo: 1,
        status: 'paid',
        month: 24,
      })
    }
    return () => {}
  }, [isUserLogin])

  return (
    <Box>
      {businessId !== null && commercialDetails ? (
        <TopComponent value='' disableBanners />
      ) : null}

      <Box sx={styles.wrapper}>
        <Box sx={styles.container}>
          <AffiliateProgramsContent
            matches={matches}
            fetchPendingPayments={fetchPendingPayments}
            fetchSettledPayments={fetchSettledPayments}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AffiliatePrograms

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: { xs: '0px', md: '40px' },
  },

  container: {
    width: '400px',
    // padding: {xs:'50px 0 100px', md:'50px 0 0'},
    paddingBottom: { xs: '70px', md: '0' },
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 0px 2px 0 rgb(148 150 159 / 24%)',
  },
}
