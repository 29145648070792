import { getApiInstance } from "../../ApiInstance";

export const getOverviewData = async () => {
  try {
    const instance = getApiInstance();
    const businessId=sessionStorage.getItem("businessId")
    const response = await instance.get(
      `${process.env.REACT_APP_BUSINESS_SIDE_API}/getActiveBusiness/${businessId}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
