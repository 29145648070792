import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  breadcrumbBgColor,
  categoryLabelColor,
  dashboardDescriptionColor,
  headingColor,
  orderStepInactiveColor,
  savingTextColor,
  secondaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { CloseRounded } from '@mui/icons-material'

function OrderStep({ completed, label, time, active, isCancelled, hideDate }) {
  return (
    <Box>
      <Box sx={{ position: 'relative' }} py={3}>
        <Box
          sx={{
            height: '3px',
            width: '100%',
            backgroundColor: breadcrumbBgColor,
          }}
        >
          <Box
            sx={{
              height: '3px',
              width: completed ? (active ? '50%' : '100%') : '0%',
              backgroundColor: secondaryColor,
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: completed
              ? secondaryColor
              : breadcrumbBgColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isCancelled ? (
            <CloseRounded sx={{ color: whiteColor, fontSize: '18px' }} />
          ) : (
            <CheckRoundedIcon sx={{ color: completed ? whiteColor : '#999', fontSize: '18px' }} />
          )}
        </Box>
      </Box>
      <Box>
        <Typography
          variant='subtitle1'
          fontSize={{xs:12, md:14}}
          color={completed ? headingColor : orderStepInactiveColor}
          textAlign='center'
        >
          {label}
        </Typography>
        {!hideDate ? <Typography color={categoryLabelColor} textAlign='center' 
          fontSize={{xs:11, md:13}}>
          {time}
        </Typography> : null}
      </Box>
    </Box>
  )
}

export default OrderStep
