import { Box } from '@mui/material'
import React from 'react'

function FullHeightLoader() {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#eaeaea',
      }}
    >
      {/* <span class='circle-loader'></span>
      <div class='loading-text'>Loading...</div>

      <div class="center-loader"> */}
      <div class='loader'></div>
      {/* </div> */}
    </Box>
  )
}

export default FullHeightLoader
