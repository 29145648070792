import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  blackColor,
  blackShade11,
  borderTextbox,
  greyColor3,
  textBtnColor,
  textBtnColorLight,
  whiteColor,
} from '../../configs/styles/muiThemes'
import PaymentCard from './PaymentCard'
import PaymentInfoCard from './PaymentInfoCard'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/Loader'

function AffliatePaymentsContent({
  fetchPendingPayments,
  fetchSettledPayments,
  affiliateCommissionPercentage
}) {
  const navigate = useNavigate()
  const { businessName } = useParams()
  const [tabValue, setTabValue] = useState(0)
  const [openFilter, setOpenFilter] = useState(false)

  const [appliedFilter, setAppliedFilter] = useState(-1)

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  )

  const {
    pendingPayments,
    settledPayments,
    pendingPaymentsLoader,
    settledPaymentsLoader,
  } = useSelector((state) => state.affiliate)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    if (appliedFilter !== -1) {
      clearFilter()
    }
  }

  const onFilterApplyed = (months) => {
    if (tabValue === 0) {
      fetchPendingPayments({
        pageNo: 1,
        status: 'pending',
        month: months,
      })
      return
    }

    if (tabValue === 1) {
      fetchSettledPayments({
        pageNo: 1,
        status: 'paid',
        month: months,
      })
      return
    }
  }

  const clearFilter = () => {
    setAppliedFilter(-1)
    fetchSettledPayments({
      pageNo: 1,
      status: 'paid',
      month: 24,
    })

    fetchPendingPayments({
      pageNo: 1,
      status: 'pending',
      month: 24,
    })
  }

  useEffect(() => {
    if (appliedFilter !== -1) {
      onFilterApplyed(appliedFilter)
    }

    return () => {}
  }, [appliedFilter])

  return (
    <Box>
      {/* Filter Modal */}
      {openFilter && (
        <FilterCard
          onClose={() => {
            setOpenFilter(false)
          }}
          onFilerSelected={(months) => {
            setAppliedFilter(months)
            setOpenFilter(false)
          }}
        />
      )}

      <Box p='0 16px 10px 16px'>
        <Grid container spacing={{ xs: 4, md: 2 }}>
          <Grid item md={6}>
            <PaymentInfoCard
              icon='https://storage.googleapis.com/bodefaults/shopweb/pending-payment.png'
              label='Pending Payments'
              value={pendingPayments?.total}
            />
          </Grid>

          <Grid item md={6}>
            <PaymentInfoCard
              icon='https://storage.googleapis.com/bodefaults/shopweb/settled-payment.png'
              label='Settled Payments'
              value={settledPayments?.total}
            />
          </Grid>
        </Grid>

        <Typography
          variant='body2'
          fontSize={13}
          fontWeight={500}
          lineHeight='24px'
          color={greyColor3}
          mt='12px !important'
        >
          Settlements are made first week of every month. 5% TDS will be held
          against your PAN. For questions please contact support@costbo.com
        </Typography>
      </Box>

      <Box sx={styles.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab
            sx={{ fontSize: 13, fontWeight: 600, textTransform: 'none' }}
            label='Pending Payments'
          />
          <Tab
            sx={{ fontSize: 13, fontWeight: 600, textTransform: 'none' }}
            label='Settled Payments'
          />
        </Tabs>

        <IconButton
          onClick={() => {
            setOpenFilter(true)
          }}
        >
          <FilterListRoundedIcon />
        </IconButton>
      </Box>

      {appliedFilter !== -1 ? (
        <Box m='16px 16px 0 16px'>
          <Chip
            label={`Current + Past ${appliedFilter} month`}
            onDelete={clearFilter}
          />
        </Box>
      ) : null}

      {pendingPaymentsLoader || settledPaymentsLoader ? <Loader /> : <></>}

      {tabValue == 0 && (
        <Box m={2}>
          {pendingPayments ? (
            pendingPayments?.details?.length > 0 ? (
              pendingPayments?.details?.map((item, index) => {
                return (
                  <PaymentCard
                    key={'pending_transactions_' + index}
                    amountEarned={item?.amountEarned}
                    transactionAmount={item?.transactionAmount}
                    commissionPercentage={item?.commissionPercentage}
                    commissionAmount={item?.commissionAmount}
                    settledDate={item?.transactionDate}
                  />
                )
              })
            ) : (
              <>
                <Typography variant='body2' fontSize={14} fontWeight={600}>
                  No pending payments
                </Typography>

                <AffiliateInfoCard
                  rellerProfileData={rellerProfileData}
                  businessAffiliateData={businessAffiliateData}
                  affiliateCommissionPercentage={affiliateCommissionPercentage}
                  onClick={() => {
                    navigate(`/${businessName}/catalog`)
                  }}
                />
              </>
            )
          ) : (
            <></>
          )}
        </Box>
      )}

      {tabValue == 1 && (
        <Box m={2}>
          {settledPayments ? (
            settledPayments?.details?.length > 0 ? (
              settledPayments?.details?.map((item, index) => {
                return (
                  <PaymentCard
                    key={'settled_transactions_' + index}
                    amountEarned={item?.amountEarned}
                    transactionAmount={item?.transactionAmount}
                    commissionPercentage={item?.commissionPercentage}
                    commissionAmount={item?.commissionAmount}
                    settledDate={item?.transactionDate}
                  />
                )
              })
            ) : (
              <>
                <Typography variant='body2' fontSize={14} fontWeight={600}>
                  No settled payments
                </Typography>

                <AffiliateInfoCard
                  rellerProfileData={rellerProfileData}
                  businessAffiliateData={businessAffiliateData}
                  affiliateCommissionPercentage={affiliateCommissionPercentage}
                  onClick={() => {
                    navigate(`/${businessName}/catalog`)
                  }}
                />
              </>
            )
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  )
}

export default AffliatePaymentsContent

const FilterCard = ({ onClose, onFilerSelected }) => {
  return (
    <Box sx={styles.filterContainer}>
      <Box sx={styles.filterCard}>
        <Box sx={styles.filterHeader}>
          <Typography
            variant='h6'
            fontSize={16}
            fontWeight={600}
            color={blackColor}
          >
            Filter
          </Typography>

          <IconButton size='small' onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>

        <Box
          m='10px 16px'
          sx={{
            height: 'calc(40vh - 55px)',
            // overflowY: 'scroll',
          }}
        >
          {[3, 6, 8, 12, 24].map((item, index) => {
            return (
              <Typography
                key={'filter_' + index}
                variant='body2'
                fontSize={13}
                fontWeight={500}
                color={blackShade11}
                sx={{ p: '10px 0px', cursor: 'pointer' }}
                onClick={() => onFilerSelected(item)}
              >
                Current + Past {item} month
              </Typography>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

const AffiliateInfoCard = ({
  rellerProfileData,
  businessAffiliateData,
  affiliateCommissionPercentage,
  onClick,
}) => {
  return (
    <Box p='20px 0 80px'>
      <Box>
        <Typography
          variant='h6'
          fontSize={15}
          fontWeight={600}
          lineHeight='18px'
          color={blackColor}
          mb='10px'
        >
          Share product links with followers
        </Typography>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          gap={3}
          mt={0.8}
        >
          <Typography
            variant='body2'
            fontSize={13}
            fontWeight={500}
            lineHeight='20px'
            color={greyColor3}
          >
            Earn{' '}
            {affiliateCommissionPercentage}
            % commission on each sale
          </Typography>

          <Typography
            variant='body2'
            fontSize={13}
            fontWeight={500}
            sx={styles.costBoText}
          >
            {rellerProfileData?.details?.resellerCode}
          </Typography>
        </Box>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' mt={2}>
        <Button onClick={onClick} sx={{ textDecoration: 'underline' }}>
          Share & Earn
        </Button>
      </Box>
    </Box>
  )
}

const styles = {
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 1,
    borderColor: 'divider',
    m: '0 16px',
  },

  filterContainer: {
    width: { xs: '100%', md: '400px' },
    height: { xs: '100vh', md: 'calc(100vh - 94px)' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0,0,0,0.2)',
    position: 'fixed',
    top: { xs: '0px', md: '94px' },
    bottom: { xs: '70px', md: '30px' },
    zIndex: 999,
  },

  filterCard: {
    height: '40vh',
    backgroundColor: whiteColor,
    borderRadius: '16px 16px 0 0',
  },

  filterHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 12px 10px 16px',
    borderBottom: borderTextbox,
  },

  costBoText: {
    color: textBtnColor,
    backgroundColor: textBtnColorLight,
    borderRadius: '5px',
    padding: '5px 10px',
    border: '1.5px dashed ' + textBtnColor,
  },
}
