import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getBusinessDataApi } from '../../services/api_calls/home/homeApi'
import {
  getAffiliateValidityApi,
  getPaymentTransactionsApi,
  getRefRatesByBusinessApi,
  getResellerProfileApi,
} from '../../services/api_calls/affiliate/AffiliateApi'

const initialState = {
  rellerProfileData: null,
  pendingPayments: null,
  settledPayments: null,
  businessAffiliateData: null,
  affiliateValidity: null,
  //Loaders
  rellerProfileLoader: false,
  pendingPaymentsLoader: false,
  settledPaymentsLoader: false,
  businessAffiliateDataLoader: false,
  affiliateValidityLoader: false,
}

//Search business Products
export const getResellerProfileData = createAsyncThunk(
  'getResellerProfileData',
  async (data) => {
    const res = await getResellerProfileApi(data)
    return res
  }
)

//get Pedning Payment Transaction Details
export const getPendingPaymentsDetails = createAsyncThunk(
  'getPendingPaymentsDetails',
  async (data) => {
    const res = await getPaymentTransactionsApi(data)

    const responseData = {
      ...res,
      businessInfo: null,
    }

    return responseData
  }
)

//get Settled Payment Transaction Details
export const getSettledPaymentsDetails = createAsyncThunk(
  'getSettledPaymentsDetails',
  async (data) => {
    const res = await getPaymentTransactionsApi(data)

    const responseData = {
      ...res,
      businessInfo: null,
    }

    return responseData
  }
)

//Get Reseller Rates By Business
export const getRefRatesByBusinessID = createAsyncThunk(
  'getRefRatesByBusinessID',
  async (data) => {
    const res = await getRefRatesByBusinessApi(data)
    return res
  }
)

//Get Affiliate Validity
export const getAffiliateValidity = createAsyncThunk(
  'getAffiliateValidity',
  async (data) => {
    const res = await getAffiliateValidityApi(data)
    return res
  }
)

export const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    clearResellerDetails: (state) => {
      state.rellerProfileData = null
      state.pendingPayments = null
      state.settledPayments = null
      state.affiliateValidity = null
    },
    updateAffiliateValidity: (state, action) => {
      state.affiliateValidity = action?.payload
    },
  },

  extraReducers: {
    //searchBusiness
    [getResellerProfileData.pending]: (state) => {
      state.rellerProfileLoader = true
      state.rellerProfileData = null
    },
    [getResellerProfileData.fulfilled]: (state, { payload }) => {
      state.rellerProfileData = payload
      state.rellerProfileLoader = false
    },
    [getResellerProfileData.rejected]: (state) => {
      state.rellerProfileLoader = false
    },

    //get Pending Payment Transaction Details
    [getPendingPaymentsDetails.pending]: (state) => {
      state.pendingPaymentsLoader = true
      state.pendingPayments = null
    },
    [getPendingPaymentsDetails.fulfilled]: (state, { payload }) => {
      state.pendingPayments = payload
      state.pendingPaymentsLoader = false
    },
    [getPendingPaymentsDetails.rejected]: (state) => {
      state.pendingPaymentsLoader = false
    },

    //get Settled Payment Transaction Details
    [getSettledPaymentsDetails.pending]: (state) => {
      state.settledPaymentsLoader = true
      state.settledPayments = null
    },
    [getSettledPaymentsDetails.fulfilled]: (state, { payload }) => {
      state.settledPayments = payload
      state.settledPaymentsLoader = false
    },
    [getSettledPaymentsDetails.rejected]: (state) => {
      state.settledPaymentsLoader = false
    },

    //Get Reseller Rates By Business
    [getRefRatesByBusinessID.pending]: (state) => {
      state.businessAffiliateDataLoader = true
      state.businessAffiliateData = null
    },
    [getRefRatesByBusinessID.fulfilled]: (state, { payload }) => {
      state.businessAffiliateData = payload
      state.businessAffiliateDataLoader = false
    },
    [getRefRatesByBusinessID.rejected]: (state) => {
      state.businessAffiliateDataLoader = false
    },

    //Get Affiliate Validity
    [getAffiliateValidity.pending]: (state) => {
      state.affiliateValidityLoader = true
      state.affiliateValidity = null
    },
    [getAffiliateValidity.fulfilled]: (state, { payload }) => {
      state.affiliateValidity = payload
      state.affiliateValidityLoader = false
    },
    [getAffiliateValidity.rejected]: (state) => {
      state.affiliateValidityLoader = false
    },
  },
})

export const { clearResellerDetails, updateAffiliateValidity } = affiliateSlice.actions

export const affiliateSliceReducer = affiliateSlice.reducer
