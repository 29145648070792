import { Box, Grid, Typography } from '@mui/material'
import {
  borderLineColor,
  couponColor,
  headingColor,
} from '../../configs/styles/muiThemes'
import { Info } from '@mui/icons-material'

function SpecialNotes({ note }) {
  return (
    <Box
      border={'1px solid ' + borderLineColor}
      borderRadius={2.2}
      px={1.85}
      pt={1.85}
      my={3}
    >
      <Typography
        variant='h5'
        component='h3'
        fontSize={{ xs: 14, md: 15 }}
        color={headingColor}
        pb={1.8}
        borderBottom={'1px solid ' + borderLineColor}
        textAlign='right'
      >
        Special Instructions
      </Typography>

      <Grid container spacing={1} py={1.8} alignItems='center'>
        <Grid item>
          <Info />
        </Grid>

        <Grid item>
          <Typography
            variant='subtitle1'
            component='p'
            fontSize={{ xs: 14, md: 15 }}
            color={couponColor}
            sx={{ textTransform: 'capitalize' }}
          >
            {note}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SpecialNotes
