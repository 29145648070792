import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

const ConfirmAlert = ({ content, onCancel, onConfirm, isOpen }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Alert</DialogTitle>

      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <Typography component='p' sx={{ whiteSpace: 'pre-line' }}>{content}</Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{ display: 'flex', alignItems: 'center', gap: '8px', pb: 2 }}
      >
        <Button
          onClick={onCancel}
          variant='outlined'
          disableElevation
          sx={{ px: 2 }}
        >
          Cancel
        </Button>

        <Button
          onClick={onConfirm}
          variant='contained'
          disableElevation
          sx={{ px: 2 }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmAlert
