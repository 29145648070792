import { Box, Button, Container, Grid, Typography } from '@mui/material'
import FreeShippingContentCard from 'components/FreeShippingContentCard'
import { OnlineOrderSuspendedMsg } from 'configs/Constants'
import { useEffect, useState } from 'react'
import { getUserName } from 'utils/authHelpers'
import { checkEcommerceDiable } from 'utils/cartHelper'
import BreadcrumbsSection from '../../components/Breadcrumbs'
import InfoCard from '../../components/InfoCard'
import {
  borderLineColor,
  delectIconInactiveColor,
  headingColor,
  primaryColor,
  savingTextColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import AdditionalSavingCard from './AdditionalSavingCard'
import CartBottomTab from './CartBottomTab'
import CartProductCard from './CartProductCard'
import CartStepper from './CartStepper'

function CartContent({
  cartDetails,
  productOfferCheckList,
  checkOrderProducts,
  commercialDetails,
  onQtyUpdate,
  cashbackData,
  onProceed,
  validateCart,
  onRemoveProduct,
  onPriceUpdate,
  commerceDisabled,
  errorCount,
  handleErrorCount,
  allProducts,
}) {
  let totalQty = 0
  let totalSavings = 0

  const businessId = sessionStorage.getItem('businessId')
  const businessName = sessionStorage.getItem('businessName')

  const isBusinessClosed = commercialDetails?.info?.hyperlocalStore
    ? !commercialDetails?.info?.hyperlocalStore?.storeOpenStatus
    : false

  const onProceedToCart = () => {
    onProceed()
  }

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  const [itemCount, setItemCount] = useState(0)

  useEffect(() => {
    let count = 0
    cartDetails?.productList?.forEach((item, index) => {
      count += item?.currentQuantity
    })
    setItemCount(count)
  }, [cartDetails])

  errorCount.current = 0

  return (
    <Container
      sx={{
        maxWidth: '1300px !important',
        // mt: { xs: 1.8, md: 3.5 }
      }}
    >
      {/* <BreadcrumbsSection
        links={[
          { label: 'Catalog', to: `/catalog` },
          { label: 'Cart', to: `#` },
        ]}
      />
      <Container sx={{ maxWidth: '900px !important', display:{xs:'none', md:'block'} }}>
        <CartStepper
          steps={[
            {
              label: 'Cart',
              route: `/${businessName}/cart`,
            },
            {
              label: 'Discount/Delivery',
              route: `#`,
            },
            {
              label: 'Confirmation',
              route: `#`,
            },
          ]}
          activeStep={0}
        />
      </Container> */}
      <CartBottomTab
        totalQty={itemCount}
        amount={cartDetails?.orderTotalProductCost}
        btnText='CHECKOUT'
        products={cartDetails?.productList}
        onBtnClicked={() => {
          onProceedToCart()
        }}
        isDisabled={isBusinessClosed}
        freeAlert={
          (
            (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
            commercialDetails?.info?.immediateDiscount
          ).toFixed(2) > 75 && commercialDetails?.info?.immediateDiscount != 0
        }
        freeAmt={
          commercialDetails?.info?.immediateDiscount -
          cartDetails?.orderTotalProductCost?.toFixed(2)
        }
        // freeAlert={
        //   true
        // }
        // freeAmt={500}
      />

      <Grid container spacing={{ xs: 2, md: 3.5 }}>
        <Grid item xs={12} md={9}>
          <Box sx={styles.cartContainer}>
            <Grid
              container
              py={1}
              display={{ xs: 'none', md: 'inline-flex' }}
              spacing={{ md: 3 }}
            >
              <Grid item xs={4.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2.3}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid>

            {cartDetails &&
              checkOrderProducts &&
              cartDetails?.productList?.map((item, index) => {
                console.log(`itemitemitemitem${index}`, item)
                const savings =
                  Number(item?.productOriginalPrice) *
                    Number(item?.currentQuantity) -
                  Number(item?.productSalePrice) * Number(item?.currentQuantity)
                totalQty = totalQty + item.currentQuantity
                totalSavings = totalSavings + savings

                const orderProduct = checkOrderProducts?.filter(
                  (product, index) => {
                    return item.productId == product.id
                  }
                )

                console.log(
                  'CART_ORDER_FILTER',
                  orderProduct,
                  item,
                  item?.productSalePrice,
                  orderProduct?.[0]?.productSalePrice,
                  item?.productOriginalPrice,
                  orderProduct?.[0]?.productOriginalPrice
                )

                const isMinQtyError =
                  item?.currentQuantity < orderProduct?.[0]?.minOrderQuantity

                const isMaxQtyError =
                  item?.currentQuantity > orderProduct?.[0]?.maxOrderQuantity

                const isPriceError =
                  item?.productSalePrice?.toFixed(2) !=
                    orderProduct?.[0]?.productSalePrice ||
                  item?.productOriginalPrice?.toFixed(2) !=
                    orderProduct?.[0]?.productOriginalPrice

                const isEcommerceError = checkEcommerceDiable(
                  orderProduct?.[0],
                  item
                )

                const productWeight = item?.measurementInfo
                  ? item?.measurementInfo?.weight
                  : 0
                const isWeightZero = productWeight == 0

                const offerOrderList = productOfferCheckList?.filter(
                  (offerOrderProduct, index) => {
                    return offerOrderProduct?.productId == item?.productId
                  }
                )

                const isOfferOrderPresent =
                  offerOrderList?.[0]?.offerOrderPresent

                if (
                  isMinQtyError ||
                  isMaxQtyError ||
                  isPriceError ||
                  isEcommerceError ||
                  isWeightZero ||
                  isOfferOrderPresent
                ) {
                  if (orderProduct?.length > 0) {
                    handleErrorCount()
                  }
                }

                let minQtyGuest = 0
                let maxQtyGuest = 0

                allProducts?.productList?.forEach((element) => {
                  if (element.id == item.productId) {
                    minQtyGuest = element?.minOrderQuantity
                    maxQtyGuest = element?.maxOrderQuantity
                    return
                  }
                })

                return (
                  <CartProductCard
                    key={'cart_product_' + index}
                    image={item?.productPicURL}
                    productName={item?.productName}
                    oldUnitPrice={item?.productOriginalPrice}
                    unitprice={item?.productSalePrice}
                    discount={item?.percentageOff}
                    price={item?.productTotalPrice}
                    productId={item?.productId}
                    saving={savings}
                    quantity={item.currentQuantity}
                    isOutofStock={item.isOutOfStock}
                    onDeleteProduct={() => {
                      onRemoveProduct(cartDetails, orderProduct?.[0], item)
                      errorCount.current = 0
                    }}
                    onPriceUpdate={() => {
                      onPriceUpdate(cartDetails, orderProduct?.[0], item)
                      errorCount.current = 0
                    }}
                    minQty={
                      isLoggedIn
                        ? orderProduct.length > 0
                          ? orderProduct?.[0]?.minOrderQuantity
                          : 0
                        : minQtyGuest
                    }
                    maxQty={
                      isLoggedIn
                        ? orderProduct.length > 0
                          ? orderProduct?.[0]?.maxOrderQuantity
                          : 0
                        : maxQtyGuest
                    }
                    cartData={cartDetails?.productList}
                    cartId={cartDetails?.cartId}
                    product={item}
                    orderProduct={orderProduct?.[0]}
                    onQtyUpdate={onQtyUpdate}
                    // Price Change Check
                    isPriceChange={isPriceError}
                    // Ecommerce Enable check
                    isEcommerceDisabled={isEcommerceError}
                    // Min Order Quantity Check
                    minQtyCheck={isMinQtyError}
                    // Max Order Quantity Check
                    maxQtyCheck={isMaxQtyError}
                    // Weight Zero Check
                    weightZeroError={isWeightZero}
                    // Offer Order Check
                    isOfferOrderPresent={isOfferOrderPresent}
                    validateCart={validateCart}
                  />
                )
              })}
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            // component={Link}
            // to='/DiscountDelivery'
            sx={styles.checkoutBtn(isBusinessClosed)}
            variant='contained'
            disableElevation
            fullWidth
            onClick={() => {
              onProceedToCart()
            }}
            disabled={isBusinessClosed}
          >
            Proceed To Checkout
          </Button>
          <Box sx={{ display: { md: 'block', xs: 'none' } }}>
            {(
              (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
              commercialDetails?.info?.immediateDiscount
            ).toFixed(2) > 75 &&
            commercialDetails?.info?.immediateDiscount != 0 ? (
              <FreeShippingContentCard
                amt={
                  commercialDetails?.info?.immediateDiscount -
                  cartDetails?.orderTotalProductCost?.toFixed(2)
                }
              />
            ) : null}
          </Box>

          {commerceDisabled ? (
            <Typography
              variant='subtitle1'
              fontSize='11px'
              color={primaryColor}
              sx={{ pt: 1.5 }}
            >
              {OnlineOrderSuspendedMsg}
            </Typography>
          ) : null}

          <Box
            border={'1px solid ' + borderLineColor}
            borderRadius='6px'
            p={1.5}
            mt={3}
          >
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={1.5}
              borderBottom={'1px solid ' + borderLineColor}
              textAlign='right'
            >
              SUMMARY
            </Typography>

            <Box pt={1}>
              <SummaryDataRow
                label='Subtotal:'
                value={`₹${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
              />
              <SummaryDataRow label='Total Quantity:' value={totalQty} />
              <SummaryDataRow label='Taxes:' value='Included' isValueGreen />
              <SummaryDataRow label='Shipping & Handling:' value='-' />
              <SummaryDataRow label='Discount:' value='-' />
            </Box>

            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              pt={0.7}
              borderTop={'1px solid ' + borderLineColor}
            >
              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={headingColor}
                  display='inline'
                >
                  Total
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={primaryColor}
                  display='inline'
                >
                  ₹{cartDetails?.orderTotalProductCost?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* <TotalSavingCard savingAmount={totalSavings} /> */}

          <AdditionalSavingCard
            cashback={commercialDetails?.info?.rewardPointPercent}
            savingAmount={totalSavings}
            orderTotal={cartDetails?.orderTotalProductCost}
            cashbackData={cashbackData}
          />

          <InfoCard />

          <Button
            // component={Link}
            // to='/DiscountDelivery'
            sx={styles.checkoutBtn}
            variant='contained'
            disableElevation
            fullWidth
            onClick={() => {
              onProceedToCart()
            }}
            disabled={isBusinessClosed}
          >
            Proceed To Checkout
          </Button>

          {commerceDisabled ? (
            <Typography
              variant='subtitle1'
              fontSize='11px'
              color={primaryColor}
              sx={{ pt: 1.5 }}
            >
              {OnlineOrderSuspendedMsg}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  )
}

export default CartContent

const SummaryDataRow = ({ label, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      py={0.2}
    >
      <Grid item>
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='13.5px'
          color={headingColor}
          display='inline'
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='h6'
          component='p'
          fontSize='13.5px'
          color={isValueGreen ? savingTextColor : headingColor}
          display='inline'
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = {
  cartContainer: {
    boxShadow: '0 4px 8px rgb(0 0 0 / 6%)',
    px: 1.2,
    borderRadius: '6px',
    border: '1px solid' + borderLineColor,
  },

  checkoutBtn: (disabled) => ({
    fontSize: '14px',
    fontWeight: 600,
    color: whiteColor,
    display: { xs: 'none !important', md: 'inline-flex !important' },
    border: disabled
      ? '0px solid ' + primaryColor
      : '2px solid ' + primaryColor,
    '&:hover': {
      backgroundColor: whiteColor,
      color: 'primary.main',
    },
  }),
}
