import { CREATE_CART_API } from 'services/constant'
import { updateSessionCartQty } from 'utils/utilities'
import { getApiInstance } from '../../ApiInstance'


export const updateProductCartQty = async (data) => {
  const instance = getApiInstance()

  try {
    const response = await instance.post(
      `cart-command-side/api/v2/cart/consumer/updateQuantity`,
      {
        ...data,
        clientType: 'web',
      }
    )
    console.log('UPDATE_CART_QTY:', response.data)
    if (response.data.success) {
      await updateSessionCartQty(data.productId, data.quantity)
    }
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const addProductToCart = async (data) => {
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      `cart-command-side/api/v2/cart/consumer/add-product`,
      {
        ...data,
      }
    )
    // console.log('ADD_NEW_PRODUCT_DATA_API:', response.data)

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const checkOrderProducts = async (data) => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance({
    header: { businessId: businessId },
  })

  let params = '?'
  data?.forEach((item) => {
    params = params + `params=${item?.productId}&`
  })

  console.log('CHECK_ORDER_PRODUCT_PARAM:', params)

  try {
    const response = await instance(
      `product-query-side/api/v1/product/checkOrderProducts${params}`
    )
    console.log('CHECK_ORDER_PRODUCT:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const updateCart = async (data) => {
  const businessId = sessionStorage.getItem('businessId')

  const instance = getApiInstance({
    header: { businessId: businessId, cartId: data?.id },
  })

  console.log('UPDATE_CART_HELPER_RETURN:', data)

  try {
    const response = await instance.post(
      `cart-command-side/api/v1/cart/updateCart`,
      {
        ...data,
      }
    )
    console.log('UPDATE_CART_DATA:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const createCartData = async (data) => {
  const businessId = sessionStorage.getItem('businessId')

  const instance = getApiInstance({
    header: { businessId: businessId },
  })

  // console.log('UPDATE_CART_HELPER_RETURN:', data)

  try {
    const response = await instance.post(CREATE_CART_API, {
      ...data,
    })
    console.log('CREATE_CART_DATA:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
