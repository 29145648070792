import { Box, Typography } from '@mui/material'
import { headingColor, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

function GetAppModalCard() {
  return (
    <Box
      borderRadius='10px'
      overflow='hidden'
      maxHeight='90vh'
      sx={{
        overflowY: 'auto',
        background: whiteColor,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      px={{md:4,xs:2}}
      py={4}
      gap={3}
    >
      <Typography
        fontSize={{ md: 18, xs: 16 }}
        fontWeight={600}
        color={headingColor}
        textAlign='center'
      >
        Use CostBo mobile app to complete your Subscription
      </Typography>
      <Box display='flex' gap={2}>
        <Box
          component={'a'}
          href='https://play.google.com/store/apps/details?id=com.costbo'
          target='_blank'
        >
          <Box
            component={'img'}
            alt='costbo android playstore'
            src='https://storage.googleapis.com/bodefaults/web/n/playstore-store-btn.png'
            width={{ md: '130px', xs: '100px' }}
          />
        </Box>
        <Box
          component={'a'}
          href='https://itunes.apple.com/app/id1468091785'
          target='_blank'
        >
          <Box
            component={'img'}
            alt='costbo app store'
            src='https://storage.googleapis.com/bodefaults/web/n/app-store-btn.png'
            width={{ md: '130px', xs: '100px' }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default GetAppModalCard
