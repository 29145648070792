import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  borderLineColor,
  delectIconInactiveColor,
  greenColor,
  greenTextColor,
  headingColor,
  lightOrange01,
  orangeColor,
  orangeThickColor,
  primaryColor,
  savingTextColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import DeliveryAddress from './DeliveryAddress'
import DeliveryMethod from './DeliveryMethod'
import TableProductCard from 'components/TableProductCard'
import SpecialNotes from './SpecialNotes'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import moment from 'moment'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { useState } from 'react'

function PaycartConfirmationContent({
  onWhatsappClick,
  cartDetails,
  paycartData,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  let totalQty = 0
  let totalSavings = 0

  return (
    <Container sx={{ maxWidth: '1300px !important' }}>
      <Box sx={styles.shareInfo}>
        {matches && (
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/whatsapp-icon.png'
            sx={styles.whatsappIcon}
          />
        )}
        <Typography
          fontSize={{ xs: 12, md: 14 }}
          fontWeight={500}
          textAlign='center'
          lineHeight={{ xs: '18px', md: '20px' }}
        >
          {!matches && (
            <Box
              component='img'
              src='https://storage.googleapis.com/bodefaults/shopweb/whatsapp-icon.png'
              sx={{
                ...styles.whatsappIcon,
                m: '0 6px -8px 0',
              }}
            />
          )}
          Please share the order details and payment link with the customer via
          Whatsapp{' '}
          <Button
            sx={{
              fontSize: { xs: 12, md: 14 },
              textDecoration: 'underline',
              textTransform: 'unset',
              px: '6px',
            }}
            onClick={onWhatsappClick}
          >
            Click Here
          </Button>
        </Typography>
      </Box>

      <Grid container spacing={3.5}>
        <Grid item xs={12} md={9}>
          <Box sx={styles.cartContainer} mt={1}>
            <Grid
              container
              py={1.5}
              // display={{ xs: 'none', md: 'inline-flex' }}
              borderBottom={'1px solid ' + borderLineColor}
              mb={1}
            >
              <Grid item xs={12} md={9}>
                <Box>
                  <Typography
                    variant='subtitle1'
                    component='h4'
                    fontSize={{ xs: 15, md: 17 }}
                    fontWeight={600}
                    color={headingColor}
                    ml={1.8}
                  >
                    EASY CART ORDER CREATED
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h4'
                    fontSize='14px'
                    fontWeight={500}
                    color={headingColor}
                    ml={1.8}
                  >
                    Order ID:
                    <Typography
                      variant='subtitle1'
                      component='h4'
                      fontSize='14px'
                      fontWeight={500}
                      color={orangeColor}
                      ml={1.8}
                      display='inline'
                    >
                      {paycartData?.reference_id}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>

              {/* <Grid item xs={2}>
                <ContentBox
                  label={'Cart Date'}
                  value={
                    cartDetails?.createdOn
                      ? moment(cartDetails?.createdOn)?.format('MMM Do, YYYY')
                      : '-'
                  }
                />
              </Grid> */}

              <Grid item xs={12} md={3}>
                <Box sx={{ m: { xs: '15px 0 0 15px', md: 0 } }}>
                  <ContentBox
                    label={'Order Requested Date'}
                    value={
                      cartDetails?.orderRequestedOn
                        ? moment(cartDetails?.orderRequestedOn)?.format(
                            'MMM Do, YYYY'
                          )
                        : '-'
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              py={1.5}
              // display={{ xs: 'none', md: 'inline-flex' }}
              spacing={{ xs: 2, md: 0 }}
            >
              <Grid item xs={12} md={5}>
                <Box display='flex'>
                  <Box flex={1} style={styles.circleDot}>
                    <CurrencyRupeeIcon sx={{ fontSize: '18px' }} />
                  </Box>
                  <Box flex={1} ml={5}>
                    <ContentBox
                      label='Payment Link:'
                      value={paycartData?.short_url}
                      icon={
                        <ContentCopyOutlinedIcon
                          sx={{ fontSize: '18px', color: orangeThickColor }}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={7}>
                <Box display={'flex'}>
                  <Box flex={1} style={styles.circleDot}>
                    <ShoppingCartOutlinedIcon sx={{ fontSize: '18px' }} />
                  </Box>
                  <Box ml={5}>
                    <ContentBox
                      label='Order Details:'
                      value={`https://shop.costbo.com/${sessionStorage.getItem(
                        'businessName'
                      )}/easycart/${cartDetails?.id}`}
                      icon={
                        <ContentCopyOutlinedIcon
                          sx={{ fontSize: '18px', color: orangeColor }}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xs={12} md={7}>
                <Box
                  display='flex'
                  alignItems='center'
                  mb={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={onWhatsappClick}
                >
                  <Box
                    flex={1}
                    style={{
                      ...styles.circleDot,
                      backgroundColor: greenTextColor,
                    }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '18px' }} />
                  </Box>

                  <Box ml={5}>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={headingColor}
                      sx={{
                        '&:hover': {
                          color: primaryColor,
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Whatsapp Share
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}
            </Grid>
          </Box>

          <Box sx={styles.cartContainer} mt={2}>
            {/* <DiscountHeader
              icon={<CategoryRoundedIcon sx={{ color: headingColor }} />}
              title="Items"
            /> */}
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 16 }}
              color={headingColor}
              ml={1}
              mt={1.5}
              mb='6px !important'
            >
              Items in Order
            </Typography>

            <Grid
              container
              py={1}
              display={{ xs: 'none', md: 'inline-flex' }}
              spacing={{ md: 3 }}
            >
              <Grid item xs={5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid>
            {cartDetails?.productList?.map((item, index) => {
              const savings =
                Number(item?.productOriginalPrice) *
                  Number(item?.currentQuantity) -
                Number(item?.productSalePrice) * Number(item?.currentQuantity)
              totalQty = totalQty + item.currentQuantity
              totalSavings = totalSavings + savings
              return (
                <TableProductCard
                  key={'cart_product_' + index}
                  image={item?.productPicURL}
                  productName={item?.productName}
                  oldUnitPrice={item?.productOriginalPrice}
                  unitprice={item?.productSalePrice}
                  discount={item?.percentageOff}
                  price={item?.productTotalPrice}
                  saving={savings}
                  quantity={item.currentQuantity}
                  isOutofStock={item.isOutOfStock}
                  cartData={cartDetails?.productList}
                  cartId={cartDetails?.cartId}
                  product={item}
                  readOnly={true}
                />
              )
            })}

            {/* {dummyCartProducts.map((item, index) => {
              return (
                <CartProductCard
                  key={'cart_product_' + index}
                  image='https://storage.googleapis.com/bo3151920215/business/38bd76c370274724ba9feaa032266ee4/product/8cc515416c36427b9a83e0c2c3f6afaa/Product4-687725.png'
                  productName='31449 Michelin Air Conditioning System Cleaner - 150 ml'
                  caterogy='Auto Parts'
                  oldUnitPrice='340.00'
                  unitprice='240.00'
                  discount={20}
                  price='300.00'
                  saving='00.00'
                  quantity={item.quantity}
                  isDiscount={item.isDiscount}
                  isOutofStock={item.isOutOfStock}
                  onDeleteProduct={() => {}}
                  readOnly={true}
                />
              )
            })} */}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            border={'1px solid ' + borderLineColor}
            borderRadius='6px'
            p={1.5}
          >
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={1.5}
              borderBottom={'1px solid ' + borderLineColor}
              textAlign='right'
            >
              SUMMARY
            </Typography>

            <Box py={1}>
              <SummaryDataRow
                label='Subtotal:'
                value={cartDetails?.orderTotalProductCost}
              />
              <SummaryDataRow
                label='Total Quantity:'
                value={cartDetails?.totalQuantity}
              />
              <SummaryDataRow label='Taxes:' value='Included' isValueGreen />
              <SummaryDataRow
                label='Shipping & Handling:'
                value={cartDetails?.shippingCost}
              />

              {cartDetails?.codCost ? (
                <SummaryDataRow
                  label='COD Cost:'
                  value={`₹${cartDetails?.codCost?.codCharges?.toFixed(2)}`}
                />
              ) : null}

              <SummaryDataRow
                label='Discount:'
                value={cartDetails?.claimedPoint}
              />
            </Box>

            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              pt={0.7}
              borderTop={'1px solid ' + borderLineColor}
            >
              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={headingColor}
                  display='inline'
                >
                  Total
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={primaryColor}
                  display='inline'
                >
                  ₹{cartDetails?.orderTotalCost}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mt={{ xs: '24px', md: '0px' }}>
            <DeliveryAddress address={cartDetails?.customerAddress} isPayCart />
          </Box>

          <DeliveryMethod
            delivery={cartDetails?.deliveryDateRange}
            shipType={cartDetails?.deliveryType?.type}
          />

          {/* <PaymentType
            paymentType={
              cartDetails?.paymentInfo?.paymentDetail?.paymentType || 'Not Paid'
            }
          /> */}

          {cartDetails ? (
            cartDetails?.notesConsumer ? (
              <SpecialNotes note={cartDetails?.notesConsumer} />
            ) : null
          ) : null}
        </Grid>
      </Grid>
    </Container>
  )
}

export default PaycartConfirmationContent

const ContentBox = ({ label, value, icon }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(value)
    setIsCopied(() => true)

    setTimeout(() => {
      setIsCopied(() => false)
    }, 2000)
  }

  const openLink = () => {
    window.open(value, '_blank')
  }

  return (
    <Box display={'flex'} flexDirection='column'>
      <Typography
        variant='subtitle1'
        component='h1'
        fontSize='14px'
        fontWeight={600}
        color={headingColor}
        display='inline'
      >
        {label}
      </Typography>

      <Box display={'flex'} alignItems='center' gap={1} sx={{ mt: -0.5 }}>
        <Typography
          variant='h6'
          component='h6'
          fontSize='13.5px'
          color={delectIconInactiveColor}
          display='inline'
          sx={{
            lineBreak: 'anywhere',
            '&:hover': {
              textDecoration: 'underline',
              color: primaryColor,
              cursor: 'pointer',
            },
          }}
          onClick={openLink}
        >
          {value}
        </Typography>
        <IconButton onClick={handleCopy}>
          {!isCopied ? (
            icon
          ) : (
            <CheckCircleOutlineRoundedIcon
              sx={{ fontSize: '20px', color: greenColor }}
            />
          )}
        </IconButton>
      </Box>
    </Box>
  )
}

const SummaryDataRow = ({ label, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      py={0.2}
    >
      <Grid item>
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='13.5px'
          color={headingColor}
          display='inline'
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='h6'
          component='p'
          fontSize='13.5px'
          color={isValueGreen ? savingTextColor : headingColor}
          display='inline'
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = {
  cartContainer: {
    boxShadow: '0 4px 8px rgb(0 0 0 / 6%)',
    px: 1.5,
    py: 0.5,
    borderRadius: 2.2,
    border: '1px solid' + borderLineColor,
  },
  actionBtn: (outline) => ({
    fontSize: '15px',
    fontWeight: 500,
    color: outline ? primaryColor : whiteColor,
    p: '7px 24px',
    mb: '10px !important',
    border: '1px solid ' + primaryColor,
    backgroundColor: outline ? whiteColor : primaryColor,
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: outline ? primaryColor : whiteColor,
      color: outline ? whiteColor : primaryColor,
    },
  }),

  circleDot: {
    position: 'absolute',
    width: '32px',
    height: '32px',
    color: whiteColor,
    backgroundColor: orangeThickColor,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // cursor: 'pointer',
  },

  shareInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px',
    backgroundColor: lightOrange01,
    p: { xs: '10px', md: '3px' },
    m: '5px 0 15px 0',
    borderRadius: { xs: '3px', md: '8px' },
  },

  whatsappIcon: {
    width: { xs: '22px', md: '26px' },
    height: { xs: '22px', md: '26px' },
    objectFit: 'contain',
  },
}
