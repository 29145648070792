import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import BusinessInfoCard from './BusinessInfoCard'
import MainProductCard from './MainProductCard'
import MapComponent from './MapComponent'
import { descriptionColor, headingColor } from '../../configs/styles/muiThemes'
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';

const OverviewContentNew = ({ data, loading }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  console.log(data)

  return (
    <Container sx={{ maxWidth: '1300px !important' }}>
      <Typography
        variant='h2'
        fontSize={{ md: '19px', xs: '16px' }}
        align='left'
        pt={3.5}
        color={headingColor}
      >
        Overview
      </Typography>

      {data && (
        <Grid container spacing={3.5} sx={{ pl: matches ? '10px' : 0 }}>
          <Grid item xs={12} md={8}>
            <Typography
              variant='subtitle1'
              align='left'
              paddingTop={2.4}
              paddingBottom={0.65}
              color={headingColor}
            >
              Description
            </Typography>
            <Box>
              {data && (
                <Typography
                  variant='body1'
                  color={descriptionColor}
                  align='justify'
                  sx={{whiteSpace: "pre-wrap"}}
                >
                  {data.description}
                </Typography>
              )}
            </Box>

            <Typography
              variant='h5'
              paddingTop={3}
              paddingBottom={2.25}
              color={headingColor}
              align='left'
            >
              Main Products
            </Typography>
            <Box>
              {data?.mainProducts ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MainProductCard
                      products={data?.mainProducts?.slice(
                        0,
                        Math.round(data?.mainProducts?.length / 2)
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MainProductCard
                      products={data?.mainProducts?.slice(
                        Math.round(data?.mainProducts?.length / 2)
                      )}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Box>

            <Typography
              variant='h5'
              paddingTop={3}
              paddingBottom={2.25}
              color={headingColor}
              align='left'
            >
              Location
            </Typography>

            <MapComponent
              latitude={data.loc ? data.loc.y : 12.9716}
              longitude={data.loc ? data.loc.x : 77.5946}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <BusinessInfoCard
              icon={
                <PersonIcon sx={{fontSize:'20px'}} />
              }
              title='Business Contact Name'
              description={data.ownerName}
            />

            <BusinessInfoCard
              icon={
                <MapIcon sx={{fontSize:'20px'}} />
              }
              title='Address'
              description={data.address}
              address={true}
            />
            {/* <WorkingHoursCard
              title='Working Hours'
              description='Mr. Mohan'
              workigHourData={data.businessHours}
            /> */}
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default OverviewContentNew
