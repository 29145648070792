import {
  Box,
  Button,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from '@mui/material'
import React, { Suspense, useState } from 'react'
import { automobileProducts } from '../../utils/demoData'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { getCommingSoon, getOutOfStock } from 'utils/productHelper'
import CategoryGroupHeader from './CategoryGroupHeader'
import ProductCard from './ProductCard'

function AllCategoryProducts(props) {
  const [products, setProducts] = useState(props?.producList?.slice(0, 40))
  const [currentPage, setCurrentPage] = useState(1)
  const couponCount = Math.ceil(props?.producList?.length / 40)

  const handlePagination = (event, value) => {
    const deals = props?.producList?.slice(value * 40 - 40, value * 40)
    setProducts(deals)
    setCurrentPage(value)
  }

  return (
    <Box>
      <CategoryGroupHeader
        title='All Products'
        showSortBy
        onSort={(option) => {
          props.onClickSort(option)
        }}
        displaySelection={props.optionData}
        totalProduct={props.totalCount}
      />

      <Grid container columnSpacing={1} rowSpacing={2.5} pt={1.5} pl={1}>
        {products.map((item, index) => {
          return (
            <Grid item xs={6} sm={3} md={2.4} key={'all_product_' + index}>
              <ProductCard
                img={item.productPhoto[0].docURL}
                price={item.productSalePrice}
                oldPrice={item.productOriginalPrice}
                description={item.productName}
                discount={item.percentageOff}
                style={{ my: 1.5 }}
                isOutOfStock={getOutOfStock(item)}
                isComingSoon={getCommingSoon(item)}
                cartData={props?.cartData?.productList}
                product={item}
                minQty={item.minOrderQuantity}
                maxQty={item.maxOrderQuantity}
                currencySymbol={item.currencySymbol ? '₹' : item.currencySymbol}
                cartId={props?.cartData?.cartId}
                onQtyUpdate={(data) => {
                  props.onQtyUpdate(data)
                }}
                onAddProduct={(data) => {
                  props.onAddProduct(data)
                }}
                onRemoveProduct={props.onRemoveProduct}
                subscribe={item?.subscription?.status === 'active'}
                availability={item?.availability?.[0]?.toLowerCase() === 'yes'}
                subscription={item?.subscription}
              />
            </Grid>
          )
        })}
      </Grid>

      <Stack
        spacing={2}
        my={4}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Pagination
          // count={Math.round(props.totalCount / 40)}
          color='primary'
          count={couponCount}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem
              components={{
                next: NextBtn,
                previous: PreviousBtn,
              }}
              {...item}
            />
          )}
          onChange={handlePagination}
        />
      </Stack>
    </Box>
  )
}

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  )
}

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  )
}

export default AllCategoryProducts

const styles = {
  btnHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}
