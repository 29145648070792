import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getCartData,
  getCartDataById,
} from 'services/api_calls/addToCart/AddToCartApi'
import {
  getCommercialData,
  getOfferOrder,
} from 'services/api_calls/productDescription/ProductDescriptionApi'

const initialState = {
  cartDetails: null,
  commercialDetails: null,
  loader: false,
}

//Fetching Cart details
export const getCartDetails = createAsyncThunk(
  'getCartDetails',
  async (param, thunkAPI) => {
    const res = await getCartData(param)
    if (res.success) {
      // sessionStorage.setItem('cartData', JSON.stringify(res.detail))
    }
    console.log('cartdata', res)
    return res
  }
)

// Fetching Product details
export const getCartDetailsById = createAsyncThunk(
  'getCartDetailsById',
  async (param, thunkAPI) => {
    const res = await getCartDataById(param)
    return res
  }
)

// Fetching Commercial details
export const getCommercialDetails = createAsyncThunk(
  'getCommercialDetails',
  async (thunkAPI) => {
    const res = await getCommercialData()
    return res
  }
)

// Fetching Offer Order details
export const getOfferOrderDetails = createAsyncThunk(
  'getOfferOrderDetails',
  async (productId) => {
    const res = await getOfferOrder(productId)
    return res
  }
)

export const cartDetailsSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCartData: (state) => {
      state.cartDetails = null
    },
  },
  extraReducers: {
    //Cart details
    [getCartDetails.pending]: (state) => {
      state.loader = false
    },
    [getCartDetails.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.cartDetails = payload.detail
    },
    [getCartDetails.rejected]: (state) => {
      state.loader = false
    },

    //Cart details By Id
    [getCartDetailsById.pending]: (state) => {
      state.loader = true
    },
    [getCartDetailsById.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.cartDetails = payload.cart
    },
    [getCartDetailsById.rejected]: (state) => {
      state.loader = false
    },

    // Commercial details
    [getCommercialDetails.pending]: (state) => {
      state.loader = true
    },
    [getCommercialDetails.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.commercialDetails = payload
    },
    [getCommercialDetails.rejected]: (state) => {
      state.loader = false
    },
  },
})


export const { clearCartData } = cartDetailsSlice.actions

export const cartDetailsReducer = cartDetailsSlice.reducer
