import {
  Box,
  Button,
  Divider,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'
import { ProductFilter_Info } from 'configs/Constants'
import { useState } from 'react'
import {
  catalogSubHeaderColor,
  filterBgColor,
  labelColor,
  primaryColor,
  productLabelColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import CheckBoxLabel from './CheckBoxLabel'

function ProductFilterMobile({ onFilterClick, category, onCloseClick }) {
  const [selectedFilter, setSelectedFilter] = useState('')
  const [type, setType] = useState('')
  const [optionSubCat, setOptionSubCat] = useState([])
  const [filteredSubCat, setFilteredSubCat] = useState([])
  const [filteredProduct, setFilteredProduct] = useState(null)

  const handleSubCatClick = (catCode) => {
    const isPresent = filteredSubCat.filter((item, index) => {
      return item.code === catCode.code
    })
    if (isPresent.length > 0) {
      if (filteredSubCat.length === 1) {
        setFilteredSubCat([])
        return
      }
      const newList = filteredSubCat.filter((item, index) => {
        return item.code !== catCode.code
      })
      setFilteredSubCat(newList)
    } else {
      setFilteredSubCat([...filteredSubCat, catCode])
    }
    console.log('SUB_CAT_CODE:', filteredSubCat)
  }
  return (
    <Box>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        px={2}
        py={1}
      >
        {console.log('Mobile_category', category)}
        <Grid item>
          <Typography
            variant='body2'
            color={catalogSubHeaderColor}
            fontSize='15px'
            fontWeight={500}
          >
            FILTER
          </Typography>
        </Grid>

        <Grid item>
          <Button
            onClick={() => {
              setType('')
              setFilteredSubCat([])
              setFilteredProduct(null)
            }}
            sx={{
              textTransform: 'none',
              color: 'primary',
            }}
          >
            Clear All
          </Button>
        </Grid>
      </Grid>

      <Divider light />

      <Box>
        <Grid container>
          <Grid item xs={5.7}>
            <Box
              height='84vh'
              sx={{ backgroundColor: filterBgColor }}
              overflow='scroll'
            >
              <FilterOptionLabel
                title='All Products'
                onFilterClick={() => {
                  setType('all-products')
                  setFilteredProduct(null)
                  setSelectedFilter('')
                }}
                isActive={type === 'all-products'}
              />

              {category?.map((mainCat, index) => {
                return (
                  <Box key={index}>
                    <FilterOptionLabel
                      title={mainCat.value}
                      onFilterClick={() => {
                        setSelectedFilter('')
                        setType('filtered-products')
                        setFilteredProduct(mainCat)
                      }}
                      isActive={filteredProduct?.value === mainCat?.value}
                    />
                    {mainCat?.subCat?.map((subCat, i) => {
                      return (
                        <FilterOptionLabel
                          title={subCat?.value}
                          isSubCategory
                          onFilterClick={() => {
                            setSelectedFilter(subCat?.value)
                            setOptionSubCat(subCat?.subCat2 || [])
                            setType('filtered-subcat')
                            setFilteredProduct(subCat)
                          }}
                          isActive={selectedFilter === subCat?.value}
                          key={i}
                        />
                      )
                    })}
                  </Box>
                )
              })}
            </Box>
          </Grid>

          <Grid item xs={6.3}>
            {selectedFilter && optionSubCat.length > 0 ? (
              <Box pl={2}>
                <FilterOptionLabel
                  title={selectedFilter}
                  // count={136}
                  showHeader
                />
                <FormGroup>
                  {optionSubCat?.map((option, index) => {
                    return (
                      <>
                        <CheckBoxLabel
                          label={option.value}
                          textSmall
                          subCategory={option}
                          checked={filteredSubCat.includes(option)}
                          onValuesChanged={(catCode) => {
                            handleSubCatClick(catCode)
                          }}
                          key={index}
                        />
                        <Divider light />
                      </>
                    )
                  })}
                </FormGroup>
              </Box>
            ) : (
              <Box
                sx={{ height: '100%' }}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography
                  color={labelColor}
                  fontSize={11}
                  fontWeight={400}
                  p={0.7}
                  textAlign='center'
                >
                  {ProductFilter_Info}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box width='100vw !important' position='fixed' bottom='0'>
        <Divider light />
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ backgroundColor: whiteColor }}
          px={2}
          py={1}
        >
          <Grid item xs={6}>
            <Button
              onClick={() => {
                onCloseClick()
              }}
              fullWidth
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              onClick={() => {
                onFilterClick({ type, filteredSubCat, filteredProduct })
              }}
              fullWidth
              color='secondary'
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const FilterOptionLabel = ({
  title,
  count,
  isSubCategory,
  showHeader,
  isActive,
  onFilterClick,
}) => {
  return (
    <Box
      backgroundColor={
        isActive ? whiteColor : showHeader ? whiteColor : filterBgColor
      }
      borderLeft={isActive ? '2px solid #4f539d' : ''}
      pt={0.4}
      pb={0}
      onClick={() => {
        onFilterClick()
      }}
    >
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        py={0.6}
        px={1}
      >
        <Typography
          variant='body1'
          color={isActive ? primaryColor : productLabelColor}
          fontSize='12px'
          fontWeight={isSubCategory ? 500 : showHeader ? 400 : 500}
          pl={isSubCategory ? 1.5 : 0}
        >
          {title}
        </Typography>

        <Typography
          variant='body2'
          color={catalogSubHeaderColor}
          fontSize='11px'
          fontWeight={500}
        >
          {count}
        </Typography>
      </Grid>
    </Box>
  )
}

export default ProductFilterMobile
