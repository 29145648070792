import { Box, Button, Container, Grid, Typography } from '@mui/material'
import {
  borderLineColor,
  categoryLabelColor,
  delectIconInactiveColor,
  headingColor,
  primaryColor,
  savingTextColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import DeliveryAddress from './DeliveryAddress'
import DeliveryMethod from './DeliveryMethod'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { useNavigate } from 'react-router-dom'
import TableProductCard from 'components/TableProductCard'
import SpecialNotes from './SpecialNotes'
import { getUserDetails } from 'utils/authHelpers'
import { orderConfirmation_Info } from 'configs/Constants'
import PaymentType from 'pages/OrderDetails/PaymentType'

function ConfirmationContent({ commercialDetails, cartDetails }) {
  let totalQty = 0
  let totalSavings = 0

  const businessName = sessionStorage.getItem('businessName')
  let navigate = useNavigate()

  const userInfo = getUserDetails()

  return (
    <Container sx={{ maxWidth: '1300px !important' }}>
      {/* {console.log('cartDetailsNew', cartDetails)} */}
      {/* <BreadcrumbsSection />

      <Container sx={{ maxWidth: '900px !important' }}>
        <CartStepper
          steps={[
            {
              label: 'Cart',
              route: `#`,
            },
            {
              label: 'Discount/Delivery',
              route: `#`,
            },
            {
              label: 'Confirmation',
              route: `#`,
            },
          ]}
          activeStep={2}
        />
      </Container> */}

      <Grid container spacing={3.5}>
        <Grid item xs={12} md={9}>
          <Box
            sx={{ ...styles.cartContainer, p: 3 }}
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <Typography
              variant='h2'
              color={headingColor}
              mb={{ xs: '0px !important', md: '6px !important' }}
              lineHeight='28px'
              fontSize={{ xs: 17, md: 22 }}
            >
              ORDER CONFIRMED
            </Typography>
            <Typography
              variant='h5'
              fontSize={{ xs: 15, md: 16 }}
              color={headingColor}
              mb='6px !important'
              lineHeight={{ xs: '37px', md: '50px' }}
            >
              Thanks For Your Order
            </Typography>
            <CheckCircleRoundedIcon
              sx={{
                fontSize: { xs: '46px', md: '65px' },
                color: savingTextColor,
              }}
            />
            <Box
              my='16px !important'
              py={2}
              px={{ md: 4, xs: 1 }}
              border={'1px solid' + borderLineColor}
              borderRadius={1.5}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: '14px', md: '16px' }}
                lineHeight='24px'
                color={headingColor}
                textAlign='center'
                display='inline'
              >
                Order ID:{' '}
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize={{ xs: '14px', md: '16px' }}
                  fontWeight={600}
                  color={primaryColor}
                  // textAlign="center"
                  display='inline'
                >
                  {cartDetails?.orderId}
                </Typography>
              </Typography>
              <Typography
                variant='body1'
                color={categoryLabelColor}
                fontWeight={500}
                textAlign='center'
                mt={1}
              >
                {orderConfirmation_Info} {userInfo?.account?.email}
              </Typography>
            </Box>
            <Button
              sx={styles.actionBtn('outline')}
              variant='contained'
              disableElevation
              size='small'
              onClick={() => {
                navigate(`/${businessName}/catalog`)
              }}
            >
              Continue Shopping
            </Button>
          </Box>
          <Box sx={styles.cartContainer} mt={2}>
            {/* <DiscountHeader
              icon={<CategoryRoundedIcon sx={{ color: headingColor }} />}
              title="Items"
            /> */}
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 16 }}
              color={headingColor}
              ml={1}
              mt={1.5}
              mb='6px !important'
            >
              Items in Order
            </Typography>

            <Grid
              container
              py={1}
              display={{ xs: 'none', md: 'inline-flex' }}
              spacing={{ md: 3 }}
            >
              <Grid item xs={5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid>
            {cartDetails?.productList?.map((item, index) => {
              const savings =
                Number(item?.productOriginalPrice) *
                  Number(item?.currentQuantity) -
                Number(item?.productSalePrice) * Number(item?.currentQuantity)
              totalQty = totalQty + item.currentQuantity
              totalSavings = totalSavings + savings
              return (
                <TableProductCard
                  key={'cart_product_' + index}
                  image={item?.productPicURL}
                  productName={item?.productName}
                  oldUnitPrice={item?.productOriginalPrice}
                  unitprice={item?.productSalePrice}
                  discount={item?.percentageOff}
                  price={item?.productTotalPrice}
                  saving={savings}
                  quantity={item.currentQuantity}
                  isOutofStock={item.isOutOfStock}
                  cartData={cartDetails?.productList}
                  cartId={cartDetails?.cartId}
                  product={item}
                  readOnly={true}
                />
              )
            })}

            {/* {dummyCartProducts.map((item, index) => {
              return (
                <CartProductCard
                  key={'cart_product_' + index}
                  image='https://storage.googleapis.com/bo3151920215/business/38bd76c370274724ba9feaa032266ee4/product/8cc515416c36427b9a83e0c2c3f6afaa/Product4-687725.png'
                  productName='31449 Michelin Air Conditioning System Cleaner - 150 ml'
                  caterogy='Auto Parts'
                  oldUnitPrice='340.00'
                  unitprice='240.00'
                  discount={20}
                  price='300.00'
                  saving='00.00'
                  quantity={item.quantity}
                  isDiscount={item.isDiscount}
                  isOutofStock={item.isOutOfStock}
                  onDeleteProduct={() => {}}
                  readOnly={true}
                />
              )
            })} */}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            border={'1px solid ' + borderLineColor}
            borderRadius='6px'
            p={1.5}
          >
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={1.5}
              borderBottom={'1px solid ' + borderLineColor}
              textAlign='right'
            >
              SUMMARY
            </Typography>

            <Box py={1}>
              <SummaryDataRow
                label='Subtotal:'
                value={cartDetails?.orderTotalProductCost}
              />
              <SummaryDataRow
                label='Total Quantity:'
                value={cartDetails?.totalQuantity}
              />
              <SummaryDataRow label='Taxes:' value='Included' isValueGreen />
              <SummaryDataRow
                label='Shipping & Handling:'
                value={cartDetails?.shippingCost}
              />

              {cartDetails?.codCost ? (
                <SummaryDataRow
                  label='COD Cost:'
                  value={`₹${cartDetails?.codCost?.codCharges?.toFixed(2)}`}
                />
              ) : null}

              <SummaryDataRow
                label='Discount:'
                value={cartDetails?.claimedPoint}
              />
            </Box>

            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              pt={0.7}
              borderTop={'1px solid ' + borderLineColor}
            >
              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={headingColor}
                  display='inline'
                >
                  Total
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={primaryColor}
                  display='inline'
                >
                  ₹{cartDetails?.orderTotalCost}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mt={{ xs: '24px', md: '0px' }}>
            <DeliveryAddress address={cartDetails?.customerAddress} />
          </Box>
          <DeliveryMethod
            delivery={cartDetails?.deliveryDateRange}
            shipType={cartDetails?.deliveryType?.type}
          />

          <PaymentType
            paymentType={cartDetails?.paymentInfo?.paymentDetail?.paymentType}
          />

          {cartDetails ? (
            cartDetails?.notesConsumer ? (
              <SpecialNotes note={cartDetails?.notesConsumer} />
            ) : null
          ) : null}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ConfirmationContent

const SummaryDataRow = ({ label, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      py={0.2}
    >
      <Grid item>
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='13.5px'
          color={headingColor}
          display='inline'
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='h6'
          component='p'
          fontSize='13.5px'
          color={isValueGreen ? savingTextColor : headingColor}
          display='inline'
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = {
  cartContainer: {
    boxShadow: '0 4px 8px rgb(0 0 0 / 6%)',
    px: 1.5,
    py: 0.5,
    borderRadius: 2.2,
    border: '1px solid' + borderLineColor,
  },
  actionBtn: (outline) => ({
    fontSize: '15px',
    fontWeight: 500,
    color: outline ? primaryColor : whiteColor,
    p: '7px 24px',
    mb: '10px !important',
    border: '1px solid ' + primaryColor,
    backgroundColor: outline ? whiteColor : primaryColor,
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: outline ? primaryColor : whiteColor,
      color: outline ? whiteColor : primaryColor,
    },
  }),
}
