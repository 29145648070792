import { Box, Typography } from '@mui/material'
import {
  borderDashedFreeContent,
  freeContentBgColor,
  savingTextColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React from 'react'

export default function FreeShippingContentCard({ amt }) {
  return (
    <Box sx={styles.card}>
      <Box sx={{ p: '5px 12px', border: borderDashedFreeContent }}>
        {amt > 0 ? (
          <Typography
            fontSize={11}
            fontWeight={400}
            color={savingTextColor}
            lineHeight={1.2}
            display='inline'
          >
            Add products worth{' '}
            <Typography
              fontSize={12}
              fontWeight={500}
              color={savingTextColor}
              display='inline'
            >
              ₹{amt}
            </Typography>{' '}
            for{' '}
            <Typography
              fontSize={12}
              fontWeight={500}
              color={savingTextColor}
              display='inline'
            >
              Free Delivery
            </Typography>
          </Typography>
        ) : (
          <Typography
            fontSize={11}
            fontWeight={400}
            color={savingTextColor}
            lineHeight={1.2}
            display='inline'
          >
            This order is eligible for{' '}
            <Typography
              fontSize={12}
              fontWeight={500}
              color={savingTextColor}
              display='inline'
            >
              Free Delivery
            </Typography>
          </Typography>
        )}
      </Box>
    </Box>
  )
}

const styles = {
  card: {
    background: freeContentBgColor,
    position: 'relative',
    overflow: 'hidden',
    mt: 2,
    '&::before': {
      content: "''",
      position: 'absolute',
      borderRadius: '50%',
      top: 'calc(50% - 8px)',
      left: '-6px',
      border: borderDashedFreeContent,
      width: '12px',
      height: '12px',
      backgroundColor: whiteColor,
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      borderRadius: '50%',
      top: 'calc(50% - 8px)',
      right: '-6px',
      border: borderDashedFreeContent,
      width: '12px',
      height: '12px',
      backgroundColor: whiteColor,
    },
  },
}
