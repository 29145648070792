import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAccountDetails,
  getAllOpenOrderDetails,
  getAllOrderDetails,
  getCashbackAvailabel,
  getOrdersCount,
} from 'redux/dashboard/dashboardSlice'
import FooterMui from '../components/FooterMui'
import AllOrdersContent from './AllOrders/AllOrdersContent'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserName } from 'utils/authHelpers'
import TopComponent from './TopComponent'
import BreadcrumbsSection from 'components/Breadcrumbs'
import { Box, Container } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'

function AllOrders() {
  const { businessName } = useParams()
  // const { businessId } = useParams()
  const businessId = sessionStorage.getItem('businessId')
  const [orderList, setOrderList] = useState([])

  const dispatch = useDispatch()
  const {
    accountDetails,
    orderDetails,
    openOrderDetails,
    cashbackData,
    loading,
    ordersCountData,
  } = useSelector((state) => state.dashboard)

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  let navigate = useNavigate()

  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    if (isLoggedIn) {
      if (accountDetails == null) {
        dispatch(getAccountDetails())
      }
      if (orderDetails == null) {
        dispatch(getAllOrderDetails({ businessId, pageNo: 1 }))
      }
      if (openOrderDetails == null) {
        dispatch(getAllOpenOrderDetails(businessId))
      }
      if (cashbackData == null) {
        dispatch(getCashbackAvailabel(businessId))
      }
      if (ordersCountData == null) {
        dispatch(getOrdersCount(businessId))
      }
    } else {
      navigate(`/${businessName}`)
    }
  }, [])

  useEffect(() => {
    // Filter to remove paycart and businesscart orders

    // const orders = orderDetails?.details?.filter((item) => {
    //   return (
    //     item.orderStatus != 'paycartcreated' &&
    //     item.orderStatus != 'businesscartcreated'
    //   )
    // })

    // all cart orders
    const orders = orderDetails?.details

    if (orders) {
      setOrderList(orders)
    }
  }, [orderDetails])

  const handleAllOrderPagination = (pageNumber) => {
    dispatch(getAllOrderDetails({ businessId, pageNo: pageNumber }))
    setPageNo(pageNumber)
  }

  return (
    <div>
      {/* <Navbar value='' /> */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 100,
          backgroundColor: whiteColor,
        }}
      >
        <TopComponent value='' disableBanners />

        <Container
          sx={{
            maxWidth: '1300px !important',
            mt: { xs: 1.8, md: 2.5 },
          }}
        >
          <BreadcrumbsSection
            links={[{ label: 'All Orders', to: `/AllOrders` }]}
          />
        </Container>
      </Box>

      <AllOrdersContent
        accountDetails={accountDetails}
        orderDetails={orderList}
        openOrderDetails={openOrderDetails}
        cashbackData={cashbackData}
        loading={loading}
        businessId={businessId}
        ordersCountData={ordersCountData}
        handleAllOrderPagination={handleAllOrderPagination}
        pageNo={pageNo}
      />
      <FooterMui />
    </div>
  )
}

export default AllOrders
