import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCouponsData, getInstantDiscounts } from "../../services/api_calls/dealsCoupons_api/DealCouponsApi";

const initialState = {
  dealsCouponsDetails: null,
  instantDiscount: null,
  loading: false,

  // Loaders
  couponsLoading: false
};

//Fetching all coupons
export const getCoupons = createAsyncThunk("getCoupons", async (thunkAPI) => {
  const res = await getCouponsData();
  console.log( res.details)
  return res.details;

});

//Fetching all Instant Discounts
export const getAllInstantDiscount = createAsyncThunk("getAllInstantDiscount", async (thunkAPI) => {
  const res = await getInstantDiscounts();
  return res;

});

export const dealsCouponsSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {},
  extraReducers: {
    [getCoupons.pending]: (state) => {
      state.couponsLoading = true;
      state.loading = true;
    },
    [getCoupons.fulfilled]: (state, { payload }) => {
      state.couponsLoading = false;
      state.loading = false;
      state.dealsCouponsDetails = payload;
    },
    [getCoupons.rejected]: (state) => {
      state.couponsLoading = false;
      state.loading = false;
    },

    //Fetch all Instant Discounts
    [getAllInstantDiscount.pending]: (state) => {
      state.loading = true;
    },
    [getAllInstantDiscount.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.instantDiscount = payload;
    },
    [getAllInstantDiscount.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const dealsCouponsReducer = dealsCouponsSlice.reducer;
