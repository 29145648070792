import { List, ListItem, Paper, Typography } from "@mui/material";
import { dealsDiscounts_ApplyStep1, dealsDiscounts_ApplyStep2, dealsDiscounts_ApplyStep3, dealsDiscounts_ApplyStep4, dealsDiscounts_ApplyStep5, dealsDiscounts_ApplyStep6, dealsDiscounts_ApplyStep7 } from "configs/Constants";
import React from "react";
import {
  backgroundColor,
  borderColor,
  couponColor,
  headingColor,
} from "../../configs/styles/muiThemes";

export default function DiscountStepCard() {
  const discountSteps = [
    dealsDiscounts_ApplyStep1,
    dealsDiscounts_ApplyStep2,
    dealsDiscounts_ApplyStep3,
    dealsDiscounts_ApplyStep4,
    dealsDiscounts_ApplyStep5,
    dealsDiscounts_ApplyStep6,
    dealsDiscounts_ApplyStep7,
  ];
  return (
    <Paper sx={styles.card}>
      <Typography variant="subtitle1" color={headingColor}>
        How to apply a discount code ?
      </Typography>
      <List sx={styles.list}>
        {discountSteps.map((item, i) => {
          return (
            <ListItem
              sx={{ display: "list-item", p: 0, ...styles.item, my: 1 }}
            >
              <Typography variant="body1" color={couponColor}>
                {item}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
}

const styles = {
  card: {
    borderRadius: "6px",
    padding: "15px",
    background: backgroundColor,
    boxShadow: 0,
    border: 1,
    borderColor: borderColor,
  },

  list: { listStyleType: "disc", ml: "20px" },
  item: {
    listStyle: "outside",
    lineHeight: "25px",
    marginBottom: "100px",
  },
};
