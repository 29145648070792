import {
    Button,
    Container,
    Grid,
    Typography,
    Stack,
    Pagination,
    PaginationItem,
    Box,
    Divider,
  } from '@mui/material'
  import { useState, useRef } from 'react'
  import ReviewCard from './ReviewCard'
  import ReviewSectionHeader from './ReviewSectionHeader'
  import ModalCard from '../../components/ModalCard'
  import ArrowBackIcon from '@mui/icons-material/ArrowBack'
  import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
  import { headingColor } from '../../configs/styles/muiThemes'
  import EmptyCard from '../../components/EmptyCard'
  import { reviewEmptyMessage, reviewEmptyImgUrl } from '../../configs/Constants'
  
  const CustomerReviewSubComponent = ({
    data,
    reviewCount,
    connectionCount,
    profileImages,

    getAllReviewDetails,
    optionData,

  }) => {
    const [openFullReview, setOpenFullReview] = useState(false)
    const selectedData = useRef(
      data ? (data.allReview !== null ? data.allReview[0] : undefined) : undefined
    )
  
    const textData = optionData
  
    // TO close full review
    const handleFullReviewClose = () => {
      setOpenFullReview(false)
    }
  
    const handlePagination = (event, value) => {
      getAllReviewDetails(textData, value)
    }
  
  
    return (
      <Box>
        {selectedData.current && (
          <ModalCard handleClose={handleFullReviewClose} open={openFullReview}>
            <ReviewCard
              disableDate={true}
              showFullData={true}
              isShownInModal
              data={selectedData.current.data}
              connection={selectedData.current.connection}
              totalReviews={selectedData.current.totalReviews}
            />
          </ModalCard>
        )}

        {data && data.allReview !== null && data.allReview.length > 0 && (
            <ReviewSectionHeader
              preselectedOption={textData}
              onClickMore={(option) => {
                getAllReviewDetails(option)
              }}
            />
          )}

          {data && data.allReview.length === 0 && (
            <Box mt={1.5}>
              <Divider light />
              <Typography variant='h4' color={headingColor} pt={2}>
                Customer Reviews
              </Typography>
              <EmptyCard
                message={reviewEmptyMessage}
                imageUrl={reviewEmptyImgUrl}
              />
            </Box>
          )}

          {data &&
            data.allReview !== null &&
            data.allReview.length > 0 &&
            connectionCount &&
            connectionCount.length > 0 &&
            reviewCount &&
            reviewCount.length > 0 &&
            profileImages && (
              <Grid container spacing={{xs:1.5, md:3}}>
                {data.allReview.map((reviewData, index) => {
                  const connection = connectionCount.filter(
                    (item) => item.profileId === reviewData.profileId
                  )
                  const totalReviews = reviewCount.filter(
                    (item) => item.profileId === reviewData.profileId
                  )
    
                  const reviewCountData =
                    totalReviews && totalReviews.length > 0
                      ? totalReviews[0].totalReview
                      : 0
                  const connectionCountData =
                    connection && connection.length > 0
                      ? connection[0].connections
                      : 0
                  const image = profileImages.filter(
                    (item) => item.profileId === reviewData.profileId
                  )
                  return (
                    <Grid item xs={12} md={6}>
                      <ReviewCard
                        onClickMore={(fullData) => {
                          selectedData.current = fullData
                          setOpenFullReview(true)
                          console.log('Hello hit:', fullData)
                        }}
                        data={reviewData}
                        disableDate={false}
                        showFullData={openFullReview}
                        images={image}
                        connection={connectionCountData}
                        totalReviews={reviewCountData}
                        getAllReviewDetails={() => getAllReviewDetails()}
                        // isHelpfulClicked={(reviewId,profileId)=>{dispatch(helpFullReviewPost(reviewId,profileId))}}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )}
    
   
     
      
  
        {/* ---Pagination--- */}
        {data &&
        data.allReview !== null &&
        data.allReview.length > 0 &&
        data?.totalReview / data?.size > 1 ? (
          <Stack
            spacing={2}
            my={4}
            // display={{ md: 'flex', xs: 'none' }}
            alignItems='center'
            justifyContent='center'
          >
            <Pagination
              count={data ? Math.ceil(data?.totalReview / 10) : 0}
              page={data ? data?.pageNo : 0}
              onChange={handlePagination}
              color='primary'
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    next: NextBtn,
                    previous: PreviousBtn,
                  }}
                  {...item}
                />
              )}
            />
          </Stack>
        ) : null}
        {/* ---Pagination End--- */}
      </Box>
    )
  }
  
  export default CustomerReviewSubComponent
  
  const PreviousBtn = () => {
    return (
      <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
        Prev
      </Button>
    )
  }
  
  const NextBtn = () => {
    return (
      <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
        Next
      </Button>
    )
  }
  
  const styles = {
    btnHover: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }
  