import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  Stack,
  Pagination,
  PaginationItem,
} from '@mui/material'
import { useEffect, useState } from 'react'
import SocialShare from '../../components/SocialShare'
import DiscountStepCard from './DiscountStepCard'
import DiscountCard from './DiscountCard'
import { headingColor } from '../../configs/styles/muiThemes'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Slider from 'react-slick/lib/slider'
import {
  dealsDiscounts_ShareMgs,
  dealsDiscounts_SubTitle,
  dealsDiscounts_Title,
  siteBaseURL,
} from 'configs/Constants'
import { addTimelineShare } from 'services/api_calls/header/HeaderApi'
import { getUserName } from 'utils/authHelpers'

const DiscountComponent = ({ dealData, loading }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 2,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // variableWidth: true,
        },
      },
    ],
  }

  const [couponList, setCouponList] = useState(dealData.slice(0, 4))
  const [currentPage, setCurrentPage] = useState(1)
  const couponCount = Math.ceil(dealData.length / 4)
  const businessName = sessionStorage.getItem('businessName')

  const handlePagination = (event, value) => {
    const deals = dealData.slice(value * 4 - 4, value * 4)
    setCouponList(deals)
    setCurrentPage(value)
  }

  const [loggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )

  const [windowurl, setWindowurl] = useState('')

  useEffect(() => {
    const url = window.location.href
    setWindowurl(url)
  }, [])

  // const formatData = () => {
  //   // const formattedList = []
  //   // count count = 0
  //   for(let i=0; i < dealData.length; i++){
  //     if(i>Math.ceil(dealData/2)) return
  //     console.log('FORMAT_DEAL: ', i*2);
  //     console.log('FORMAT_DEAL: ', i*2+1);
  //   }
  // }

  // formatData()

  return (
    <Container sx={styles.container}>
      <Grid container spacing={{ md: 3, xs: 1 }}>
        <Grid item xs={12} md={8}>
          <Stack>
            <Typography
              variant='h2'
              fontSize={{ md: '19px', xs: '16px' }}
              color={headingColor}
              pt={3.5}
            >
              {dealsDiscounts_Title}
            </Typography>
            <Typography
              variant='caption'
              fontSize={{ md: '14px', xs: '13px' }}
              marginBottom='15px !important'
              mt={1}
            >
              {dealsDiscounts_SubTitle}
            </Typography>
          </Stack>
          {couponList && (
            <Grid container spacing={3} display={{ md: 'flex', xs: 'none' }}>
              {couponList.map((deal, index) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <DiscountCard data={deal} index={index} />
                  </Grid>
                )
              })}
            </Grid>
          )}

          <Box display={{ xs: 'block', md: 'none' }}>
            <Slider {...settings}>
              {dealData?.map((deal, index) => {
                return (
                  <Grid
                    container
                    xs={12}
                    key={index}
                    direction='column'
                    spacing={2}
                  >
                    <Grid item>
                      <DiscountCard index={index} data={deal} />
                    </Grid>
                  </Grid>
                )
              })}
            </Slider>
          </Box>

          {/* ---Pagination--- */}
          {couponCount > 1 ? (
            <Stack
              spacing={2}
              my={4}
              display={{ md: 'flex', xs: 'none' }}
              alignItems='center'
              justifyContent='center'
            >
              <Pagination
                count={couponCount}
                page={currentPage}
                onChange={handlePagination}
                color='primary'
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      next: NextBtn,
                      previous: PreviousBtn,
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          ) : null}
          {/* ---Pagination End--- */}
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack sx={{ mb: '13px !important' }}>
            <Typography
              variant='subtitle1'
              color={headingColor}
              marginBottom='10px !important'
              pt={3.5}
            >
              {dealsDiscounts_ShareMgs}
            </Typography>
            <SocialShare
              back={() => {
                if (loggedIn)
                  addTimelineShare({
                    shareType: 'timelineshare',
                    latitude: 77.65563062,
                    longitude: 12.92352506,
                  })
              }}
              // url={`${siteBaseURL}${businessName}/dealsCoupons`}
              url={`${windowurl}`}
              quote='My product'
            />
          </Stack>
          <DiscountStepCard />
        </Grid>
      </Grid>
    </Container>
  )
}
export default DiscountComponent

const styles = {
  container: {
    maxWidth: '1300px !important',
  },
  btnHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  )
}

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  )
}
