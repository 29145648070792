import {
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  bannerDesColor,
  borderLineColor,
  headingColor,
  homeSubTextColor,
  primaryColor,
} from 'configs/styles/muiThemes'
import moment from 'moment'

export default function ProductDescriptionSideBar({
  rewardPointPercent,
  minOrderAmount,
  immediateDiscount,
  shipmentArea,
  returnPolicy,
  notes,
  cashback,
  shipmentDistance
}) {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      border={'1px solid ' + borderLineColor}
      borderRadius={2.2}
      px={1.3}
      py={0.85}
      my={{ md: 3, xs: 0 }}
    >
      <Grid container spacing={1.2} py={1.6}>
        <Grid item>
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/offer_discount_info.png'
            alt={businessFullName + ' CostBo'}
            width='40px'
            height='40px'
          />
        </Grid>

        <Grid item xs>
          <Typography
            variant='subtitle1'
            fontSize='15px'
            fontWeight={600}
            color={headingColor}
          >
            Discount & Info
          </Typography>
          <Box mt={0.5}>
            {rewardPointPercent ? (
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  sx={{
                    display: 'list-item',
                  }}
                  primary={
                    <Typography
                      variant='subtitle1'
                      fontSize='14px'
                      color={bannerDesColor}
                      fontWeight={400}
                      lineHeight='26px'
                    >
                      {rewardPointPercent}% Cashback on all orders.
                    </Typography>
                  }
                />
              </ListItem>
            ) : null}
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                sx={{
                  display: 'list-item',
                }}
                primary={
                  <Typography
                    variant='subtitle1'
                    fontSize='14px'
                    color={bannerDesColor}
                    fontWeight={400}
                    lineHeight='26px'
                  >
                    ₹{minOrderAmount} Minimum purchase amount
                  </Typography>
                }
              />
            </ListItem>

            {immediateDiscount ? (
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  sx={{
                    display: 'list-item',
                  }}
                  primary={
                    <Typography
                      variant='subtitle1'
                      fontSize='14px'
                      color={bannerDesColor}
                      fontWeight={400}
                      lineHeight='26px'
                    >
                      Free shipping above ₹{immediateDiscount}
                    </Typography>
                  }
                />
              </ListItem>
            ) : null}

            <ListItem sx={{ py: 0 }}>
              <ListItemText
                sx={{
                  display: 'list-item',
                }}
                primary={ 
                  <Typography
                    variant='subtitle1'
                    fontSize='14px'
                    color={bannerDesColor}
                    fontWeight={400}
                    lineHeight='26px'
                  >
                    {shipmentDistance >= 3000 ? 'All India shipment available' : `Limited shipping - ${shipmentDistance} Km from Business warehouse`}
                  </Typography>
                }
              />
            </ListItem>
          </Box>
        </Grid>
      </Grid>

      <Divider light />

      <Grid container spacing={1.2} py={1.6}>
        <Grid item>
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/offer_available_cashback_product.png'
            alt={businessFullName + ' CostBo'}
            width='40px'
            height='40px'
          />
        </Grid>

        <Grid item xs>
          <Typography
            variant='subtitle1'
            fontSize='15px'
            fontWeight={600}
            color={headingColor}
          >
            Available Cashback
          </Typography>
          <Box mt={0.5}>
            {/* <Button sx={{ textDecoration: 'underline', px: 0 }}>
              Login To Check
            </Button> */}
            {cashback !== undefined && cashback?.details?.length > 0 ? (
              <Typography
                variant='body2'
                component='p'
                fontSize='15px'
                fontWeight={500}
                color={primaryColor}
                display='inline'
              >
                ₹{cashback?.details?.[0].availablePointValue?.toFixed(2)}{' '}
                {cashback?.details?.[0]?.expiryDate ? (
                  <Typography
                    variant='body2'
                    component='p'
                    fontSize='12px'
                    fontWeight={400}
                    color={homeSubTextColor}
                    display='inline'
                  >
                    {/* expires on {rewardPoints?.details?.[0]?.expiryDate} */}
                    expires on{' '}
                    {moment(cashback?.details?.[0]?.expiryDate).format(
                      'MMMM DD YYYY'
                    )}
                  </Typography>
                ) : null}
              </Typography>
            ) : (
              <Typography
                variant='body2'
                component='p'
                fontSize='15px'
                fontWeight={500}
                color={homeSubTextColor}
                display='inline'
              >
                ₹0
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      {returnPolicy ? (
        <>
          <Divider light />

          <Grid container spacing={1.2} py={1.6}>
            <Grid item>
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/Intro-3.png'
                alt={businessFullName + ' CostBo'}
                width='40px'
                height='40px'
              />
            </Grid>

            <Grid item xs>
              <Typography
                variant='subtitle1'
                fontSize='15px'
                fontWeight={600}
                color={headingColor}
              >
                Return Policy
              </Typography>
              <Box mt={0.5}>
                <Typography
                  variant='subtitle1'
                  fontSize='14px'
                  color={bannerDesColor}
                  fontWeight={400}
                  lineHeight='26px'
                >
                  {returnPolicy}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : null}

      {notes ? (
        <>
          <Divider light />

          <Grid container spacing={1.2} py={1.6}>
            <Grid item>
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/Intro-4.png'
                alt={businessFullName + ' CostBo'}
                width='40px'
                height='40px'
              />
            </Grid>

            <Grid item xs>
              <Typography
                variant='subtitle1'
                fontSize='15px'
                fontWeight={600}
                color={headingColor}
              >
                Special Info
              </Typography>
              <Box mt={0.5}>
                <Typography
                  variant='subtitle1'
                  fontSize='14px'
                  color={bannerDesColor}
                  fontWeight={400}
                  lineHeight='26px'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {notes}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Box>
  )
}
