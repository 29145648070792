import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import {
  facebookBGColor,
  linkedInBGColor,
  telegramBGColor,
  twitterBGColor,
  whatsappBGColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import TelegramIcon from '@mui/icons-material/Telegram'

function AffiliateShareMenu({
  url,
  quote,
  onClick,
  hideLabel,
  whatsappShareRef,
  faceBookShareRef,
  twitterShareRef,
  linkedInShareRef,
  telegramShareRef,
  isSpaceBetween
}) {
  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent={'flex-start'}
        gap={isSpaceBetween ? '28px' :'15px'}
        m='0 0 -12px 0 !important'
      >
        <SocialIconItem
          icon={<WhatsAppIcon />}
          label='WhatApp'
          hideLabel={hideLabel}
          onClick={() => onClick('whatsapp')}
          color={whatsappBGColor}
        />

        <SocialIconItem
          icon={<FacebookOutlinedIcon />}
          label='Facebook'
          hideLabel={hideLabel}
          onClick={() => onClick('facebook')}
          color={facebookBGColor}
        />

        <SocialIconItem
          icon={<LinkedInIcon />}
          label='LinkedIn'
          hideLabel={hideLabel}
          onClick={() => onClick('linkedIn')}
          color={linkedInBGColor}
        />

        <SocialIconItem
          icon={<TwitterIcon />}
          label='Twitter'
          hideLabel={hideLabel}
          onClick={() => onClick('twitter')}
          color={twitterBGColor}
        />

        <SocialIconItem
          icon={<TelegramIcon />}
          label='Telegram'
          hideLabel={hideLabel}
          onClick={() => onClick('telegram')}
          color={telegramBGColor}
        />
      </Box>

      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        mt={2}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          <WhatsappShareButton
            url={url}
            title={`${quote}\n`}
            ref={whatsappShareRef}
          ></WhatsappShareButton>
        </Box>

        <Box display='flex' flexDirection='column' alignItems='center'>
          <FacebookShareButton
            url={url}
            quote='hello'
            hashtag=''
            ref={faceBookShareRef}
          ></FacebookShareButton>
        </Box>

        <Box display='flex' flexDirection='column' alignItems='center'>
          <LinkedinShareButton
            url={url}
            title={quote}
            ref={linkedInShareRef}
          ></LinkedinShareButton>
        </Box>

        <Box display='flex' flexDirection='column' alignItems='center'>
          <TwitterShareButton
            url={`${quote}\n${url}`}
            ref={twitterShareRef}
          ></TwitterShareButton>
        </Box>

        <Box display='flex' flexDirection='column' alignItems='center'>
          <TelegramShareButton
            url={`${quote}\n${url}`}
            ref={telegramShareRef}
          ></TelegramShareButton>
        </Box>
      </Box>
    </>
  )
}

export default AffiliateShareMenu

const SocialIconItem = ({ icon, label, color, onClick, hideLabel }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      sx={{ cursor: 'pointer' }}
    >
      <Box sx={styles.iconStyle(color)} onClick={onClick}>
        {icon}
      </Box>

      {!hideLabel && (
        <Typography
          variant='body2'
          fontSize={12}
          fontWeight={500}
          textAlign='center'
        >
          {label}
        </Typography>
      )}
    </Box>
  )
}

const styles = {
  iconStyle: (bgColor) => ({
    width: '34px',
    height: '34px',
    backgroundColor: bgColor,
    borderRadius: '7px',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: whiteColor,
    marginBottom: '4px',
  }),
}
