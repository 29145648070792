import { Box, Button, Grid, Radio, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  borderLineColor,
  discountBgColor,
  errorTextColor,
  headingColor,
  offerTextColor,
  savingTextColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'
import { useDispatch } from 'react-redux'
import { validateCodeDetails } from 'redux/checkout/CheckoutSlice'
import { discountCouponDataHelper } from 'utils/checkoutHelpers'
import {
  resellerCard_CouponExpiredErr,
  resellerCard_EmptyCouponErr,
  resellerCard_InvalidCouponErr,
} from 'configs/Constants'
import OfferAppliedPopupModal from 'components/OfferPopupModal/OfferAppliedPopupModal'
import { allowOnlyEnglish } from 'utils/utilities'

function ResellerCouponCard({
  isSelected,
  onSelect,
  dealsCouponsDetails,
  rewardPoints,
  cartDetails,
  commercialDetails,
  discountAmount,
  setDiscountAmount,
  setAppliedDiscountDetails,
  // Loader
  setIsCouponValidating,
}) {
  const dispatch = useDispatch()
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [amount, setAmount] = useState('')
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  let businessFullName = sessionStorage.getItem('businessFullName')

  const [showSpecialOfferPopup, setShowSpecialOfferPopup] = useState(null)

  useEffect(() => {
    if (!isSelected) {
      setDiscountAmount(0)
      setAppliedDiscountDetails(null)
      setIsError(false)
      setIsSuccess(false)
      setErrorMessage('')
      setAmount('')
      setCode('')
    }
  }, [isSelected])

  useEffect(() => {
    autoApplyCoupon()

    return () => {}
  }, [])

  // Auto Apply Affiliate Coupon
  const autoApplyCoupon = () => {
    const couponData = JSON.parse(sessionStorage.getItem('specialOfferData'))

    if (couponData?.resellerCode) {
      setIsCouponValidating(true)
      setCode(couponData?.resellerCode)
      applyCoupon(couponData?.resellerCode)
      onSelect()
    } else {
      setIsCouponValidating(false)
    }
  }

  const applyCoupon = (resellerCode) => {
    setIsSuccess(false)
    setIsError(false)

    if (!code && !resellerCode) {
      setIsError(true)
      setErrorMessage(resellerCard_EmptyCouponErr)

      setDiscountAmount(0)
      setAppliedDiscountDetails(null)
      setAmount('')

      setIsCouponValidating(false)
      return
    }

    dispatch(validateCodeDetails(code || resellerCode)).then((res) => {
      if (!res?.payload?.reseller || res?.payload?.reseller?.resellerType == 'business') {
        setIsError(true)
        setErrorMessage(resellerCard_InvalidCouponErr)

        setDiscountAmount(0)
        setAppliedDiscountDetails(null)
        setAmount('')

        setIsCouponValidating(false)
        return
      }

      if (
        !moment().isBetween(
          res?.payload?.reseller?.codeValidityStart,
          res?.payload?.reseller?.codeValidityEnd
        )
      ) {
        setIsError(true)
        setErrorMessage(resellerCard_CouponExpiredErr)

        setDiscountAmount(0)
        setAppliedDiscountDetails(null)
        setAmount('')

        setIsCouponValidating(false)
        return
      }

      let discountAmt =
        cartDetails.orderTotalProductCost *
        (res.payload.reseller.discountPercentage / 100)
      setAmount(discountAmt.toFixed(2))
      setDiscountAmount(discountAmt.toFixed(2))
      setAppliedDiscountDetails(
        discountCouponDataHelper(res?.payload?.reseller, cartDetails)
      )

      if (resellerCode) {
        setShowSpecialOfferPopup({
          show: true,
          data: { ...res?.payload?.reseller, discountAmt },
        })
      }

      // console.log('cartResellerDetails', cartResellerDetails)
      setIsSuccess(true)
      setIsError(false)
      setIsCouponValidating(false)

      console.log('VALIDATE_RESPONSE', res)
    })
  }

  return (
    <Box sx={styles.discountCard(isSelected)}>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <Box display='flex' alignItems='center'>
            <Radio
              checked={isSelected}
              onChange={() => {
                onSelect()
              }}
              value='a'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
              size='small'
              sx={{ width: 35, height: 30 }}
            />
            <Typography
              variant='h6'
              component='p'
              fontSize={{ xs: 13.5, ms: 14 }}
              color={headingColor}
              display='inline'
            >
              Referral Coupons
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/discounts_coupon.svg'
            alt={businessFullName + ' CostBo'}
            width={{ xs: '48px', md: '46px' }}
            height={{ xs: '32px', md: '30px' }}
          />
        </Grid>
      </Grid>

      <Box mx={1.3} mt={{ xs: 1, md: 1.3 }} mb={{ xs: 0, md: 1.5 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={8} md={8.5}>
            <TextField
              value={code}
              variant='standard'
              onChange={({ target }) => {
                const finalValue = allowOnlyEnglish(target.value)
                setCode(finalValue?.toUpperCase())
              }}
              placeholder='Enter Refferal Code'
              sx={styles.inputBox(isSelected, isSuccess)}
              fullWidth
              disabled={!isSelected}
              InputProps={{
                disableUnderline: true,
                // color: 'red',
              }}
            />
            {/* <Box
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={isSelected ? handleClick : () => {}}
              sx={styles.inputBox(isSelected)}
              fullWidth
              disabled={!isSelected}
              disableRipple
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              // sx={styles.dropdownBtn}
              // endIcon={<KeyboardArrowDownOutlined />}
            >
              <Box>{text}</Box>

              <ArrowDropDownRoundedIcon sx={{ fontSize: '28px' }} />
            </Box>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              variant='selectedMenu'
              PaperProps={{
                style: {
                  width: 250,
                  height: 250,
                },
              }}
            >
              {dealsCouponsDetails?.map((item, i) => {
                if (!moment().isBefore(item?.codeValidityEnd)) return
                return (
                  <MenuItem onClick={handleClose} value={item.resellerCode}>
                    {item.resellerCode} - {item.discountPercentage}% OFF
                  </MenuItem>
                )
              })}
            </Menu> */}
          </Grid>
          <Grid item xs={3}>
            {isSelected && (
              <Button
                sx={styles.actionBtn}
                variant='contained'
                disableElevation
                onClick={() => applyCoupon()}
              >
                Apply
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      {isSuccess ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={{ xs: '11px', md: '12px' }}
          color={savingTextColor}
          display='inline'
          mx={1.4}
        >
          Congrats! Offer applied ₹{discountAmount}
          {/* {JSON.stringify(cartResellerDetails)} */}
        </Typography>
      ) : null}
      {/* Error and Success Message */}
      {isError ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={{ xs: '11px', md: '12px' }}
          color={errorTextColor}
          display='inline'
          mx={1.4}
        >
          {errorMessage}
        </Typography>
      ) : null}
      {!isSuccess && !isError && (
        <Typography
          variant='body1'
          fontSize={{ xs: 10, md: 11 }}
          lineHeight='16px'
          component='p'
          px={1.7}
          mt={{ xs: 0.2, md: 0 }}
          color={headingColor}
        >
          Have you received a referral link with discounts? Enter the code here
        </Typography>
      )}

      <OfferAppliedPopupModal
        open={showSpecialOfferPopup?.show}
        onClose={() => {
          setShowSpecialOfferPopup(null)
        }}
        data={showSpecialOfferPopup?.data}
      />
    </Box>
  )
}

export default ResellerCouponCard

const styles = {
  discountCard: (isSelected) => ({
    backgroundColor: isSelected ? discountBgColor : whiteColor,
    borderRadius: '6px',
    border: '1px solid' + borderLineColor,
    px: 0.5,
    py: 1,
    height: { xs: '120px', md: '125px' },
  }),

  inputBox: (isSelected, isSuccess) => ({
    height: { xs: '30px', md: '30px' },
    fontSize: { xs: '12px', md: '13px' },
    fontWeight: 400,
    color: isSelected ? headingColor : offerTextColor,
    border: '1.5px dashed #299956',
    backgroundColor: whiteColor,
    borderRadius: { xs: '4px', md: '6px' },
    pt: { xs: 0.2, md: 0.5 },
    px: 1,
    '&:hover': {
      backgroundColor: whiteColor,
    },
  }),

  actionBtn: {
    height: { xs: '33px', md: '36px' },
    fontSize: { xs: '13px', md: '14px' },
    fontWeight: 600,
    color: savingTextColor,
    backgroundColor: whiteColor,
    px: { xs: '20px', md: '28px' },
    border: '1px solid ' + savingTextColor,
    borderRadius: { xs: '4px', md: '6px' },
    ml: '10px',
    '&:hover': {
      backgroundColor: savingTextColor,
      color: whiteColor,
    },
  },
}
