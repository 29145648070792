import { BUSINESS_INFO_URL, METRIC_URL } from 'services/constant'
import { getApiInstance } from '../../ApiInstance'

export const getBuyAgainData = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()
  try {
    const response = await instance(
      `cart-query-side/api/v2/cart/consumer/buyAgain-products?pageNo=1`,
      {
        headers: {
          businessId: businessId,
        },
      }
    )
    console.log('BUY_DATA', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getMetricData = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()
  try {
    const response = await instance(METRIC_URL, {
      headers: {
        businessId: businessId,
      },
    })
    // console.log('BUY_DATA', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}


export const getBusinessDataApi = async (data) => {
  try {
    const instance = getApiInstance()
    const response = await instance.get(`${BUSINESS_INFO_URL}?params=${data}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
