import { Box, Typography } from '@mui/material'
import {
  headingColor,
  shareBGColor,
  topbarColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import { useEffect, useState } from 'react'
import SocialShareVertical from './SocialShareVertical'
import ShareIcon from '@mui/icons-material/Share'
import { addTimelineShare } from 'services/api_calls/header/HeaderApi'
import AffiliateShareModal from 'pages/Affiliates/AffiliateShareModal'
import { useSelector } from 'react-redux'

export default function ShareFollowCard({
  onFollowClick,
  status,
  loggedIn,
  businessAffiliateData,
  rellerProfileData,
  showShareOptions,
  setShowShareOptions,
}) {
  const [social, setSocial] = useState(false)
  let businessFullName = sessionStorage.getItem('businessFullName')
  // const [socialToolTip, setSocialToolTip] = useState(false)
  // const [followToolTip, setFollowToolTip] = useState(false)
  const [windowurl, setWindowurl] = useState('')

  const { commercialDetails } = useSelector((state) => state.product)

  useEffect(() => {
    const url = window.location.href
    setWindowurl(url)
  }, [])

  return (
    <>
      <Box
        //   sx={{ position: 'relative' }}
        display={{
          xs: 'flex',
          width: { xs: '90vw', md: '100%' },
        }}
        alignItems='center'
        justifyContent={'center'}
        gap={1.5}
        pb={1}
        pt={1}
        position='relative'
        // onMouseLeave={() => setSocial(false)}
      >
        <Box
          display='flex'
          alignItems='center'
          sx={{
            background: topbarColor,
            p: '3px 15px',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={() => onFollowClick(status == 1 ? 0 : 1)}
        >
          <Box
            sx={{
              position: 'relative',
              // width: '36px',
              // height: '36px',
              borderRadius: '50%',
              cursor: 'pointer',
              color: whiteColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {status == 1 ? (
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/follow.svg'
                sx={{ width: '17px' }}
                alt={businessFullName + ' CostBo'}
              />
            ) : (
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/unfollow.svg'
                sx={{ width: '18px' }}
                alt={businessFullName + ' CostBo'}
              />
            )}
          </Box>
          <Typography fontSize={12} fontWeight={500} color={whiteColor} ml={1}>
            {status == 1 ? 'Following' : 'Follow'}
          </Typography>
        </Box>
        <Box
          onClick={() => {
            setShowShareOptions(true)
          }}
          // onMouseLeave={() => setSocial(false)}
          // onClick={() => setSocial(true)}
          display='flex'
          alignItems='center'
          position='relative'
          sx={{
            background: shareBGColor,
            cursor: 'pointer',
            p: '3px 15px',
            borderRadius: '4px',
          }}
        >
          <Box
            sx={{
              // width: '36px',
              // height: '36px',
              borderRadius: '50%',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ShareIcon sx={{ fontSize: 17 }} />
          </Box>
          <Typography
            fontSize={12}
            fontWeight={500}
            color={headingColor}
            ml={1}
          >
            Share
          </Typography>
        </Box>

        {showShareOptions ? (
          <Box position='absolute' bottom='-50px' zIndex={10}>
            {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
            businessAffiliateData?.businessResellers?.length > 0 &&
            rellerProfileData ? (
              <AffiliateShareModal
                // productDetails={openShareModal?.data?.productDetails}
                commissionDetails={businessAffiliateData}
                rellerProfileData={{ details: rellerProfileData }}
                open={showShareOptions}
                onClose={() => {
                  setShowShareOptions(false)
                }}
                influencerShareType='business'
              />
            ) : (
              <SocialShareVertical
                // url={`${siteBaseURL}${businessName}`}
                url={`${windowurl}`}
                quote=''
                back={() => {
                  setShowShareOptions(false)
                  if (loggedIn)
                    addTimelineShare({
                      shareType: 'timelineshare',
                      latitude: 77.65563062,
                      longitude: 12.92352506,
                    })
                }}
              />
            )}
          </Box>
        ) : null}
      </Box>
    </>
  )
}
