import {
  Box,
  Container,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Skeleton,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Suspense, useEffect, useRef, useState } from 'react'
import { StarRounded } from '@mui/icons-material'
import Slider from 'react-slick/lib/slider'
import {
  bannerDesColor,
  bannerListColor,
  bannerstarColor,
  headingColor,
  homeSubTextColor,
  primaryColor,
  topbarColor,
  whiteColor,
} from '../configs/styles/muiThemes'
import ModalCard from './ModalCard'
import ProductGalleryModal from 'pages/SingleProduct/ProductGalleryModal'
import {
  banner_Cashback,
  banner_Followers,
  banner_LoginText,
  banner_Reviews,
  banner_Views,
  banner_Welcome,
  noProductImgUrl,
} from '../configs/Constants'
import ShareFollowCard from './ShareFollowCard'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSocialActivityOfBusiness,
  updateFollowDetails,
} from 'redux/header/headerSlice'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

function BannerMui(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isModal, setIsModal] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const settings = {
    dots: matches ? true : false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    arrows: false,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  }

  const [activeImg, setActiveImg] = useState(
    props?.businessDetails?.[0]?.documentsURL?.[0]?.docURL
  )

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  )

  const businessName = sessionStorage.getItem('businessName')
  let businessFullName = sessionStorage.getItem('businessFullName')

  useEffect(() => {
    setActiveImg(props?.businessDetails?.[0]?.documentsURL?.[0]?.docURL)
    businessFullName = sessionStorage.getItem('businessFullName')
  }, [props])

  const handleModalClose = () => {
    setIsModal(false)
  }

  const logoLoadingRef = useRef(null)
  const logoImgRef = useRef(null)

  const bannerOneLoadingRef = useRef(null)
  const bannerOneRef = useRef(null)

  const bannerTwoLoadingRef = useRef(null)
  const bannerTwoRef = useRef(null)

  // Affiliate Social Share
  const [showShareOptions, setShowShareOptions] = useState(false)

  const handleCopyLink = (copyLink) => {
    const BUSINESS_LINK = copyLink
      ? copyLink
      : props?.commercialDetails?.info?.costboPowered?.domainURL ||
        `https://shop.costbo.com/${props?.businessDetails?.[0]?.webURL}`
    navigator.clipboard.writeText(BUSINESS_LINK)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  return (
    <Suspense fallback={<p></p>}>
      <Box sx={styles.bannerContainer}>
        <ModalCard handleClose={handleModalClose} open={isModal} width='80%'>
          <ProductGalleryModal
            title={props?.businessDetails?.[0]?.businessName}
            productImages={props?.businessDetails?.[0]?.documentsURL}
            activeImg={activeImg}
            onActiveClick={(data) => setActiveImg(data)}
          />
        </ModalCard>

        <Container sx={{ maxWidth: '1300px !important' }}>
          <Grid
            container
            py={2}
            columnSpacing={{ md: 5, xs: 1 }}
            rowSpacing={1}
            alignItems='center'
          >
            <Grid item md={5} xs={12}>
              {/* <Box
                display={{ md: 'flex', xs: 'none' }}
                justifyContent='center'
                pb={2}
              >
                <Box ref={logoLoadingRef}>
                  <Skeleton
                    variant='rectangular'
                    width='80px'
                    height='80px'
                    borderRadius='6px'
                  />
                </Box>

                <Box
                  ref={logoImgRef}
                  src={props?.businessDetails?.[0]?.logoURL}
                  // src=""
                  onLoad={() => {
                    logoLoadingRef.current.style.display = 'none'
                    logoImgRef.current.style.width = '80px'
                    logoImgRef.current.style.height = '80px'
                  }}
                  component='img'
                  alt={businessFullName + ' CostBo'}
                  style={{
                    width: '1px',
                    height: '1px',
                    borderRadius: '6px',
                  }}
                  textAlign='center'
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = noProductImgUrl
                  }}
                />
              </Box> */}

              <Typography
                variant='h5'
                component='h6'
                color={headingColor}
                textAlign='center'
                fontSize={{ md: '15px', xs: '14px' }}
                fontWeight={500}
              >
                {banner_Welcome}
              </Typography>

              <Typography
                variant='h1'
                marginY='12px !important'
                textAlign='center'
                fontSize={{ md: '23px', xs: '20px' }}
                color={headingColor}
              >
                {props?.businessDetails?.[0]?.businessName}
              </Typography>

              <Typography
                variant='body1'
                color={bannerDesColor}
                textAlign='center'
                height='40px'
                overflow='hidden'
              >
                {props?.businessDetails?.[0]?.mainProducts
                  ?.map((e) => e.productName)
                  .join(', ')}
              </Typography>

              <Grid
                container
                justifyContent='center'
                sx={{ mt: 2, mb: { md: 2, xs: 0 } }}
                flexDirection='column'
              >
                <Box display='flex' justifyContent='center' alignItems='center'>
                  {props?.summary?.average ? (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <StarRounded
                          sx={{
                            color: bannerstarColor,
                            fontSize: '20px',
                            mb: 0.3,
                          }}
                        />
                        <Typography
                          variant='body1'
                          color={bannerListColor}
                          fontWeight={500}
                        >
                          {props?.summary?.average}
                        </Typography>
                      </Box>
                      <Typography variant='body1' sx={styles.dotDivider}>
                        &#183;
                      </Typography>
                    </>
                  ) : null}

                  <Typography
                    variant='body1'
                    component={Link}
                    to={`/${businessName}/review`}
                    color={bannerListColor}
                    fontWeight={500}
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    {props?.businessDetails?.[0]?.reviewCount} {banner_Reviews}
                  </Typography>

                  <Typography variant='body1' sx={styles.dotDivider}>
                    &#183;
                  </Typography>
                  <Typography
                    variant='body1'
                    color={bannerListColor}
                    fontWeight={500}
                  >
                    {props.social ? props.social.totalFollower : 0}{' '}
                    {banner_Followers}
                  </Typography>
                  <Typography variant='body1' sx={styles.dotDivider}>
                    &#183;
                  </Typography>
                  <Typography
                    variant='body1'
                    color={bannerListColor}
                    fontWeight={500}
                  >
                    {props.total ? props.total.totalView : 0} {banner_Views}
                  </Typography>
                </Box>

                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems={'center'}
                  my={2}
                >
                  <Typography
                    fontSize={13}
                    lineHeight={1.5}
                    fontWeight={600}
                    display='inline'
                    mr={1}
                    color={headingColor}
                  >
                    {banner_Cashback}
                  </Typography>
                  {!props?.rewardpoints ? (
                    <Typography
                      fontSize={13}
                      lineHeight={1.5}
                      fontWeight={500}
                      display='inline'
                      color={primaryColor}
                      // ml={1}
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        props.onLogin()
                      }}
                    >
                      {banner_LoginText}
                    </Typography>
                  ) : null}
                  {props.rewardpoints ? (
                    props.rewardpoints !== undefined &&
                    props.rewardpoints?.details?.length > 0 ? (
                      <Typography
                        variant='body2'
                        component='p'
                        fontSize='13px'
                        fontWeight={600}
                        color={primaryColor}
                        display='inline'
                      >
                        ₹
                        {props.rewardpoints?.details?.[0].availablePointValue?.toFixed(
                          2
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        variant='body2'
                        component='p'
                        fontSize='13px'
                        fontWeight={500}
                        color={homeSubTextColor}
                        display='inline'
                      >
                        ₹0
                      </Typography>
                    )
                  ) : null}
                </Box>

                {props?.commercialDetails?.info?.costboPowered
                  ?.affiliateEnabled &&
                  businessAffiliateData?.businessResellers?.length >
                    0 && (
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems={'center'}
                        mb={2}
                      >
                        <Typography
                          fontSize={13}
                          lineHeight={1.5}
                          fontWeight={600}
                          display='inline'
                          mr={1}
                          color={headingColor}
                        >
                          Brand Affiliate Program
                        </Typography>

                        <Typography
                          fontSize={13}
                          lineHeight={1.5}
                          fontWeight={500}
                          display='inline'
                          color={primaryColor}
                          // ml={1}
                          sx={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() => {
                            navigate(
                              rellerProfileData?.details
                                ? `/${businessName}/affiliateHome`
                                : `/${businessName}/affiliates`
                            )
                          }}
                        >
                          Share & Earn
                        </Typography>
                      </Box>
                    )}

                <Box
                  display='flex'
                  justifyContent='center'
                  gap={1}
                  mb={1}
                  alignItems='center'
                >
                  <Typography
                    component='a'
                    href={
                      props?.commercialDetails?.info?.costboPowered
                        ?.domainURL ||
                      `https://shop.costbo.com/${props?.businessDetails?.[0]?.webURL}`
                    }
                    target='_blank'
                    textAlign='center'
                    fontSize={{ md: 13, xs: 11 }}
                    fontWeight={500}
                    sx={{
                      color: primaryColor,
                    }}
                  >
                    {props?.commercialDetails?.info?.costboPowered?.domainURL ||
                      `https://shop.costbo.com/${props?.businessDetails?.[0]?.webURL}`}
                  </Typography>
                  {isCopied ? (
                    <>
                      <TaskAltIcon
                        sx={{
                          color: primaryColor,
                          fontSize: { md: 18, xs: 16 },
                        }}
                      />
                      <Box
                        component='span'
                        sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: primaryColor,
                          ml: '-5px',
                          display: { md: 'inline', xs: 'none' },
                        }}
                      >
                        Copied
                      </Box>
                    </>
                  ) : (
                    <ContentCopyIcon
                      onClick={() => handleCopyLink()}
                      sx={{
                        cursor: 'pointer',
                        fontSize: { md: 18, xs: 16 },
                      }}
                    />
                  )}
                </Box>

                <Box display={{ md: 'block', xs: 'none' }}>
                  <ShareFollowCard
                    onFollowClick={(data) => {
                      if (!props?.isLoggedIn) {
                        props.onLogin()
                        return
                      }
                      dispatch(updateFollowDetails(data)).then((res) => {
                        dispatch(getSocialActivityOfBusiness())
                      })
                    }}
                    status={props?.social?.status}
                    loggedIn={props?.isLoggedIn}
                    businessAffiliateData={businessAffiliateData}
                    rellerProfileData={rellerProfileData?.details}
                    showShareOptions={showShareOptions}
                    setShowShareOptions={setShowShareOptions}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: {
                  md: 'block',
                  xs: props.bannerHide ? 'none' : 'block',
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={7.8} xs={12}>
                  {/* <Box
                    ref={bannerSliderLoadingRef}
                    width='100%'
                    height={{ md: '330px', xs: 'calc(100vw - 25vw)' }}
                  >
                    <Skeleton
                      variant='rectangular'
                      sx={{
                        width: '100%',
                        height: { md: '330px', xs: 'calc(100vw - 25vw)' },
                        borderRadius: { md: '16px 0 0 16px', xs: '16px' },
                      }}
                    />
                  </Box> */}

                  <Slider {...settings}>
                    {props?.businessDetails?.[0]?.documentsURL?.map(
                      (item, index) => {
                        return (
                          <Box
                            component='img'
                            alt={businessFullName + ' CostBo'}
                            src={item.docURL}
                            sx={{
                              cursor: 'pointer',
                              objectFit: 'contain',
                              backgroundColor: whiteColor,
                            }}
                            // onLoad={() => {
                            //   bannerSliderLoadingRef.current.style.display =
                            //     'none'
                            //   bannerSliderRef.current.style.width = matches ? '530px' : '100%'
                            //   bannerSliderRef.current.style.height = matches ? '330px' : 'calc(100vw - 25vw)'
                            // }}
                            onClick={() => {
                              setActiveImg(item.docURL)
                              setIsModal(true)
                            }}
                            width={{ md: '530px', xs: '100%' }}
                            height={{ md: '330px', xs: 'calc(100vw - 25vw)' }}
                            borderRadius={{ md: '16px 0 0 16px', xs: '16px' }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src = noProductImgUrl
                            }}
                          />
                        )
                      }
                    )}
                  </Slider>
                </Grid>

                {matches ? (
                  <Grid item md={4.2} xs={12}>
                    {props?.businessDetails?.[0]?.documentsURL?.length > 0 && (
                      <Stack spacing={2}>
                        <Box ref={bannerOneLoadingRef}>
                          <Skeleton
                            variant='rectangular'
                            width='240px'
                            height='156px'
                            sx={{ borderRadius: '0 16px 16px 0' }}
                          />
                        </Box>

                        <Box
                          ref={bannerOneRef}
                          component='img'
                          alt={businessFullName + ' CostBo'}
                          src={
                            props?.businessDetails?.[0]?.documentsURL?.[0]
                              ?.docURL
                          }
                          width='1px'
                          height='1px'
                          onLoad={() => {
                            bannerOneLoadingRef.current.style.display = 'none'
                            bannerOneRef.current.style.width = '240px'
                            bannerOneRef.current.style.height = '156px'
                          }}
                          onClick={() => {
                            setActiveImg(
                              props?.businessDetails?.[0]?.documentsURL?.[0]
                                ?.docURL
                            )
                            setIsModal(true)
                          }}
                          sx={{
                            cursor: 'pointer',
                            backgroundColor: whiteColor,
                            objectFit: 'contain',
                          }}
                          borderRadius='0 16px 16px 0'
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.src = noProductImgUrl
                          }}
                          marginTop='0px !important'
                        />

                        {props?.businessDetails?.[0]?.documentsURL?.[1] ? (
                          <>
                            <Box ref={bannerTwoLoadingRef}>
                              <Skeleton
                                variant='rectangular'
                                width='240px'
                                height='156px'
                                sx={{ borderRadius: '0 16px 16px 0' }}
                              />
                            </Box>

                            <Box
                              ref={bannerTwoRef}
                              component='img'
                              alt={businessFullName + ' CostBo'}
                              src={
                                props.businessDetails?.[0]?.documentsURL?.[1]
                                  ?.docURL
                              }
                              onLoad={() => {
                                bannerTwoLoadingRef.current.style.display =
                                  'none'
                                bannerTwoRef.current.style.width = '240px'
                                bannerTwoRef.current.style.height = '156px'
                              }}
                              onClick={() => {
                                setActiveImg(
                                  props.businessDetails?.[0]?.documentsURL?.[1]
                                    ?.docURL
                                )
                                setIsModal(true)
                              }}
                              sx={{
                                cursor: 'pointer',
                                backgroundColor: whiteColor,
                                objectFit: 'contain',
                              }}
                              // style={{
                              //   aspectRatio: 4/3
                              // }}
                              width='1px'
                              height='1px'
                              borderRadius='0 16px 16px 0'
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null // prevents looping
                                currentTarget.src = noProductImgUrl
                              }}
                            />
                          </>
                        ) : null}
                      </Stack>
                    )}
                    {/* {props.businessDetails.businessInfo[0].documentsURL.length ===
                    1 && (
                    <Stack spacing={1.5}>
                      <Box
                        component='img'
                        alt={businessFullName + ' CostBo'}
                        src={
                          props.businessDetails.businessInfo[0].documentsURL[0]
                            .docURL
                        }
                        style={{
                          aspectRatio: 4 / 3,
                        }}
                        width='100%'
                        height='100%'
                        borderRadius='0 16px 16px 0'
                      />
                    </Stack>
                  )} */}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            <Grid
              item
              sx={{
                display: { md: 'none', xs: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <ShareFollowCard
                onFollowClick={(data) => {
                  if (!props?.isLoggedIn) {
                    props.onLogin()
                    return
                  }
                  dispatch(updateFollowDetails(data)).then((res) => {
                    dispatch(getSocialActivityOfBusiness())
                  })
                }}
                status={props?.social?.status}
                businessAffiliateData={businessAffiliateData}
                rellerProfileData={rellerProfileData?.details}
                showShareOptions={showShareOptions}
                setShowShareOptions={setShowShareOptions}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Suspense>
  )
}

export default BannerMui

const styles = {
  bannerContainer: {
    position: 'relative',
    background: 'rgb(245, 245, 245)',
    py: { md: 3 },
  },

  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  dotDivider: {
    color: bannerListColor,
    mx: 1,
    bottom: 0.5,
  },
}
