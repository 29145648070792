import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCoupons } from '../redux/dealsCoupons/dealsCouponsSlice'
import DiscountNew from './DealsCoupons/DiscountNew'
import EmptyCard from '../components/EmptyCard'
import { dealsEmptyImgUrl, dealsEmptyMessage } from '../configs/Constants'
import FooterMui from '../components/FooterMui'
import { Box, Container, Typography } from '@mui/material'
import TopComponent from './TopComponent'
import { headingColor } from 'configs/styles/muiThemes'
import Loader from 'components/Loader'
import { useParams } from 'react-router-dom'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import { checkCartOrderProducts } from 'redux/cart/CartSlice'
import {
  getCategoryDataFinder,
  getDataForSideBar,
} from 'redux/subCatergory/subCategorySlice'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { getCartDetails } from 'redux/addToCart/AddToCartSlice'
import { getUserName } from 'utils/authHelpers'

function DealsCoupons() {
  const { businessName } = useParams()
  const businessIdData = sessionStorage.getItem('businessId')
  const { commercialDetails } = useSelector((state) => state.product)
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  const { cartDetails } = useSelector((state) => state.cart)
  const dispatch = useDispatch()
  const { dealsCouponsDetails, loading } = useSelector((state) => state.coupons)
  const { businessId } = useSelector((state) => state.rootSlice)

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem('businessName')
    if (businessName !== localBusinessName) {
      sessionStorage.setItem('cartData', 'null')
    }

    dispatch(getBusinessIdDetails(businessName))
    sessionStorage.setItem('businessName', businessName)
  }

  useEffect(() => {
    getBusinessData()
  }, [businessName])

  useEffect(() => {
    if (businessIdData !== null) {
      dispatch(getDataForSideBar())
      dispatch(getCategoryDataFinder())
      if (!commercialDetails) {
        dispatch(getCommercialDetails())
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId))
        }
      }
    }

    return () => {
      // dispatch(clearSearchData())
    }
  }, [businessId])

  useEffect(() => {
    if (businessId?.validate) {
      console.log('Valid_BusinessID')
      dispatch(getCoupons())
    }
  }, [businessId])

  return (
    <div>
      <TopComponent value={'coupons'} bannerHide />
      <Box
        px={10}
        size={25}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        {loading === true && <Loader />}
      </Box>
      {loading === false &&
        dealsCouponsDetails !== null &&
        dealsCouponsDetails.length > 0 && (
          <>
            <DiscountNew dealData={dealsCouponsDetails} />
            <FooterMui />
          </>
        )}
      {loading === false &&
        dealsCouponsDetails !== null &&
        dealsCouponsDetails.length === 0 && (
          <>
            <Container sx={{ maxWidth: '1300px !important' }}>
              <Typography
                variant='h2'
                fontSize='19px'
                color={headingColor}
                pt={3.5}
              >
                Deals & Coupons
              </Typography>
              <EmptyCard
                message={dealsEmptyMessage}
                imageUrl={dealsEmptyImgUrl}
              />
            </Container>
            <FooterMui />
          </>
        )}
    </div>
  )
}
export default DealsCoupons
