import { Box, Button, Typography } from '@mui/material'
import {
  errorTextColor,
  headingColor,
  loginPrimary,
  secondaryColor,
  stepperIconColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import { Link } from 'react-router-dom'
import {
  inputError_EnterValidKey,
  resetPassword_EnterKey,
  resetPassword_EnterPassword,
  resetPassword_SubTitle,
} from 'configs/Constants'
import AuthTextBox from 'components/AuthTextBox'
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { useState } from 'react'
import AuthPasswordBox from 'components/AuthPasswordBox'
import { validateKey, validatePasswordSave } from 'utils/validationUtils'
import { finishSetup, getLoginDetials } from 'redux/authRedux/authSlice'
import { useDispatch } from 'react-redux'

function ResetPasswordNew({ email, onForgotpasswordSuccess, phone }) {
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(true)
  const [key, setKey] = useState('')
  const [password, setPassword] = useState('')
  const [passwordErrMsg, setPasswordErrMsg] = useState('')
  const [passwordErr, setPasswordErr] = useState(false)
  const [keyErrMsg, setKeyErrMsg] = useState('')
  const [keyErr, setKeyErr] = useState(false)

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const symbol = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  const numbers = /[0-9]/
  const capital = /^(?=.*[A-Z])/
  const length = /^(?=.{8,})/

  const maskMail = (mail) => {
    let str = mail
    str = str.split('')
    let finalArr = []
    let len = str.indexOf('@')
    str.forEach((item, pos) => {
      pos >= 2 && pos <= len - 3 ? finalArr.push('*') : finalArr.push(str[pos])
    })
    return finalArr.join('')
  }

  const handleSubmit = () => {
    console.log(key, password)
    setPasswordErrMsg('')
    setPasswordErr(false)
    setKeyErrMsg('')
    setErrorMsg(false)
    setLoading(true)

    const keyMsg = validateKey(key)
    const passMsg = validatePasswordSave(password)

    console.log(passMsg)
    if (keyMsg != '') {
      setKeyErr(true)
      setKeyErrMsg(keyMsg)
      setLoading(false)
      return
    }

    if (passMsg != '') {
      setPasswordErrMsg(passMsg)
      setPasswordErr(true)
      setLoading(false)
      return
    }
    resetPassword()
  }
  const resetPassword = async () => {
    return dispatch(finishSetup({ key, password })).then((response) => {
      console.log(response)
      if (response.payload) {
        if (response.payload.status === 200) {
          const data = {
            phone: phone,
            password: password,
          }
          loginDetails(data)
        }
      } else {
        console.log()
        setErrorMsg(true)
        setLoading(false)
      }
    })
  }

  const loginDetails = async (data) => {
    return dispatch(getLoginDetials(data)).then((response) => {
      console.log(response)
      if (response.payload) {
        if (response.payload.status === 200) {
          onForgotpasswordSuccess()
          setLoading(false)
        }
      } else {
        setErrorMsg(true)
        setLoading(false)
      }
    })
  }

  // function validate() {
  //   setPasswordErrMsg('')
  //   setKeyErrMsg('')

  //   const keyMsg = validateKey(key)
  //   const passMsg = validatePasswordSave(password)

  //   setPasswordErr(passMsg)
  //   setKeyErrMsg(keyMsg)
  //   // setErrorMsg(false)
  //   // setErrorMsgForPassword(false)
  //   // const temp = { ...errors }
  //   // console.log(fieldValues)

  //   // if ('key' in fieldValues) {
  //   //   temp.key = validateKey(fieldValues.key)
  //   //   console.log(temp.key)
  //   // }

  //   // if ('password' in fieldValues) {
  //   //   temp.password = validatePasswordSave(fieldValues.password)
  //   //   console.log(temp.password)
  //   // }
  //   // console.log(fieldValues)
  //   // console.log(temp)

  //   // setErrors({
  //   //   ...temp,
  //   // })
  //   // if (fieldValues === values)
  //   //   return Object.values(temp).every((x) => x === '')
  // }
  return (
    <Box px={2} pt={2}>
      <Typography
        variant='h5'
        component='h3'
        color={headingColor}
        fontSize={{ xs: 15 }}
        textAlign='center'
        lineHeight={1.5}
        pb={{ xs: 0, md: 0.9 }}
      >
        Reset Password
      </Typography>
      <Typography
        variant='body1'
        fontSize={{ xs: 13, md: 13 }}
        fontWeight={400}
        color={stepperIconColor}
        textAlign='start'
        mt={{ xs: 0.3, md: 1 }}
      >
        {resetPassword_SubTitle}
      </Typography>
      <Typography
        variant='body1'
        fontSize={{ xs: 13, md: 13 }}
        fontWeight={500}
        color={headingColor}
        textAlign='start'
        mb='10px !important'
      >
        {maskMail(email)}
      </Typography>
      <AuthTextBox
        value={key}
        placeholder={resetPassword_EnterKey}
        icon={<PasswordRoundedIcon fontSize='small' />}
        isError={keyErr}
        errorMsg={keyErrMsg}
        onChange={(e) => {
          if (keyErr) setKeyErr(false)
          if (errorMsg) setErrorMsg(false)
          setKey(e.target.value)
        }}
        name='key'
        autoFocus={true}
      />
      {errorMsg && (
        <Box>
          <Typography
            variant='subtitle1'
            component='p'
            fontSize={13}
            color={errorTextColor}
          >
            {inputError_EnterValidKey}
          </Typography>
        </Box>
      )}

      <AuthPasswordBox
        value={password}
        onChange={(e) => {
          if (passwordErr) setPasswordErr(false)
          const value = e.target.value.replace(/[ ]/g, '')
          setPassword(value)
        }}
        name='password'
        placeholder={resetPassword_EnterPassword}
        isError={passwordErr}
        errorMsg={passwordErrMsg}
        lower={length.test(password)}
        symbol={symbol.test(password.trim())}
        upper={capital.test(password)}
        numberFormat={numbers.test(password)}
        icon={
          <VisibilityRoundedIcon
            onClick={() => {
              setShowPassword(!showPassword)
            }}
            fontSize='small'
            sx={{ color: !showPassword ? loginPrimary : '' }}
          />
        }
        inputType={!showPassword ? 'text' : 'password'}
        isPasswordError={passwordErr}
        maxLength={25}
      />
      <Box sx={{ mt: 2 }}>
        <Typography
          variant='body1'
          component='p'
          color={stepperIconColor}
          fontSize={12}
          display='inline'
        >
          By continuing, you agree to our{' '}
          <Typography
            color={loginPrimary}
            display='inline'
            fontSize={12}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/termsAndConditions`}
          >
            Terms of Use
          </Typography>{' '}
          &{' '}
          <Typography
            color={loginPrimary}
            display='inline'
            fontSize={12}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/privacyPolicy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box>
      <Button
        sx={{
          ...styles.actionBtn(),
          border: loading ? 'none' : `1px solid ${secondaryColor}`,
        }}
        variant='contained'
        fullWidth
        disableElevation
        size='small'
        type='button'
        onClick={handleSubmit}
        disabled={loading}
      >
        Reset & Login
      </Button>
    </Box>
  )
}

export default ResetPasswordNew

const styles = {
  actionBtn: (outline) => ({
    fontSize: '15px',
    fontWeight: 500,
    color: outline ? secondaryColor : whiteColor,
    p: '3px 20px',
    mt: '15px !important',
    border: '1px solid ' + secondaryColor,
    backgroundColor: outline ? whiteColor : secondaryColor,
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: outline ? secondaryColor : whiteColor,
      color: outline ? whiteColor : secondaryColor,
    },
    letterSpacing: '0.5px',
  }),
}
