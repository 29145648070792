import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  categoryLabelColor,
  categoryLabelSpanColor,
  headingColor,
  primaryColor,
  topbarColor,
} from '../../configs/styles/muiThemes'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import AffiliateShareModal from 'pages/Affiliates/AffiliateShareModal'
import SocialShareVertical from 'components/SocialShareVertical'
import IosShareIcon from '@mui/icons-material/IosShare'

export default function ProductDescriptionHeader({
  title,
  subCategory,
  subCategory2,
  copyLink,
  isCopied,
  handleChatClick,
  businessAffiliateData,
  rellerProfileData,
  productDetails,
  commercialDetails,
}) {
  const [showShareOptions, setShowShareOptions] = useState(false)
  const [windowurl, setWindowurl] = useState('')

  useEffect(() => {
    const url = window.location.href
    setWindowurl(url)
  }, [])

  return (
    <Box position='relative'>
      <Typography
        variant='h6'
        fontSize={14}
        fontWeight={500}
        color={categoryLabelColor}
        display='inline'
      >
        Category:{' '}
      </Typography>
      <Typography
        variant='h6'
        fontSize={14}
        fontWeight={600}
        color={categoryLabelSpanColor}
        display='inline'
      >
        {subCategory}
        {subCategory2 ? (
          <>
            <NavigateNextRoundedIcon fontSize='small' sx={{ mb: '-5px' }} />{' '}
            {subCategory2}
          </>
        ) : null}
      </Typography>
      <Typography
        variant='h4'
        color={headingColor}
        mt={{ md: 1.5, xs: 1 }}
        lineHeight={1.6}
        fontSize={17}
      >
        {title}
      </Typography>

      <Box display='flex' gap={1} mt={{ xs: 1, md: 1.5 }} alignItems='center'>
        <Typography
          textAlign='center'
          fontSize={{ md: 14, xs: 13 }}
          fontWeight={500}
          sx={{
            color: topbarColor,
          }}
        >
          Share Product Links
        </Typography>
        {isCopied ? (
          <>
            <TaskAltIcon
              sx={{
                color: primaryColor,
                fontSize: { md: 18, xs: 16 },
              }}
            />
            <Box
              component='span'
              sx={{
                fontSize: 13,
                fontWeight: 500,
                color: primaryColor,
                ml: '-5px',
                display: { md: 'inline', xs: 'none' },
              }}
            >
              Copied
            </Box>
          </>
        ) : (
          <IosShareIcon
            // onClick={copyLink}
            onClick={() => {
              setShowShareOptions(true)
            }}
            sx={{
              cursor: 'pointer',
              fontSize: { md: 18, xs: 16 },
              mb: '1px',
            }}
          />
        )}

        {showShareOptions ? (
          <Box position='absolute' bottom='-50px' zIndex={10}>
            {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
            businessAffiliateData?.businessResellers?.length > 0 &&
            rellerProfileData ? (
              <AffiliateShareModal
                productDetails={productDetails}
                commissionDetails={businessAffiliateData}
                rellerProfileData={{ details: rellerProfileData }}
                open={showShareOptions}
                onClose={() => {
                  setShowShareOptions(false)
                }}
                isProductShare
              />
            ) : (
              <SocialShareVertical
                // url={`${siteBaseURL}${businessName}`}
                url={`${windowurl}`}
                quote=''
                back={() => {
                  setShowShareOptions(false)
                }}
                showCopy
                onCopyClick={() => {
                  copyLink()
                  setShowShareOptions(false)
                }}
              />
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}
