import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { useState } from 'react'
import { labelColor } from '../../configs/styles/muiThemes'

function CheckBoxLabel({ label, textSmall, subCategory, onValuesChanged, checked }) {
  const [selectedIds, setSelectedIds] = useState([])

  function handleChecked(code) {
    if (selectedIds.includes(code)) {
      const tempIds = selectedIds.filter((item) => item !== code)
      setSelectedIds(tempIds)
      if (onValuesChanged) onValuesChanged(selectedIds)
    } else {
      const tempIds = [...selectedIds]
      tempIds.push(code)
      setSelectedIds(tempIds)
      if (onValuesChanged) onValuesChanged(selectedIds)
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          color='primary'
          // checked={selectedIds.indexOf(subCategory.code) !== -1}
          checked={checked}
          size='small'
          onChange={() => {
            // handleChecked(subCategory.code)
            onValuesChanged(subCategory)
          }}

        />
      }
      label={
        <Typography
          variant='body1'
          color={labelColor}
          fontSize={textSmall ? '12px' : '14px'}
          fontWeight={400}
        >
          {label}
        </Typography>
      }
    />
  )
}

export default CheckBoxLabel
