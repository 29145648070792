import { Box, Grid, Stack, Typography } from '@mui/material'
import { noProductImgUrl } from 'configs/Constants'
import { useEffect, useState } from 'react'
import {
  borderLineColor,
  headingColor,
  offerTextColor,
  primaryColor,
  savingTextColor,
  whiteColor,
} from '../configs/styles/muiThemes'

function TableProductCard({
  image,
  productName,
  caterogy,
  oldUnitPrice,
  unitprice,
  discount,
  price,
  saving,
  quantity,
  isDiscount,
  isOutofStock,
  onDeleteProduct,
  readOnly,
  minQty,
  maxQty,
  cartData,
  cartId,
  product,
  orderProduct,
  onQtyUpdate,
  isPriceChange,
  isEcommerceDisabled,
  minQtyCheck,
  maxQtyCheck,
  validateCart,
  onRemoveProduct,
  onPriceUpdate,
  weightZeroError,
}) {
  //For Ui count update
  const [qtyCount, setQtyCount] = useState(quantity)
  let businessFullName = sessionStorage.getItem('businessFullName')

  useEffect(() => {
    setQtyCount(quantity)
  }, [productName])

  const getDeleteIconColor = () => {
    if (!validateCart) {
      return false
    }

    return (
      isEcommerceDisabled ||
      isPriceChange ||
      minQtyCheck ||
      maxQtyCheck ||
      weightZeroError
    )
  }

  return (
    <>
      <Box
        py={1.8}
        borderTop={'1px solid' + borderLineColor}
        display={{ md: 'block', xs: 'none' }}
      >
        <Grid
          container
          alignItems={{ xs: 'flex-start', md: 'center' }}
          spacing={{ md: 3, xs: 1 }}
        >
          <Grid item xs={12} md={readOnly ? 5 : 4.5}>
            <Grid container spacing={2} alignItems='flex-start'>
              <Grid item>
                <Box
                  component='img'
                  src={image}
                  alt={businessFullName + ' CostBo'}
                  backgroundColor={whiteColor}
                  sx={styles.productImg}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = noProductImgUrl
                  }}
                />
              </Grid>
              <Grid item xs>
                <Stack>
                  {/* <Typography
                  variant='subtitle1'
                  fontSize='13px'
                  color={categoryLabelColor}
                >
                  {JSON.stringify(validateCart)}
                </Typography> */}
                  <Typography
                    variant='h6'
                    component='h4'
                    fontSize={{ xs: 13, md: 14 }}
                    fontWeight={500}
                    lineHeight='24px'
                    color={headingColor}
                    mt={0.7}
                  >
                    {productName}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5} md={2}>
            <Box>
              {discount ? (
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={400}
                  color={offerTextColor}
                  display='inline'
                  sx={{ textDecorationLine: 'line-through' }}
                >
                  ₹{Number(oldUnitPrice).toFixed(2)}
                </Typography>
              ) : null}

              <Typography
                variant='subtitle1'
                component='h4'
                fontSize={{ xs: 13, md: 14 }}
                color={headingColor}
                display='inline'
                ml={1}
              >
                ₹{Number(unitprice).toFixed(2)}
              </Typography>

              {discount ? (
                <Typography
                  variant='subtitle2'
                  component='p'
                  color={primaryColor}
                  mt={0.3}
                  // ml={{xs:0.7, md:0}}
                  // display={{ xs: 'inline', md: 'block' }}
                  display='block'
                >
                  {discount}% OFF
                </Typography>
              ) : null}
            </Box>
          </Grid>

          <Grid item xs={5} md={2.3}>
            {readOnly ? (
              <>
                <Typography
                  variant='subtitle1'
                  fontSize='13px'
                  color={offerTextColor}
                  display={{ xs: 'block', md: 'none' }}
                >
                  Qty
                </Typography>
                <Typography
                  variant='body1'
                  color={headingColor}
                  fontSize='13px'
                  ml={{ md: 4, xs: 1 }}
                  // width="45px"
                  fontWeight={600}
                  display={'inline'}
                >
                  {qtyCount}
                </Typography>
              </>
            ) : null}
          </Grid>

          <Grid
            item
            xs={0}
            md={1.2}
            display={{ xs: 'none', md: 'inline-flex' }}
          >
            <Typography
              variant='subtitle1'
              component='h4'
              lineHeight='24px'
              fontSize={14}
              color={headingColor}
            >
              ₹{Number(price).toFixed(2)}
            </Typography>
          </Grid>

          <Grid item xs={2} md={readOnly ? 1 : 2}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              mr={0.6}
            >
              <Typography
                variant='subtitle1'
                component='h4'
                lineHeight='24px'
                fontSize={14}
                color={savingTextColor}
                display={{ xs: 'none', md: 'inline' }}
              >
                ₹{Number(saving).toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        py={0.8}
        borderTop={'1px solid' + borderLineColor}
        display={{ xs: 'block', md: 'none' }}
      >
        <Grid container alignItems='center'>
          <Grid item xs={4}>
            <Box
              component='img'
              src={image}
              alt={businessFullName + ' CostBo'}
              backgroundColor={whiteColor}
              sx={styles.productImg}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = noProductImgUrl
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Stack>
              <Typography
                variant='h6'
                component='h4'
                fontSize={{ xs: 13, md: 14 }}
                fontWeight={500}
                lineHeight='24px'
                color={headingColor}
                mt={0.7}
              >
                {productName}
              </Typography>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap'
              >
                <Box>
                  {discount ? (
                    <Typography
                      variant='subtitle1'
                      component='p'
                      fontSize={{ xs: 13, md: 13 }}
                      fontWeight={400}
                      color={offerTextColor}
                      display='inline'
                      sx={{ textDecorationLine: 'line-through' }}
                    >
                      ₹{Number(oldUnitPrice).toFixed(2)}
                    </Typography>
                  ) : null}

                  <Typography
                    variant='subtitle1'
                    component='h4'
                    fontSize={{ xs: 13, md: 14 }}
                    color={headingColor}
                    display='inline'
                    ml={1}
                  >
                    ₹{Number(unitprice).toFixed(2)}
                  </Typography>

                  {discount ? (
                    <Typography
                      variant='subtitle2'
                      component='p'
                      color={primaryColor}
                      mt={0.3}
                      ml={1}
                      // ml={{xs:0.7, md:0}}
                      // display={{ xs: 'inline', md: 'block' }}
                      display='inline'
                    >
                      {discount}% OFF
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  <Typography
                    variant='subtitle1'
                    fontSize='13px'
                    color={offerTextColor}
                    display={{ xs: 'inline', md: 'none' }}
                  >
                    Qty
                  </Typography>
                  <Typography
                    variant='body1'
                    color={headingColor}
                    fontSize='13px'
                    ml={{ md: 4, xs: 1 }}
                    // width="45px"
                    fontWeight={600}
                    display={'inline'}
                  >
                    {qtyCount}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default TableProductCard

const styles = {
  productImg: {
    width: { md: '100px', xs: '90px' },
    height: { md: '75px', xs: '68px' },
    border: '1px solid' + borderLineColor,
    borderRadius: '10px',
    p: 0.5,
    objectFit: 'contain',
  },

  offTag: {
    display: 'inline-block',
    backgroundColor: 'primary.main',
    borderRadius: '0 6px 0 0',
    p: '4px 15px',
    borderRadius: '5px',
  },
}