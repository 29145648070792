import { INSTANT_DISCOUNT_API } from 'services/constant'
import { getProfileId } from 'utils/authHelpers'
import { getApiInstance } from '../../ApiInstance'

export const getCouponsData = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance({
    header: { businessId: businessId },
  })
  try {
    const response = await instance(
      `${process.env.REACT_APP_REWARD_QUERY}/getCoupons`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getInstantDiscounts = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const profileId = getProfileId()

  const instance = getApiInstance({
    header: { businessId: businessId, profileId: profileId },
  })
  try {
    const response = await instance(`${INSTANT_DISCOUNT_API}?pageNo=1`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
