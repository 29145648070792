import { Box, Container, Grid, Typography } from '@mui/material'
import BreadcrumbsSection from '../../components/Breadcrumbs'
import {
  borderLineColor,
  completedColor,
  delectIconInactiveColor,
  headingColor,
  primaryColor,
  savingTextColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import DeliveryAddress from '../Confirmation/DeliveryAddress'
import DeliveryMethod from '../Confirmation/DeliveryMethod'
import OrderStep from '../AllOrders/OrderStep'
import moment from 'moment'
import PaymentType from './PaymentType'
import TableProductCard from 'components/TableProductCard'
import SpecialNotes from 'pages/Confirmation/SpecialNotes'
import ETADetails from './ETADetails'

function OrderDetailsContent({
  data,
  orderStatus,
  cartDate,
  fromPage,
  loading,
}) {
  const isOrderCreated =
    orderStatus === 'ordercreated' ||
    orderStatus === 'orderapproved' ||
    orderStatus === 'ordershipped' ||
    orderStatus === 'orderdelivered'
  // || orderStatus == 'paycartcreated'

  const isOrderApproved =
    orderStatus === 'orderapproved' ||
    orderStatus === 'ordershipped' ||
    orderStatus === 'orderdelivered'

  const isOrderShipped =
    orderStatus === 'ordershipped' || orderStatus === 'orderdelivered'

  const isOrderDelivered = orderStatus === 'orderdelivered'
  const isCancelled = orderStatus === 'bordercanceled'

  return (
    <Container sx={{ maxWidth: '1300px !important', mt: { xs: 1.8, md: 2.5 } }}>
      {/* <BreadcrumbsSection
        links={[
          { label: fromPage?.label, to: `/${fromPage?.to}` },
          { label: 'Order Details', to: `#` },
        ]}
      /> */}

      <Grid container spacing={3.5}>
        <Grid item xs={12} md={9}>
          <Box
            border={'1px solid ' + borderLineColor}
            borderRadius={2.2}
            p={1.85}
            display={{ xs: 'block', md: 'none' }}
          >
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={1.5}
              borderBottom={'1px solid ' + borderLineColor}
              textAlign='right'
            >
              SUMMARY
            </Typography>

            <Box py={1}>
              <SummaryDataRow
                label='Subtotal:'
                value={`₹${data?.cart?.orderTotalProductCost?.toFixed(2)}`}
              />
              <SummaryDataRow
                label='Total Quantity:'
                value={data?.cart?.totalQuantity}
              />
              <SummaryDataRow label='Taxes:' value='Included' isValueGreen />
              <SummaryDataRow
                label='Shipping & Handling:'
                value={`₹${data?.cart?.shippingCost?.toFixed(2)}`}
              />

              {data?.cart?.codCost ? (
                <SummaryDataRow
                  label='COD Cost:'
                  value={`₹${data?.cart?.codCost?.codCharges?.toFixed(2)}`}
                />
              ) : null}

              <SummaryDataRow
                label='Discount:'
                value={`₹${data?.cart?.claimedPoint?.toFixed(2)}`}
              />
            </Box>

            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              pt={0.7}
              borderTop={'1px solid ' + borderLineColor}
            >
              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={headingColor}
                  display='inline'
                >
                  Total
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  color={primaryColor}
                  display='inline'
                >
                  ₹
                  {(
                    data?.cart?.orderTotalProductCost +
                    data?.cart?.shippingCost -
                    data?.cart?.claimedPoint
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box sx={styles.cartContainer}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2.6}
              mt={2}
              flexWrap='wrap'
              gap={1}
            >
              <Typography
                variant='h5'
                fontSize={{ xs: 15, md: 17 }}
                color={headingColor}
              >
                ORDER DETAILS
              </Typography>
              <Typography
                variant='subtitle1'
                fontSize={{ xs: 13, md: 14 }}
                fontWeight={500}
                lineHeight='24px'
                color={completedColor}
              >
                {getOrderStatus(orderStatus)}
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              py={{ xs: 0.5, md: 3 }}
            >
              <Typography
                variant='h4'
                fontSize={{ xs: 16, md: 20 }}
                color={headingColor}
                textAlign='center'
              >
                Thank you for your Order
              </Typography>
              <Typography
                variant='h6'
                color={headingColor}
                textAlign='center'
                fontSize={{ xs: 14, md: 15 }}
                fontWeight={500}
                mt={1.5}
                display='inline'
              >
                Order ID:{' '}
                <Typography
                  variant='h6'
                  color={headingColor}
                  fontSize={{ xs: 14, md: 15 }}
                  textAlign='center'
                  mt={1.5}
                  display='inline'
                >
                  #{data?.cart?.orderId}
                </Typography>
              </Typography>
              <Typography
                variant='h6'
                color={headingColor}
                fontWeight={500}
                fontSize={{ xs: 14, md: 15 }}
                textAlign='center'
                display='inline'
              >
                Date:{' '}
                <Typography
                  variant='h6'
                  fontSize={{ xs: 14, md: 15 }}
                  color={headingColor}
                  textAlign='center'
                  display='inline'
                >
                  {data?.cart?.orderCreatedOn
                    ? moment(data?.cart?.orderCreatedOn).format('MMM DD, YYYY')
                    : cartDate
                    ? moment(cartDate).format('MMM DD, YYYY')
                    : ''}
                </Typography>
              </Typography>
            </Box>

            <Box px={{ md: 10, xs: 2 }}>
              <Grid container>
                <Grid item xs={3}>
                  <OrderStep
                    completed={
                      isOrderCreated || orderStatus === 'bordercanceled'
                    }
                    active={
                      orderStatus === 'ordercreated' ||
                      orderStatus === 'paycartcreated'
                    }
                    label='Created'
                    time={
                      data?.cart?.orderCreatedOn
                        ? moment(data?.cart?.orderCreatedOn).format(
                            'MMM DD, YYYY'
                          )
                        : ''
                    }
                  />
                </Grid>

                {orderStatus === 'bordercanceled' ? (
                  <Grid item xs={3}>
                    <OrderStep
                      completed={isCancelled}
                      active={isCancelled}
                      label='Cancelled'
                      time=''
                      isCancelled={isCancelled}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3}>
                    <OrderStep
                      completed={isOrderApproved}
                      active={orderStatus === 'orderapproved'}
                      label='Approved'
                      time={
                        isOrderApproved
                          ? data?.cart?.orderApprovedOn
                            ? moment(data?.cart?.orderApprovedOn).format(
                                'MMM DD, YYYY'
                              )
                            : ''
                          : 'pending'
                      }
                      hideDate={isCancelled}
                    />
                  </Grid>
                )}

                <Grid item xs={3}>
                  <OrderStep
                    completed={isOrderShipped}
                    active={orderStatus === 'ordershipped'}
                    label='Shipped'
                    time={
                      isOrderShipped
                        ? data?.cart?.orderShippedOn
                          ? moment(data?.cart?.orderShippedOn).format(
                              'MMM DD, YYYY'
                            )
                          : ''
                        : 'pending'
                    }
                    hideDate={isCancelled}
                  />
                </Grid>

                <Grid item xs={3}>
                  <OrderStep
                    completed={isOrderDelivered}
                    label='Delivered'
                    time={
                      isOrderDelivered
                        ? data?.cart?.orderDeliveredOn
                          ? moment(data?.cart?.orderDeliveredOn).format(
                              'MMM DD, YYYY'
                            )
                          : ''
                        : 'pending'
                    }
                    hideDate={isCancelled}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={styles.cartContainer}>
            <Typography
              variant='h5'
              component='h3'
              color={headingColor}
              ml={1}
              mt={1.5}
              mb={0.5}
            >
              Items in Order
            </Typography>
            <Grid container py={1} display={{ xs: 'none', md: 'inline-flex' }}>
              <Grid item xs={5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='14px'
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid>

            {data?.cart?.productList?.map((item, index) => {
              const savings =
                Number(item?.productOriginalPrice) *
                  Number(item?.currentQuantity) -
                Number(item?.productSalePrice) * Number(item?.currentQuantity)

              return (
                <TableProductCard
                  key={'cart_product_' + index}
                  image={item?.productPicURL}
                  productName={item?.productName}
                  oldUnitPrice={item?.productOriginalPrice}
                  unitprice={item?.productSalePrice}
                  discount={item?.percentageOff}
                  price={item?.productTotalPrice}
                  saving={savings}
                  quantity={item.currentQuantity}
                  isOutofStock={item.isOutOfStock}
                  onDeleteProduct={() => {}}
                  readOnly={true}
                />
              )
            })}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            border={'1px solid ' + borderLineColor}
            borderRadius={2.2}
            p={1.85}
            display={{ xs: 'none', md: 'block' }}
            mt={2}
          >
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={1.5}
              borderBottom={'1px solid ' + borderLineColor}
              textAlign='right'
            >
              SUMMARY
            </Typography>

            <Box py={1}>
              <SummaryDataRow
                label='Subtotal:'
                value={`₹${data?.cart?.orderTotalProductCost?.toFixed(2)}`}
              />
              <SummaryDataRow
                label='Total Quantity:'
                value={data?.cart?.totalQuantity}
              />
              <SummaryDataRow label='Taxes:' value='Included' isValueGreen />
              <SummaryDataRow
                label='Shipping & Handling:'
                value={`₹${data?.cart?.shippingCost?.toFixed(2)}`}
              />

              {data?.cart?.codCost ? (
                <SummaryDataRow
                  label='COD Cost:'
                  value={`₹${data?.cart?.codCost?.codCharges?.toFixed(2)}`}
                />
              ) : null}

              <SummaryDataRow
                label='Discount:'
                value={`₹${data?.cart?.claimedPoint?.toFixed(2)}`}
              />
            </Box>

            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              pt={0.7}
              borderTop={'1px solid ' + borderLineColor}
            >
              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={headingColor}
                  display='inline'
                >
                  Total
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  color={primaryColor}
                  display='inline'
                >
                  ₹{Number(data?.cart?.orderTotalCost).toFixed(2)}
                  {/* {(
                    data?.cart?.orderTotalProductCost +
                    data?.cart?.shippingCost -
                    data?.cart?.claimedPoint
                  ).toFixed(2)} */}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <DeliveryAddress
            // name={data?.cart?.customerAddress?.name}
            // type={data?.cart?.customerAddress?.addressType}
            // houseNo={data?.cart?.customerAddress?.houseNo}
            // address={data?.cart?.customerAddress?.addressLine1}
            // city={data?.cart?.customerAddress?.city}
            // state={data?.cart?.customerAddress?.state}
            // country={data?.cart?.customerAddress?.country}
            // contactNumber={data?.cart?.customerAddress?.phoneNo + JSON.stringify(data?.cart?.customerAddress)}
            address={data?.cart?.customerAddress}
          />

          <DeliveryMethod
            delivery={data?.cart?.deliveryDateRange}
            shipType={data?.cart?.deliveryType?.shipType}
          />

          {/* {data?.cart?.shipmentInfo ? ( */}
            <ETADetails
              deliveryETA={data?.cart?.shipmentInfo?.etaDeliveryTime}
              deliveryDate={data?.cart?.shipmentInfo?.etaDeliveryDate}
              pickupETA={data?.cart?.shipmentInfo?.etaPickUpTime}
              pickupDate={data?.cart?.shipmentInfo?.etaPickUpDate}
            />
          {/* ) : null} */}

          <PaymentType
            paymentType={data?.cart?.paymentInfo?.paymentDetail?.paymentType}
          />

          {data?.cart ? (
            data?.cart?.notesConsumer ? (
              <SpecialNotes note={data?.cart?.notesConsumer} />
            ) : null
          ) : null}

          {/* For Testing Only */}
          {/* <SummaryDataRow
            label='paymentType:'
            value={data?.cart?.paymentInfo?.paymentDetail?.paymentType}
          />
          <SummaryDataRow
            label='modeOfPayment:'
            value={data?.cart?.modeOfPayment}
          />
          <SummaryDataRow
            label='deliveryType.type:'
            value={data?.cart?.deliveryType?.type}
          />
          <SummaryDataRow
            label='deliveryType.shipType:'
            value={data?.cart?.deliveryType?.shipType}
          />
          <SummaryDataRow
            label='shipmentInfo.carrierName:'
            value={data?.cart?.shipmentInfo?.carrierName}
          />
          <SummaryDataRow
            label='discountDetails.discountType:'
            value={data?.cart?.discountDetails?.[0]?.discountType}
          />
          <SummaryDataRow
            label='discountDetails.discountAmount:'
            value={data?.cart?.discountDetails?.[0]?.discountAmount}
          />
          <SummaryDataRow
            label='deliveryDateRange:'
            value={data?.cart?.deliveryDateRange}
          /> */}
        </Grid>
      </Grid>
    </Container>
  )
}

export default OrderDetailsContent

const SummaryDataRow = ({ label, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      py={0.2}
    >
      <Grid item>
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='13.5px'
          color={headingColor}
          display='inline'
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='h6'
          component='p'
          fontSize='13.5px'
          color={isValueGreen ? savingTextColor : headingColor}
          display='inline'
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}
const styles = {
  cartContainer: {
    boxShadow: '0 4px 8px rgb(0 0 0 / 6%)',
    px: 1.5,
    pt: 0.5,
    pb: 2,
    borderRadius: 2.2,
    mt: 2,
    border: '1px solid' + borderLineColor,
  },
  actionBtn: (outline) => ({
    fontSize: '15px',
    fontWeight: 500,
    color: outline ? primaryColor : whiteColor,
    p: '7px 24px',
    mb: '10px !important',
    border: '1px solid ' + primaryColor,
    backgroundColor: outline ? whiteColor : primaryColor,
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: outline ? primaryColor : whiteColor,
      color: outline ? whiteColor : primaryColor,
    },
  }),
}

const getOrderStatus = (status) => {
  switch (status) {
    case 'ordercreated':
      return 'Created'

    case 'orderapproved':
      return 'Approved'

    case 'ordershipped':
      return 'Shipped'

    case 'orderdelivered':
      return 'Delivered'

    case 'bordercanceled':
      return 'Canceled'

    case 'paycartcreated':
      return 'Easy Cart Created'

    default:
      return ''
  }
}
