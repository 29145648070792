import { Box, Container, Grid, Typography } from '@mui/material'
import { Mail, WhatsappOutlined } from '@mui/icons-material'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import {
  backgroundColor,
  borderLineColor,
  copyrightTextColor,
  footerCopyrightBGColor,
  footerListColor,
  headingColor,
  topbarColor,
  whatsappIconColor,
  whiteColor,
} from '../configs/styles/muiThemes'
import { Link } from 'react-router-dom'
import {
  footer_PoweredBy,
  footer_Advantage1,
  footer_Advantage2,
  footer_Advantage3,
  footer_Advantage4,
  footer_PrivacyPolicy,
  footer_TermsLabel,
  costBoEmailText,
  costBoContactNumberText,
  footer_CopyrightText,
} from 'configs/Constants'
import { useSelector } from 'react-redux'

const businessUrl = sessionStorage.getItem('businessName')

function FooterMui() {
  const { commercialDetails } = useSelector((state) => state.product)
  const { businessDetails } = useSelector((state) => state.header)

  let businessFullName = sessionStorage.getItem('businessFullName')
  const whatsAppNumber =
    commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace('+', '')

  const emailAddress =
    commercialDetails?.info?.costboPowered?.supportEmail || 'support@costbo.com'

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore`,
        '_blank'
      )
    } else {
      window.fcWidget.open()
      window.fcWidget.show()
    }
  }

  return (
    <Box
      mt={3}
      mb={{ xs: '100px', md: 0 }}
      display={{ md: 'block', xs: 'none' }}
    >
      {/* TEST:
      {JSON.stringify(commercialDetails?.info?.costboPowered?.whatsAppNumber)}
      TEST:
      {JSON.stringify(businessDetails?.businessInfo?.[0]?.businessName)} */}
      <Box
        sx={{
          backgroundColor: topbarColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 1,
        }}
      >
        <Box
          display='flex'
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems='center'
        >
          <Typography
            variant='body2'
            color={whiteColor}
            display='inline'
            fontWeight={500}
            fontSize={14}
          >
            {footer_PoweredBy}
          </Typography>

          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/logo_footer.png'
            alt={businessFullName + ' CostBo'}
            width={{ xs: '110px', md: '122px' }}
            height='auto'
            ml={{ xs: 0, md: 2 }}
            mt={{ xs: 1, md: 0 }}
          />
        </Box>
      </Box>
      <Container
        sx={{
          maxWidth: '1300px !important',
        }}
      >
        <Box
          pt='18px'
          pb='18px'
          sx={{
            borderBottom: '1px solid' + borderLineColor,
            display: { xs: 'none', md: 'block' },
          }}
        >
          {/* <Typography
            variant='h4'
            fontSize={15}
            color={headingColor}
            textAlign='center'
            pb={1.2}
            lineHeight='24px'
          >
            {footer_AdvantagesHeading}
          </Typography> */}

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <AdvantageCard
                icon='https://storage.googleapis.com/bodefaults/shopweb/shop-5.png'
                text={footer_Advantage1}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AdvantageCard
                icon='https://storage.googleapis.com/bodefaults/shopweb/shop-6.png'
                text={footer_Advantage2}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AdvantageCard
                icon='https://storage.googleapis.com/bodefaults/shopweb/pm_buy_authentic_product.png'
                text={footer_Advantage3}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AdvantageCard
                icon='https://storage.googleapis.com/bodefaults/shopweb/pm_delivery_tracking.png'
                text={footer_Advantage4}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          display={'flex'}
          justifyContent='space-between'
          alignItems='center'
          flexDirection={{ md: 'row', xs: 'column' }}
          gap={2}
          my={1.2}
        >
          <Box
            width={{ xs: '100%', md: 'auto' }}
            display='flex'
            alignItems='center'
            justifyContent={{ xs: 'space-between', md: 'flex-start' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={{ md: 2.6, xs: 1.3 }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: '60px', md: '20px' },
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                color={copyrightTextColor}
                textAlign='center'
                component={Link}
                to='/privacyPolicy'
                sx={{ mr: { xs: '10px', md: '0px' } }}
              >
                {footer_PrivacyPolicy}
              </Typography>

              <Typography
                fontSize={14}
                fontWeight={500}
                color={copyrightTextColor}
                textAlign='center'
                component={Link}
                // to={businessUrl + "/termsAndConditions"}
                to='/termsAndConditions'
                // target="_self"
                // ml={2.5}
              >
                {footer_TermsLabel}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={copyrightTextColor}
                textAlign='center'
                component={Link}
                // to={businessUrl + "/termsAndConditions"}
                to='/return-policy'
                // target="_self"
                // ml={2.5}
              >
                Returns Policy
              </Typography>

              <Typography
                fontSize={14}
                fontWeight={500}
                color={copyrightTextColor}
                textAlign='center'
                sx={{
                  cursor: 'pointer',
                  '&:hover': { color: topbarColor },
                  ml: { xs: '20px', md: '0px' },
                }}
                onClick={() =>
                  window.location.replace(
                    '/cancellation-policy#cancellationPolicy'
                  )
                }
              >
                Cancellation Policy
              </Typography>
            </Box>
          </Box>

          <Box
            display='flex'
            alignItems='center'
            flexDirection={{ md: 'row', xs: 'row' }}
            gap={{ xs: 1.5, md: 1 }}
          >
            <Box
              display='flex'
              alignItems='center'
              mr={3}
              // onClick={() => {
              //   window.fcWidget.open()
              //   window.fcWidget.show()
              // }}
              onClick={handleChatClick}
              sx={{ cursor: 'pointer' }}
            >
              <QuestionAnswerOutlinedIcon
                sx={{ fontSize: '23px', color: topbarColor }}
              />
              <Typography
                fontSize={14}
                fontWeight={500}
                color={copyrightTextColor}
                ml={1.5}
              >
                24 x 7 Support Chat
              </Typography>
            </Box>

            <Box display='flex' alignItems='center'>
              <Mail sx={{ fontSize: '23px', color: topbarColor }} />
              <Typography
                fontSize={14}
                fontWeight={500}
                color={copyrightTextColor}
                ml={1.5}
                component='a'
                href={`https://mail.google.com/mail/u/0/?fs=1&to=${emailAddress}&tf=cm`}
                target='_blank'
              >
                {costBoEmailText}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {/* <Box display='flex' alignItems='center'>
                <WhatsappOutlined
                  sx={{ fontSize: '23px', color: whatsappIconColor }}
                />
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={copyrightTextColor}
                  ml={1.3}
                  component='a'
                  // href='https://api.whatsapp.com/send?phone=918088422611'
                  href={`https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+support+team,+I+have+a+query+about+the+products+in+your+webstore`}
                  target='_blank'
                >
                  {costBoContactNumberText}
                </Typography>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box backgroundColor={footerCopyrightBGColor}>
        <Container
          sx={{
            maxWidth: '1300px !important',
          }}
        >
          <Grid
            container
            direction={{ xs: 'column', sm: 'row' }}
            alignItems='center'
            justifyContent='space-between'
            sx={styles.copyrightBar}
          >
            <Grid item>
              <Typography
                variant='body1'
                fontSize={13}
                fontWeight={500}
                color={copyrightTextColor}
              >
                {footer_CopyrightText}
              </Typography>
            </Grid>

            <Grid item sx={styles.paymentLogos}>
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/payment_paypal.png'
                alt={businessFullName + ' CostBo'}
                width='auto'
                height='14px'
                sx={{ mx: 1.2, objectFit: 'contain' }}
              />
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/payment_visa.png'
                alt={businessFullName + ' CostBo'}
                width='auto'
                height='12px'
                sx={{ mr: 1.2, objectFit: 'contain' }}
              />

              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/payment_mastercart.png'
                alt={businessFullName + ' CostBo'}
                width='auto'
                height='14px'
                sx={{ mr: 1.2, objectFit: 'contain' }}
              />

              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/payment_upi.png'
                alt={businessFullName + ' CostBo'}
                width='auto'
                height='14px'
                sx={{ mr: 1.2, objectFit: 'contain' }}
              />

              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/payment_gpay.png'
                alt={businessFullName + ' CostBo'}
                width='auto'
                height='14px'
                sx={{ mr: 1.2, objectFit: 'contain' }}
              />

              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/payment_paytm.png'
                alt={businessFullName + ' CostBo'}
                width='auto'
                height='14px'
                sx={{ mr: 1.2, objectFit: 'contain' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default FooterMui

const AdvantageCard = ({ icon, text }) => {
  let businessFullName = sessionStorage.getItem('businessFullName')
  return (
    <Box display='flex' alignItems='center'>
      <Box
        component='img'
        src={icon}
        alt={businessFullName + ' CostBo'}
        width='40px'
        height='40px'
      />

      <Typography
        variant='body2'
        color={headingColor}
        fontSize={13}
        lineHeight='20px'
        ml={1.5}
      >
        {text}
      </Typography>
    </Box>
  )
}

const styles = {
  footerContainer: {
    background: backgroundColor,
    pb: '40px',
    mb: { xs: '50px', md: 0 },
  },

  logo: { objectFit: 'contain' },

  textBold: {
    py: '6px',
    textAlign: { xs: 'center', md: 'left' },
  },

  heading: {
    py: '6px',
  },

  textLink: {
    fontSize: { xs: '13px', md: '16px' },
    fontWeight: 500,
    color: 'primary.main',
    py: '6px',
    textAlign: { xs: 'center', md: 'left' },
  },

  listText: {
    fontSize: { xs: '14px', md: '15px' },
    fontWeight: 500,
    color: footerListColor,
    display: 'block',
  },

  linkHover: {
    mt: 1,
    '&:hover': {
      color: 'secondary.main',
    },
  },

  copyrightBar: {
    p: '10px 0px',
    // borderTop: '1px solid' + borderLineColor,
  },

  paymentLogos: {
    display: 'flex',
    justifyContent: 'center',
    mt: { xs: 1, md: 0 },
  },

  centerMedia: { textAlign: { xs: 'center', md: 'left' } },
}
