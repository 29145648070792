import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  dashboardDescriptionColor,
  headingColor,
  stepperInactiveColor,
} from '../../configs/styles/muiThemes'

function DashboardCard({ icon, title, value, linkPath }) {

  let businessFullName = sessionStorage.getItem('businessFullName')
  
  return (
    <Box
      p={1.25}
      borderRadius={1}
      sx={{ backgroundColor: stepperInactiveColor, borderRadius: '6px' }}
      display='flex'
      alignItems='center'
      component={Link}
      to={linkPath ? linkPath : '#'}

    >
      <Box
        src={icon}
        component='img'
        alt={businessFullName + ' CostBo'}
        sx={{
          width: { xs: '26px', md: '26px' },
          height: { xs: '26px', md: '26px' },
        }}
        textAlign='center'
      />
      <Box ml={1.875}>
        <Typography
          variant='h6'
          fontSize={{ xs: 13, md: 14 }}
          fontWeight={400}
          color={dashboardDescriptionColor}
        >
          {title}
        </Typography>
        <Typography
          variant='h5'
          fontSize={{ xs: 14, md: 16 }}
          color={headingColor}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

export default DashboardCard
