import { Box, Button, Typography } from '@mui/material'
import {
  discountPercentageColor,
  headingColor,
  sidebarHeadingColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React from 'react'

function LoginAlertCard({ handleClose, onLoginClick }) {
  return (
    <Box
      px={{ md: 2, xs: 1.5 }}
      py={5}
      sx={{ maxHeight: '88vh', overflowY: 'auto', background: whiteColor }}
      borderRadius={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      gap={1}
    >
      <Typography
        color={headingColor}
        variant='h5'
        fontWeight={600}
        fontSize={15}
      >
        Please login to apply for credit line
      </Typography>
      <Button onClick={onLoginClick} sx={styles.btn}>
        Login
      </Button>
      <Typography
        color={sidebarHeadingColor}
        component='a'
        fontSize={13}
        onClick={handleClose}
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
          ml: 1,
          textAlign: 'center',
          mt: 1,
        }}
      >
        Cancel
      </Typography>
    </Box>
  )
}

export default LoginAlertCard

const styles = {
  btn: {
    backgroundColor: discountPercentageColor,
    borderRadius: '5px',
    p: '4px 24px',
    color: whiteColor,
    width: '70%',
    fontSize: 12,
    border: '1px solid ' + discountPercentageColor,
    '&:hover': {
      backgroundColor: whiteColor,
      color: discountPercentageColor,
    },
    display: 'block',
    mt: 2,
  },
}
