import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick/lib/slider'

import ModalCard from 'components/ModalCard'
import ProductGalleryModal from './ProductGalleryModal'
import {
  arrowBgColor,
  borderCart,
  filterBgColor,
  headingColor,
  iconInactiveColor,
  primaryColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import CartDetailsBottonCard from 'components/CartDetailsBottonCard'
import ProductImages from './ProductImages'
import ProductDescriptionHeader from './ProductDescriptionHeader'
import ProductDescriptionBody from './ProductDescriptionBody'
import ProductDescriptionSideBar from './ProductDescriptionSideBar'
import DescriptionSpecificationTabs from './DescriptionSpecificationTabs'
import ProductCard from 'pages/CatalogMui/ProductCard'
import BreadcrumbsSection from 'components/Breadcrumbs'
import Loader from 'components/Loader'
import { useSelector } from 'react-redux'
import {
  addInfluencerShareAPI,
  addResellerOnShareAPI,
  getAffiliateValidityApi,
  getShortLink,
} from 'services/api_calls/affiliate/AffiliateApi'
import { getOperatingSystem } from 'utils/utilities'
import ScrollIndicator from 'components/ScrollIndicator'

export default function ProductDescription({
  productDetails,
  similarProductDetails,
  commercialDetails,
  recLoading,
  productId,
  cartProductList,
  totalQty,
  productQty,
  cashback,
  isOutOfStock,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [value, setValue] = useState(0)
  const [isModal, setIsModal] = useState(false)
  const [recommendedProducts, setRecommendedProducts] = useState([])
  const slider = useRef(null)

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [activeImg, setActiveImg] = useState(
    productDetails?.product?.productPhoto[0]?.docURL
  )
  const [productCount, setProductCount] = useState(0)
  const businessName = sessionStorage.getItem('businessName')
  const [windowurl, setWindowurl] = useState('')
  const [isCopied, setIsCopied] = useState(false)

  const { businessDetails } = useSelector((state) => state.header)

  const [isScrollEnd, setIsScrollEnd] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    const url = window.location.href
    setWindowurl(url)
  }, [])

  useEffect(() => {
    setActiveImg(productDetails?.product?.productPhoto[0]?.docURL)
  }, [productDetails])

  useEffect(() => {
    const filterArray = similarProductDetails?.details?.filter(
      (item) => item.id !== productId
    )
    setRecommendedProducts(filterArray)
    setProductCount(filterArray?.length)
  }, [similarProductDetails])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  }

  const onSlideNext = () => {
    if (currentSlideIndex < recommendedProducts.length - 7) {
      slider.current.slickNext()
    }
  }

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev()
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleModalClose = () => {
    setIsModal(false)
  }

  // Copy Product Link
  const handleCopyLink = () => {
    const BUSINESS_LINK = window.location.href
    navigator.clipboard.writeText(BUSINESS_LINK)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  // Initiate Chat
  const handleChatClick = () => {
    const whatsAppNumber =
      commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace('+', '')

    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        '_blank'
      )
    } else {
      window.fcWidget.open()
      window.fcWidget.show()
    }
  }

  // Affiliate Social Share
  const [shortShareLink, setShortShareLink] = useState('')
  const [isAffiliateExist, setIsAffiliateExist] = useState(false)

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  )

  const whatsappShareRef = useRef(null)
  const faceBookShareRef = useRef(null)
  const twitterShareRef = useRef(null)
  const linkedInShareRef = useRef(null)
  const telegramShareRef = useRef(null)

  // generate short share link
  const generateShortShareLink = async () => {
    const businessInfo = JSON.parse(sessionStorage.getItem('businessInfo'))

    const businessData = {
      name: businessInfo?.[0]?.businessName,
      costboWebURL: businessName,
    }

    const res = await getShortLink({
      businessData,
      // productDetails: productDetails,
      rellerProfileData: rellerProfileData?.details,
    })

    if (res?.shortURL) {
      setShortShareLink(res?.shortURL)
      return res?.shortURL
    } else {
      const link = `https://shop.costbo.com/${businessName}?atag=${rellerProfileData?.details?.resellerCode}`
      setShortShareLink(link)
      return link
    }
  }

  // Open Affiliate Share Modal
  const openShareModal = (shareMedium) => {
    if (shareMedium === 'whatsapp') {
      whatsappShareRef.current.click()
    } else if (shareMedium === 'facebook') {
      faceBookShareRef.current.click()
    } else if (shareMedium === 'linkedIn') {
      linkedInShareRef.current.click()
    } else if (shareMedium === 'twitter') {
      twitterShareRef.current.click()
    } else if (shareMedium === 'telegram') {
      telegramShareRef.current.click()
    }
  }

  const handleAffiliateShare = async (shareMedium, isCopy) => {
    const businessInfo = JSON.parse(sessionStorage.getItem('businessInfo'))
    const businessData = businessInfo?.[0]
    const commissionData = businessAffiliateData?.businessResellers?.[0]
    const commerceInfo = JSON.parse(sessionStorage.getItem('commerceInfo'))

    // POST API Data
    const commissionPostData = {
      commissionPercentage: commissionData?.affiliatePercentage,
      discountPercentage: commissionData?.consumerPercentage,
    }

    const businessPostData = {
      id: businessData?.id,
      name: businessData?.businessName,
      logoURL: businessData?.logoURL,
      costboWebURL: businessName,
      businessCategories: businessData?.businessCategories,
      documentsURL: businessData?.documentsURL,
      mainProducts: businessData?.mainProducts,
      businessArea: commerceInfo?.info?.businessArea,
      description: '',
    }

    const productPostData = {
      ...productDetails?.product,
      productId: productDetails?.product?.id,
      name: productDetails?.product?.productName,
    }

    let shortLink = shortShareLink || ''

    if (!shortShareLink) {
      shortLink = await generateShortShareLink()
    }

    if (isCopy) {
      navigator.clipboard.writeText(shortLink)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    } else {
      setTimeout(() => {
        openShareModal(shareMedium)
      }, 1000)
    }

    if (!isAffiliateExist) {
      const isAffiliateValid = await getAffiliateValidityApi(businessData?.id)

      if (isAffiliateValid?.affiliateexist === false) {
        const response = await addResellerOnShareAPI({
          productDetails: productPostData,
          commissionDetails: commissionPostData,
          rellerProfileData: rellerProfileData?.details,
          businessData: businessPostData,
        })

        if (response) {
          setIsAffiliateExist(true)
        }
      } else {
        setIsAffiliateExist(true)
      }
    }

    const deviceName = getOperatingSystem(window)
    const shareResponse = await addInfluencerShareAPI({
      productDetails: productPostData,
      commissionDetails: commissionPostData,
      businessData: businessPostData,
      deviceName,
      shareMedium,
    })
  }

  const validateCopyType = () => {
    if (
      businessAffiliateData?.businessResellers?.length > 0 &&
      rellerProfileData?.details
    ) {
      handleAffiliateShare('shareLink', true)
    } else {
      handleCopyLink()
    }
  }

  // Scroll Indicator if scoll not at end
  useEffect(() => {
    console.log('HITTTT_SCROLL')
    const container = containerRef.current

    const handleScroll = () => {
      // Check if the horizontal scroll has reached the end
      const isEnd =
        container?.scrollLeft + container?.clientWidth + 200 >=
        container?.scrollWidth

      console.log('HITTTT_SCROLL:', container)

      setIsScrollEnd(isEnd)
    }

    if (recommendedProducts?.length > 0) {
      container?.addEventListener('scroll', handleScroll)
    }

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [recommendedProducts])

  return (
    <Box>
      <ModalCard handleClose={handleModalClose} open={isModal} width='70%'>
        <ProductGalleryModal
          title={productDetails?.product?.productName}
          productImages={productDetails?.product?.productPhoto}
          activeImg={activeImg}
          onActiveClick={(data) => setActiveImg(data)}
          magnify
        />
      </ModalCard>

      <Container sx={{ maxWidth: '1300px !important' }}>
        {!matches && (
          <Box pt={3}>
            <BreadcrumbsSection
              links={[
                { label: 'Catalog', to: `/Catalog` },
                {
                  label: 'Product Description',
                  to: `/singleProduct/${productId}`,
                },
              ]}
            />
          </Box>
        )}

        {matches && (
          <Box
            sx={{
              position: 'sticky',
              top: 146,
              width: '100%',
              zIndex: 100,
              backgroundColor: whiteColor,
              pt: 3,
            }}
          >
            <>
              <BreadcrumbsSection
                links={[
                  { label: 'Catalog', to: `/Catalog` },
                  {
                    label: 'Product Description',
                    to: `/singleProduct/${productId}`,
                  },
                ]}
              />

              {cartProductList?.productList?.length > 0 ? (
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  py={1}
                  px={2}
                >
                  <Grid item>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item>
                        <Typography variant='h5' color={headingColor}>
                          Items in your cart
                        </Typography>
                      </Grid>
                      {/* <Grid item>
                      <Box sx={{ width: '40vw' }}>
                        <Slider {...cartListSettings}>
                          {cartProductList?.productList?.map((item, index) => {
                            return (
                              <ProductCount
                                src={item.productPicURL}
                                alt={item.productName}
                                count={item.currentQuantity}
                                key={'cart_product_' + index}
                                mx={0.5}
                              />
                            )
                          })}
                        </Slider>
                      </Box>
                    </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item display={{ xs: 'none', md: 'block' }}>
                    <Typography
                      variant='h6'
                      color={headingColor}
                      fontSize='14px'
                      fontWeight={500}
                      display='inline'
                      px={2}
                      borderRight={borderCart}
                    >
                      {totalQty} items in cart
                    </Typography>

                    <Typography
                      variant='h5'
                      color={headingColor}
                      fontSize='17px'
                      fontWeight={600}
                      display='inline'
                      mx={2}
                    >
                      ₹{' '}
                      {Number(cartProductList?.orderTotalProductCost).toFixed(
                        2
                      )}
                    </Typography>

                    <Button
                      component={Link}
                      to={`/${businessName}/cart`}
                      sx={{
                        fontSize: '13px',
                        fontWeight: 600,
                        color: whiteColor,
                        p: '2px 15px',
                        border: '2px solid ' + primaryColor,
                        textTransform: 'none',
                        borderRadius: '5px',
                        '&:hover': {
                          backgroundColor: whiteColor,
                          color: 'primary.main',
                        },
                      }}
                      variant='contained'
                      disableElevation
                      size='small'
                      onClick={() => {}}
                    >
                      GO TO CART
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </>
          </Box>
        )}

        {!matches ? (
          totalQty > 0 ? (
            <CartDetailsBottonCard
              amount={cartProductList?.orderTotalProductCost}
              cartProductList={cartProductList}
              onGoToCartClicked={() => {}}
              itemCount={totalQty}
            />
          ) : null
        ) : null}

        {matches ? (
          <Box>
            <Grid container direction='row' py={2} spacing={{ md: 6, xs: 1.5 }}>
              <Grid item md={9}>
                <Stack spacing={{ md: 2, xs: 1.5 }}>
                  <Grid container spacing={{ md: 5, xs: 2 }}>
                    <Grid item md={6}>
                      <ProductImages
                        onImageClick={() => setIsModal(true)}
                        productImages={productDetails?.product?.productPhoto}
                        activeImg={activeImg}
                        onActiveClick={(data) => setActiveImg(data)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Box>
                        <ProductDescriptionHeader
                          title={productDetails?.product?.productName}
                          subCategory={
                            productDetails?.product?.productSubCategory[0]
                              ?.value
                          }
                          subCategory2={
                            productDetails?.product?.productSubCategory2[0]
                              ?.value
                          }
                          copyLink={handleCopyLink}
                          isCopied={isCopied}
                          handleChatClick={handleChatClick}
                          // Affiliate Share
                          commercialDetails={commercialDetails}
                          businessAffiliateData={businessAffiliateData}
                          rellerProfileData={rellerProfileData?.details}
                          productDetails={productDetails?.product}
                        />
                        <Divider sx={{ my: '20px !important' }} />
                        <ProductDescriptionBody
                          productName={productDetails?.product?.productName}
                          productOriginalPrice={
                            productDetails?.product?.productOriginalPrice
                          }
                          productSalePrice={
                            productDetails?.product?.productSalePrice
                          }
                          percentageOff={productDetails?.product?.percentageOff}
                          min={productDetails?.product?.minOrderQuantity}
                          max={productDetails?.product?.maxOrderQuantity}
                          productDetails={productDetails}
                          isOutOfStock={isOutOfStock}
                          onQtyUpdate={onQtyUpdate}
                          productQty={productQty}
                          onAddProduct={onAddProduct}
                          onRemoveProduct={onRemoveProduct}
                          url={windowurl}
                          subscribe={
                            productDetails?.product?.subscription?.status ===
                            'active'
                          }
                          availability={
                            productDetails?.product?.availability?.[0] === 'Yes'
                          }
                          subscription={productDetails?.product?.subscription}
                          handleChatClick={handleChatClick}
                          // Affiliate Share
                          businessAffiliateData={businessAffiliateData}
                          rellerProfileData={rellerProfileData?.details}
                          onAffiliateShareClick={handleAffiliateShare}
                          shortShareLink={shortShareLink}
                          whatsappShareRef={whatsappShareRef}
                          faceBookShareRef={faceBookShareRef}
                          twitterShareRef={twitterShareRef}
                          linkedInShareRef={linkedInShareRef}
                          telegramShareRef={telegramShareRef}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <DescriptionSpecificationTabs
                    value={value}
                    handleChange={(event, newValue) =>
                      handleChange(event, newValue)
                    }
                    descriptionData={
                      productDetails?.product?.productDescription
                    }
                    specificationData={productDetails?.product?.label}
                    refLink={productDetails?.product?.refLink}
                  />
                </Stack>
              </Grid>

              <Grid item md={3}>
                {commercialDetails?.info?.rewardPointPercent ||
                commercialDetails?.info?.orderInfoDetails?.[0]
                  ?.minOrderAmount ||
                commercialDetails?.info?.immediateDiscount ||
                commercialDetails?.info?.shipmentArea ||
                commercialDetails?.info?.returnPolicy ||
                commercialDetails?.info?.notes ? (
                  <ProductDescriptionSideBar
                    rewardPointPercent={
                      commercialDetails?.info?.rewardPointPercent
                    }
                    minOrderAmount={
                      commercialDetails?.info?.orderInfoDetails?.[0]
                        ?.minOrderAmount
                    }
                    immediateDiscount={
                      commercialDetails?.info?.immediateDiscount
                    }
                    shipmentArea={commercialDetails?.info?.shipmentArea}
                    shipmentDistance={commercialDetails?.info?.shipmentDistance}
                    returnPolicy={commercialDetails?.info?.returnPolicy}
                    notes={commercialDetails?.info?.notes}
                    cashback={cashback}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Box>
        ) : null}

        {!matches ? (
          <Box>
            <Grid container direction='row' py={2} spacing={{ md: 6, xs: 2 }}>
              <Grid item xs={12}>
                <ProductDescriptionHeader
                  title={productDetails?.product?.productName}
                  subCategory={
                    productDetails?.product?.productSubCategory[0]?.value
                  }
                  subCategory2={
                    productDetails?.product?.productSubCategory2[0]?.value
                  }
                  copyLink={handleCopyLink}
                  isCopied={isCopied}
                  // Affiliate Share
                  commercialDetails={commercialDetails}
                  businessAffiliateData={businessAffiliateData}
                  rellerProfileData={rellerProfileData?.details}
                  productDetails={productDetails?.product}
                />
              </Grid>
              <Grid item xs={12}>
                <ProductImages
                  onImageClick={() => setIsModal(true)}
                  productImages={productDetails?.product?.productPhoto}
                  activeImg={activeImg}
                  onActiveClick={(data) => setActiveImg(data)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <ProductDescriptionBody
                    productName={productDetails?.product?.productName}
                    productOriginalPrice={
                      productDetails?.product?.productOriginalPrice
                    }
                    productSalePrice={productDetails?.product?.productSalePrice}
                    percentageOff={productDetails?.product?.percentageOff}
                    min={productDetails?.product?.minOrderQuantity}
                    max={productDetails?.product?.maxOrderQuantity}
                    productDetails={productDetails}
                    isOutOfStock={isOutOfStock}
                    onQtyUpdate={onQtyUpdate}
                    productQty={productQty}
                    onAddProduct={onAddProduct}
                    onRemoveProduct={onRemoveProduct}
                    subscribe={
                      productDetails?.product?.subscription?.status === 'active'
                    }
                    availability={
                      productDetails?.product?.availability?.[0] === 'Yes'
                    }
                    subscription={productDetails?.product?.subscription}
                    handleChatClick={handleChatClick}
                    // Affiliate Share
                    businessAffiliateData={businessAffiliateData}
                    rellerProfileData={rellerProfileData?.details}
                    onAffiliateShareClick={handleAffiliateShare}
                    shortShareLink={shortShareLink}
                    whatsappShareRef={whatsappShareRef}
                    faceBookShareRef={faceBookShareRef}
                    twitterShareRef={twitterShareRef}
                    linkedInShareRef={linkedInShareRef}
                    telegramShareRef={telegramShareRef}
                  />
                  <DescriptionSpecificationTabs
                    value={value}
                    handleChange={(event, newValue) =>
                      handleChange(event, newValue)
                    }
                    descriptionData={
                      productDetails?.product?.productDescription
                    }
                    specificationData={productDetails?.product?.label}
                  />
                </Box>
              </Grid>
              <Grid item md={3} xs={12}>
                <ProductDescriptionSideBar
                  rewardPointPercent={
                    commercialDetails?.info?.rewardPointPercent
                  }
                  minOrderAmount={
                    commercialDetails?.info?.orderInfoDetails?.[0]
                      ?.minOrderAmount
                  }
                  immediateDiscount={commercialDetails?.info?.immediateDiscount}
                  shipmentArea={commercialDetails?.info?.shipmentArea}
                  shipmentDistance={commercialDetails?.info?.shipmentDistance}
                  returnPolicy={commercialDetails?.info?.returnPolicy}
                  notes={commercialDetails?.info?.notes}
                  cashback={cashback}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}

        {!recLoading ? (
          <Box mb={8} position='relative'>
            <Box mt={{ md: 2, xs: 0 }} mb={1}>
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                {recommendedProducts?.length > 0 ? (
                  <Grid item>
                    <Typography
                      variant='h4'
                      color={headingColor}
                      display='inline'
                    >
                      Recommended Products
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item>
                  <Grid container spacing={1.5}>
                    {recommendedProducts?.length > 7 ? (
                      <Grid item display={{ xs: 'none', md: 'block' }}>
                        <Grid container spacing={1.5}>
                          <Grid item>
                            <Box
                              width='40px'
                              height='40px'
                              // backgroundColor={arrowBgColor}
                              backgroundColor={
                                currentSlideIndex === 0
                                  ? filterBgColor
                                  : arrowBgColor
                              }
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              borderRadius={2}
                              sx={{ cursor: 'pointer' }}
                              onClick={() => onSlidePrev()}
                            >
                              <ChevronLeft
                                fontSize='medium'
                                sx={{
                                  color:
                                    currentSlideIndex === 0
                                      ? iconInactiveColor
                                      : headingColor,
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item>
                            <Box
                              width='40px'
                              height='40px'
                              // backgroundColor={arrowBgColor}
                              backgroundColor={
                                productCount === currentSlideIndex
                                  ? filterBgColor
                                  : arrowBgColor
                              }
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              borderRadius={2}
                              sx={{ cursor: 'pointer' }}
                              onClick={() => onSlideNext()}
                            >
                              <ChevronRight
                                fontSize='medium'
                                sx={{
                                  color:
                                    productCount === currentSlideIndex + 7
                                      ? iconInactiveColor
                                      : headingColor,
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {matches ? (
              recommendedProducts?.length <= 7 ? (
                <Grid container mt={2} spacing={2}>
                  {recommendedProducts?.map((item) => {
                    return (
                      <Grid item md={1.71}>
                        <ProductCard
                          img={item.productPhoto[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={item.isOutOfStock}
                          isComingSoon={item.isComingSoon}
                          disableAddtoCart={true}
                          product={item}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <Slider
                  ref={slider}
                  {...settings}
                  beforeChange={(oldIndex, newIndex) => {
                    setCurrentSlideIndex(newIndex)
                  }}
                >
                  {/* {recommendedProducts?.slice(0, 10).map((item, index) => { */}
                  {recommendedProducts?.map((item) => {
                    return (
                      <Box py={1.5} mx={1}>
                        <ProductCard
                          img={item.productPhoto[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={item.isOutOfStock}
                          isComingSoon={item.isComingSoon}
                          disableAddtoCart={true}
                          product={item}
                        />
                      </Box>
                    )
                  })}
                </Slider>
              )
            ) : (
              <Box sx={styles.scrollBox} ref={containerRef}>
                {recommendedProducts?.slice(0, 10).map((item) => {
                  return (
                    <Box py={1.5} mx={1}>
                      <ProductCard
                        img={item.productPhoto[0]?.docURL}
                        price={item.productSalePrice}
                        oldPrice={item.productOriginalPrice}
                        description={item.productName}
                        discount={item.percentageOff}
                        style={{ my: 1.5 }}
                        isOutOfStock={item.isOutOfStock}
                        isComingSoon={item.isComingSoon}
                        disableAddtoCart={true}
                        product={item}
                      />
                    </Box>
                  )
                })}
              </Box>

              // <Slider
              //   ref={slider}
              //   {...settings}
              //   beforeChange={(oldIndex, newIndex) => {
              //     setCurrentSlideIndex(newIndex)
              //   }}
              // >
              //   {recommendedProducts?.slice(0, 10).map((item, index) => {
              //     return (
              //       <Box py={1.5} mx={1}>
              //         <ProductCard
              //           img={item.productPhoto[0]?.docURL}
              //           price={item.productSalePrice}
              //           oldPrice={item.productOriginalPrice}
              //           description={item.productName}
              //           discount={item.percentageOff}
              //           style={{ my: 1.5 }}
              //           isOutOfStock={item.isOutOfStock}
              //           isComingSoon={item.isComingSoon}
              //           disableAddtoCart={true}
              //           product={item}
              //         />
              //       </Box>
              //     )
              //   })}
              // </Slider>
            )}

            {/* Scroll Indicator Icon */}
            {!isScrollEnd && recommendedProducts?.length > 2 && (
              <Box
                sx={{
                  display: { xs: 'block', md: 'none' },
                  position: 'absolute',
                  right: '0px',
                  top: '57%',
                }}
              >
                <ScrollIndicator />
              </Box>
            )}
          </Box>
        ) : (
          <Box
            minHeight='20vh'
            size={25}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Loader />
          </Box>
        )}
      </Container>
    </Box>
  )
}

const styles = {
  scrollBox: {
    display: 'flex',
    alignItems: 'center',

    overflowX: 'scroll',
    overscrollBehaviorInline: 'contain',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '-webkit-overflow-scrolling': 'touch',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
}
