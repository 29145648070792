import { NavigateNextRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Typography,
  Breadcrumbs,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getActiveBusiness } from 'redux/header/headerSlice'
import {
  headingColor,
  primaryColor,
} from '../../configs/styles/muiThemes'

function DiscountDeliveryBreadcrum({ links, currentPage, setCurrentPage }) {
  const dispatch = useDispatch()
  const { businessDetails } = useSelector((state) => state.header)
  const businessName = sessionStorage.getItem('businessName')

  const [businessTitle, setBusinessTitle] = useState('')

  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    const businessInfo = sessionStorage.getItem('businessInfo')
    if (!businessInfo) {
      dispatch(getActiveBusiness())
    } else {
      const data = JSON.parse(businessInfo)
      setBusinessTitle(data?.[0]?.businessName)
      console.log(data?.[0]?.businessName)
    }
  }, [businessDetails])

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          // display: { xs: 'none', md: 'flex' },
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '39px',
        }}
        px={2}
        py='5px'
        borderRadius='6px'
        mb={{ xs: 1, md: 1.5 }}
      >
        <Breadcrumbs separator={<NavigateNextRounded fontSize='small' />}>
          <Typography
            key='1'
            color={headingColor}
            fontSize='14px'
            component={Link}
            to={`/${businessName}`}
          >
            {businessTitle.split(' ').slice(0, 2).join(' ')}
          </Typography>

          {matches
            ? links?.map((link, index) => {
                return (
                  <Typography
                    key='2'
                    // color={
                    //   index == links?.length - 1 ? primaryColor : headingColor
                    // }
                    color={headingColor}
                    fontSize='14px'
                    component={Link}
                    to={link?.disabled ? '' : `/${businessName}${link.to}`}
                  >
                    {link.label}
                  </Typography>
                )
              })
            : null}

          {matches ? (
            <Typography
              key='2'
              color={
                currentPage === 'address-page' ? headingColor : primaryColor
              }
              fontSize='14px'
              onClick={() => {
                setCurrentPage('discount')
              }}
              sx={{ cursor: currentPage === 'address-page' ? 'pointer' : '' }}
            >
              Discounts/Coupons
            </Typography>
          ) : null}

          {matches ? (
            currentPage === 'address-page' ? (
              <Typography
                key='2'
                color={
                  currentPage === 'address-page' ? primaryColor : headingColor
                }
                fontSize='14px'
              >
                Address & Delivery
              </Typography>
            ) : null
          ) : null}
        </Breadcrumbs>

        <Button
          onClick={() => {}}
          sx={{
            textTransform: 'none',
            color: 'primary',
            textDecorationLine: 'underline',
            fontSize: '14px',
            fontWeight: 600,
          }}
          endIcon={<NavigateNextRounded />}
          component={Link}
          to={`/${businessName}/catalog`}
        >
          Shop More
        </Button>
      </Box>
    </>
  )
}

export default DiscountDeliveryBreadcrum
