import { Box, Grid, Typography } from '@mui/material'
import {
  borderLineColor,
  couponColor,
  headingColor,
} from '../../configs/styles/muiThemes'
import { PaymentRounded } from '@mui/icons-material'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import moment from 'moment'

function ETADetails({ deliveryETA, pickupETA, deliveryDate, pickupDate }) {
  return (
    <Box
      border={'1px solid ' + borderLineColor}
      borderRadius={2.2}
      px={1.85}
      pt={1.85}
      my={3}
    >
      <Typography
        variant='h5'
        component='h3'
        color={headingColor}
        fontSize={{ xs: 14, md: 15 }}
        pb={1.8}
        borderBottom={'1px solid ' + borderLineColor}
        textAlign='right'
      >
        Delivery ETA
      </Typography>

      <Grid container spacing={1} pt={1.8}>
        <Grid item>
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/pick-up-icon.svg'
            alt='cod icon'
            width='25px'
            height='20px'
            sx={{ objectFit: 'contain' }}
          />
        </Grid>

        <Grid item xs>
          <Typography variant='subtitle1' component='p' color={couponColor}>
            PickUp Time <br />
            {pickupDate && pickupDate !== 0
              ? moment(pickupDate).format('MMM Do, ')
              : 'N/A'}
            {pickupETA && pickupETA !== 0
              ? moment(pickupETA).format('hh:mm A')
              : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} pb={1.8} pt={1}>
        <Grid item>
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/delivery-icon.svg'
            alt='cod icon'
            width='25px'
            height='30px'
            sx={{ objectFit: 'contain' }}
          />
        </Grid>

        <Grid item xs>
          <Typography
            variant='subtitle1'
            component='p'
            color={couponColor}
            mt='4px'
          >
            Delivery Time <br />
            {deliveryDate && deliveryDate !== 0
              ? moment(deliveryDate).format('MMM Do, ')
              : 'N/A'}
            {deliveryETA && deliveryETA !== 0
              ? moment(deliveryETA).format('hh:mm A')
              : ''}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ETADetails
