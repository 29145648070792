import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import en from 'javascript-time-ago/locale/en.json'
import Home from './pages/Home'
import Overview from './pages/Overview'
import DealsCoupons from './pages/DealsCoupons'
import Review from './pages/Review'
import { WinstonProvider } from 'winston-react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import logger from './utils/logger'
import TimeAgo from 'javascript-time-ago'
import CatalogMui from './pages/CatalogMui'
import SingleProduct from './pages/SingleProduct'
import Cart from './pages/Cart'
import DiscountDelivery from './pages/DiscountDelivery'
import Confirmation from './pages/Confirmation'
import Dashboard from './pages/Dashboard'
import AllOrders from './pages/AllOrders'
import OpenOrders from './pages/OpenOrders'
import CompletedOrders from './pages/CompletedOrders'
import CanceledOrders from './pages/CanceledOrders'
import OrderDetails from './pages/OrderDetails'
import Search from './pages/Search'
import OtherOrders from 'pages/OtherOrders'
import TermsAndConditions from 'pages/TermsAndConditions'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import ScrollToTop from 'components/ScrollToTop'
import { useEffect, useState } from 'react'
import { GlobalDebug } from 'remove-consoles'
import { Suspense } from 'react'
import ShopCostBoHome from 'pages/ShopCostBoHome'
import UserAuth from 'pages/UserAuth'
import { getAccountData } from 'services/api_calls/dashboard_api/DashboardApi'
import { getAccessToken } from 'utils/authHelpers'
import ReturnPolicy from 'pages/ReturnPolicy'
import { getUserLocation } from 'utils/geoLocationHelper'
import FullHeightLoader from 'components/FullHeightLoader'
import { useDispatch } from 'react-redux'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { getBusinessIdData } from 'services/api_calls/root/RootApi'
import ShareAndEarn from './pages/ShareAndEarn'
import AffiliateEnroll from './pages/AffiliateEnroll'
import AffiliateBankDetails from 'pages/AffiliateBankDetails'
import AffiliatePrograms from 'pages/AffiliatePrograms'
import PayCartConfirmation from 'pages/PayCartConfirmation'
import PaycartDetails from 'pages/PaycartDetails'

const isLogged = false

const routes = (
  <Suspense fallback={<p></p>}>
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path='/' exact element={<ShopCostBoHome />} />
          <Route path='/:businessName' exact element={<Home />} />
          <Route path='/:businessName/catalog' element={<CatalogMui />} />
          <Route path='/:businessName/overview' element={<Overview />} />
          <Route
            path='/:businessName/dealsCoupons'
            element={<DealsCoupons />}
          />
          <Route path='/:businessName/review' element={<Review />} />
          <Route
            path='/:businessName/singleProduct/:productId'
            element={<SingleProduct />}
          />
          <Route path='/:businessName/cart' element={<Cart />} />
          <Route
            path='/:businessName/discountDelivery'
            element={<DiscountDelivery />}
          />
          <Route
            path='/:businessName/confirmation'
            element={<Confirmation />}
          />
          <Route
            path='/:businessName/easycartConfirmation'
            element={<PayCartConfirmation />}
          />
          <Route path='/:businessName/dashboard' element={<Dashboard />} />
          <Route path='/:businessName/allOrders' element={<AllOrders />} />
          <Route path='/:businessName/openOrders' element={<OpenOrders />} />
          <Route
            path='/:businessName/completedOrders'
            element={<CompletedOrders />}
          />
          <Route
            path='/:businessName/canceledOrders'
            element={<CanceledOrders />}
          />
          <Route path='/:businessName/otherOrders' element={<OtherOrders />} />
          <Route
            path='/:businessName/orderDetails/:cartId'
            element={<OrderDetails />}
          />
          <Route
            path='/:businessName/easycart/:paycartId'
            element={<PaycartDetails />}
          />

          <Route path='/:businessName/search' element={<Search />} />
          {/* SubDomain Auth */}
          <Route path='/:businessName/userauth' element={<UserAuth />} />
          <Route
            //  path='/:businessName/termsAndConditions'
            path='/termsAndConditions'
            element={<TermsAndConditions />}
          />
          <Route
            //  path='/:businessName/privacyPolicy'
            path='/privacyPolicy'
            element={<PrivacyPolicy />}
            // element={
            //   isLogged ? (
            //     <PrivacyPolicy />
            //   ) : (
            //     <Navigate to="/itree" replace />
            //   )
            // }
          />
          <Route path='/return-policy' element={<ReturnPolicy />} />
          <Route path='/cancellation-policy' element={<ReturnPolicy />} />

          {/* Affiliate */}
          <Route path='/:businessName/affiliates' element={<ShareAndEarn />} />
          <Route
            path='/:businessName/affiliateEnroll'
            element={<AffiliateEnroll />}
          />
          <Route
            path='/:businessName/affiliateBankDetails'
            exact
            element={<AffiliateBankDetails />}
          />
          <Route
            path='/:businessName/affiliateHome'
            exact
            element={<AffiliatePrograms />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  </Suspense>
)

function App() {
  // const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  TimeAgo.addDefaultLocale(en)

  const checkTokenValid = async () => {
    const currentUrl = window.location.hostname
    const businessName = sessionStorage.getItem('businessName')
    const token = getAccessToken()

    if (token) {
      const response = await getAccountData()

      if (response?.status === 401) {
        sessionStorage.clear()
        localStorage.clear()

        if (businessName) {
          // window.location.replace(`https://shop.costbo.com/${businessName}`)
          window.location.replace(`https://shop.costbo.com/${businessName}`)
        } else {
          window.location.replace(`https://shop.costbo.com/`)
        }
      }

      console.log('TOKEN_CHECK:', response)
      console.log('TOKEN_CHECK_HOSTNAME:', currentUrl)
    }
  }

  // const getBusinessInfo = async (businessName) => {
  //   const response = await getBusinessIdData(businessName)
  //   if (!response?.validate) {
  //     setLoader(false)
  //     getUserLocation()
  //     return
  //   }
  //   if (response?.businessId) {
  //     if (localStorage.getItem('businessName') !== businessName) {
  //       localStorage.setItem('businessName', businessName)
  //       // window.location.replace(window.location.href)
  //     }

  //     dispatch(getCommercialDetails()).then((res) => {
  //       console.log('resss', res)
  //       if (
  //         res?.payload?.info.costboPowered?.colorScheme?.primaryColor &&
  //         res?.payload?.info.costboPowered?.colorScheme?.secondaryColor
  //       ) {
  //         if (
  //           sessionStorage.getItem(`primary`) !==
  //             res?.payload?.info.costboPowered?.colorScheme?.primaryColor ||
  //           sessionStorage.getItem(`secondary`) !==
  //             res?.payload?.info.costboPowered?.colorScheme?.secondaryColor
  //         ) {
  //           sessionStorage.setItem(
  //             `primary`,
  //             res?.payload?.info.costboPowered?.colorScheme?.primaryColor
  //           )
  //           sessionStorage.setItem(
  //             `secondary`,
  //             res?.payload?.info.costboPowered?.colorScheme?.secondaryColor
  //           )
  //           window.location.replace(window.location.href)
  //         }
  //       } else {
  //         getUserLocation()
  //         sessionStorage.setItem(`primary`, 'fa6138')
  //         sessionStorage.setItem(`secondary`, '3C98AF')
  //         // window.location.replace(window.location.href)
  //       }
  //       setLoader(false)
  //     })
  //   }
  // }

  useEffect(() => {
    process.env.REACT_APP_ENV === 'STAGING' && GlobalDebug(false)
    // console.log = () => {}
    console.warn = () => {}
    checkTokenValid()
    getUserLocation()

    // const url = window.location.pathname
    // const paths = url.split('/')
    // const businessName = paths[1]
    // if (businessName?.length === 0) {
    //   setLoader(false)
    // } else {
    //   getBusinessInfo(businessName)
    // }
  }, [])

  return (
    <WinstonProvider logger={logger}>
      <div className='main-wrapper'>{routes}</div>
      {/* <div className='main-wrapper'>{!loader && routes}</div>
      {loader && <FullHeightLoader />}  */}
    </WinstonProvider>
  )
}

export default App
