import { LATITUDE, LONGITUDE } from 'configs/Constants'
import {
  getApiInstance,
  getApiInstanceForExistingUser,
  getApiInstanceForLogin,
  getApiInstanceForPassword,
  getApiInstanceForRegister,
} from 'services/ApiInstance'
import { AUTH_TOKEN_URL, CREATE_GUEST_USER_URL } from 'services/constant'
import { getProfileId, storeUserInfo } from 'utils/authHelpers'

export const authLogin = async (data) => {
  console.log(data)
  const phone = '+91' + data.phone.replace(' ', '')
  const loginFormData = new FormData()
  loginFormData.append('grant_type', 'password')
  loginFormData.append('username', phone.replace('-', ''))
  loginFormData.append('password', data.password)

  const instance = getApiInstanceForLogin()
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/oauth/token`,
      loginFormData
    )
    return response
  } catch (error) {
    console.log('hblhlkgbkb', error.response)
    return error.response
  }
}

export const storeUserData = async (data) => {
  console.log(data)
  // let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000)
  const lat = localStorage.getItem('lat') || LATITUDE
  const long = localStorage.getItem('long') || LONGITUDE
  const formData = {
    login: data.phone,
    password: data.password,
    firstName: data.name,
    lastName: '',
    email: data.email.toLowerCase(),
    phone: data.phone,
    type: '0',
    imeiNumber: '',
    deviceModel: 'WEB',
    osVersion: 'Windows',
    deviceID: 'windows-10-Chrome',
    clientVersion: '',
    latitude: lat,
    longitude: long,
    gender: 'M/F',
    birthYear: '0',
    fcmKey: '',
    apnsKey: '',
    fcmUserToken: data.token,
    id: '',
    imageUrl: '',
    status: '',
    sr: false,
    country: 'INDIA',
    xmppID: data.xmpprandomNumber,
  }

  console.log('USER_DATA', formData)

  const instance = getApiInstanceForRegister()
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/registerUser`,
      formData
    )
    return response
  } catch (error) {
    console.log(error)
  }
}
export const updateUserData = async (data) => {
  console.log(data)
  const { values, id, token, xmpprandomNumber } = data
  console.log('values', values, 'id', id)
  // let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000)
  const lat = localStorage.getItem('lat') || LATITUDE
  const long = localStorage.getItem('long') || LONGITUDE
  const formData = {
    login: values.phone,
    password: values.password,
    firstName: values.name,
    lastName: '',
    id: id,
    email: values.email.toLowerCase(),
    phone: values.phone,
    type: '0',
    imeiNumber: '',
    deviceModel: 'WEB',
    osVersion: 'Windows',
    deviceID: 'windows-10-Chrome',
    clientVersion: '',
    latitude: lat,
    longitude: long,
    gender: 'M/F',
    birthYear: '0',
    fcmKey: '',
    apnsKey: '',
    fcmUserToken: values.token,
    imageUrl: '',
    status: '',
    sr: false,
    country: 'INDIA',
    xmppID: xmpprandomNumber,
  }

  console.log('USER_DATA', formData)

  const instance = getApiInstanceForRegister()
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/account/updateUser`,
      formData,
      { headers: { Authorization: 'Bearer ' + token } }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const eRegister = async (data) => {
  console.log('E_REGISTER', data)
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/eRegister`,
      data.eRegister,
      {
        headers: {
          profileId: data.profileId,
        },
      }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getCodeToMail = async (data) => {
  console.log(data)
  let phone = '+91' + data.phone.replace('-', '')
  phone = phone.replace(' ', '')
  const instance = getApiInstanceForPassword()
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/account/reset-password/init`,
      phone
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const changePassword = async (data) => {
  console.log(data)
  let key = data.key
  let password = data.password
  const formJson = {
    key: key,
    newPassword: password,
  }
  const instance = getApiInstanceForPassword()
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/account/reset-password/finish`,
      formJson
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getProfileData = async () => {
  const profile = { _pId: getProfileId() }
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      `https://web.costbo.com/account-query-side/api/v1/account/getAccount`,
      profile
    )
    if (response.status === 200) {
      storeUserInfo(response.data)
    }
    return response
  } catch (error) {
    console.log(error)
  }
}

export const checkUser = async (data) => {
  console.log(data)
  const instance = getApiInstanceForExistingUser()
  const phone = '+91' + data?.phone?.replace(' ', '')
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/account/checkUser`,
      {},
      {
        headers: {
          phone: phone.replace('-', ''),
        },
      }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}
export const checkUserGuest = async (data) => {
  console.log('req_header', data)
  const instance = getApiInstanceForExistingUser()
  const phone = '+91' + data?.replace(' ', '')
  console.log('req_header1', phone)
  try {
    const response = await instance.post(
      `https://web.costbo.com/costbouaa/api/account/checkUser`,
      {},
      {
        headers: {
          phone: phone.replace('-', ''),
        },
      }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}
export const createGuestUser = async (data) => {
  console.log('req_body', data)
  const instance = getApiInstance()
  try {
    const response = await instance.post(CREATE_GUEST_USER_URL, data)
    return response
  } catch (error) {
    console.log(error)
  }
}
export const userAuthGuestUser = async (data) => {
  console.log('req_body', data)
  const instance = getApiInstance()
  try {
    const response = await instance.post(AUTH_TOKEN_URL, data, {
      headers: { Authorization: 'Basic Z3Vlc3RfYXBwOg==' },
    })

    sessionStorage.setItem('token', response.data.access_token)
    sessionStorage.setItem('profileId', response.data.randomNo)
    return response
  } catch (error) {
    console.log(error)
  }
}
export const upadateRewardProfile = async (data) => {
  // console.log('req_body', data)
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      'reward-command-side/api/v1/reward/updateRewardProfile',
      data.data,
      {
        headers: { contactNo: data.phone },
      }
    )

    // sessionStorage.setItem('token', response.data.access_token)
    // sessionStorage.setItem('profileId', response.data.randomNo)
    return response
  } catch (error) {
    console.log(error)
  }
}
export const getOwnRewards = async (data) => {
  // console.log('req_body', data)
  const instance = getApiInstance()
  try {
    const response = await instance(
      `reward-query-side/api/v1/reward/getOwnRewards?contact=${data}`
    )
    return response
  } catch (error) {
    console.log(error)
  }
}
