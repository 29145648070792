import FooterMui from "../components/FooterMui";
import SearchComponent from "./Search/SearchComponent";
import TopComponent from "./TopComponent";

function Search() {
  return (
    <div>
      {/* <Navbar value="" /> */}
      <TopComponent value='' disableBanners />
      {/* <ConfirmationContent /> */}
      <SearchComponent />
      <FooterMui />
    </div>
  );
}

export default Search;
