import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAccountDetails,
  getAllOrderDetails,
} from 'redux/dashboard/dashboardSlice'
import FooterMui from '../components/FooterMui'
import { useParams } from 'react-router-dom'
import OtherOrdersContent from './OtherOrders/OtherOrdersContent'
import TopComponent from './TopComponent'

function OtherOrders() {
  const { businessId } = useParams()
  const [otherOrders, setOtherOrders] = useState([])
  const dispatch = useDispatch()
  const { accountDetails, orderDetails, loading } = useSelector(
    (state) => state.dashboard
  )

  useEffect(() => {
    if (accountDetails == null) {
      dispatch(getAccountDetails())
    }
    if (orderDetails == null) {
      dispatch(getAllOrderDetails(businessId))
    }
  }, [])

  useEffect(() => {
    const orderList = orderDetails?.details?.filter((item)=>{
        return item.orderStatus === 'paycartcreated'
    })

    if(orderList){
        setOtherOrders(orderList)
    }
  }, [orderDetails])
  

  return (
    <div>
      {/* <Navbar value='' /> */}
      <TopComponent value='' disableBanners />
      <OtherOrdersContent
        accountDetails={accountDetails}
        orderDetails={otherOrders}
        loading={loading}
        businessId={businessId}
      />
      <FooterMui />
    </div>
  )
}

export default OtherOrders
