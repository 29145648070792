import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Radio,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  borderLineColor,
  discountBgColor,
  errorTextColor,
  headingColor,
  minOrderTextColor,
  primaryColor,
  secondaryColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { addressCard_DeleteAddressInfo } from 'configs/Constants'
import moment from 'moment'
import { checkIsUpdateRequired } from 'utils/AddressHelper/addressHelper'
import { textOneLines } from 'utils/styleUtility'

function AddressCard({
  onEdit,
  address,
  readOnly,
  onSelectMethod,
  onDeleteAddress,
  onEditAddress,
  cardStyle,
  isAddressSelected,
  showAlternateNo,
  recipientPhInline,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [open, setOpen] = useState(false)

  const [isUpdateRequired, setIsUpdateRequired] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setIsUpdateRequired(checkIsUpdateRequired(address?.updatedOn))

    return () => {}
  }, [address])

  return (
    <Box sx={{ ...styles.addressCard, ...cardStyle }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {addressCard_DeleteAddressInfo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onDeleteAddress()
              handleClose()
            }}
            variant='contained'
          >
            Delete
          </Button>
          <Button onClick={handleClose} autoFocus variant='outlined'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        onClick={() => {
          if (!readOnly) {
            onSelectMethod()
          }
        }}
      >
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item xs={12}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Box flex={1} display='flex' alignItems='center'>
                {!readOnly ? (
                  <Radio
                    value='a'
                    checked={isAddressSelected || false}
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'A' }}
                    // onChange={() => {
                    //   onSelectMethod()
                    // }}
                    size='small'
                    sx={{ width: 35, height: 27 }}
                  />
                ) : null}

                <Typography
                  variant='h6'
                  component='p'
                  fontSize={{ xs: 12.5, md: 13.5 }}
                  color={headingColor}
                  display='inline'
                  px={readOnly ? 1.5 : 0}
                  lineHeight='20px'
                >
                  {matches
                    ? address?.name
                    : `${address?.name.slice(0, 15)}${
                        address?.name?.length > 16 ? '...' : ''
                      }`}
                </Typography>
              </Box>

              {isUpdateRequired && (
                <Button
                  variant='outlined'
                  onClick={() => onEditAddress()}
                  sx={{
                    fontSize: 10.5,
                    color: primaryColor,
                    borderRadius: '3px',
                    border: `1px solid ${primaryColor}`,
                    lineHeight: '17px',
                    p: '2px 4px',
                    mr: 1,
                    '&:hover': {
                      color: whiteColor,
                      bgcolor: primaryColor,
                    },
                  }}
                >
                  Update Required
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box px={1.5}>
          <Box sx={{ height: { xs: '65px', md: '70px' } }}>
            {matches ? (
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 12, md: 13 }}
                lineHeight={{ xs: '20px', md: '22px' }}
                pt={0.3}
                color={minOrderTextColor}
              >
                {address?.addressLine1?.slice(0, 100)}
                {address?.addressLine1?.length > 100 ? '...' : null}
              </Typography>
            ) : (
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 12, md: 13 }}
                lineHeight={{ xs: '20px', md: '22px' }}
                pt={0.3}
                color={minOrderTextColor}
              >
                {address?.addressLine1?.slice(0, 60)}
                {address?.addressLine1?.length > 60 ? '...' : null}
              </Typography>
            )}

            <Typography
              variant='subtitle1'
              component='p'
              fontSize={{ xs: 12, md: 13 }}
              lineHeight={{ xs: '20px', md: '22px' }}
              color={minOrderTextColor}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {address?.city}, {address?.state} - {address?.zip}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Box
        display={'flex'}
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        px={1.5}
      >
        <Box>
          <Typography
            variant='subtitle1'
            component='h6'
            fontSize={{ xs: 13, md: 13 }}
            lineHeight={{ xs: '18px', md: '22px' }}
            py={0.75}
            color={headingColor}
          >
            Phone : {address?.phoneNo}{' '}
            {recipientPhInline
              ? address?.alternatePhoneNo
                ? `, +91${address?.alternatePhoneNo}`
                : ''
              : ''}
          </Typography>

          {showAlternateNo && address?.alternatePhoneNo && (
            <Typography
              variant='subtitle1'
              component='h6'
              fontSize={{ xs: 13, md: 13 }}
              lineHeight={{ xs: '18px', md: '18px' }}
              pb={0.75}
              color={headingColor}
            >
              Recipient Ph:{' '}
              {address?.alternatePhoneNo
                ? '+91' + address?.alternatePhoneNo
                : '-'}
            </Typography>
          )}
        </Box>
        {/* <Typography
            variant='h6'
            component='p'
            sx={{ display: { md: 'block', xs: 'none' } }}
            fontSize={{ xs: 13, md: 13.5 }}
            lineHeight={{ xs: '18px', md: '22px' }}
            color={headingColor}
            px={readOnly ? 1.5 : 0}
          >
            {address?.addressType}
          </Typography> */}
        {!readOnly ? (
          <Box display='block'>
            <Grid container>
              <Grid item>
                <IconButton onClick={() => onEditAddress()}>
                  <EditRoundedIcon
                    sx={{
                      ...styles.actionBtn,
                      fontSize: { xs: '16px', md: '18px' },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleClickOpen()}>
                  <DeleteRoundedIcon
                    sx={{
                      ...styles.actionBtn,
                      fontSize: { xs: '16px', md: '18px' },
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <IconButton onClick={() => onEditAddress()}>
            <EditRoundedIcon
              sx={{
                ...styles.actionBtn,
                fontSize: { xs: '16px', md: '18px' },
              }}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default AddressCard

const styles = {
  addressCard: {
    backgroundColor: discountBgColor,
    borderRadius: 1.8,
    border: '1px solid' + borderLineColor,
    py: 0.3,
    mr: { md: 2, xs: 0.5 },
  },
  actionBtn: {
    '&:hover': {
      color: errorTextColor,
    },
  },
}
