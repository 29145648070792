import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addProductToCart,
  checkOrderProducts,
  createCartData,
  updateCart,
  updateProductCartQty,
} from 'services/api_calls/cart/CartApi'
import {
  addLocalCartHelper,
  updateCartHelper,
  updateCartProductPriceHelper,
  updateLocalCartHelper,
} from 'utils/cartHelper'

const initialState = {
  updateQty: null,
  addedProduct: null,
  localCartData: null,
  checkOrderProducts: [],
  updateCartRes: null,
  updatePriceRes: null,
  loading: false,
  createCartRes: null,
  updateCartIntRes: null,
}

//Updating Cart Product Qty
export const updateCartProductQuantity = createAsyncThunk(
  'updateCartProductQuantity',
  async (data) => {
    const res = await updateProductCartQty(data)
    // console.log("UPDATE_CART_QTY_DATA",res)
    return res
  }
)

//Create cart
export const createCart = createAsyncThunk('createCart', async (data) => {
  const res = await createCartData(data)
  // console.log("ADD_NEW_PRODUCT_DATA",data)
  return res
})

//Adding New Product to cart
export const addNewProductCart = createAsyncThunk(
  'addNewProductCart',
  async (data) => {
    const res = await addProductToCart(data)
    // console.log("ADD_NEW_PRODUCT_DATA",data)
    return res
  }
)

//Check Order Products Cart Page
export const checkCartOrderProducts = createAsyncThunk(
  'checkCartOrderProducts',
  async (data) => {
    const res = await checkOrderProducts(data)
    return res
  }
)

//Update Cart
export const updateCartProducts = createAsyncThunk(
  'updateCartProducts',
  async (data) => {
    const postData = updateCartHelper(
      data?.cartData,
      data?.orderProductData,
      data?.selectedProduct
    )
    const res = await updateCart(postData)
    return res
  }
)
//Update Cart
export const updateCartDetails = createAsyncThunk(
  'updateCartDetails',
  async (data) => {
    const res = await updateCart(data)
    return res
  }
)

//Update Cart Product Price
export const updateCartProductPrice = createAsyncThunk(
  'updateCartProductPrice',
  async (data) => {
    const postData = updateCartProductPriceHelper(
      data?.cartData,
      data?.orderProductData,
      data?.selectedProduct
    )
    const res = await updateCart(postData)
    return res
  }
)
//Add to Local Cart
export const addLocalCart = createAsyncThunk('addLocalCart', async (data) => {
  const res = addLocalCartHelper(data)
  console.log("SESSION_CART_DATA_1");
  sessionStorage.setItem('cartData', JSON.stringify(res))
  return res
})

//Remove product from Local Cart
export const removeProductLocalCart = createAsyncThunk(
  'removeProductLocalCart',
  async (data) => {
    const cartData = JSON.parse(sessionStorage.getItem('cartData'))
    let totalCost = 0
    let totalQty = 0

    const productList = cartData?.productList?.filter((item) => {
      if (item?.productId !== data?.productId) {
        totalCost += item?.productSalePrice * item?.currentQuantity
        totalQty += item?.currentQuantity
      }
      return item?.productId !== data?.productId
    })

    cartData.productList = productList
    cartData.orderTotalCost = totalCost
    cartData.orderTotalProductCost = totalCost
    cartData.totalQuantity = totalQty

    console.log("SESSION_CART_DATA_2");
    sessionStorage.setItem('cartData', JSON.stringify(cartData))
    return cartData
  }
)

//Update Local Cart
export const updateToLocalCart = createAsyncThunk(
  'updateToLocalCart',
  async (data) => {
    const res = updateLocalCartHelper(data)

    console.log("SESSION_CART_DATA_3");
    sessionStorage.setItem('cartData', JSON.stringify(res))
    return res
  }
)

export const cartSlice = createSlice({
  name: 'cartupdateadd',
  initialState,
  reducers: {},
  extraReducers: {
    [updateCartProductQuantity.pending]: (state) => {
      state.loading = false
    },
    [updateCartProductQuantity.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.updateQty = payload
    },
    [updateCartProductQuantity.rejected]: (state) => {
      state.loading = false
    },

    //Adding new product
    [addNewProductCart.pending]: (state) => {
      state.loading = true
    },
    [addNewProductCart.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.addedProduct = payload
    },
    [addNewProductCart.rejected]: (state) => {
      state.loading = false
    },

    //Create Cart
    [createCart.pending]: (state) => {
      state.loading = true
    },
    [createCart.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.createCartRes = payload
    },
    [createCart.rejected]: (state) => {
      state.loading = false
    },

    //Check Order Products Cart Page
    [checkCartOrderProducts.pending]: (state) => {
      state.loading = false
    },
    [checkCartOrderProducts.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.checkOrderProducts = payload?.details
    },
    [checkCartOrderProducts.rejected]: (state) => {
      state.loading = false
    },

    //Update Cart Product
    [updateCartProducts.pending]: (state) => {
      state.loading = false
    },
    [updateCartProducts.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.updateCartRes = payload?.details
    },
    [updateCartProducts.rejected]: (state) => {
      state.loading = false
    },

    //Update Cart Product Price
    [updateCartProductPrice.pending]: (state) => {
      state.loading = false
    },
    [updateCartProductPrice.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.updatePriceRes = payload
    },
    [updateCartProductPrice.rejected]: (state) => {
      state.loading = false
    },

    //Add Local Cart
    [addLocalCart.pending]: (state) => {
      state.loading = false
    },
    [addLocalCart.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.localCartData = payload
    },
    [addLocalCart.rejected]: (state) => {
      state.loading = false
    },

    //Update Local Cart
    [updateToLocalCart.pending]: (state) => {
      state.loading = false
    },
    [updateToLocalCart.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.localCartData = payload
    },
    [updateToLocalCart.rejected]: (state) => {
      state.loading = false
    },

    //remove Product Local Cart
    [removeProductLocalCart.pending]: (state) => {
      state.loading = false
    },
    [removeProductLocalCart.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.localCartData = payload
    },
    [removeProductLocalCart.rejected]: (state) => {
      state.loading = false
    },
    //Update Cart
    [updateCartDetails.pending]: (state) => {
      state.loading = false
    },
    [updateCartDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.updateCartIntRes = payload
    },
    [updateCartDetails.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const cartReducer = cartSlice.reducer
