import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Radio,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  borderLineColor,
  discountBgColor,
  errorTextColor,
  headingColor,
  offerTextColor,
  savingTextColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { instantDiscountDataHelper } from 'utils/checkoutHelpers'
import {
  instantCashCard_DiscountExceedErr,
  instantCashCard_MinAmtErr,
  instantCashCard_SelectErr,
} from 'configs/Constants'

function InstantCashCard({
  isSelected,
  onSelect,
  instantDiscounts,
  cartDetails,
  setDiscountAmount,
  setAppliedDiscountDetails,
}) {
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [amount, setAmount] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [selectedDiscount, setSelectedDiscount] = useState(null)
  let businessFullName = sessionStorage.getItem('businessFullName')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (item) => {
    // console.log('SELECTED_INSTANT_DISCOUNT', item)

    setAnchorEl(null)
    if (!item?.id) return

    if (!item?.pointValue || item?.pointValue === 0) {
      if (item?.pointPercentage !== 0) {
        const orderTotal = cartDetails?.orderTotalProductCost
        const discountPercentage = item?.pointPercentage

        const discountAmt = (orderTotal / 100) * discountPercentage
        setSelectedDiscount({ ...item, pointValue: discountAmt })
      }
    } else {
      setSelectedDiscount(item)
    }
  }

  useEffect(() => {
    if (!isSelected) {
      setSelectedDiscount(null)
      setDiscountAmount(0)
      setAppliedDiscountDetails(null)
      setIsError(false)
      setIsSuccess(false)
      setErrorMsg('')
      setAmount(null)
    }
  }, [isSelected])

  const applyDiscount = () => {
    setIsError(false)
    setIsSuccess(false)

    if (!selectedDiscount) {
      setErrorMsg(instantCashCard_SelectErr)
      setIsError(true)
      setDiscountAmount(0)
      console.log(`SELECTED_INSTANT_DISCOUNT => Select Instant Discount`)
      return
    }

    if (cartDetails.orderTotalProductCost < selectedDiscount.minimumAmount) {
      setErrorMsg(
        `${instantCashCard_MinAmtErr} ${selectedDiscount.minimumAmount}`
      )
      setIsError(true)
      setDiscountAmount(0)
      console.log(
        `SELECTED_INSTANT_DISCOUNT => Minimum order amount should be ₹ ${selectedDiscount.minimumAmount}`
      )
      return
    }

    if (selectedDiscount.pointValue > cartDetails.orderTotalProductCost) {
      setErrorMsg(instantCashCard_DiscountExceedErr)
      setIsError(true)
      setDiscountAmount(0)
      console.log(
        `SELECTED_INSTANT_DISCOUNT => Discount cannot exceed Order Amount`
      )
      return
    }

    setIsSuccess(true)
    setAmount(selectedDiscount.pointValue)
    setDiscountAmount(selectedDiscount.pointValue)
    const data = instantDiscountDataHelper(selectedDiscount, cartDetails)
    setAppliedDiscountDetails(data)

    console.log(`SELECTED_INSTANT_DISCOUNT_Res =>`, data)
    console.log(
      `SELECTED_INSTANT_DISCOUNT => Congrats! Discount applied ₹ ${selectedDiscount.pointValue}`
    )
  }

  return (
    <Box sx={styles.discountCard(isSelected)}>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <Radio
            checked={isSelected}
            onChange={() => {
              onSelect()
            }}
            value='a'
            name='radio-buttons'
            inputProps={{ 'aria-label': 'A' }}
            size='small'
            sx={{ width: 35, height: 30 }}
          />
          <Typography
            variant='h6'
            component='p'
            fontSize={{ xs: 13.5, ms: 14 }}
            color={headingColor}
            display='inline'
          >
            Instant Cash Discount
          </Typography>
        </Grid>

        <Grid item>
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/coupon-4.svg'
            alt={businessFullName + ' CostBo'}
            width={{ xs: '48px', md: '46px' }}
            height={{ xs: '32px', md: '30px' }}
          />
        </Grid>
      </Grid>

      <Box mx={1.3} mt={{ xs: 1, md: 1.3 }} mb={{ xs: 0, md: 0.5 }}>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid item xs={8.5}>
            <Box
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={isSelected ? handleClick : () => {}}
              sx={styles.inputBox(isSelected, isSuccess)}
              fullWidth
              disabled={!isSelected}
              disableRipple
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              // sx={styles.dropdownBtn}
              // endIcon={<KeyboardArrowDownOutlined />}
            >
              <Typography
                variant='body1'
                component='p'
                fontSize='11px'
                color={headingColor}
                lineHeight='16px'
              >
                {/* {text} */}
                {selectedDiscount
                  ? `${
                      selectedDiscount.pointPercentage !== 0
                        ? `${selectedDiscount.pointPercentage}%`
                        : `₹${selectedDiscount.pointValue}`
                    } OFF on minimum purchase of ₹${
                      selectedDiscount.minimumAmount
                    }`
                  : 'Select Instant Discount'}
              </Typography>

              <ArrowDropDownRoundedIcon sx={{ fontSize: '28px' }} />
            </Box>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              variant='selectedMenu'
              PaperProps={{
                style: {
                  width: 320,
                  maxHeight: 250,
                },
              }}
            >
              {instantDiscounts?.map((item) => {
                if (
                  !moment().isBetween(item?.pointStartDate, item?.pointEndDate)
                )
                  return null

                return (
                  <MenuItem
                    onClick={() => {
                      handleClose(item)
                    }}
                    value={item.resellerCode}
                  >
                    {item.pointValue === 0
                      ? `${item.pointPercentage}%`
                      : `₹${item.pointValue}`}{' '}
                    OFF on minimum purchase of ₹ {item.minimumAmount}
                  </MenuItem>
                )
              })}
            </Menu>
          </Grid>

          <Grid item xs={3}>
            {isSelected && (
              <Button
                sx={styles.actionBtn}
                variant='contained'
                disableElevation
                onClick={() => {
                  applyDiscount()
                }}
              >
                Apply
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      {isSuccess ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={{ xs: '11px', md: '12px' }}
          color={savingTextColor}
          display='inline'
          mx={1.4}
        >
          Congrats! Discount applied ₹{Number(amount).toFixed(2)}
          {/* {JSON.stringify(cartResellerDetails)} */}
        </Typography>
      ) : null}
      {/* Error and Success Message */}
      {isError ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={{ xs: '11px', md: '12px' }}
          color={errorTextColor}
          display='inline'
          mx={1.4}
        >
          {errorMsg}
        </Typography>
      ) : null}
    </Box>
  )
}

export default InstantCashCard

const styles = {
  discountCard: (isSelected) => ({
    backgroundColor: isSelected ? discountBgColor : whiteColor,
    borderRadius: '6px',
    border: '1px solid' + borderLineColor,
    px: 0.5,
    py: 1,
    height: { xs: '109px', md: '125px' },
  }),

  inputBox: (isSelected, isSuccess) => ({
    height: { xs: '24px', md: '24px' },
    fontSize: { xs: '12px', md: '13px' },
    fontWeight: isSuccess ? 600 : 400,
    // color: savingTextColor,
    color: isSelected ? headingColor : offerTextColor,
    border: '1.5px dashed #299956',
    backgroundColor: whiteColor,
    borderRadius: { xs: '4px', md: '6px' },
    py: 0.8,
    px: 1,
    '&:hover': {
      backgroundColor: whiteColor,
    },
  }),

  actionBtn: {
    height: { xs: '33px', md: '36px' },
    fontSize: { xs: '13px', md: '14px' },
    fontWeight: 600,
    color: savingTextColor,
    backgroundColor: whiteColor,
    px: { xs: '20px', md: '28px' },
    border: '1px solid ' + savingTextColor,
    borderRadius: { xs: '4px', md: '6px' },
    '&:hover': {
      backgroundColor: savingTextColor,
      color: whiteColor,
    },
  },
}

// const styles = {
//   discountCard: (isSelected) => ({
//     backgroundColor: isSelected ? discountBgColor : whiteColor,
//     borderRadius: 1.8,
//     border: '1px solid' + borderLineColor,
//     px: 0.5,
//     py: 1,
//     height: '160px',
//   }),

//   inputBox: (isSelected) => ({
//     height: '40px',
//     fontSize: '14px',
//     fontWeight: 400,
//     // color: savingTextColor,
//     color: isSelected ? headingColor : offerTextColor,
//     border: '1.5px dashed #299956',
//     backgroundColor: whiteColor,
//     borderRadius: 1.5,
//     py: 0.8,
//     px: 1,
//     '&:hover': {
//       backgroundColor: whiteColor,
//     },
//   }),
//   inputBoxOut: {
//     height: '40px',
//     fontSize: '14px',
//     fontWeight: 400,
//     // color: savingTextColor,
//     color: headingColor,
//     border: 'none',
//     backgroundColor: whiteColor,
//     borderRadius: 1.5,
//     py: 0.8,
//     px: 1,
//   },

//   actionBtn: {
//     height: '40px',
//     fontSize: { xs: '13px', md: '14px' },
//     fontWeight: 600,
//     color: savingTextColor,
//     backgroundColor: whiteColor,
//     px: { xs: '20px', md: '28px' },
//     border: '1px solid ' + savingTextColor,
//     borderRadius: 1.5,
//     '&:hover': {
//       backgroundColor: savingTextColor,
//       color: whiteColor,
//     },
//   },
// }
