import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAccountDetails,
  getAllCompletedOrderDetails,
  getOrdersCount,
} from 'redux/dashboard/dashboardSlice'
import FooterMui from '../components/FooterMui'
import CompletedOrdersContent from './CompletedOrders/CompletedOrdersContent'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserName } from 'utils/authHelpers'
import TopComponent from './TopComponent'

function CompletedOrders() {
  const { businessName } = useParams()
  // const { businessId } = useParams()
  const businessId = sessionStorage.getItem('businessId')

  const dispatch = useDispatch()
  const { accountDetails, completedOrderDetails, loading, ordersCountData } =
    useSelector((state) => state.dashboard)

  const isLoggedIn = getUserName().length === 0 ? false : true

  let navigate = useNavigate()

  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    if (isLoggedIn) {
      if (accountDetails == null) {
        dispatch(getAccountDetails())
      }
      if (completedOrderDetails == null) {
        dispatch(getAllCompletedOrderDetails({ businessId, pageNo: 1 }))
      }

      dispatch(getOrdersCount(businessId))
    } else {
      navigate(`/${businessName}`)
    }
  }, [])

  const handleOrderPagination = (pageNumber) => {
    dispatch(getAllCompletedOrderDetails({ businessId, pageNo: pageNumber }))
    setPageNo(pageNumber)
  }

  return (
    <div>
      {/* <Navbar value='' /> */}

      <TopComponent value='' disableBanners />
      <CompletedOrdersContent
        accountDetails={accountDetails}
        completedOrderDetails={completedOrderDetails}
        loading={loading}
        businessId={businessId}
        ordersCountData={ordersCountData}
        handleOrderPagination={handleOrderPagination}
        pageNo={pageNo}
      />
      <FooterMui />
    </div>
  )
}

export default CompletedOrders
