import { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import {
  getActiveBusiness,
  getBusinessReviewCount,
  getSocialActivityOfBusiness,
} from '../redux/header/headerSlice'
import { getUserName } from 'utils/authHelpers'
import { getRewardPointsDetails } from 'redux/checkout/CheckoutSlice'
import BannerMui from '../components/BannerMui'
import Navbar from '../components/layout/navbar'
import { skeletonBox } from 'utils/styleUtility'
import { getOverviewDetails } from 'redux/overview/overviewSlice'
import { getReviewSummaryDetails } from 'redux/reviews/reviewsSlice'
import { updateIsLogin } from 'redux/authRedux/authSlice'
import {
  getAffiliateValidity,
  getRefRatesByBusinessID,
  getResellerProfileData,
} from 'redux/affiliate/AffiliateSlice'

const TopComponent = ({
  value,
  showLoginForm,
  disableBanners,
  bannerHide,
  onUserLogin,
}) => {
  const dispatch = useDispatch()
  const { businessDetails, loading, summary, social, total } = useSelector(
    (state) => state.header
  )
  const { reviewSummary } = useSelector((state) => state.review)
  const { overviewDetails } = useSelector((state) => state.overview)
  const { commercialDetails } = useSelector((state) => state.cart)

  const { isUserLogin, loginDetails } = useSelector((state) => state.auth)
  const { rewardpoints } = useSelector((state) => state.checkout)
  const { businessId } = useSelector((state) => state.rootSlice)
  const businessInfo = JSON.parse(sessionStorage.getItem('businessInfo'))
  const activity = JSON.parse(sessionStorage.getItem('socialActivity'))
  const totalData = JSON.parse(sessionStorage.getItem('totalViews'))

  const { businessAffiliateData, affiliateValidity } = useSelector(
    (state) => state.affiliate
  )

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    if (!businessDetails) {
      dispatch(getActiveBusiness())
    }
    if (!overviewDetails) {
      dispatch(getOverviewDetails())
    }
  }, [])

  useEffect(() => {
    if (businessId) {
      if (!social) {
        dispatch(getSocialActivityOfBusiness())
      }
      if (!total) {
        dispatch(getBusinessReviewCount())
      }
      if (reviewSummary?.length === 0) {
        dispatch(getReviewSummaryDetails())
      }

      // Check business Affiliate enrolled
      if (!businessAffiliateData) {
        dispatch(getRefRatesByBusinessID(businessId?.businessId))
      }
    }
  }, [businessId])

  useEffect(() => {
    if (loginDetails) {
      setIsLoggedIn(true)
      dispatch(getRewardPointsDetails())
      dispatch(updateIsLogin())
    } else {
      dispatch(updateIsLogin())
    }
  }, [loginDetails])

  useEffect(() => {
    if (isUserLogin) {
      dispatch(getResellerProfileData())
      if (!affiliateValidity) {
        dispatch(getAffiliateValidity(businessId?.businessId))
      }
    }
    return () => {}
  }, [isUserLogin])

  return (
    <Suspense fallback={<p></p>}>
      {businessDetails && commercialDetails ? (
        <>
          {businessInfo === null && (
            <>
              <Navbar
                value={value}
                logoUrl={businessDetails?.businessInfo?.[0]?.logoURL}
                showLoginForm={showLoginForm || showLogin}
                onUserLogin={onUserLogin}
              />

              {loading === false &&
              businessDetails &&
              businessDetails.businessInfo &&
              summary &&
              summary.reviewSummary &&
              social &&
              total ? (
                <Box>
                  {disableBanners ? null : (
                    <Suspense fallback={<p></p>}>
                      <BannerMui
                        isLoggedIn={isLoggedIn}
                        businessDetails={businessDetails}
                        summary={reviewSummary}
                        social={social}
                        total={total}
                        rewardpoints={rewardpoints}
                        onLogin={() => setShowLogin(!showLogin)}
                        bannerHide={bannerHide}
                        commercialDetails={commercialDetails}
                      />
                    </Suspense>
                  )}
                </Box>
              ) : disableBanners ? null : (
                <Box
                  sx={{
                    width: '100%',
                    height: '370px',
                    ...skeletonBox.boxAnim,
                    backgroundColor: '#e8e8e8',
                  }}
                />
              )}
            </>
          )}

          {businessInfo !== null && (
            <>
              <Navbar
                value={value}
                logoUrl={businessInfo?.[0]?.logoURL}
                showLoginForm={showLoginForm || showLogin}
                rewardpoints={rewardpoints}
              />

              {disableBanners ? null : (
                <BannerMui
                  isLoggedIn={isLoggedIn}
                  businessDetails={businessInfo}
                  summary={reviewSummary}
                  social={activity}
                  total={totalData}
                  rewardpoints={rewardpoints}
                  onLogin={() => setShowLogin(!showLogin)}
                  bannerHide={bannerHide}
                  commercialDetails={commercialDetails}
                />
              )}
            </>
          )}
        </>
      ) : null}
    </Suspense>
  )
}
export default TopComponent
