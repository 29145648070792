import { Avatar, Badge, Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import {
  badgeBackgroundColor,
  borderCart,
  borderProduct,
  headingColor,
  primaryColor,
  whiteColor,
} from '../configs/styles/muiThemes'
import { Link } from 'react-router-dom'
import Slider from 'react-slick/lib/slider'
import { goToCartText } from 'configs/Constants'

function CartDetailsBottonCard({
  itemCount,
  amount,
  onGoToCartClicked,
  cartProductList,
}) {
  const businessName = sessionStorage.getItem('businessName')
  const cartListSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  }

  if (cartProductList?.productList?.length == 0) return <></>

  return (
    <Box backgroundColor={whiteColor} p="8px 0" sx={styles.cartBottomCard}>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        {/* <Box sx={{ width: '50%' }}>
          <Slider {...cartListSettings}>
            {cartProductList?.productList?.map((item, index) => {
              return (
                <ProductCount
                  src={item.productPicURL}
                  alt={item.productName}
                  count={item.currentQuantity}
                  key={'cart_product_' + index}
                  mx={0.5}
                />
              )
            })}
          </Slider>
        </Box> */}

        <Grid item>
        <Typography
            variant='h6'
            color={headingColor}
            fontSize='13px'
            fontWeight={500}
            display='inline'
            px={2}
            borderRight={borderCart}
          >
            {itemCount} items in cart
          </Typography>

          <Typography
            variant='h5'
            color={headingColor}
            fontSize='15px'
            fontWeight={600}
            display='inline'
            mx={2}
          >
            ₹ {Number(amount).toFixed(2)}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            sx={styles.gotoCardBtn}
            variant='contained'
            disableElevation
            size='small'
            // onClick={() => {onGoToCartClicked()}}
            component={Link}
            to={`/${businessName}/Cart`}
          >
            {goToCartText}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CartDetailsBottonCard

const ProductCount = ({ src, alt, count }) => {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box py={0.3} px={0.6}>
      <Badge
        sx={styles.badge}
        overlap='circular'
        badgeContent={count}
        // sx={{ m: 2 }}
      >
        <Box
          component='img'
          src={src}
          alt={businessFullName + ' CostBo'}
          backgroundColor={whiteColor}
          sx={styles.itemCard}
        />
      </Badge>
    </Box>
  )
}

const styles = {
  cartBottomCard: {
    width: '100vw',
    display: { xs: 'block', md: 'none' },
    borderBottom: '1.5px solid #f4f6fa',
    position: 'fixed',
    bottom: '63px',
    left: 0,
    zIndex: 10,
  },

  gotoCardBtn: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'white',
    p: '3px 15px',
    border: '2px solid ' + primaryColor,
    textTransform: 'none',
    borderRadius: '6px',
    m:"0 10px 0 0 !important"
  },

  itemCard: {
    width: '45px',
    height: '45px',
    border: borderProduct,
    borderRadius: '10px',
    p: 0.5,
  },

  badge: {
    '& .MuiBadge-badge': {
      color: whiteColor,
      backgroundColor: badgeBackgroundColor,
    },
  },
}
