import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, Radio, Typography } from '@mui/material'
import {
  headingColor,
  iconInactiveColor,
  arrowBgColor,
  filterBgColor,
  whiteColor,
  radioBtnLabelColor,
  primaryColor,
} from '../../configs/styles/muiThemes'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import SearchIcon from '@mui/icons-material/Search'
import { Link } from 'react-router-dom'

function DiscountHeader({
  title,
  subtitle,
  icon,
  add,
  type,
  discountType,
  onCancelDiscount,
  onAddAddress,
  onChangeAddress,
  onNext,
  onPrev,
  slideIndex,
  addressCount,
  address,
  buttonName,
  items,
  showInstorePick,
  isInstorePick,
  onInstorePickClick,
  collapseCartTabel,
  onToggleCartItems,
  specialInstructions,
  collapseInstructions,
  onToggleInstructions,
  disablePB,
  gst,
  onSearchClick,
}) {
  const businessName = sessionStorage.getItem('businessName')
  return (
    <Grid
      container
      pt={{ md: 1.6, xs: 1 }}
      pb={{ md: disablePB ? 0 : 1.6, xs: 0.8 }}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      spacing={1.5}
    >
      <Grid item>
        <Grid
          container
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, ms: 16 }}
              color={headingColor}
              display='inline'
            >
              {icon} {'    '}
              {title}
              <Typography
                variant='h5'
                component='h3'
                fontSize={{ xs: 14, ms: 16 }}
                color={headingColor}
                fontWeight={400}
                display={{ xs: 'block', md: 'inline' }}
                ml={0.6}
              >
                {subtitle}
              </Typography>
            </Typography>
          </Grid>

          {showInstorePick ? (
            <Grid item>
              <Radio
                checked={isInstorePick}
                onChange={() => {
                  onInstorePickClick()
                }}
                value='a'
                name='radio-buttons'
                inputProps={{ 'aria-label': 'A' }}
              />
              <Typography
                variant='subtitle1'
                component='p'
                color={radioBtnLabelColor}
                display='inline'
              >
                Instore Pickup (Open Hours)
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {address ? (
        add ? (
          <>
            <Grid item sx={{ display: { md: 'none', xs: 'block' } }}>
              <IconButton sx={styles.searchBtn} onClick={onSearchClick}>
                <SearchIcon />
              </IconButton>
            </Grid>

            <Grid item sx={{ display: { md: 'block', xs: 'none' } }}>
              <Grid container spacing={1.5} direction='row' alignItems='center'>
                <Grid item>
                  <Box>
                    <IconButton sx={styles.searchBtn} onClick={onSearchClick}>
                      <SearchIcon />
                    </IconButton>
                    <Button
                      variant='outlined'
                      sx={styles.actionBtn}
                      startIcon={<AddRoundedIcon />}
                      onClick={onAddAddress}
                    >
                      Add new Address
                    </Button>
                  </Box>
                </Grid>

                {addressCount > 2 ? (
                  <Grid item display={{ xs: 'none', md: 'block' }}>
                    <Grid container spacing={1.5} direction='row'>
                      <Grid item>
                        <Box
                          width='40px'
                          height='40px'
                          // backgroundColor={arrowBgColor}
                          backgroundColor={
                            slideIndex === 0 ? filterBgColor : arrowBgColor
                          }
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          borderRadius={2}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => onPrev()}
                        >
                          <ChevronLeft
                            fontSize='medium'
                            sx={{
                              color:
                                slideIndex === 0
                                  ? iconInactiveColor
                                  : headingColor,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          width='40px'
                          height='40px'
                          // backgroundColor={arrowBgColor}
                          backgroundColor={
                            addressCount - 2 === slideIndex
                              ? filterBgColor
                              : arrowBgColor
                          }
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          borderRadius={2}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => onNext()}
                        >
                          <ChevronRight
                            fontSize='medium'
                            sx={{
                              color:
                                addressCount - 2 === slideIndex
                                  ? iconInactiveColor
                                  : headingColor,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item>
            <Box display='flex' alignItems='center'>
              <IconButton sx={styles.searchBtn} onClick={onSearchClick}>
                <SearchIcon />
              </IconButton>
              <Button
                variant='outlined'
                sx={{
                  ...styles.actionBtn,
                  display: { md: 'flex', xs: 'none' },
                }}
                startIcon={<EditRoundedIcon />}
                onClick={() => onChangeAddress()}
              >
                Change Addrress
              </Button>
            </Box>
          </Grid>
        )
      ) : null}
      {items ? (
        <>
          <Grid item display={{ xs: 'none', md: 'flex' }}>
            <Button
              variant='outlined'
              sx={styles.actionBtn}
              component={Link}
              to={`/${businessName}/cart`}
            >
              Modify Cart
            </Button>
          </Grid>

          <Grid item display={{ xs: 'flex', md: 'none' }}>
            <IconButton
              aria-label='delete'
              size='small'
              onClick={() => {
                onToggleCartItems()
              }}
            >
              {collapseCartTabel ? (
                <ExpandMoreRounded fontSize='medium' />
              ) : (
                <ExpandLessRounded fontSize='medium' />
              )}
            </IconButton>
          </Grid>
        </>
      ) : null}
      {gst ? (
        <>
          <Grid item display={{ xs: 'flex', md: 'none' }}>
            <IconButton
              aria-label='delete'
              size='small'
              onClick={() => {
                onToggleCartItems()
              }}
            >
              {collapseCartTabel ? (
                <ExpandMoreRounded fontSize='medium' />
              ) : (
                <ExpandLessRounded fontSize='medium' />
              )}
            </IconButton>
          </Grid>
        </>
      ) : null}

      {specialInstructions ? (
        <Grid item>
          <IconButton
            aria-label='delete'
            size='small'
            onClick={() => {
              onToggleInstructions()
            }}
          >
            {collapseInstructions ? (
              <ExpandMoreRounded fontSize='medium' />
            ) : (
              <ExpandLessRounded fontSize='medium' />
            )}
          </IconButton>
        </Grid>
      ) : null}

      {discountType ? (
        <Grid item>
          <Button
            variant='outlined'
            sx={styles.actionBtn}
            onClick={onCancelDiscount}
            size='small'
          >
            Cancel Discount
          </Button>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default DiscountHeader

const styles = {
  actionBtn: {
    fontSize: { xs: '11px', md: '13px' },
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: 'primary.main',
      color: whiteColor,
    },
  },

  searchBtn: {
    width: '35px',
    height: '32px',
    color: primaryColor,
    backgroundColor: whiteColor,
    border: `0.5px solid ${primaryColor}`,
    borderRadius: '5px',
    mr: 1.5,

    '&:hover': {
      color: whiteColor,
      backgroundColor: primaryColor,
    },
  },
}
