import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addSearchInfo,
  getSearchProducts,
} from 'services/api_calls/searchProduct_api/SearchProductApi'

const initialState = {
  searchData: null,
  searchQuery: '',
  loading: false,
  data: null,
  loader: false,
}

//Search products
export const searchProducts = createAsyncThunk(
  'searchProducts',
  async (data) => {
    const res = await getSearchProducts(data)
    return res
  }
)
export const addSearchInfoAPI = createAsyncThunk(
  'addSearchInfoAPI',
  async (data) => {
    const res = await addSearchInfo(data)
    return res
  }
)

export const searchProductSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    clearSearchData: (state) => {
      state.searchQuery = ''
      state.searchData = null
    },
  },
  extraReducers: {
    [searchProducts.pending]: (state) => {
      state.loading = false
    },
    [searchProducts.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.searchData = payload
    },
    [searchProducts.rejected]: (state) => {
      state.loading = false
    },
    //searchBusiness
    [addSearchInfoAPI.pending]: (state) => {
      state.loader = true
      state.data = null
    },
    [addSearchInfoAPI.fulfilled]: (state, { payload }) => {
      state.loader = payload
      state.data = false
    },
    [addSearchInfoAPI.rejected]: (state) => {
      state.loader = false
    },
  },
})

export const { setSearchQuery, clearSearchData } = searchProductSlice.actions

export const searchProductReducer = searchProductSlice.reducer
