import { Box, Grid, Typography } from '@mui/material'
import {
  businessBodyColor,
  headingColor,
  secondaryColor,
  stepperInactiveColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function BusinessInfoCard(props) {
  return (
    <Box sx={styles.card}>
      <Grid container direction='row' spacing={2} alignItems='center'>
        <Grid item>
          <Box sx={styles.iconCircle}>{props.icon}</Box>
        </Grid>

        <Grid item xs>
          <Box>
            <Typography
              variant='h5'
              align='left'
              fontSize='14px'
              color={headingColor}
              // marginBottom='8px !important'
            >
              {props.title}
            </Typography>
            {!props.address ? (
              <Typography variant='body1' color={businessBodyColor}>
                {props.description}
              </Typography>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      {props.address ? (
        <Typography
          variant='body1'
          color={businessBodyColor}
          mt={1}
          sx={{
            borderTop: '1px solid #e9eaed',
            p: '10px 5px',
          }}
        >
          {props.description}
        </Typography>
      ) : null}
    </Box>
  )
}

export default BusinessInfoCard

const styles = {
  card: {
    borderRadius: '6px',
    padding: '12px 10px',
    background: stepperInactiveColor,
    border: '1px solid ' + stepperInactiveColor,
    marginBottom: '15px',
  },

  iconCircle: {
    width: 37,
    height: 37,
    fontSize: '17px',
    borderRadius: '50%',
    backgroundColor: secondaryColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: whiteColor,
  },
}
