import { Box, Button, Typography } from '@mui/material'
import {
  cardEmptyGuestBtnText,
  reviewLoginAlertImgUrl,
  reviewLoginMsg,
} from 'configs/Constants'
import { headingColor } from 'configs/styles/muiThemes'
import React from 'react'

function ReviewLoginAlert({ onLoginClick }) {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Box
        component='img'
        src={reviewLoginAlertImgUrl}
        width='100px'
        height='auto'
        alt={businessFullName + ' CostBo'}
      />
      <Typography variant='subtitle2' fontSize={16} color={headingColor} py={2}>
        {reviewLoginMsg}
      </Typography>

      <Button
        variant='contained'
        disableElevation
        onClick={() => {
          onLoginClick()
        }}
      >
        {cardEmptyGuestBtnText}
      </Button>
    </Box>
  )
}

export default ReviewLoginAlert
