import FreeShippingContentCard from 'components/FreeShippingContentCard'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { fintechApplyData } from 'utils/utilities'
import {
  backgroundColor,
  bgColor,
  blackColor,
  borderCart,
  catalogSubHeaderColor,
  codBtnColor,
  couponColor,
  greyShade40,
  headingColor,
  loginPrimary,
  primaryColor,
  secondaryColor,
  topbarColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import InfoIcon from '@mui/icons-material/Info'

function CartBottomTab({
  totalQty,
  amount,
  btnText,
  onBtnClicked,
  codAmt,
  onCodClicked,
  isDisabled,
  showCodBtn,
  codCost,
  freeAlert,
  confirmPayLoader,
  finboxStatus,
  finboxAvlAmt,
  page,
  onModalFinboxUrl,
  handleFinboxClick,
  freeAmt,
  finvervAmt,
  handleNotRegistered,
  handleNotCompleted,
  handleFinvervClick,
  showEasyCartBtn,
  confirmPaycartOrder,
  paycartLoading,
}) {
  const [tooltip, setTooltip] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')

  const handleClickOutside = () => {
    setTooltip(false)
  }
  const ref = useOutsideClick(handleClickOutside)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handlePaymentConfirm = () => {
    if (selectedOption === 'pay-now') {
      onBtnClicked()
    } else if (selectedOption === 'easy-cart') {
      confirmPaycartOrder()
    } else if (selectedOption === 'cod') {
      onCodClicked()
    } else {
      return false
    }
  }

  return (
    <Box backgroundColor={whiteColor} py={1.5} sx={styles.card}>
      {freeAlert ? (
        <Box mb={1}>
          <FreeShippingContentCard amt={freeAmt} />
        </Box>
      ) : null}

      {page === 'address-page' && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: borderCart,
              p: '6px 0 8px',
              mb: 1.5,
              px: 1.5,
            }}
          >
            <Typography
              variant='body2'
              fontSize={13}
              color={blackColor}
              fontWeight={600}
            >
              Select a Payment Method
            </Typography>

            <Box>
              <Typography
                variant='h5'
                fontSize={11}
                color={catalogSubHeaderColor}
                fontWeight={500}
              >
                Total Amount
              </Typography>

              <Typography
                variant='body1'
                fontSize={14.5}
                color={blackColor}
                fontWeight={600}
              >
                ₹ {Number(amount).toFixed(2)}
              </Typography>
            </Box>
          </Box>

          <Box px={1.5}>
            <Stack direction='row'>
              <PaymentOption
                label='Pay Now'
                isChecked={selectedOption === 'pay-now'}
                value='pay-now'
                handleChange={handleOptionChange}
              />

              {showEasyCartBtn && page === 'address-page' && (
                <PaymentOption
                  label='Generate Easy Cart Link'
                  isChecked={selectedOption === 'easy-cart'}
                  value='easy-cart'
                  handleChange={handleOptionChange}
                />
              )}
            </Stack>

            {showCodBtn && (
              <PaymentOption
                label='Cash on Delivery'
                subText={`(COD Cast ₹${Number(codCost).toFixed(2)}) | ₹${Number(
                  codAmt
                ).toFixed(2)}`}
                isChecked={selectedOption === 'cod'}
                value='cod'
                handleChange={handleOptionChange}
              />
            )}

            <Box display='flex' justifyContent='flex-end' mt={1.5}>
              <Button
                sx={{
                  ...styles.gotoCardBtn(isDisabled),
                  border: confirmPayLoader
                    ? 'none'
                    : `1px solid ${loginPrimary}`,
                }}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  handlePaymentConfirm()
                }}
                disabled={isDisabled || confirmPayLoader || paycartLoading}
              >
                {confirmPayLoader || paycartLoading ? (
                  <CircularProgress
                    sx={{ color: loginPrimary, mr: 1 }}
                    size={16}
                  />
                ) : null}
                CONFIRM
              </Button>
            </Box>
          </Box>
        </>
      )}

      <Box px={1} sx={{ position: 'relative' }}>
        {tooltip ? (
          <Box
            sx={{
              backgroundColor: '#f5f5f5',
              px: 2,
              py: 1,
              borderRadius: 2,
              maxWidth: '60%',
              position: 'absolute',
              bottom: '115%',
              border: '1px solid #efefef',
            }}
            ref={ref}
          >
            {finboxStatus === 'APPLY' || finboxStatus === 'notRegistered' ? (
              <>
                {fintechApplyData.map((item, index) => {
                  return (
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: 'list-item',
                        }}
                        key={index + 'main_product'}
                        primary={
                          <Typography
                            variant='subtitle1'
                            fontSize='12px'
                            color={couponColor}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )
                })}
              </>
            ) : null}
            {finboxStatus === 'INCOMPLETE' ||
            finboxStatus === 'notCompleted' ? (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    key={'main_product'}
                    primary={
                      <Typography
                        variant='subtitle1'
                        fontSize='13px'
                        color={couponColor}
                      >
                        You are just few steps away from receiving working
                        capital
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
            {finboxStatus === 'APPROVED' ? (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    key={'main_product'}
                    primary={
                      <Typography
                        variant='subtitle1'
                        fontSize='13px'
                        color={couponColor}
                      >
                        Just complete last few steps to avail the funds
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
            {finboxStatus === 'ACTIVE' || finboxStatus === 'DUE' ? (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    key={1}
                    primary={
                      <Typography
                        variant='subtitle1'
                        fontSize='13px'
                        color={couponColor}
                      >
                        Available credit: ₹{finboxAvlAmt}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    key={2}
                    primary={
                      <Typography
                        variant='subtitle1'
                        fontSize='13px'
                        color={couponColor}
                      >
                        You will receive an OTP in your registered phone number
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    key={3}
                    primary={
                      <Typography
                        variant='subtitle1'
                        fontSize='13px'
                        color={couponColor}
                      >
                        Verify and agree to the terms to use the credit line
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
            {finboxStatus === 'balanceAvailable' ? (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    key={1}
                    primary={
                      <Typography
                        variant='subtitle1'
                        fontSize='13px'
                        color={couponColor}
                      >
                        Available credit: ₹{finvervAmt}
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
          </Box>
        ) : null}

        {finboxStatus === 'APPLY' && page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: 'none',
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: '16px', ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  onModalFinboxUrl()
                }}
              >
                Apply for Credit line
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {(finboxStatus === 'INCOMPLETE' || finboxStatus === 'APPROVED') &&
        page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: 'none',
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: '16px', ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  onModalFinboxUrl()
                }}
                // disabled={isDisabled}
              >
                Complete your credit application
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {(finboxStatus === 'ACTIVE' || finboxStatus === 'DUE') &&
        page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
            // sx={{ borderBottom: '1px solid #737373' }}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: 'none',
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: '16px', ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  handleFinboxClick()
                }}
                // disabled={isDisabled}
              >
                PAY WITH CREDIT LINE
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {finboxStatus === 'balanceAvailable' && page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
            // sx={{ borderBottom: '1px solid #737373' }}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: 'none',
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: '16px', ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  handleFinvervClick()
                }}
                // disabled={isDisabled}
              >
                PAY WITH CREDIT LINE
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {finboxStatus === 'notRegistered' && page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: 'none',
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: '16px', ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  handleNotRegistered()
                }}
              >
                Apply for Credit line
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {finboxStatus === 'notCompleted' && page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: 'none',
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: '16px', ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  handleNotCompleted()
                }}
                // disabled={isDisabled}
              >
                Complete your credit application
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {/* {showEasyCartBtn && page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item xs></Grid>

            <Grid item>
              <Button
                sx={styles.codBtn(paycartLoading)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  confirmPaycartOrder()
                }}
                disabled={paycartLoading}
              >
                GENERATE EASY CART LINK
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {showCodBtn ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item>
              <Typography
                variant='body2'
                color={catalogSubHeaderColor}
                fontWeight={500}
                display='inline'
                px={1.5}
                borderRight={borderCart}
              >
                COD Cost:{' '}
                <span style={{ fontWeight: 600, color: blackColor }}>
                  ₹{Number(codCost).toFixed(2)}
                </span>
              </Typography>
              
              <Typography
                variant='h6'
                color={headingColor}
                display='inline'
                ml={1.5}
              >
                ₹{Number(codAmt).toFixed(2)}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                sx={styles.codBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  onCodClicked()
                }}
                disabled={isDisabled}
              >
                CASH ON DELIVERY
              </Button>
            </Grid>
          </Grid>
        ) : null} */}

        {page !== 'address-page' && (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <Typography
                variant='body2'
                color={catalogSubHeaderColor}
                fontWeight={500}
                display='inline'
                px={1.5}
                borderRight={borderCart}
              >
                {totalQty} items in cart
              </Typography>
              <Typography
                variant='h6'
                color={headingColor}
                display='inline'
                ml={1.5}
              >
                ₹{Number(amount).toFixed(2)}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                sx={{
                  ...styles.gotoCardBtn(isDisabled),
                  border: confirmPayLoader
                    ? 'none'
                    : `1px solid ${loginPrimary}`,
                }}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  onBtnClicked()
                }}
                disabled={isDisabled || confirmPayLoader}
              >
                {confirmPayLoader ? (
                  <CircularProgress
                    sx={{ color: loginPrimary, mr: 1 }}
                    size={16}
                  />
                ) : null}
                {btnText}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default CartBottomTab

const PaymentOption = ({ isChecked, handleChange, value, label, subText }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Radio
        size='small'
        id={value}
        checked={isChecked}
        onChange={handleChange}
        value={value}
      />

      <Box>
        <Typography
          component='label'
          for={value}
          sx={{
            display: 'inline',
            fontSize: 13.5,
            fontWeight: 600,
            color: blackColor,
          }}
        >
          {label}
        </Typography>

        <Typography
          sx={{
            display: 'inline',
            fontSize: 12,
            fontWeight: 500,
            color: catalogSubHeaderColor,
            ml: 0.5,
          }}
        >
          {subText}
        </Typography>
      </Box>
    </Box>
  )
}

const useOutsideClick = (callback) => {
  const ref = useRef()

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [ref])

  return ref
}

const styles = {
  card: {
    width: '100vw',
    display: { xs: 'block', md: 'none' },
    borderTop: '1px solid #f4f6fa',
    borderBottom: '1.5px solid #f4f6fa',
    position: 'fixed',
    bottom: '58px',
    left: 0,
    zIndex: 10,
    boxShadow: '0 1px 4px rgb(0 0 0 / 8%)',
  },

  gotoCardBtn: (disabled) => ({
    // width:'145px',
    fontSize: '12px',
    fontWeight: 500,
    color: 'white',
    p: { xs: '3px 15px', md: '3px 8px' },
    border: disabled
      ? '0px solid ' + primaryColor
      : '2px solid ' + primaryColor,
    textTransform: 'none',
    borderRadius: '6px',
  }),

  codBtn: (disabled) => ({
    // width:'145px',
    fontSize: '12px',
    fontWeight: 500,
    color: 'white',
    p: { xs: '3px 15px', md: '3px 8px' },
    border: disabled
      ? '0px solid ' + secondaryColor
      : '2px solid ' + secondaryColor,
    textTransform: 'none',
    borderRadius: '6px',
    backgroundColor: secondaryColor,
  }),

  finboxBtn: (disabled) => ({
    // width:'145px',
    fontSize: '12px',
    fontWeight: 500,
    color: 'white',
    p: { xs: '3px 15px', md: '3px 8px' },
    border: disabled ? '0px solid ' + topbarColor : '2px solid ' + topbarColor,
    borderRadius: '6px',
    backgroundColor: topbarColor,
    textTransform: 'uppercase',
  }),
}
