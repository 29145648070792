import moment from "moment"

export const checkIsUpdateRequired = (updatedOn) => {
  const lastUpdatedDate = updatedOn ? moment(updatedOn) : null
  const checkDate = moment('17-11-2023', 'DD-MM-YYYY')

  // console.log('DATE_CHECK__lastUpdatedDate:', lastUpdatedDate)
  // console.log('DATE_CHECK__checkDate:', checkDate)

  if (!lastUpdatedDate) {
    return true
  }

  if (lastUpdatedDate) {
    if (lastUpdatedDate.isBefore(checkDate)) {
      return true
    }
  }

  return false
}
