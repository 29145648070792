import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import ModalCard from 'components/ModalCard'
import { headingColor, whiteColor } from 'configs/styles/muiThemes'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  validateCoupon,
  validateCouponAtag,
} from 'services/api_calls/checkout/checkoutApi'

function OfferPopupModal({ couponCode }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { businessDetails } = useSelector((state) => state.header)

  const [couponData, setCouponData] = useState(null)
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    validateCouponCode()
    return () => {}
  }, [])

  const validateCouponCode = async () => {
    const response = await validateCouponAtag(couponCode?.toLowerCase())

    if (response?.reseller) {
      sessionStorage.setItem(
        'specialOfferData',
        JSON.stringify(response?.reseller)
      )
      setCouponData(response?.reseller)
      setShowPopup(true)
    }
  }

  return (
    <ModalCard
      handleClose={() => {
        setShowPopup(false)
      }}
      open={showPopup}
      width={matches ? '30%' : '80%'}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: whiteColor,
          borderRadius: '15px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background:
              'url(https://storage.googleapis.com/bodefaults/shopweb/special-offer-wave-bg.jpg) no-repeat',
            padding: '15px',
            borderRadius: '15px 15px 0 0',
          }}
        >
          <Box
            component='img'
            src={businessDetails?.businessInfo?.[0]?.logoURL}
            alt='business logo'
            style={styles.brandLogo}
          />
          <Typography
            variant='h4'
            component='h4'
            fontSize={{ xs: 15, md: 17 }}
            color={headingColor}
          >
            {couponData?.discountPercentage === 0
              ? 'Buy directly from us!'
              : 'Special offer exclusively for you!'}
          </Typography>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px',
          }}
        >
          {couponData?.discountPercentage !== 0 ? (
            <>
              <Typography
                variant='h4'
                component='h2'
                fontSize={{ xs: 24, md: 24 }}
                fontWeight='700'
                color={headingColor}
                lineHeight='32px'
                margin='14px !important'
              >
                GET {couponData?.discountPercentage}% OFF
              </Typography>

              <Typography
                variant='body1'
                component='p'
                fontSize={{ xs: 15, md: 15.5 }}
                fontWeight='500'
                lineHeight='25px'
                color='#ff6e3b'
                sx={{ textDecoration: 'underline' }}
              >
                on this order!
              </Typography>

              <Typography
                variant='h4'
                component='h6'
                fontSize={{ xs: 15, md: 15 }}
                color={headingColor}
                m='20px 0 10px 0 !important'
                textAlign='center'
                lineHeight='32px'
              >
                Use coupon code{' '}
                <span style={styles.couponCode}>
                  {couponData?.resellerCode}
                </span>{' '}
                on checkout
              </Typography>

              {/* <Typography
            variant='h4'
            component='h5'
            fontSize={{ xs: 13, md: 13.5 }}
            color='#909090'
            fontWeight='500'
            lineHeight='20px'
            textAlign='center'
            m='0 0 5px 0 !important'
          >
            Offer promoted by our star affiliate{' '}
            {couponData?.resellerName?.slice(0, 1)?.toUpperCase() +
              couponData?.resellerName?.slice(1)}
          </Typography> */}

              <Button
                variant='contained'
                disableElevation
                sx={{ m: '15px 0 15px' }}
                onClick={() => {
                  setShowPopup(false)
                }}
              >
                GRAB THIS DEAL
              </Button>
            </>
          ) : (
            <>
              <Typography
                variant='h4'
                component='h2'
                fontSize={22}
                fontWeight='700'
                color={headingColor}
                lineHeight='32px'
                margin='14px 14px 10px 14px !important'
              >
                ENJOY LOWEST PRICES!
              </Typography>

              <Typography
                variant='body1'
                component='p'
                fontSize={14}
                fontWeight='500'
                lineHeight='24px'
                color='#ff6e3b'
                sx={{ textDecoration: 'underline' }}
              >
                Great savings! On time shipping
              </Typography>

              <Button
                variant='contained'
                disableElevation
                color='secondary'
                sx={{ m: '20px 0 15px' }}
                onClick={() => {
                  setShowPopup(false)
                }}
              >
                CONTINUE SHOPPING
              </Button>
            </>
          )}
        </Box>
      </Box>
    </ModalCard>
  )
}

export default OfferPopupModal

const styles = {
  brandLogo: {
    width: '55px',
    height: 'auto',
    borderRadius: '8px',
    border: '1px solid #e7eaef',
    marginBottom: '10px',
  },

  couponCode: {
    border: '1.5px dashed #01a4b5',
    borderRadius: '5px',
    background: '#f9feff',
    padding: '3px 12px',
    color: '#01a4b5',
    fontSize: '13px',
    fontWeight: '600',
    margin: '0px 8px',
  },
}
