import { Box, Typography } from '@mui/material'
import { loginBgImgUrl } from 'configs/Constants'
import {
  headingColor,
  whiteColor,
  navHeaderColor,
} from 'configs/styles/muiThemes'
import React from 'react'

function AuthLayoutNew({ children }) {
  let businessFullName = sessionStorage.getItem('businessFullName')

  const businessInfo = JSON.parse(sessionStorage.getItem('businessInfo'))
  return (
    <Box
      sx={{
        background: whiteColor,
        borderRadius: '16px',
        maxHeight: { xs: '75vh', md: 'none' },
        overflowY: { xs: 'scroll', md: 'auto' },
      }}
    >
      <Box
        sx={{
          background: `url(${loginBgImgUrl}) no-repeat`,
          backgroundSize: 'auto 100%',
          borderRadius: '16px 16px 0px 0px',
          //   width: '100%',
          padding: 2,
          //   minHeight: '25vh',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component='img'
          src={businessInfo?.[0]?.logoURL}
          alt={businessFullName + ' CostBo'}
          width='55px'
          sx={{ objectFit: 'contain', borderRadius: 1 }}
        />
        <Typography
          variant='subtitle1'
          component='h3'
          color={headingColor}
          textAlign='center'
          fontSize={17}
          lineHeight={1.5}
          fontWeight={600}
        >
          {businessInfo?.[0]?.businessName}
        </Typography>
      </Box>
      <Box sx={{ minHeight: '10vh' }}>{children}</Box>
      <Box
        sx={{
          //   width: { xs: '100%' },
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 15px',
        }}
      >
        <Typography
          variant='body1'
          component='h4'
          color={navHeaderColor}
          textAlign='center'
          fontSize={12}
          fontWeight={500}
          //   sx={{ mt: { xs: 0, md: 3 } }}
        >
          Secure E-Commerce powered by
        </Typography>

        <Box display='flex' justifyContent='center'>
          <Box
            component='img'
            src={'https://storage.googleapis.com/bodefaults/shopweb/logo_v3.png'}
            alt={businessFullName + ' CostBo'}
            width={{ xs: '110px', md: '140px' }}
            height='auto'
            display='block'
            sx={{ m: '10px 0 18px' }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AuthLayoutNew
