import { Box, Button, Container, Typography } from '@mui/material'
import { backgroundColor, blackColor, borderLineColor, discountSectionBGColor, filterBgColor, headingColor, iconInactiveColor, navLinkColor, whiteColor } from 'configs/styles/muiThemes'
import DisCountCard from 'pages/DealsCoupons/DiscountCard'
import React, { useRef, useState } from 'react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import Slider from 'react-slick/lib/slider'
// function SampleNextArrow(props) {
//   const { className, style, onClick } = props
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', background: 'black' }}
//       onClick={onClick}
//     />
//   )
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', background: 'black' }}
//       onClick={onClick}
//     />
//   )
// }

function DiscountSection({ dealsCouponsDetails }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const slider = useRef(null)


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const onSlideNext = () => {
    if (currentSlideIndex < dealsCouponsDetails?.length - 4) {
      slider.current.slickNext()
    }
  }

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev()
    }
  }

  return (
    <Box backgroundColor={discountSectionBGColor} pb={2.5}>
      <Container sx={{ maxWidth: '1300px !important' }}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <Typography
            variant='h2'
            color={headingColor}
            fontSize={{ xs: '17px', md: '21px' }}
            textAlign={'center'}
            pt={2.8}
          >
            Awesome Discounts, Just a click away
          </Typography>
          <Typography
            variant='caption'
            fontSize={{ xs: '14px', md: '16px' }}
            textAlign={'center'}
            marginBottom='15px !important'
            mt={1}
          >
            Use coupon codes at checkout, Save More!
          </Typography>
        </Box>

        <Box sx={{ position: 'relative' }}>
          <Box
            width='40px'
            height='40px'
            backgroundColor={
              currentSlideIndex === 0 ? filterBgColor : whiteColor
            }
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius={2}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              left:"-40px",
              top: '50px',
            }}
            border={'1px solid ' + borderLineColor}
            onClick={() => onSlidePrev()}
          >
            <ChevronLeft
              fontSize='medium'
              sx={{
                color:
                  currentSlideIndex === 0 ? iconInactiveColor : navLinkColor,
              }}
            />
          </Box>
          <Box
            width='40px'
            height='40px'
            backgroundColor={
              currentSlideIndex === dealsCouponsDetails?.length - 6
                ? filterBgColor
                : whiteColor
            }
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius={2}
            border={'1px solid ' + borderLineColor}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: "-40px",
              top: '50px',
            }}
            onClick={() => onSlideNext()}
          >
            <ChevronRight
              fontSize='medium'
              sx={{
                color:
                  currentSlideIndex === dealsCouponsDetails?.length - 4
                    ? iconInactiveColor
                    : navLinkColor,
              }}
            />
          </Box>
          <Slider
            ref={slider}
            {...settings}
            beforeChange={(oldIndex, newIndex) => {
              setCurrentSlideIndex(newIndex)
            }}
          >
            {dealsCouponsDetails.map((item, index) => {
              return (
                <Box px={0.5}>
                  <DisCountCard data={item} index={index} />
                </Box>
              )
            })}
          </Slider>
        </Box>
      </Container>
    </Box>
  )
}

export default DiscountSection
