import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick/lib/slider'
import { getOutOfStock } from 'utils/productHelper'
import { whiteColor } from 'configs/styles/muiThemes'
import CategoryGroupHeader from './CategoryGroupHeader'
import ProductCard from './ProductCard'
import ScrollIndicator from 'components/ScrollIndicator'

function CategoryGroup({
  title,
  subCat,
  products,
  count,
  onViewMore,
  cartData,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  showInGrid,
}) {
  const slider = useRef(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const [isScrollEnd, setIsScrollEnd] = useState(false)
  const containerRef = useRef(null)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  }

  const onSlideNext = () => {
    if (currentSlideIndex < products.length - 5) {
      slider.current.slickNext()
    }
  }

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev()
    }
  }

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      // Check if the horizontal scroll has reached the end
      const isEnd =
        container?.scrollLeft + container?.clientWidth + 200 >=
        container?.scrollWidth

      setIsScrollEnd(isEnd)
    }

    container?.addEventListener('scroll', handleScroll)

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Box display='flex' flexDirection='column' position='relative'>
      <Box my={showInGrid ? 0.5 : 0}>
        <CategoryGroupHeader
          title={title}
          onNext={onSlideNext}
          onPrev={onSlidePrev}
          slideIndex={currentSlideIndex}
          productCount={products.length - 4}
          totalProduct={count}
          onViewMore={(catCode) => {
            onViewMore(catCode)
          }}
          subCat={subCat}
          noAction={showInGrid}
        />
      </Box>

      {/* <Box sx={styles.scrollBox}>
        {products.map((item, index) => {
          return (
            <>
              <Box py={1.5} mr={1.2}>
                <ProductCard
                  img={item.productPhoto[0].docURL}
                  price={item.productSalePrice}
                  oldPrice={item.productOriginalPrice}
                  description={item.productName}
                  discount={item.percentageOff}
                  style={{ my: 1.5 }}
                  isOutOfStock={getOutOfStock(item)}
                  isComingSoon={item.commerceEnabled === 'NO'}
                  minQty={item.minOrderQuantity}
                  maxQty={item.maxOrderQuantity}
                  cartData={cartData}
                  product={item}
                  cartId={cartId}
                  onQtyUpdate={(data) => {
                    onQtyUpdate(data)
                  }}
                  onAddProduct={(data) => {
                    onAddProduct(data)
                  }}
                  onRemoveProduct={onRemoveProduct}
                />
              </Box>
            </>
          )
        })}

        {products.length > 10 ? (
          <Box sx={styles.productCard}>
            <Button
              onClick={() => {
                onViewMore(subCat.subcatcode)
              }}
              sx={{
                width:'143px',
                textTransform: 'none',
                color: 'primary',
                textDecorationLine: 'underline',
                fontSize: '15px',
              }}
            >
              View More
            </Button>
          </Box>
        ) : null}
      </Box> */}

      {showInGrid ? (
        <Grid container py={1.5} spacing={2.5}>
          {products.map((item, index) => {
            return (
              <Grid item xs={6} md={2.4} key={`cat_products_${index}`}>
                <ProductCard
                  img={item.productPhoto[0].docURL}
                  price={item.productSalePrice}
                  oldPrice={item.productOriginalPrice}
                  description={item.productName}
                  discount={item.percentageOff}
                  style={{ my: 1.5 }}
                  isOutOfStock={getOutOfStock(item)}
                  isComingSoon={item.commerceEnabled === 'NO'}
                  minQty={item.minOrderQuantity}
                  maxQty={item.maxOrderQuantity}
                  cartData={cartData}
                  product={item}
                  cartId={cartId}
                  onQtyUpdate={(data) => {
                    onQtyUpdate(data)
                  }}
                  onAddProduct={(data) => {
                    onAddProduct(data)
                  }}
                  onRemoveProduct={onRemoveProduct}
                  subscribe={item?.subscription?.status === 'active'}
                  availability={
                    item?.availability?.[0]?.toLowerCase() === 'yes'
                  }
                  subscription={item?.subscription}
                />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <>
          {/* Slider in Large Screen */}
          {products.length > 4 && (
            <Box display={{ xs: 'none', md: 'block' }}>
              <Slider
                ref={slider}
                {...settings}
                beforeChange={(oldIndex, newIndex) => {
                  setCurrentSlideIndex(newIndex)
                }}
              >
                {products.map((item, index) => {
                  return (
                    <>
                      <Box py={1.5} mr={1.2}>
                        <ProductCard
                          img={item.productPhoto[0].docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item.commerceEnabled === 'NO'}
                          minQty={item.minOrderQuantity}
                          maxQty={item.maxOrderQuantity}
                          cartData={cartData}
                          product={item}
                          cartId={cartId}
                          onQtyUpdate={(data) => {
                            onQtyUpdate(data)
                          }}
                          onAddProduct={(data) => {
                            onAddProduct(data)
                          }}
                          onRemoveProduct={onRemoveProduct}
                          subscribe={item?.subscription?.status === 'active'}
                          availability={
                            item?.availability?.[0]?.toLowerCase() === 'yes'
                          }
                          subscription={item?.subscription}
                        />
                      </Box>
                    </>
                  )
                })}

                {products.length > 5 ? (
                  <Box sx={styles.productCard}>
                    <Button
                      onClick={() => {
                        onViewMore(subCat.subcatcode)
                      }}
                      sx={{
                        textTransform: 'none',
                        color: 'primary',
                        textDecorationLine: 'underline',
                        fontSize: '15px',
                      }}
                    >
                      View More
                    </Button>
                  </Box>
                ) : null}
              </Slider>
            </Box>
          )}

          {/* Horizontal Scroll in Small Screen */}
          {products.length > 0 && (
            <Box display={{ xs: 'block', md: 'none' }}>
              <Box sx={styles.scrollBox} ref={containerRef}>
                {products.map((item, index) => {
                  return (
                    <>
                      <Box py={1.5} mr={2.2}>
                        <ProductCard
                          img={item.productPhoto[0].docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item.commerceEnabled === 'NO'}
                          minQty={item.minOrderQuantity}
                          maxQty={item.maxOrderQuantity}
                          cartData={cartData}
                          product={item}
                          cartId={cartId}
                          onQtyUpdate={(data) => {
                            onQtyUpdate(data)
                          }}
                          onAddProduct={(data) => {
                            onAddProduct(data)
                          }}
                          onRemoveProduct={onRemoveProduct}
                          subscribe={item?.subscription?.status === 'active'}
                          availability={
                            item?.availability?.[0]?.toLowerCase() === 'yes'
                          }
                          subscription={item?.subscription}
                        />
                      </Box>
                    </>
                  )
                })}

                {products.length > 10 ? (
                  <Box sx={styles.productCard}>
                    <Button
                      onClick={() => {
                        onViewMore(subCat.subcatcode)
                      }}
                      sx={{
                        width: '143px',
                        textTransform: 'none',
                        color: 'primary',
                        textDecorationLine: 'underline',
                        fontSize: '15px',
                      }}
                    >
                      View More
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}

          {/* Displaying Products in Grid, If there are only 4 or less */}
          {products.length <= 4 && (
            <Box
              py={1.5}
              display={{ xs: 'none', md: 'flex' }}
              flexDirection='row'
            >
              {products.map((item, index) => {
                return (
                  <Box mr={2.2}>
                    <ProductCard
                      img={item.productPhoto[0].docURL}
                      price={item.productSalePrice}
                      oldPrice={item.productOriginalPrice}
                      description={item.productName}
                      discount={item.percentageOff}
                      style={{ my: 1.5 }}
                      isOutOfStock={getOutOfStock(item)}
                      isComingSoon={item.commerceEnabled === 'NO'}
                      minQty={item.minOrderQuantity}
                      maxQty={item.maxOrderQuantity}
                      cartData={cartData}
                      product={item}
                      cartId={cartId}
                      onQtyUpdate={(data) => {
                        onQtyUpdate(data)
                      }}
                      onAddProduct={(data) => {
                        onAddProduct(data)
                      }}
                      onRemoveProduct={onRemoveProduct}
                      subscribe={item?.subscription?.status === 'active'}
                      availability={
                        item?.availability?.[0]?.toLowerCase() === 'yes'
                      }
                      subscription={item?.subscription}
                    />
                  </Box>
                )
              })}
            </Box>
          )}
        </>
      )}

      {/* Scroll Indicator Icon */}
      {!isScrollEnd && products?.length > 2 && (
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
            position: 'absolute',
            right: '0px',
            top: '49%',
            zIndex: 50,
          }}
        >
          <ScrollIndicator show={isScrollEnd} />
        </Box>
      )}
    </Box>
  )
}

export default CategoryGroup

const styles = {
  productCard: {
    width: '150px !important',
    height: '285px',
    display: { md: 'none !important', xs: 'flex !important' },
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 1.5,
    backgroundColor: whiteColor,
    border: '1px solid #f2f2f2',
    px: 1.2,
    pt: 1.2,
    pb: 0.5,
    boxShadow: '0px 1px 3px rgb(0 0 0 / 13%)',
    '&:hover': {
      boxShadow: '0 8px 15px 0 rgba(0, 0, 0, 0.1)',
    },
  },

  scrollBox: {
    display: 'flex',
    alignItems: 'center',

    overflowX: 'scroll',
    overscrollBehaviorInline: 'contain',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '-webkit-overflow-scrolling': 'touch',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
}
