import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDownOutlined,
} from '@mui/icons-material'
import { Box, Button, Grid, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import {
  borderTextbox,
  catalogSubHeaderColor,
  filterBgColor,
  headingColor,
  iconInactiveColor,
  labelColor,
  navLinkColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function CategoryGroupHeader({
  title,
  onNext,
  onPrev,
  showSortBy,
  slideIndex,
  productCount,
  totalProduct,
  onViewMore,
  onSort,
  displaySelection,
  subCat,
  noAction,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedOption, setSelectedOption] = useState(displaySelection)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    setAnchorEl(null)
    console.log('DATA : ', event.target.innerText)
    setSelectedOption(event.target.innerText)
    onSort(event.target.innerText)
  }

  return (
    <Box mt={1.5}>
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent='space-between'
      >
        <Grid item sx={{ width: { xs: '100%', md: 'auto' } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography
                variant='h5'
                color={headingColor}
                fontSize={{ md: '16px', xs: '15px' }}
                display='inline'
              >
                {title}
              </Typography>

              <Box
                component='span'
                color={catalogSubHeaderColor}
                fontSize='14px'
                fontWeight={500}
                mx={1}
                textAlign='center'
              >
                ·
              </Box>

              <Typography
                variant='body2'
                color={catalogSubHeaderColor}
                fontSize='14px'
                fontWeight={500}
                display='inline'
              >
                {totalProduct} items
              </Typography>
            </Box>

            {/* {totalProduct > 2 && (
              <Box sx={styles.scrollArrow}>
                <KeyboardDoubleArrowRightIcon />
              </Box>
            )} */}
          </Box>
        </Grid>

        {showSortBy ? (
          <Grid item>
            <Typography
              variant='body'
              fontSize='14px'
              color={labelColor}
              fontWeight='400'
            >
              Sort by :
            </Typography>

            <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={styles.dropdownBtn}
              endIcon={<KeyboardArrowDownOutlined />}
            >
              {selectedOption ? selectedOption : 'Discount - High To Low'}
            </Button>

            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              variant='selectedMenu'
            >
              <MenuItem onClick={handleClose}>Newest First</MenuItem>
              <MenuItem onClick={handleClose}>Discount - High To Low</MenuItem>
              <MenuItem onClick={handleClose}>Price - Low To High</MenuItem>
              <MenuItem onClick={handleClose}>Price - High To Low</MenuItem>
            </Menu>
          </Grid>
        ) : noAction ? null : (
          <Grid item>
            {totalProduct > 5 && (
              <Grid container spacing={1.5}>
                <Grid item>
                  <Button
                    onClick={() => {
                      onViewMore(subCat.subcatcode)
                    }}
                    sx={{
                      textTransform: 'none',
                      color: 'primary',
                      textDecorationLine: 'underline',
                      fontSize: '13px',
                      display: { xs: 'none', md: 'block' },
                    }}
                  >
                    View More
                  </Button>
                </Grid>

                <Grid item display={{ xs: 'none', md: 'block' }}>
                  <Grid container spacing={1.5}>
                    <Grid item>
                      <Box
                        width='36px'
                        height='36px'
                        // backgroundColor={arrowBgColor}
                        backgroundColor={
                          slideIndex === 0 ? filterBgColor : whiteColor
                        }
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        borderRadius={2}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onPrev()}
                        border={borderTextbox}
                      >
                        <ChevronLeft
                          fontSize='medium'
                          sx={{
                            color:
                              slideIndex === 0
                                ? iconInactiveColor
                                : navLinkColor,
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item>
                      <Box
                        width='36px'
                        height='36px'
                        // backgroundColor={arrowBgColor}
                        backgroundColor={
                          productCount === slideIndex
                            ? filterBgColor
                            : whiteColor
                        }
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        borderRadius={2}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onNext()}
                        border={borderTextbox}
                      >
                        <ChevronRight
                          fontSize='medium'
                          sx={{
                            color:
                              productCount - 1 === slideIndex
                                ? iconInactiveColor
                                : navLinkColor,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default CategoryGroupHeader

const styles = {
  dropdownBtn: {
    fontSize: '15px',
    fontWeight: 600,
    textTransform: 'none',
    color: headingColor,
    '&:hover': {
      backgroundColor: whiteColor,
    },
  },

  scrollArrow: {
    display: { xs: 'block', md: 'none' },
    mb: -0.8,
    // '-webkit-animation': `${load7} 1.8s infinite ease-in-out`,
    // animation: `${load7} 1.8s infinite ease-in-out`,
  },
}
