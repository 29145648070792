import { Box, Grid, Typography, } from "@mui/material";
import React from "react";
import {
  catalogSubHeaderColor,
  productLabelColor,
} from "../../configs/styles/muiThemes";

function MainCategory({ label, productCount, isSubCatagory, titleColor,subCat,onViewMore }) {
  return (
    <Box sx={{ cursor: "pointer" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
      >
      <Box   onClick={() => {
        onViewMore(subCat)
      }}>
        <Typography
          variant="body1"
          color={titleColor ? titleColor : productLabelColor}
          fontSize="14px"
          fontWeight={isSubCatagory ? 500 : 600}
          // sx={{ "&:hover": { color: productLabelColor } }}
        >
          {label}
        </Typography>
        </Box>

        <Typography
          variant="body2"
          color={catalogSubHeaderColor}
          fontSize="13px"
          fontWeight={500}
        >
          {productCount}
        </Typography>
      </Grid>
    </Box>
  );
}

export default MainCategory;
