import { Box, Grid, Typography } from '@mui/material'
import {
  borderLineColor,
  couponColor,
  headingColor,
} from '../../configs/styles/muiThemes'
import { PaymentRounded } from '@mui/icons-material'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'

function PaymentType({ paymentType }) {
  return (
    <Box
      border={'1px solid ' + borderLineColor}
      borderRadius={2.2}
      px={1.85}
      pt={1.85}
      my={3}
    >
      <Typography
        variant='h5'
        component='h3'
        color={headingColor}
        fontSize={{ xs: 14, md: 15 }}
        pb={1.8}
        borderBottom={'1px solid ' + borderLineColor}
        textAlign='right'
      >
        Payment Type
      </Typography>

      <Grid container spacing={1} py={1.8}>
        <Grid item>
          {paymentType !== 'cod-delhivery' ? (
            <PaymentRounded />
          ) : (
            <Box
              component='img'
              src='https://storage.googleapis.com/bodefaults/shopweb/cod-icon.jpeg'
              alt='cod icon'
              width='25px'
              height='30px'
              sx={{ objectFit: 'contain' }}
            />
          )}
        </Grid>

        <Grid item>
          <Typography variant='subtitle1' component='p' color={couponColor}>
            {paymentType === 'cod-delhivery' ? 'COD - Delhivery' : paymentType}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PaymentType
