import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import {
  dashboardDescriptionColor,
  emptyMessageColor,
  whiteColor,
} from '../configs/styles/muiThemes'
import { Link } from 'react-router-dom'

export default function EmptyCard({
  message,
  description,
  imageUrl,
  actionBtnText,
  type,
  onTypeClick,
  iconWidth,
}) {
  const businessName = sessionStorage.getItem('businessName')
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Container sx={styles.container}>
      <Box
        component='img'
        src={imageUrl}
        width={{xs:iconWidth ? iconWidth : '100px', md:'100px'}}
        height='auto'
        alt={businessFullName + ' CostBo'}
      />
      <Typography
        variant='h6'
        fontSize={{ xs: 13.5, md: 16 }}
        color={emptyMessageColor}
        marginTop={1.5}
        lineHeight={{ xs: '20px', md: '26px' }}
      >
        {message}
      </Typography>

      {description ? (
        <Typography
          variant='body1'
          fontSize={{ xs: 11.5, md: 14 }}
          color={dashboardDescriptionColor}
          marginTop={1}
          lineHeight={{ xs: '20px', md: '23px' }}
        >
          {description}
        </Typography>
      ) : null}

      {actionBtnText ? (
        <Button
          component={Link}
          to={`/${businessName}/catalog`}
          variant='contained'
          disableElevation
          onClick={() => {}}
          sx={styles.actionBtn}
        >
          {actionBtnText}
        </Button>
      ) : null}

      {type ? (
        <Button
          variant='contained'
          disableElevation
          onClick={onTypeClick}
          sx={styles.actionBtn}
        >
          {type}
        </Button>
      ) : null}
    </Container>
  )
}

const styles = {
  container: {
    maxWidth: { xs: 'auto', md: '1300px !important' },
    px: { xs: '30px', md: '80px' },
    py: '15px',
    textAlign: 'center',
  },
  actionBtn: {
    mt: 3,
    '&:hover': {
      color: whiteColor,
    },
  },
}
