import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAccountDetails,
  getAddress,
  getCashbackAvailabel,
  getOrdersCount,
} from 'redux/dashboard/dashboardSlice'
import FooterMui from '../components/FooterMui'
import DashboardContent from './Dashboard/DashboardContent'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserName } from 'utils/authHelpers'
import TopComponent from './TopComponent'
import { Box, Container } from '@mui/material'
import BreadcrumbsSection from 'components/Breadcrumbs'
import { whiteColor } from 'configs/styles/muiThemes'

function Dashboard() {
  const { businessName } = useParams()
  const businessId = sessionStorage.getItem('businessId')

  const [selectedAddress, setSelectedAddress] = useState(null)

  const dispatch = useDispatch()
  const {
    accountDetails,
    orderDetails,
    openOrderDetails,
    cashbackData,
    allAddress,
    ordersCountData,
    loading,
  } = useSelector((state) => state.dashboard)

  const isLoggedIn = getUserName().length === 0 ? false : true

  let navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      if (!accountDetails) {
        dispatch(getAccountDetails())
      }
      dispatch(getOrdersCount(businessId))
      // dispatch(getAllOrderDetails(businessId))
      // dispatch(getAllOpenOrderDetails(businessId))
      dispatch(getCashbackAvailabel(businessId))
      dispatch(getAddress())
    } else {
      navigate(`/${businessName}`)
    }
  }, [])

  useEffect(() => {
    allAddress?.details?.forEach((item, index) => {
      if (item.selected) {
        setSelectedAddress(item)
      }
    })
  }, [allAddress])

  return (
    <div>
      {/* <Navbar value='' /> */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 100,
          backgroundColor: whiteColor,
        }}
      >
        <TopComponent value='' disableBanners />

        <Container
          sx={{
            maxWidth: '1300px !important',
            mt: { xs: 1.8, md: 2.5 },
          }}
        >
          <BreadcrumbsSection
            links={[{ label: 'Dashboard', to: `/Dashboard` }]}
          />
        </Container>
      </Box>

      <DashboardContent
        accountDetails={accountDetails}
        orderDetails={orderDetails}
        openOrderDetails={openOrderDetails}
        cashbackData={cashbackData}
        loading={loading}
        businessId={businessId}
        address={selectedAddress}
        ordersCountData={ordersCountData}
      />
      <FooterMui />
    </div>
  )
}

export default Dashboard
