import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Pagination,
  PaginationItem,
  Stack,
  Button,
} from '@mui/material'
import EmptyCard from 'components/EmptyCard'
import {
  cancelledOrdersEmptyImgUrl,
  cancelledOrdersEmptyMessage,
  emptyOrderBtnText,
  OrderEmptyDescription,
} from 'configs/Constants'
import React from 'react'
import BreadcrumbsSection from '../../components/Breadcrumbs'
import {
  dashboardDescriptionColor,
  headingColor,
  primaryColor,
} from '../../configs/styles/muiThemes'
import OrderCard from '../AllOrders/OrderCard'
import DasboardInfoCard from '../Dashboard/DasboardInfoCard'
import DashboardCard from '../Dashboard/DashboardCard'
import DashboardNavbar from '../Dashboard/DashboardNavbar'
// import OrderCard from "./OrderCard";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Loader from 'components/Loader'

function CanceledOrdersContent({
  accountDetails,
  cancelledOrderDetails,
  loading,
  businessId,
  ordersCountData,
  handleOrderPagination,
  pageNo,
}) {
  const handlePagination = (event, value) => {
    console.log('OPEN_ORDER_PAGINATION', value)
    handleOrderPagination(value)
  }

  return (
    <Container sx={{ maxWidth: '1300px !important', mt: { xs: 1.8, md: 2.5 } }}>
      {/* <BreadcrumbsSection
        links={[
          { label: 'All Orders', to: `/AllOrders` },
          { label: 'Canceled Orders', to: `/CanceledOrders` },
        ]}
      /> */}
      <Box mt={{ md: '20px !important', xs: 0 }}>
      <Grid
        container
        rowSpacing={{ md: 2, xs: 0 }}
        columnSpacing={{ md: 5, xs: 0 }}
        // mt={{ md: 2, xs: 0 }}
      >
        <Grid item md={3} xs={12}>
          <DashboardNavbar active='canceledOrders' businessId={businessId} />
        </Grid>

        {loading ? (
          <Grid item md={9} xs={12}>
            <Box display='flex' flexDirection='row' justifyContent='center'>
              <Loader />
            </Box>
          </Grid>
        ) : (
          <Grid item md={9} xs={12}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2.0}
              mt={{ md: 0, xs: 2.3 }}
              flexWrap='wrap'
              gap={1}
            >
              <Box>
                <Typography
                  variant='h4'
                  component='h2'
                  fontSize={{ xs: 15, md: 17 }}
                  color={headingColor}
                  display='inline'
                >
                  Cancelled Orders{' '}
                </Typography>
                <Typography
                  variant='subtitle1'
                  fontSize={{ xs: 14, md: 16 }}
                  color={dashboardDescriptionColor}
                  display='inline'
                >
                  {/* ({cancelledOrderDetails?.details?.length}) */}(
                  {ordersCountData?.details?.cancelOrder})
                </Typography>
              </Box>

              {/* <Typography
                variant='subtitle1'
                component='p'
                fontSize='14px'
                lineHeight='24px'
                color={dashboardDescriptionColor}
                display='inline'
              >
                Hello,{' '}
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize='14px'
                  fontWeight={600}
                  color={primaryColor}
                  display='inline'
                >
                  {accountDetails?.account?.name} !
                </Typography>
              </Typography> */}
            </Box>
            {cancelledOrderDetails?.details?.map((order, index) => {
              return (
                <OrderCard
                  key={'allcancelledorders_' + index}
                  cartId={order.cartId}
                  orderId={order.orderId}
                  date={order.orderDate}
                  quantity={order.totalQuantity}
                  totalPrice={order.orderTotalCost}
                  orderStatus={order.orderStatus}
                  cartDate={order.cartDate}
                  orderData={order}
                  fromPage={{ label: 'Canceled Orders', to: 'CanceledOrders' }}
                />
              )
            })}

            {ordersCountData ? (
              ordersCountData?.details?.cancelOrder > 20 ? (
                <Stack
                  spacing={2}
                  my={4}
                  display={{ md: 'flex', xs: 'none' }}
                  alignItems='center'
                  justifyContent='center'
                >
                  <Pagination
                    count={
                      ordersCountData
                        ? Math.ceil(ordersCountData?.details?.cancelOrder / 20)
                        : 0
                    }
                    page={pageNo}
                    onChange={handlePagination}
                    color='primary'
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          next: NextBtn,
                          previous: PreviousBtn,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              ) : null
            ) : null}

            {cancelledOrderDetails?.details?.length == 0 ? (
              <EmptyCard
                message={cancelledOrdersEmptyMessage}
                description={OrderEmptyDescription}
                imageUrl={cancelledOrdersEmptyImgUrl}
                actionBtnText={emptyOrderBtnText}
              />
            ) : null}
          </Grid>
        )}
      </Grid>
      </Box>
    </Container>
  )
}

export default CanceledOrdersContent

const styles = {
  btnHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  )
}

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  )
}
