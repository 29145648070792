import { Box, Divider, Grid, Typography } from "@mui/material";
import { InfoCard_DeliveryChargeInfo, InfoCard_DiscountInfo } from "configs/Constants";
import React from "react";
import {
  borderLineColor,
  couponColor,
  whiteColor,
} from "../configs/styles/muiThemes";

function InfoCard(props) {
  return (
    <Box
      border={"1px solid " + borderLineColor}
      borderRadius={2.2}
      p={1.25}
      my={3}
    >
      <Grid container spacing={1.2} py={1}>
        <Grid item>
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/shopweb/offer_discount_info.png"
            alt="Info Icon"
            width="35px"
            height="35px"
          />
        </Grid>

        <Grid item xs>
          <Typography variant="subtitle1" fontSize="13px" color={couponColor}>
            {InfoCard_DiscountInfo}
          </Typography>
        </Grid>
      </Grid>

      <Divider light />

      <Grid container spacing={1.2} py={1.6}>
        <Grid item>
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/shopweb/offer_available_cashback_product.png"
            alt="Info Icon"
            width="35px"
            height="35px"
          />
        </Grid>

        <Grid item xs>
          <Typography variant="subtitle1" fontSize="13px" color={couponColor}>
            {InfoCard_DeliveryChargeInfo}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default InfoCard;
