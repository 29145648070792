import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import {
  headingColor,
  primaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

import { noProductImgUrl } from '../../configs/Constants'
import ImageMagnifier from '../../components/ImageMagnifier/ImageMagnifier'
import ImageMagnifierMobile from 'components/ImageMagnifier/ImageMagnifierMobile'

export default function ProductGalleryModal({
  title,
  productImages,
  activeImg,
  onActiveClick,
  magnify,
}) {
  let businessFullName = sessionStorage.getItem('businessFullName')
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  return (
    <Box
      p={{ md: 3, xs: 2 }}
      sx={{
        background: whiteColor,
        minHeight: '75vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: { md: 'row', xs: 'column' },
      }}
    >
      <Typography
        variant='h4'
        color={headingColor}
        mb='6px!important'
        lineHeight={1.6}
        display={{ md: 'none', xs: 'block' }}
      >
        {title}
      </Typography>
      <Grid
        container
        direction={{ md: 'row', xs: 'column-reverse' }}
        spacing={2}
      >
        <Grid item xs={12} md={5}>
          <Box p={{ md: 5, xs: 0 }}>
            <Typography
              variant='h4'
              color={headingColor}
              mt={1.5}
              mb='20px !important'
              lineHeight={1.6}
              display={{ md: 'block', xs: 'none' }}
            >
              {title}
            </Typography>
            <Grid container spacing={{ md: 4, xs: 2 }} justifyContent='center'>
              {productImages?.map((item, i) => {
                return (
                  <Grid item xs={6}>
                    {/* <LazyLoad throttle={200}> */}
                    <Box
                      component='img'
                      src={item.docURL}
                      alt={businessFullName + ' CostBo'}
                      onClick={() => onActiveClick(item.docURL)}
                      backgroundColor={whiteColor}
                      sx={{
                        width: { xs: '120px', md: '150px' },
                        height: { xs: '80px', md: '120px' },
                        border:
                          activeImg === item.docURL
                            ? '1px solid' + primaryColor
                            : '',
                        boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
                        borderRadius: '10px',
                        p: 0.5,
                        objectFit: 'contain',
                        cursor: 'pointer',
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = noProductImgUrl
                      }}
                    />
                    {/* </LazyLoad> */}
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          {matches && magnify ? (
            <ImageMagnifier
              imgUrl={activeImg}
              alt={businessFullName + ' CostBo'}
              width='100%'
              height={{ xs: '250px', md: '70vh' }}
            />
          ) : (
            <ImageMagnifierMobile
              imgUrl={activeImg}
              width='100%'
              // height={{ xs: "250px", md: "70vh" }}
            />
            // <Box width='100%' height='100%' display='flex' alignItems='center'>
            //   <Box
            //     component='img'
            //     src={activeImg}
            //     alt={businessFullName + ' CostBo'}
            //     backgroundColor={whiteColor}
            //     sx={{
            //       width: '100%',
            //       height: { xs: '250px', md: '70vh' },
            //       borderRadius: '10px',
            //       objectFit: 'contain',
            //     }}
            //     onError={({ currentTarget }) => {
            //       currentTarget.onerror = null // prevents looping
            //       currentTarget.src = noProductImgUrl
            //     }}
            //   />
            // </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
