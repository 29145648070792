import { Box, Typography } from "@mui/material";
import {
  dashboardInfoColor,
  navHeaderColor,
  stepperInactiveColor,
} from "../../configs/styles/muiThemes";

function DasboardInfoCard({ title, label, value }) {
  return (
    <Box>
      <Typography
        color={navHeaderColor}
        py={0.625}
        px={1.25}
        fontWeight={500}
        mb="3px !important"
        fontSize={13}
        sx={{ backgroundColor: stepperInactiveColor }}
        borderRadius={1}
      >
        {title}
      </Typography>
      <Box px={1.5}>
        <Typography
          variant="subtitle1"
          component="p"
          fontSize="13px"
          lineHeight="24px"
          color={navHeaderColor}
          display="inline"
        >
          {label}
          {/* {": "} */}
          <Typography
            variant="subtitle1"
            component="p"
            fontSize="13px"
            fontWeight={500}
            color={dashboardInfoColor}
            display="inline"
          >
            {value}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}

export default DasboardInfoCard;
