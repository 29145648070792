import { Box, Typography } from '@mui/material'
import {
  categoryLabelSpanColor,
  headingColor,
  labelColor,
  primaryColor,
  productLabelColor,
  searchLabelColor,
} from 'configs/styles/muiThemes'
import moment from 'moment'
import React from 'react'

function VerticalTimeline({ data }) {
  return (
    <Box px={2} mt={1}>
      <ol className='c-timeline'>
        {data?.map((item, i) => {
          return (
            <li className='c-timeline__item' key={i}>
              <div className='c-timeline__content'>
                <Typography
                  fontSize={14.5}
                  fontWeight={600}
                  color={headingColor}
                >
                  {item?.ScanDetail?.Scan}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={searchLabelColor}
                >
                  {item?.ScanDetail?.ScannedLocation}
                </Typography>
                <Typography fontSize={13} fontWeight={500} color={primaryColor}>
                  {item?.ScanDetail?.Instructions}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={searchLabelColor}
                  sx={{ mb: '15px !important' }}
                >
                  {moment(item?.ScanDetail?.ScanDateTime).format(
                    'hh:mm:ss, MMM DD, YYYY'
                  )}
                </Typography>
              </div>
            </li>
          )
        })}
      </ol>
    </Box>
  )
}

export default VerticalTimeline
