import {
  Box,
  Fade,
  IconButton,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from '@mui/material'
import React, { Suspense, useRef, useState } from 'react'
import { AddRounded } from '@mui/icons-material'
import LazyLoad from 'react-lazyload'
import {
  blackColor,
  greyShade37,
  headingColor,
  infoAlertBgColor,
  offerTextColor,
  primaryColor,
  topbarColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import { Link } from 'react-router-dom'
import { noProductImgUrl } from 'configs/Constants'
import moment from 'moment'
import ModalCard from 'components/ModalCard'
import GetAppModalCard from 'components/GetAppModalCard'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { useSelector } from 'react-redux'
import AffiliateShareModal from 'pages/Affiliates/AffiliateShareModal'
const QuantityPicker = React.lazy(() => import('./QuantityPicker'))

function ProductCard({
  img,
  price,
  oldPrice,
  description,
  discount,
  isOutOfStock,
  isComingSoon,
  disableAddtoCart,
  minQty,
  maxQty,
  currencySymbol,
  cartData,
  product,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  subscribe,
  availability,
  subscription,
}) {
  const [isModal, setIsModal] = useState(false)
  const [disableCard, setDisableCard] = useState(false)
  const businessName = sessionStorage.getItem('businessName')
  let businessFullName = sessionStorage.getItem('businessFullName')
  const commerceInfo = JSON.parse(sessionStorage.getItem('commerceInfo'))
  const commerceEnable = commerceInfo
    ? commerceInfo?.info?.commerceEnable
    : true

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  )

  const getProductCartCount = (cartList, product) => {
    const isPresent = cartList?.filter((cartProduct) => {
      return cartProduct?.productId === product?.id
    })

    const cartCount =
      isPresent?.length > 0 ? isPresent?.[0]?.currentQuantity : 0

    return cartCount
  }

  const count = getProductCartCount(cartData, product)

  const loading = cartData?.filter((item) => {
    return item?.productId === product?.id
  })

  const loadingRef = useRef(null)
  const productImgRef = useRef(null)

  const currentDate = new Date()

  const isStartDateAfter = moment(product?.commerceStartDate).isAfter(
    currentDate
  )

  // Affiliate Share
  const [openShareModal, setOpenShareModal] = useState({
    show: false,
    data: null,
  })

  const handleShareProduct = () => {
    console.log('PRODUCT:', product)
    console.log('commissionDetails:', businessAffiliateData)
    console.log('rellerProfileData:', rellerProfileData)

    setOpenShareModal({
      show: true,
      data: {
        productDetails: product,
        commissionDetails: businessAffiliateData,
      },
    })
  }

  return (
    <Suspense fallback={<p></p>}>
      {/* Affiliate Share Modal */}
      {openShareModal?.show && (
        <AffiliateShareModal
          productDetails={openShareModal?.data?.productDetails}
          commissionDetails={openShareModal?.data?.commissionDetails}
          rellerProfileData={rellerProfileData}
          open={openShareModal?.show}
          onClose={() => {
            setOpenShareModal({ show: false, data: null })
          }}
          isProductShare
        />
      )}

      <Box
        sx={{
          ...styles.productCard,
        }}
        title={description}
      >
        <Box sx={{ position: 'relative' }}>
          {commerceInfo?.info?.costboPowered?.affiliateEnabled &&
          businessAffiliateData?.businessResellers?.length > 0 &&
          rellerProfileData?.details ? (
            <Box position='absolute' top={0} right={0} zIndex={2}>
              <IconButton
                onClick={handleShareProduct}
                size='small'
                sx={{ backgroundColor: greyShade37 }}
              >
                <ShareOutlinedIcon
                  fontSize='small'
                  sx={{ color: blackColor }}
                />
              </IconButton>
            </Box>
          ) : (
            <></>
          )}

          <Box
            component={Link}
            to={`/${businessName}/singleProduct/${product?.id}`}
            sx={{ width: '100%', position: 'relative' }}
          >
            {discount ? (
              <Box sx={styles.offTag}>
                <Typography
                  variant='body1'
                  color={whiteColor}
                  fontSize='12px'
                  fontWeight={400}
                  textAlign='center'
                >
                  {discount}%
                </Typography>
              </Box>
            ) : null}

            <Box
            // width='143px'
            // height='128px'
            >
              <Box ref={loadingRef}>
                <Skeleton variant='rectangular' width='143px' height='128px' />
              </Box>

              <LazyLoad throttle={200}>
                <Box
                  ref={productImgRef}
                  component='img'
                  src={img}
                  alt={businessFullName + ' CostBo'}
                  width='1px'
                  height='1px'
                  onLoad={() => {
                    loadingRef.current.style.display = 'none'
                    productImgRef.current.style.width = '143px'
                    productImgRef.current.style.height = '128px'
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = noProductImgUrl
                    setDisableCard(true)
                  }}
                  sx={{
                    // borderRadius: '12px',
                    objectFit: 'contain',
                  }}
                />
              </LazyLoad>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            variant='body1'
            color={headingColor}
            fontSize='13px'
            fontWeight={500}
            lineHeight='22px'
            height='65px'
            mt={0.8}
            overflow='hidden'
            title={description}
          >
            {description?.slice(0, 65)}
            {description?.length > 65 ? '...' : ''}
          </Typography>
        </Box>

        <Box pt={0.5} display='flex' alignItems='center'>
          {discount ? (
            <Typography
              variant='body1'
              color={offerTextColor}
              fontSize='14px'
              fontWeight={500}
              sx={{ textDecorationLine: 'line-through' }}
              mr={1.2}
              display={disableAddtoCart ? 'inline' : 'block'}
            >
              {discount
                ? currencySymbol
                  ? `${currencySymbol} ${oldPrice}`
                  : `₹ ${oldPrice}`
                : ''}
            </Typography>
          ) : null}

          <Typography
            variant='h6'
            color={headingColor}
            component='p'
            display={disableAddtoCart ? 'inline' : 'block'}
          >
            {currencySymbol ? `${currencySymbol} ${price}` : `₹ ${price}`}
          </Typography>
        </Box>

        {subscribe ? (
          <Box
            sx={{
              backgroundColor: topbarColor,
              position: 'absolute',
              bottom: 0,
              cursor: 'pointer',
              width: '90%',
            }}
            onClick={() => setIsModal(true)}
          >
            <Typography
              color={whiteColor}
              fontSize={10}
              fontWeight={400}
              textAlign='center'
            >
              Subscribe for ₹ {subscription?.offerValue}
            </Typography>
          </Box>
        ) : null}

        {!disableAddtoCart ? (
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            height={disableAddtoCart ? 'auto' : '68px'}
          >
            {subscribe && !availability ? (
              <Box>
                <Typography
                  variant='body1'
                  color={'rgb(226 62 29 / 85%)'}
                  fontSize='15px'
                  fontWeight={500}
                  textAlign='right'
                  textTransform={'uppercase'}
                >
                  Subscribe only
                </Typography>
              </Box>
            ) : null}
            <Box></Box>
            {isOutOfStock || !commerceEnable ? (
              <Box sx={styles.alertContainer}>
                <Box>
                  <Typography
                    variant='body1'
                    color={
                      isOutOfStock
                        ? 'rgb(226 62 29 / 85%)'
                        : 'rgb(15 190 83 / 85%)'
                    }
                    fontSize='15px'
                    fontWeight={500}
                    textAlign='right'
                  >
                    {isOutOfStock ? 'Out Of Stock' : 'Coming Soon'}
                  </Typography>
                  <Typography
                    variant='body1'
                    color={headingColor}
                    fontSize='9.5px'
                    fontWeight={500}
                    textAlign='right'
                    sx={{ whiteSpace: 'pre-wrap' }}
                  >
                    {isStartDateAfter
                      ? `Available from - ${moment(
                          product?.commerceStartDate
                        ).format('DD/MM/YYYY')}`
                      : ''}
                  </Typography>
                </Box>
              </Box>
            ) : count > 0 ? (
              <QuantityPicker
                count={count}
                onIncrement={() => {
                  if (loading?.[0]?.loading) return

                  if (count < maxQty) {
                    onQtyUpdate({
                      cartId: cartId,
                      productId: product?.id,
                      quantity: count + 1,
                    })
                  }
                }}
                onDecrement={() => {
                  if (loading?.[0]?.loading) return

                  if (count > minQty) {
                    onQtyUpdate({
                      cartId: cartId,
                      productId: product?.id,
                      quantity: count - 1,
                    })
                  } else {
                    onRemoveProduct({
                      productId: product?.id,
                    })
                  }
                }}
                minQty={minQty}
                maxQty={maxQty}
              />
            ) : !disableAddtoCart && availability ? (
              <Box
                sx={styles.addBtn}
                disa
                onClick={() => {
                  if (loading?.[0]?.loading) return

                  let gstTaxValue = 0

                  if (product.gstInfo != null) {
                    gstTaxValue = product.gstInfo.gstPercentage.replace(
                      /[&\/\\#,+()$~%.'":*?<>{}]/g,
                      ''
                    )
                  } else {
                    gstTaxValue = 0
                  }

                  let productAmtWithoutGST =
                    (product.productSalePrice * 100) /
                    (100 + Number(gstTaxValue))
                  let productTaxPerUnit =
                    product.productSalePrice - productAmtWithoutGST

                  console.log(
                    'ADD_PRODUCT_productSalePrice',
                    product.productSalePrice
                  )
                  console.log('ADD_PRODUCT_gstTaxValue', gstTaxValue)
                  console.log(
                    'ADD_PRODUCT_productSalePrice100',
                    (product.productSalePrice * 100) / 100 + gstTaxValue
                  )
                  console.log(
                    'ADD_PRODUCT_productAmtWithoutGST',
                    productAmtWithoutGST
                  )
                  console.log(
                    'ADD_PRODUCT_productTaxPerUnit',
                    productTaxPerUnit
                  )

                  onAddProduct({
                    cartId: cartId,
                    product: {
                      productId: product?.id,
                      productName: product?.productName,
                      productPicURL: img,
                      topicId: '',
                      productOriginalPrice: product?.productOriginalPrice,
                      productSalePrice: product?.productSalePrice,
                      originalQuantity: minQty,
                      currentQuantity: minQty,
                      currencySymbol: '₹',
                      percentageOff: product?.percentageOff,
                      quantityChangedFlag: false,
                      priceChangedFlag: false,
                      productTotalPrice: minQty * product?.productSalePrice,
                      productTax: productTaxPerUnit.toFixed(2),
                      productTaxRate: gstTaxValue,
                      measurementInfo: product?.measurementInfo,
                    },
                    clientType: 'web',
                  })
                }}
              >
                <AddRounded sx={{ color: primaryColor, fontSize: '18px' }} />
              </Box>
            ) : null}
          </Box>
        ) : null}
        <ModalCard
          handleClose={() => setIsModal(false)}
          open={isModal}
          width='40%'
        >
          <GetAppModalCard />
        </ModalCard>
      </Box>
    </Suspense>
  )
}

export default ProductCard

// const AlertTag = ({ message, bgColor }) => {
//   return (
//     <Box backgroundColor={bgColor} sx={styles.alertTag}>
//       <Typography
//         variant='body1'
//         color={whiteColor}
//         fontSize='13px'
//         fontWeight={600}
//         textAlign='center'
//       >
//         {message}
//       </Typography>
//     </Box>
//   )
// }

const styles = {
  productCard: {
    width: '143px',
    // maxWidth: '173px',
    borderRadius: 1.5,
    backgroundColor: whiteColor,
    border: '1px solid #f2f2f2',
    px: 1.2,
    pt: 1.2,
    pb: 0.5,
    boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
    position: 'relative',
    transition: 'all 0.6s ease',
    '&:hover': {
      boxShadow: '0 8px 15px 0 rgba(0, 0, 0, 0.1)',
    },
  },

  addBtn: {
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: whiteColor,
    border: '1px solid' + infoAlertBgColor,
    borderRadius: 1.2,
    // padding: '5px',
    // position: 'absolute',
    // right: '0px',
    // bottom: '-12px',
    cursor: 'pointer',
  },

  minMaxContainer: {
    width: '100%',
    backgroundColor: 'rgb(207 207 207 / 88%)',
    borderRadius: '0 0 6px 6px',
    position: 'absolute',
    left: '0px',
    bottom: '0px',
    // py: 0.1,
  },

  offTag: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    borderRadius: '6px',
    lineHeight: '12px',
    p: '1px 8px',
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 1,
  },

  tagUi: {
    width: '16.5px',
    height: '16.5px',
    backgroundColor: 'primary.main',
    transform: 'rotate(45deg)',
    position: 'absolute',
    top: '3px',
    left: '-8px',
    zIndex: '0',
    borderRadius: '3px',
  },

  alertTag: {
    width: '100%',
    // backgroundColor: "rgb(226 62 29 / 85%)",
    borderRadius: '0 0 6px 6px',
    py: 0.3,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },

  alertContainer: {
    // width: '100%',
    // height: '148px',
    // backgroundColor: 'rgba(256, 256, 256, 0.8)',
    blur: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // position: 'absolute',
    // top: 0,
  },
}
