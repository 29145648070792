import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getCommercialData,
  getProductData,
  getSimilarProductData,
} from 'services/api_calls/productDescription/ProductDescriptionApi'

const initialState = {
  productDetails: null,
  similarProductDetails: null,
  commercialDetails: null,
  loader: false,
  recLoading: false,

  // Loaders
  commercialDetailsProductLoading: false
}

//Fetching Product details
export const getProductDetails = createAsyncThunk(
  'getProductDetails',
  async (param, thunkAPI) => {
    const res = await getProductData(param)
    return res
  }
)

//Fetching Product details
export const getSimilarProductDetails = createAsyncThunk(
  'getSimilarProductDetails',
  async (param, thunkAPI) => {
    const res = await getSimilarProductData(param)
    console.log("SIMILAR_PRODS:", res);
    return res
  }
)

//Fetching Commercial details
export const getCommercialDetails = createAsyncThunk(
  'getCommercialDetails',
  async (thunkAPI) => {
    const res = await getCommercialData()
    return res
  }
)

export const productDetailsSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: {
    //product details
    [getProductDetails.pending]: (state) => {
      state.loader = true
      state.productDetails = null
    },
    [getProductDetails.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.productDetails = payload
    },
    [getProductDetails.rejected]: (state) => {
      state.loader = false
    },

    //similar product details
    [getSimilarProductDetails.pending]: (state) => {
      state.recLoading = true
    },
    [getSimilarProductDetails.fulfilled]: (state, { payload }) => {
      state.recLoading = false
      state.similarProductDetails = payload
    },
    [getSimilarProductDetails.rejected]: (state) => {
      state.recLoading = false
    },

    //Commercial details
    [getCommercialDetails.pending]: (state) => {
      state.commercialDetailsProductLoading = true
      state.loading = true
    },
    [getCommercialDetails.fulfilled]: (state, { payload }) => {
      state.commercialDetailsProductLoading = false
      state.loader = false
      state.commercialDetails = payload
    },
    [getCommercialDetails.rejected]: (state) => {
      state.commercialDetailsProductLoading = false
      state.loader = false
    },
  },
})

export const productDetailsReducer = productDetailsSlice.reducer
