import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import styled from '@emotion/styled'
import {
  greyShade34,
  primaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function FormActionButtons({
  onSave,
  onNext,
  onPrevious,
  hidePreviousBtn,
  hideNextBtn,
  hideSaveBtn,
  loading,
}) {
  return (
    <Box display='flex' alignItems='center' gap='20px'>
      {!hidePreviousBtn && (
        <StyledButton onClick={onPrevious}>
          <SkipPreviousOutlinedIcon />
        </StyledButton>
      )}

      <Box flex={1}>
        {!hideSaveBtn && (
          <StyledSaveButton onClick={onSave} fullWidth>
            {loading && <CircularProgress size={14} sx={{ color: greyShade34 }} />}
            ENROLL AS AFFILIATE
          </StyledSaveButton>
        )}
      </Box>

      {!hideNextBtn && (
        <StyledButton onClick={onNext}>
          <SkipNextOutlinedIcon />
        </StyledButton>
      )}
    </Box>
  )
}

export default FormActionButtons

const StyledSaveButton = styled(Button)({
  color: whiteColor,
  backgroundColor: primaryColor,
  border: '1px solid ' + primaryColor,

  '&:hover': {
    color: primaryColor,
    backgroundColor: whiteColor,
  },
})

const StyledButton = styled(Button)({
  color: whiteColor,
  backgroundColor: primaryColor,
  border: '2px solid ' + primaryColor,

  '&:hover': {
    color: primaryColor,
    backgroundColor: whiteColor,
  },
})
