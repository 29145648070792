import { Box, IconButton, Modal, useMediaQuery } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { whiteColor } from '../configs/styles/muiThemes'

export default function ModalCard(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Box
        sx={{ ...style(true), width: matches ? props?.width || 600 : '90%' }}
      >
        <Box sx={modalContent}>
          <IconButton sx={btnClose} onClick={props.handleClose}>
            <CloseIcon sx={{ fontSize: 18 }} />
          </IconButton>
          {props.children}
        </Box>
      </Box>
    </Modal>
  )
}

const style = (transparent) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  bgcolor: transparent ? null : whiteColor,
  // overflow:"hidden",
  boxShadow: 24,
})
const modalContent = {
  width: '100%',
  height: '100%',
  position: 'relative',
}

const btnClose = {
  background: whiteColor,
  position: 'absolute',
  top: '-10px',
  right: '-6px',
  width: '30px',
  height: '30px',
  opacity: 1,
  borderRadius: '50px',
  zIndex: '50 !important',
  '&:hover': {
    background: whiteColor,
  },
}
