import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import {
  addressForm_AlternateNumber,
  addressForm_AlternateNumberError,
  addressForm_CityNameError,
  addressForm_EnterMail,
  addressForm_EnterName,
  addressForm_EnterNumber,
  addressForm_HouseNo,
  addressForm_HouseNoError,
  addressForm_Landmark,
  addressForm_MailError,
  addressForm_NameError,
  addressForm_NumberError,
  addressForm_Pincode,
  addressForm_PincodeError,
  addressForm_SelectAddressType,
} from 'configs/Constants'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAddressDetails,
  getCoordinatesDetails,
  getStateCityDetails,
  modifyAddressDetails,
} from 'redux/checkout/CheckoutSlice'
import { validateMobileNumber } from 'utils/validationUtils'
import TextboxWithLabel from '../../../components/TextboxWithLabel'
import {
  blackColor,
  errorTextColor,
  headingColor,
  primaryColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'
import PickAddressFromMapModal from 'components/PickAddress/PickAddressFromMapModal'
import NearMeIcon from '@mui/icons-material/NearMe'
import AlertDialog from 'components/AlertDialog'
import { checkIsUpdateRequired } from 'utils/AddressHelper/addressHelper'
import { allowOnlyEnglish } from 'utils/utilities'

function EditAddressForm({
  title,
  accountDetails,
  addressDetails,
  handleAddAddressClose,
  handleUpdateAddressClose,
  editAddress,
  setSeletedAddress,
  setErrorMessage
}) {
  const [name, setName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [alternateNumber, setAlternateNumber] = useState('')
  const [pincode, setPincode] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [landmark, setLandmark] = useState('')
  const [type, setType] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [selected, setDefault] = useState(false)

  const [latLong, setLatLong] = useState({
    latitude: '',
    longitude: '',
  })
  const [isUpdateRequired, setIsUpdateRequired] = useState(true)
  const [openAlert, setOpenAlert] = useState(false)

  const [isAddressError, setIsAddressError] = useState(false)
  const [isEmailError, setIsEmailError] = useState(false)
  const [isNameError, setIsNameError] = useState(false)
  const [isPincodeError, setIsPincodeError] = useState(false)
  const [isCityError, setIsCityError] = useState(false)
  const [isStateError, setIsStateError] = useState(false)
  const [isTypeError, setIsTypeError] = useState(false)
  const [isAlternateError, setIsAlternateError] = useState(false)

  const [loading, setLoading] = useState(false)

  const { stateCityDetails } = useSelector((state) => state.checkout)

  const [openMap, setOpenMap] = useState({
    show: false,
    userLat: '',
    userLong: '',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (editAddress) {
      setName(editAddress.name)
      setContactNumber(editAddress.phoneNo)
      setAlternateNumber(editAddress.alternatePhoneNo)
      setLandmark(editAddress.landMark)
      setType(editAddress.addressType)
      setAddress(editAddress.addressLine1)
      setCity(editAddress.city)
      setState(editAddress.state)
      setDefault(editAddress.selected)
      setPincode(editAddress.zip)
      setOpenMap((prevState) => ({
        ...prevState,
        userLat: editAddress?.latitude,
        userLong: editAddress?.longitude,
      }))

      setIsUpdateRequired(checkIsUpdateRequired(editAddress?.updatedOn))
    }
  }, [editAddress])

  const reset = () => {
    setName('')
    setContactNumber('')
    setAlternateNumber('')
    setLandmark('')
    setType('')
    setAddress('')
    setCity('')
    setState('')
    // setEmail('')
    setDefault('')
    setPincode('')
  }

  useEffect(() => {
    setEmail(accountDetails?.account?.email)
    console.log('accountDetails', accountDetails)
  }, [accountDetails])

  useEffect(() => {
    if (stateCityDetails) {
    }
  }, [stateCityDetails])

  const editAddressClicked = () => {
    let error = false

    if (alternateNumber) {
      let alterError = validateMobileNumber(alternateNumber)
      console.log('hiytdf', alterError)
      if (alterError) {
        setIsAlternateError(true)
        error = true
      }
    }
    if (!address) {
      setIsAddressError(true)
      error = true
    }
    if (!name) {
      setIsNameError(true)
      error = true
    }
    if (!email) {
      setIsEmailError(true)
      error = true
    }
    if (pincode.length !== 6) {
      setIsPincodeError(true)
      error = true
    }
    if (!city) {
      setIsCityError(true)
      error = true
    }
    if (!state) {
      setIsStateError(true)
      error = true
    }
    if (!type) {
      setIsTypeError(true)
      error = true
    }
    if (error) {
      setLoading(false)
      return
    }
    let newAddress = [...addressDetails]
    let newObj = null

    if (
      address === editAddress.addressLine1 &&
      city === editAddress.city &&
      state === editAddress.state &&
      pincode === editAddress.zip
    ) {
      let formattedData = []

      if (selected === editAddress.selected || !selected) {
        console.log('hiitt__First')
        newAddress.forEach((obj) => {
          if (obj === editAddress) {
            newObj = {
              ...obj,
              name: name,
              addressLine1: address,
              city: city,
              zip: pincode,
              state: state,
              landMark: landmark,
              phoneNo: contactNumber,
              alternatePhoneNo: alternateNumber,
              addressType: type,
              selected: selected,
              updatedOn: moment().valueOf(),
            }
          }
        })
        let filteredAddress = newAddress.filter(
          (address) => address !== editAddress
        )
        formattedData = [...filteredAddress, newObj]
      } else {
        console.log('hittttttt False')
        let newAddressData = []
        let filteredSelectedAddress = addressDetails?.filter(
          (address) => address.selected === true
        )
        console.log('filteredSelectedAddress', filteredSelectedAddress)
        let addressWithoutSelected = addressDetails?.filter(
          (address) => address.selected !== true
        )
        console.log('addressWithoutSelected', addressWithoutSelected)
        newAddressData = [...addressWithoutSelected]
        if (filteredSelectedAddress.length > 0) {
          let obj = { ...filteredSelectedAddress[0], selected: false }
          newAddressData = [...newAddressData, obj]
        }
        newAddress.forEach((obj) => {
          if (obj === editAddress) {
            newObj = {
              ...obj,
              name: name,
              addressLine1: address,
              city: city,
              zip: pincode,
              state: state,
              landMark: landmark,
              phoneNo: contactNumber,
              alternatePhoneNo: alternateNumber,
              addressType: type,
              selected: selected,
              updatedOn: moment().valueOf(),
            }
          }
        })
        let filteredAddress = newAddressData.filter(
          (address) => address !== editAddress
        )
        formattedData = [...filteredAddress, newObj]
      }

      dispatch(
        modifyAddressDetails({
          addressDetails: formattedData,
        })
      ).then((res) => {
        console.log('deliveryAddress__Edit:', newObj)
        setErrorMessage("")
        setSeletedAddress(newObj)

        handleUpdateAddressClose()
        dispatch(getAddressDetails())
        reset()
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      })
    } else {
      dispatch(
        getCoordinatesDetails({ address: pincode, city, state, pincode })
      ).then((res) => {
        if (res?.payload?.results?.length > 0) {
          let newAddressData = []
          if (selected === editAddress.selected || !selected) {
            console.log('hittttt true', editAddress)

            newAddress.forEach((obj) => {
              if (obj === editAddress) {
                console.log('hittttt true__obj', obj)
                newObj = {
                  name: name,
                  addressLine1: address,
                  addressLine2: '',
                  addressLine3: '',
                  houseNo: '',
                  city: city,
                  zip: pincode,
                  state: state,
                  country: 'India',
                  landMark: landmark,
                  phoneNo: contactNumber,
                  alternatePhoneNo: alternateNumber,
                  addressType: type,
                  // latitude: res?.payload?.results?.[0]?.geometry?.location?.lat,
                  // longitude: res?.payload?.results?.[0]?.geometry?.location?.lng,
                  latitude:
                    latLong.latitude ||
                    res?.payload?.results?.[0]?.geometry?.location?.lat,
                  longitude:
                    latLong.longitude ||
                    res?.payload?.results?.[0]?.geometry?.location?.lng,
                  selected: selected,
                  addedOn:
                    obj?.addedOn || moment().format('YYYY-MM-DDTHH:MM:SS'),
                  updatedOn: moment().valueOf(),
                }
              }
            })
            let filteredAddress = newAddress.filter(
              (address) => address !== editAddress
            )
            newAddressData = [...filteredAddress, newObj]
          } else {
            console.log('hittttt false', editAddress)
            let filteredSelectedAddress = addressDetails?.filter(
              (address) => address.selected === true
            )
            let addressWithoutSelected = addressDetails?.filter(
              (address) => address.selected !== true
            )
            newAddressData = [...addressWithoutSelected]
            if (filteredSelectedAddress.length > 0) {
              let obj = { ...filteredSelectedAddress[0], selected: false }
              newAddressData = [...newAddressData, obj]
            }
            newAddress.forEach((obj) => {
              if (obj === editAddress) {
                console.log('hittttt false__obj', obj)
                newObj = {
                  name: name,
                  addressLine1: address,
                  addressLine2: '',
                  addressLine3: '',
                  houseNo: '',
                  city: city,
                  zip: pincode,
                  state: state,
                  country: 'India',
                  landMark: landmark,
                  phoneNo: contactNumber,
                  alternatePhoneNo: alternateNumber,
                  addressType: type,
                  latitude:
                    latLong.latitude ||
                    res?.payload?.results?.[0]?.geometry?.location?.lat,
                  longitude:
                    latLong.longitude ||
                    res?.payload?.results?.[0]?.geometry?.location?.lng,
                  selected: selected,
                  addedOn:
                    obj?.addedOn || moment().format('YYYY-MM-DDTHH:MM:SS'),
                  updatedOn: moment().valueOf(),
                }
              }
            })
            let filteredAddress = newAddressData.filter(
              (address) => address !== editAddress
            )
            newAddressData = [...filteredAddress, newObj]
            console.log('newAddress', newAddress)
          }

          dispatch(
            modifyAddressDetails({
              addressDetails: newAddressData,
            })
          ).then((res) => {
            console.log('deliveryAddress__Edit:::', newObj)
            setSeletedAddress(newObj)
            handleUpdateAddressClose()
            dispatch(getAddressDetails())
            reset()
            setTimeout(() => {
              setLoading(false)
            }, 3000)
          })
        } else {
          setIsPincodeError(true)
          setLoading(false)
          console.log('address line havind no coordinates')
          return
        }
      })
    }
  }

  const updateAddress = () => {
    let error = false

    if (alternateNumber) {
      let alterError = validateMobileNumber(alternateNumber)

      if (alterError) {
        setIsAlternateError(true)
        error = true
      }
    }
    if (!address) {
      setIsAddressError(true)
      error = true
    }
    if (!name) {
      setIsNameError(true)
      error = true
    }
    if (!email) {
      setIsEmailError(true)
      error = true
    }
    if (pincode.length !== 6) {
      setIsPincodeError(true)
      error = true
    }
    if (!city) {
      setIsCityError(true)
      error = true
    }
    if (!state) {
      setIsStateError(true)
      error = true
    }
    if (!type) {
      setIsTypeError(true)
      error = true
    }
    if (error) {
      setLoading(false)
      return
    }

    console.log('Update_Address__addressDetails:', addressDetails)

    const addressList = addressDetails?.filter((item) => item)

    console.log('Update_Address__addressList:', addressList)
    console.log('Update_Address__editAddress:', editAddress)

    const newAddress = {
      ...editAddress,
      name: name,
      addressLine1: address?.trim(),
      city: city,
      zip: pincode,
      state: state,
      landMark: landmark,
      phoneNo: contactNumber,
      alternatePhoneNo: alternateNumber,
      addressType: type,
      selected: selected,
      updatedOn: moment().valueOf(),
      addedOn: editAddress?.addedOn || moment().format('YYYY-MM-DDTHH:MM:SS'),
    }

    const updatedAddList = addressList?.map((item, index) => {
      if (
        item?.addressLine1 === editAddress?.addressLine1 &&
        item?.name === editAddress?.name &&
        item?.latitude === editAddress?.latitude &&
        item?.longitude === editAddress?.longitude &&
        item?.zip === editAddress?.zip
      ) {
        console.log('Update_Address__HITT:' + index, {
          ...item,
          ...newAddress,
        })
        return {
          ...item,
          ...newAddress,
        }
      } else {
        return { ...item, selected: selected ? false : item?.selected }
      }
    })

    console.log('Update_Address__updatedAddList:', updatedAddList)

    dispatch(
      modifyAddressDetails({
        addressDetails: updatedAddList,
      })
    ).then((res) => {
      setSeletedAddress(() => newAddress)

      handleUpdateAddressClose()
      dispatch(getAddressDetails())
      reset()
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    })
  }

  // autoFill user selected address from map
  const handlePickLocationConfirm = (userAddress) => {
    if (userAddress) {
      console.log('userAddress:', userAddress)
      setCity((prevState) => allowOnlyEnglish(userAddress?.city))
      setPincode((prevState) => allowOnlyEnglish(userAddress?.postalCode))
      setState((prevState) => allowOnlyEnglish(userAddress?.state))
      setAddress('')
      // setAddress((prevState) => userAddress?.address)
      setLandmark((prevState) => allowOnlyEnglish(userAddress?.locality))

      setLatLong((prevState) => ({
        latitude: userAddress?.lat,
        longitude: userAddress?.lng,
      }))
    }

    setOpenMap({
      show: false,
      userLat: '',
      userLong: '',
    })

    setIsUpdateRequired(false)
  }

  const onChooseLocation = () => {
    setOpenMap((prevState) => ({
      ...prevState,
      show: true,
      userLat: editAddress?.latitude,
      userLong: editAddress?.longitude,
    }))
  }

  return (
    <Box
      px={{ md: 4, xs: 1.5 }}
      py={2}
      sx={{
        height: { xs: '68vh', md: '88vh' },
        overflowY: 'auto',
        background: whiteColor,
      }}
      borderRadius={1}
    >
      <Typography
        variant='h2'
        textAlign='center'
        fontSize={17}
        color={headingColor}
        pb={{ md: 1.5, xs: 0.7 }}
      >
        {title}
      </Typography>

      <PickAddressFromMapModal
        isOpen={openMap.show}
        setIsOpen={() => {
          setOpenMap((prevState) => ({
            show: false,
            userLat: editAddress?.latitude,
            userLong: editAddress?.longitude,
          }))
        }}
        onConfirm={handlePickLocationConfirm}
        userLat={openMap.userLat}
        userLong={openMap.userLong}
      />

      <Box>
        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label='Name'
              value={name}
              onValueChange={(text) => {
                if (isNameError) {
                  setIsNameError(false)
                }
                setName(text)
              }}
              placeholder={addressForm_EnterName}
              required={true}
              isError={isNameError}
              errorMsg={addressForm_NameError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label='Email-Id'
              value={email}
              placeholder={addressForm_EnterMail}
              required={true}
              disabled
              isError={isEmailError}
              errorMsg={addressForm_MailError}
            />
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label={addressForm_EnterNumber}
              value={contactNumber?.replace('+91', '+91  ')}
              placeholder={addressForm_NumberError}
              required={true}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label={addressForm_AlternateNumber}
              value={alternateNumber}
              maxLength={10}
              onValueChange={(text) => {
                if (isAlternateError) {
                  setIsAlternateError(false)
                }
                const value = text
                  .replace(/[^0-9.]/g, '')
                  .replace(/(\..*)\./g, '$1')
                setAlternateNumber(value)
              }}
              placeholder={addressForm_AlternateNumberError}
              isError={isAlternateError}
              errorMsg={'Please enter a valid Mobile number'}
              startIcon={
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={blackColor}
                  sx={{ mt: -0.1 }}
                >
                  +91
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Typography
          component='p'
          fontSize={12}
          fontWeight={400}
          color={blackColor}
          lineHeight='20px'
          mt={0.5}
        >
          Please update the recipient number, if you are booking the order on
          behalf of someone else.
        </Typography>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={4}>
            <TextboxWithLabel
              label='Pincode'
              value={pincode}
              onValueChange={(text) => {
                if (text.length === 6) {
                  dispatch(getStateCityDetails(text)).then((res) => {
                    if (text.length === 6) {
                      const filterState =
                        res?.payload?.results?.[0]?.address_components?.filter(
                          (item) =>
                            item?.types?.includes('administrative_area_level_1')
                        )
                      const filterCity1 =
                        res?.payload?.results?.[0]?.address_components?.filter(
                          (item) =>
                            item?.types?.includes('administrative_area_level_2')
                        )
                      const filterCity2 =
                        res?.payload?.results?.[0]?.address_components?.filter(
                          (item) =>
                            item?.types?.includes('administrative_area_level_3')
                        )
                      const filterCity3 =
                        res?.payload?.results?.[0]?.address_components?.filter(
                          (item) => item?.types?.includes('locality')
                        )

                      setCity(
                        filterCity1?.length > 0
                          ? filterCity1?.[0]?.long_name
                          : filterCity2?.length > 0
                          ? filterCity2?.[0]?.long_name
                          : filterCity3?.length > 0
                          ? filterCity3?.[0]?.long_name
                          : ''
                      )
                      console.log(
                        'filterCity',
                        filterCity1,
                        filterCity2,
                        filterCity3
                      )
                      console.log('filterState', filterState)
                      setState(
                        filterState?.length > 0
                          ? filterState?.[0]?.long_name
                          : ''
                      )
                      setOpenMap((prevState) => ({
                        show: true,
                        userLat:
                          res?.payload?.results?.[0]?.geometry?.location?.lat ||
                          '',
                        userLong:
                          res?.payload?.results?.[0]?.geometry?.location?.lng ||
                          '',
                      }))
                    }

                    // if (text?.length === 6)
                    //   setState(
                    //     res?.payload?.results?.[0]?.address_components?.[
                    //       res?.payload?.results?.[0]?.address_components
                    //         ?.length - 2
                    //     ]?.long_name
                    //   )
                    // setCity(
                    //   res?.payload?.results?.[0]?.address_components?.[
                    //     res?.payload?.results?.[0]?.address_components?.length -
                    //       3
                    //   ]?.long_name
                    // )
                    setIsStateError(false)
                    setIsCityError(false)
                  })
                  setIsPincodeError(false)
                }
                setPincode(text)
              }}
              maxLength={6}
              placeholder={addressForm_Pincode}
              required={true}
              isError={isPincodeError}
              errorMsg={addressForm_PincodeError}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextboxWithLabel
              label='City'
              placeholder=''
              required={true}
              value={city}
              onValueChange={(text) => {
                setIsCityError(false)
                setCity(text)
              }}
              // disabled
              isError={isCityError}
              errorMsg={addressForm_CityNameError}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextboxWithLabel
              label='State'
              placeholder=''
              required={true}
              value={state}
              onValueChange={(text) => {
                setIsStateError(false)
                setState(text)
              }}
              // disabled
              isError={isStateError}
              errorMsg={'Enter City Name'}
            />
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={12}>
            <Button
              sx={styles.chooseLocationBtn}
              variant='contained'
              disableElevation
              onClick={onChooseLocation}
              startIcon={<NearMeIcon sx={{ fontSize: '40px' }} />}
            >
              Update Current Location
            </Button>

            <Typography
              component='p'
              fontSize={12.2}
              fontWeight={500}
              color={primaryColor}
              lineHeight='20px'
              mt={0.5}
            >
              Please choose the exact location of the building from the map, for
              a smooth delivery
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={12}>
            <TextboxWithLabel
              label={addressForm_HouseNo}
              placeholder=''
              value={address}
              onValueChange={(text) => {
                if (isAddressError) {
                  setIsAddressError(false)
                }
                setAddress(text)
              }}
              required={true}
              isError={isAddressError}
              errorMsg={addressForm_HouseNoError}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label='Landmark'
              placeholder={addressForm_Landmark}
              value={landmark}
              onValueChange={(text) => setLandmark(text)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={6}>
            <>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={type}
                onChange={({ target }) => {
                  if (isTypeError) {
                    setIsTypeError(false)
                  }
                  setType(target.value)
                }}
              >
                <FormControlLabel
                  value='Home'
                  control={<Radio />}
                  label='Home'
                />
                <FormControlLabel
                  value='Office/Commercial'
                  control={<Radio />}
                  label='Office/Commercial'
                />
              </RadioGroup>
              {isTypeError ? (
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize={13}
                  color={errorTextColor}
                >
                  {addressForm_SelectAddressType}
                </Typography>
              ) : null}
            </>
          </Grid>
          {/* <CheckBoxLabel
            label={'Make this my default address'}
            checked={filteredSubCat.includes(subcat2)}
            onValuesChanged={(catCode) => {
              handleSubCatClick(catCode)
            }}
          /> */}
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={<Checkbox checked={selected} />}
              label='Default address'
              value={selected}
              onChange={() => {
                setDefault(!selected)
              }}
            />
          </Grid>
        </Grid>

        {/* {selected.toString()} */}
      </Box>
      <Box display='flex' justifyContent='center' mt={2}>
        <Button
          variant='contained'
          sx={{ px: 10, ...styles.submitBtn }}
          size='large'
          onClick={() => {
            if (loading) return

            if (isUpdateRequired) {
              setOpenAlert(true)
              return
            }

            setLoading(true)
            // editAddressClicked()
            updateAddress()
          }}
        >
          {loading ? (
            <CircularProgress sx={{ color: whiteColor, mr: 1 }} size={16} />
          ) : null}{' '}
          Update Address
        </Button>
      </Box>

      <AlertDialog
        content='Please update the current location.'
        isOpen={openAlert}
        onClose={() => {
          setOpenAlert(false)
        }}
      />
    </Box>
  )
}

export default EditAddressForm

const styles = {
  submitBtn: {
    fontSize: '14px',
    fontWeight: 600,
    color: whiteColor,
    border: '2px solid ' + primaryColor,
    '&:hover': {
      backgroundColor: whiteColor,
      color: 'primary.main',
    },
  },

  chooseLocationBtn: {
    background: whiteColor,
    p: '0px 0px',
    fontSize: '13px',
    color: primaryColor,
    fontWeight: 600,
    textTransform: 'unset',
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: whiteColor,
      color: primaryColor,
      textDecoration: 'none',
    },
  },
}
