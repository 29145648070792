import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import {
  blackColor,
  blackShade8,
  whiteColor,
} from '../../configs/styles/muiThemes'

function HeroSection({ handleGetStarted, rellerProfileData, businessAffiliateData, affiCommissionPercentage }) {

  const businessResellerData = businessAffiliateData?.businessResellers?.[0]

  return (
    <Box
      display='flex'
      alignItems='center'
      p='12px'
      sx={{
        backgroundImage: 'url(https://storage.googleapis.com/bodefaults/shopweb/affiliates-banner.png)',
        backgroundSize: '100% 100%',
      }}
    >
      <Box>
        <Typography
          variant='h2'
          fontSize={15}
          fontWeight={500}
          lineHeight='24px'
          color={blackColor}
          sx={{ m: '10px 0 !important' }}
        >
          Direct Product Promotions, Higher commission than marketplaces
        </Typography>

        <Typography
          variant='body2'
          fontSize={13}
          fontWeight={400}
          lineHeight='18px'
          color={blackColor}
          sx={{ m: '8px 0 15px 0 !important' }}
        >
          {/* {businessResellerData?.affiliatePercentage}% commissions on every sale */}
          {affiCommissionPercentage}% commissions on every sale
        </Typography>

        <Button
          variant='contained'
          onClick={handleGetStarted}
          disableElevation
          sx={styles.getStartedBtn}
        >
          {rellerProfileData?.details ? 'SHARE & EARN - CLICK HERE' : 'GET STARTED - CLICK HERE'}
        </Button>
      </Box>

      <Box
        component='img'
        src='https://storage.googleapis.com/bodefaults/shopweb/affiliates-banner-image.png'
        sx={{ width: '145px', height: 'auto' }}
      />
    </Box>
  )
}

export default HeroSection

const styles = {
  getStartedBtn: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '20px',
    padding: '6px 12px',
    color: blackShade8,
    backgroundColor: whiteColor,
    textDecoration:'underline',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.3)',
    marginBottom:'12px',

    '&:hover': {
      color: blackShade8,
      backgroundColor: whiteColor,
      textDecoration:'none',
    },
  },
}
