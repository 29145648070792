import moment from 'moment'

export const getDayOfWeek = (day) => {
  switch (day) {
    case 1:
      return 'Sunday'
    case 2:
      return 'Monday'
    case 3:
      return 'Tuesday'
    case 4:
      return 'Wednesday'
    case 5:
      return 'Thursday'
    case 6:
      return 'Friday'
    case 7:
      return 'Saturday'
    default:
      return 'No Data'
  }
}

export const checkIsStoreOpen = (fromTime, toTime) => {
  var beginningTime = moment(fromTime, 'hh:mm A')
  var endTime = moment(toTime, 'hh:mm A')

  return moment().isBefore(endTime) && moment().isAfter(beginningTime)
    ? true
    : false
}

export const getGraphData = (reviewSummary, summary) => {
  console.log(summary)
  const newsummary = []
  summary?.forEach((item) => {
    reviewSummary?.forEach((review) => {
      item =
        item?.reviewRating === Math.round(review?.reviewRating)
          ? { ...item, percent: item.percent + review?.percent }
          : item
    })
    newsummary.push(item)
  })
  return newsummary
}

export const summary = [
  {
    reviewRating: 5.0,
    count: 0,
    percent: 0,
  },
  {
    reviewRating: 4.0,
    count: 0,
    percent: 0,
  },
  {
    reviewRating: 3.0,
    count: 0,
    percent: 0,
  },
  {
    reviewRating: 2.0,
    count: 0,
    percent: 0,
  },
  {
    reviewRating: 1.0,
    count: 0,
    percent: 0,
  },
]

export const updateSessionCartQty = (productId, qty) => {
  let cartData = JSON.parse(sessionStorage.getItem('cartData'))
  cartData?.productList?.forEach((item, index) => {
    if (item.productId === productId) {
      cartData.productList[index] = { ...item, currentQuantity: qty }
      if (item.currentQuantity < qty) {
        cartData.orderTotalProductCost =
          cartData?.orderTotalProductCost + item?.productSalePrice
      } else {
        cartData.orderTotalProductCost =
          cartData?.orderTotalProductCost - item?.productSalePrice
      }
    }
  })

  console.log("SESSION_CART_DATA_4");
  sessionStorage.setItem('cartData', JSON.stringify(cartData))

  console.log('UPDATE_SESSION_CART:', cartData)
  console.log('UPDATE_SESSION_CART_PID:', productId)
  console.log('UPDATE_SESSION_CART_QTY:', qty)
}

export const getOperatingSystem = (window) => {
  // console.log(
  //   'device',
  //   window.navigator.userAgent,
  //   window.navigator.mediaDevices
  // )
  // window.navigator.mediaDevices
  //   .enumerateDevices()
  //   .then((devices) => {
  //     devices.forEach((device) => {
  //       console.log(
  //         'hukgkyfgjhym',
  //         `${device.kind}: ${device.label} id = ${device.deviceId}`
  //       )
  //     })
  //   })
  //   .catch((err) => {
  //     console.log(`${err.name}: ${err.message}`)
  //   })
  let operatingSystem = 'unknown'
  if (window.navigator.userAgent.indexOf('Win') !== -1) {
    operatingSystem = 'Windows'
  }
  if (window.navigator.userAgent.indexOf('Mac') !== -1) {
    operatingSystem = 'Mac'
  }
  if (window.navigator.userAgent.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX'
  }
  if (window.navigator.userAgent.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux'
  }
  if (window.navigator.userAgent.indexOf('Android') !== -1) {
    operatingSystem = 'Android'
  }
  if (window.navigator.userAgent.indexOf('iPhone') !== -1) {
    operatingSystem = 'iPhone'
  }

  return operatingSystem
}

export const getBrowser = (window) => {
  let currentBrowser = 'unknown'
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
    currentBrowser = 'Chrome'
  } else if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
    currentBrowser = 'Mozilla Firefox'
  } else if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
    currentBrowser = 'Internet Exployer'
  } else if (window.navigator.userAgent.indexOf('Edge') !== -1) {
    currentBrowser = 'Edge'
  } else if (window.navigator.userAgent.indexOf('Safari') !== -1) {
    currentBrowser = 'Safari'
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'Opera'
  } else {
    console.log('Others')
  }

  return currentBrowser
}

export const fintechApplyData = [
  'Hassle free working capital in minutes',
  'No interest, upto 1 month*',
  'GST/PAN, Aadhar needed, One time verification, Upto 10L revolving line of credit',
]

export const fintechData = [
  'GST/PAN Needed',
  'UP to 2 months interest free',
  'One time verification',
  'Up to 5L revolving credit line',
]

export const addThousandsSeparator = (number, removeDecimal = false) => {
  if (!number) return "0";
  const num_parts = Number(number).toFixed(2).toString().split(".");
  num_parts[0] = num_parts[0].replace(
    /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g,
    ","
  );

  const result = num_parts.join(".");
  return removeDecimal ? result.slice(0, result.length - 3) : result;
};

export const allowOnlyEnglish = (inputValue) => {
  // check if the input contains only English characters
  const sanitizedValue = inputValue.replace(
    /[^a-zA-Z0-9\s!@#$%^&*()-_+=<>?.,:;'"{}[\]|/\\]/g,
    ""
  );

  return sanitizedValue
};