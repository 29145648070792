import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Typography, Grid, Box, Button, Container } from '@mui/material'
import { blackColor, trackColor, whiteColor } from 'configs/styles/muiThemes'

function HeaderSection() {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box sx={styles.headerSection}>
      <Container
        sx={{
          maxWidth: '1300px !important',
        }}
      >
        <Grid container>
          <Grid item md={5.8} xs={12}>
            <Box>
              <Box textAlign={{ xs: 'center', md: 'left' }}>
                <Box
                  component='img'
                  src='https://storage.googleapis.com/bodefaults/shopweb/logo_footer.png'
                  alt={businessFullName + ' CostBo'}
                  width='212px'
                  height='auto'
                />
              </Box>

              <Typography
                variant='h3'
                color={whiteColor}
                fontSize={{ xs: 22, md: 40 }}
                mt={{ xs: '20px', md: '40px' }}
                mb={{ xs: '10px !important', md: '18px !important' }}
                lineHeight={{ xs: '36px', md: '58px' }}
                textAlign={{ xs: 'center', md: 'left' }}
              >
                Enjoy a whole new shopping experience
              </Typography>

              <Typography
                variant='subtitle1'
                color={whiteColor}
                fontSize={{ xs: 16, md: 20 }}
                mt={{ xs: '10px', md: '35px' }}
                mb={{ xs: '0px !important', md: '14px !important' }}
                pr={{ xs: '0%', md: '30%' }}
                lineHeight={{ xs: '27px', md: '36px' }}
                textAlign={{ xs: 'center', md: 'left' }}
              >
                India's leading Direct to Consumer (D2C) network for Brands &
                Stores
              </Typography>

              <Box
                display='flex'
                alignItems='center'
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                <Button
                  sx={styles.actionBtn}
                  variant='contained'
                  disableElevation
                  onClick={() => {}}
                  component='a'
                  href='https://www.costbo.com/'
                  endIcon={<ArrowForwardRoundedIcon fontSize='small' />}
                >
                  shop@costbo.com
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6.2} xs={12}>
            <Box
              display={{ xs: 'none', md: 'flex' }}
              alignItems='flex-end'
              mt='23px'
            >
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/landing_shopping_banner_2.jpg'
                alt={businessFullName + ' CostBo'}
                width='220px'
                height='auto'
                sx={{ borderRadius: '25px', mr: 1.5 }}
              />
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/landing_shopping_banner_1.jpg'
                alt={businessFullName + ' CostBo'}
                width='409px'
                height='auto'
                sx={{ borderRadius: '25px', ml: 1.5 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default HeaderSection

const styles = {
  headerSection: {
    height: { xs: 'auto', md: '690px' },
    background: 'url(https://storage.googleapis.com/bodefaults/shopweb/home-bg.png) no-repeat center',
    backgroundSize: '100% 100%',
    backgroundColor: { xs: trackColor, md: 'transparent' },
    paddingTop: { xs: '20px', md: '100px' },
    paddingBottom: { xs: '20px', md: '0px' },
    marginBottom: '30px',
  },

  actionBtn: {
    fontSize: '15px',
    fontWeight: 600,
    color: whiteColor,
    p: '8px 25px',
    border: '1px solid ' + blackColor,
    textTransform: 'none',
    borderRadius: '5px',
    backgroundColor: blackColor,
    marginTop: '24px',
    '&:hover': {
      backgroundColor: whiteColor,
      color: blackColor,
    },
  },
}
