import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from '@mui/material'
import EmptyCard from 'components/EmptyCard'
import {
  emptyOrderBtnText,
  openOrdersEmptyImgUrl,
  openOrdersEmptyMessage,
  OrderEmptyDescription,
} from 'configs/Constants'
import BreadcrumbsSection from '../../components/Breadcrumbs'
import {
  dashboardDescriptionColor,
  headingColor,
} from '../../configs/styles/muiThemes'
import OrderCard from '../AllOrders/OrderCard'
import DashboardNavbar from '../Dashboard/DashboardNavbar'
// import OrderCard from "./OrderCard";

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Loader from 'components/Loader'

function OpenOrdersContent({
  accountDetails,
  openOrderDetails,
  loading,
  businessId,
  ordersCountData,
  handleOrderPagination,
  pageNo,
}) {
  const handlePagination = (event, value) => {
    console.log('OPEN_ORDER_PAGINATION', value)
    handleOrderPagination(value)
  }

  return (
    <Container sx={{ maxWidth: '1300px !important', mt: { xs: 1.8, md: 2.5 } }}>
      {/* <BreadcrumbsSection
        links={[
          { label: 'All Orders', to: `/AllOrders` },
          { label: 'Open Orders', to: `/OpenOrders` },
        ]}
      /> */}

      <Box mt={{ md: '20px !important', xs: 0 }}>
        <Grid
          container
          rowSpacing={{ md: 2, xs: 0 }}
          columnSpacing={{ md: 5, xs: 0 }}
          // mt={{ md: 2, xs: 0 }}
        >
          <Grid item md={3} xs={12}>
            <DashboardNavbar active='openOrders' businessId={businessId} />
          </Grid>

          {loading ? (
            <Grid item md={9} xs={12}>
              <Box display='flex' flexDirection='row' justifyContent='center'>
                <Loader />
              </Box>
            </Grid>
          ) : (
            <Grid item md={9} xs={12}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                flexWrap='wrap'
                gap={1}
                mb={2.0}
                mt={{ md: 0, xs: 2.3 }}
              >
                <Box>
                  <Typography
                    variant='h4'
                    component='h2'
                    fontSize={{ xs: 15, md: 17 }}
                    color={headingColor}
                    display='inline'
                  >
                    Open Orders{' '}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    fontSize={{ xs: 14, md: 16 }}
                    color={dashboardDescriptionColor}
                    display='inline'
                  >
                    {/* ({openOrderDetails?.details?.length}) */}(
                    {ordersCountData?.details?.openOrder})
                  </Typography>
                </Box>
                {/* <Typography
                variant='subtitle1'
                component='p'
                fontSize='14px'
                lineHeight='24px'
                color={dashboardDescriptionColor}
                display='inline'
              >
                Hello,{' '}
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize='14px'
                  fontWeight={600}
                  color={primaryColor}
                  display='inline'
                >
                  {accountDetails?.account?.name} !
                </Typography>
              </Typography> */}
              </Box>
              {openOrderDetails?.details?.map((order, index) => {
                return (
                  <OrderCard
                    key={'allopenorders_' + index}
                    cartId={order.cartId}
                    orderId={order.orderId}
                    date={order.orderDate}
                    quantity={order.totalQuantity}
                    totalPrice={order.orderTotalCost}
                    orderStatus={order.orderStatus}
                    cartDate={order.cartDate}
                    orderData={order}
                    fromPage={{ label: 'Open Orders', to: 'OpenOrders' }}
                  />
                )
              })}

              {ordersCountData ? (
                ordersCountData?.details?.openOrder > 20 ? (
                  <Stack
                    spacing={2}
                    my={4}
                    display={{ md: 'flex', xs: 'none' }}
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Pagination
                      count={
                        ordersCountData
                          ? Math.ceil(ordersCountData?.details?.openOrder / 20)
                          : 0
                      }
                      page={pageNo}
                      onChange={handlePagination}
                      color='primary'
                      renderItem={(item) => (
                        <PaginationItem
                          components={{
                            next: NextBtn,
                            previous: PreviousBtn,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Stack>
                ) : null
              ) : null}

              {openOrderDetails?.details?.length === 0 ? (
                <EmptyCard
                  message={openOrdersEmptyMessage}
                  description={OrderEmptyDescription}
                  imageUrl={openOrdersEmptyImgUrl}
                  actionBtnText={emptyOrderBtnText}
                />
              ) : null}
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  )
}

export default OpenOrdersContent

const styles = {
  btnHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  )
}

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  )
}
