import CryptoJS from 'crypto-js'

export const isAuthenticated = () => {
  const authToken = getAccessToken()

  if (authToken) {
    return authToken
  }
  return null
}

export const isAuthenticatedLL = () => {
  const authToken = getAccessToken()
  if (authToken) {
    return authToken
  }
  return null
}

export const storeToken = (accessToken, profile) => {
  sessionStorage.setItem('access_token', accessToken)
  sessionStorage.setItem('profile', profile)
}

export const storeUserInfo = (userInfo) => {
  const stringifyObj = JSON.stringify(userInfo)

  // convert string to base64

  const b64Str = btoa(stringifyObj)
  console.log(b64Str)
  sessionStorage.setItem('userInfo', b64Str)
}

export const getUserDetails = () => {
  const tempString = sessionStorage.getItem('userInfo')
  if (tempString) {
    const objStr = atob(tempString)
    // console.log(objStr)
    const loggedInUserDetails = JSON.parse(objStr)
    // console.log("loggedInUserDetails",loggedInUserDetails)
    return loggedInUserDetails
  }
  return undefined
}

export const getUserName = () => {
  const userDetails = getUserDetails()
  // console.log(userDetails)
  if (userDetails) return userDetails?.account?.name
  else return ''
}

export const getPhoneNumber = () => {
  const userDetails = getUserDetails()
  // console.log(userDetails)
  if (userDetails) return userDetails.account.phonenumber
  else return ''
}

export const geProfilePhoto = () => {
  const userDetails = getUserDetails()
  if (userDetails) return userDetails.account.photoURL
  else return ''
}

export const removeSessionInfo = () => {
  sessionStorage.removeItem('userInfo')
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('profileId')
  sessionStorage.removeItem('cartData')
  sessionStorage.removeItem('cashback')
  sessionStorage.removeItem('userType')
  sessionStorage.removeItem('phone')
  sessionStorage.removeItem('finboxStatus')
  sessionStorage.removeItem('finvervStatus')
  sessionStorage.removeItem('finvervUser')
  sessionStorage.removeItem('finboxUser')
}

export const getUserInfo = () => sessionStorage.getItem('')

export const getAccessToken = () => {
  let token = sessionStorage.getItem('token')
  return token
}

export const getProfileId = () => {
  return sessionStorage.getItem('profileId')
}

export const encryptData = (data) => {
  const cipherText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    'kZcpaSt3Tby@XkhZG4fW2t2W'
  ).toString()

  return cipherText
}

export const decryptData = (data) => {
  const d_data = data

  const bytes = CryptoJS.AES.decrypt(d_data, 'kZcpaSt3Tby@XkhZG4fW2t2W')
  return bytes.toString(CryptoJS.enc.Utf8).slice(1, -1)
  // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  // console.log('ORIGINAL_DATA', decryptedData)
  // return decryptedData
}
