import { getProfileId } from 'utils/authHelpers'
import { getApiInstance } from '../../ApiInstance'

export const getProductData = async (data) => {
  const businessId = sessionStorage.getItem('businessId')
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    })

    const response = await instance.get(
      `product-query-side/api/v1/product/viewActiveProduct/${data}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
export const getSimilarProductData = async (data) => {
  try {
    const instance = getApiInstance({
      header: { businessId: data?.businessId },
    })

    const response = await instance.get(
      `product-query-side/api/v1/product/getSimilarProducts`,
      { params: { pageNo: 1, subCatCode: data?.subCat } }
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return null
  }
}
export const getCommercialData = async () => {
  // const businessId = 'f7b2fa9797ce4e8f858bd372693d0980'
  const businessId = sessionStorage.getItem("businessId")
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    })

    const response = await instance.get(
      `business-query-side/api/v1/business/getCommerceInfo`
    )
    sessionStorage.setItem('commerceInfo', JSON.stringify(response?.data))
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const getOfferOrder = async (productId) => {
  const businessId = sessionStorage.getItem("businessId")
  const profileId = getProfileId()

  try {
    const instance = getApiInstance({
      header: { businessId: businessId, profileId:profileId },
    })

    const response = await instance.get(
      `cart-query-side/api/v2/cart/consumer/offerOrder/${productId}`
    )
    console.log("OFFER_ORDER_DATA", response.data);
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
