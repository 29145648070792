import {
  Box,
} from '@mui/material'
import LandingPageFooter from './LandingPage/LandingPageFooter'
import HeaderSection from './LandingPage/HeaderSection'
import InfoSection from './LandingPage/InfoSection'
import CostBoInfluencerCard from './LandingPage/CostBoInfluencerCard'
import { useNavigate } from 'react-router-dom'

function ShopCostBoHome() {
  return (
    <Box>
      <HeaderSection />
      <InfoSection />

      <CostBoInfluencerCard />

      {/* Footer */}
      <LandingPageFooter />
    </Box>
  )
}

export default ShopCostBoHome