import { StarRounded } from '@mui/icons-material'
import { Box, Button, Grid, Rating, Typography } from '@mui/material'
import moment from 'moment'
import {
  arrowBgColor,
  borderBackground,
  headingColor,
  reviewBodyColor,
  reviewSubColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function OwnReview(props) {
  return (
    <Box>
      <Grid container spacing={1} sx={{ my: '6px' }}>
        <Grid item>
          <Typography variant='subtitle1' color={headingColor} align='left'>
            Your Review
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant='body1' color={reviewBodyColor}>
            on {moment(props.reviews.messageCreateDate).format('MMM DD, YYYY')}
          </Typography>
        </Grid>
      </Grid>

      <Rating
        readOnly
        icon={<StarRounded sx={{ color: 'primary.main', fontSize:'20px' }} />}
        emptyIcon={<StarRounded sx={{ color: arrowBgColor, fontSize:'20px' }} />}
        precision={0.1}
        value={props.reviews.reviewRating}
        sx={{ marginBottom: 1 }}
      />

      <Typography variant='h5' fontSize={{md:'15px',xs:'13px'}} color={headingColor} mb={'6px !important'}>
        {props.reviews.reviewTitle}
      </Typography>

      <Typography variant='body1' fontSize={{md:'13px',xs:'11px'}} color={reviewBodyColor}>
        {props.reviews.message}
      </Typography>

      <Box sx={styles.editBtnContainer}>
        <Button
          sx={[styles.editBtn, { ml: 3 }]}
          variant='contained'
          disableElevation
          size='small'
          onClick={props.onEditClick}
        >
          EDIT REVIEW
        </Button>
      </Box>
    </Box>
  )
}

export default OwnReview

const styles = {
  actionContainer: {
    borderTop: borderBackground,
    pt: "10px",
    mt: "36px",
  },

  actionBtn: {
    fontSize: "15px",
    fontWeight: 400,
    textTransform: "none",
    color: reviewSubColor,
    "&:hover": {
      backgroundColor: whiteColor,
    },
  },

  editBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mt: 1,
    pt:2,
    borderTop: borderBackground,
  },

  editBtn: {
    fontSize: "14px",
    fontWeight: 500,
    color: whiteColor,
    p: "7px 28px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "primary.main",
    },
  },
}
