import { Badge, Box, Button, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  borderCart,
  borderLineColor,
  filterMobileColor,
  headingColor,
  primaryColor,
  secondaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

function CatalogHeader({ onFilterClick, itemCount, totalPrice }) {
  const businessName = sessionStorage.getItem('businessName')
  return (
    <Box
      backgroundColor={whiteColor}
      borderBottom={'1px solid ' + borderLineColor}
    >
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        py={{ md: 1.5, xs: 0.5 }}
      >
        <Grid item>
          <Typography
            variant='h2'
            fontSize={{ md: '19px', xs: '16px' }}
            color={headingColor}
          >
            Catalog
          </Typography>
        </Grid>

        <Grid item display={{ xs: 'block', md: 'none' }}>
          <Button
            startIcon={<FilterAltOutlinedIcon />}
            sx={styles.btnHover}
            onClick={() => {
              onFilterClick()
            }}
          >
            Filter
          </Button>
        </Grid>

        {itemCount > 0 ? (
          <Grid item display={{ xs: 'none', md: 'block' }}>
            <Typography
              variant='h6'
              color={headingColor}
              fontSize='13px'
              fontWeight={500}
              display='inline'
              px={2}
              borderRight={borderCart}
            >
              {itemCount} items in cart
            </Typography>

            <Typography
              variant='h5'
              color={headingColor}
              fontSize='15px'
              fontWeight={600}
              display='inline'
              mx={2}
            >
              ₹ {Number(totalPrice).toFixed(2)}
            </Typography>

            <Button
              component={Link}
              to={`/${businessName}/Cart`}
              sx={styles.gotoCardBtn}
              variant='contained'
              disableElevation
              size='small'
              onClick={() => {}}
            >
              GO TO CART
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  )
}

export default CatalogHeader

const styles = {
  gotoCardBtn: {
    fontSize: '13px',
    fontWeight: 600,
    color: whiteColor,
    p: '2px 15px',
    border: '2px solid ' + primaryColor,
    textTransform: 'none',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: whiteColor,
      color: 'primary.main',
    },
  },

  btnHover: {
    color: filterMobileColor,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}
