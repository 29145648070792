import { Box, Typography } from '@mui/material'
import { commingSoonMsg } from 'configs/Constants'
import { headingColor } from 'configs/styles/muiThemes'
import React from 'react'

function CommingSoonCard() {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Box
        component='img'
        src='https://storage.googleapis.com/bodefaults/shopweb/Empty/coming_soon.png'
        width='150px'
        height='auto'
        sx={{ mt: 3 }}
        alt={businessFullName + ' CostBo'}
      />
      <Typography
        variant='subtitle2'
        fontSize={15}
        color={headingColor}
        pt={1}
        pb={2}
        sx={{ width: { xs: '90%', md: '40%' } }}
        textAlign='center'
      >
        {commingSoonMsg}
      </Typography>
    </Box>
  )
}

export default CommingSoonCard
