import { Box, Grid, Typography } from '@mui/material'
import { bannerColor, headingColor, whiteColor } from 'configs/styles/muiThemes'
import { keyframes } from '@emotion/react'

function DiscountOfferSection({
  rewardPointPercent,
  minOrderAmount,
  immediateDiscount,
  shipmentDistance,
}) {
  const marqueX = keyframes`
  from {
    transform: translateX(-90%);
  }
  to {
    transform: translateX(10%);
  }
`
  return (
    <Box pt={1.5} pb={1}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            zIndex: 2,
            left: '1px',
            right: '1px',
            top: '1px',
            bottom: '1px',
            border: '5px dashed #fff',
            // pointerEvents: 'none',
            '-webkit-clip-path': 'inset(4px 4px 4px 4px)',
            clipPath: 'inset(4px 4px 4px 4px)',
          },
        }}
      >
        <Grid container>
          <Grid item md={2.5} xs={12}>
            <Box
              sx={{
                background: '#f2f2f2',
                height: '50px',
                display: 'flex',
                // justifyContent: 'center',
                position: 'relative',
                pl: 1.5,
                '-webkit-box-align': 'center',
                '-ms-flex-align': 'center',
                alignItems: 'center',
                lineHeight: 1.2,
                zIndex: 1,
                whiteSpace: 'nowrap',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  right: '-46px',
                  top: '-44px',
                  bottom: '-36px',
                  borderRight: { md: '60px solid #f2f2f2', xs: 'none' },
                  '-webkit-transform': 'rotate(26deg)',
                  transform: 'rotate(26deg)',
                  zIndex: -1,
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  right: '-62px',
                  top: '-44px',
                  bottom: '-36px',
                  borderRight: { md: '5px solid #f2f2f2', xs: 'none' },
                  '-webkit-transform': 'rotate(26deg)',
                  transform: 'rotate(26deg)',
                  zIndex: -1,
                },
              }}
            >
              <Typography
                textAlign='start'
                fontSize={15}
                fontWeight={600}
                color={headingColor}
              >
                Discounts & Other Info
              </Typography>
            </Box>
          </Grid>
          <Grid item md={9.5} xs={12}>
            <Box
              sx={{
                background: bannerColor,
                height: '50px',
                display: 'flex',
                px: { md: '70px', xs: '0' },
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  width: { md: '100%', xs: '90vw' },
                  overflow: 'hidden',
                  mx: 2,
                }}
              >
                <Typography
                  sx={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    animation: `${marqueX} 30s linear infinite`,
                    animationDirection: 'reverse',
                  }}
                >
                  {rewardPointPercent ? (
                    <Typography
                      variant='subtitle1'
                      fontSize={{ xs: 13, md: 14 }}
                      component='p'
                      color={whiteColor}
                      fontWeight={600}
                      display='inline'
                      pr={3}
                    >
                      • {rewardPointPercent}% Cashback on all orders!
                    </Typography>
                  ) : null}

                  <Typography
                    variant='subtitle1'
                    fontSize={{ xs: 13, md: 14 }}
                    component='p'
                    color={whiteColor}
                    fontWeight={600}
                    display='inline'
                    pr={3}
                  >
                    • ₹{minOrderAmount} Minimum Purchase!
                  </Typography>
                  {immediateDiscount ? (
                    <Typography
                      variant='subtitle1'
                      fontSize={{ xs: 13, md: 14 }}
                      component='p'
                      color={whiteColor}
                      fontWeight={600}
                      display='inline'
                      pr={3}
                    >
                      • Free Shipping Above ₹{immediateDiscount}!
                    </Typography>
                  ) : null}
                  {shipmentDistance >= 3000 ? (
                    <Typography
                      variant='subtitle1'
                      fontSize={{ xs: 13, md: 14 }}
                      component='p'
                      color={whiteColor}
                      fontWeight={600}
                      display='inline'
                      pr={3}
                    >
                      • All India Shipment Available
                    </Typography>
                  ) : (
                    <Typography
                      variant='subtitle1'
                      fontSize={{ xs: 13, md: 14 }}
                      component='p'
                      color={whiteColor}
                      fontWeight={600}
                      display='inline'
                      pr={3}
                    >
                      • Limited shipping - {shipmentDistance} Km from Business warehouse
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default DiscountOfferSection
