import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCategoryDetailsByBusiness } from "services/api_calls/catalog/CatalogFilterMenuApi";



const initialState = {
    businessDetails:undefined || {},
    loading: false,
};

//Fetching overview details
export const getCategoryDetails = createAsyncThunk("getCategoryDetails", async (thunkAPI) => {
    const res = await getCategoryDetailsByBusiness();
  
   console.log(res)
    return res;
});



export const catalogFilterSlice = createSlice({
    name: "catalogFilter",
    initialState,
    reducers: {},
    extraReducers: {
        [getCategoryDetails.pending]: (state) => {
            state.loading = true;
        },
        [getCategoryDetails.fulfilled]: (state, { payload }) => {
         
            state.loading = false;
            state.businessDetails = payload;
        },
        [getCategoryDetails.rejected]: (state) => {
            state.loading = false;
        },
        
    },
});

export const catalogReducer = catalogFilterSlice.reducer;
