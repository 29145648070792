import { StarRounded } from "@mui/icons-material";
import { Grid, Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import {
  arrowBgColor,
  headingColor,
  progressBgColor,
  reviewBodyColor,
  secondaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function ReviewSummaryGraph(props) {

  return (
    <Box>
      <Box sx={styles.header}>
        <Rating
          readOnly
          icon={<StarRounded sx={{ color: "primary.main" }} />}
          emptyIcon={<StarRounded sx={{ color: arrowBgColor }} />}
          precision={0.1}
          value={props.reviewSummary?.average}
        />

        <Typography variant="h5" color={headingColor} marginX={0.8}>
        {props.reviewSummary?.average} out of 5
        </Typography>
        <Typography variant="body1" color={reviewBodyColor}>
          ({props.reviewSummary?.totalRated} Reviews)
        </Typography>
      </Box>
      {props.graphData.map((graph, index) => {
        return (
      <ProgressRow label={`${graph.reviewRating}`} progressValue={Math.round(graph.percent)} />
      )
    })}
    </Box>
  );
}

export default ReviewSummaryGraph;

const ProgressRow = ({ label, progressValue }) => {
  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      spacing={2}
      sx={{ my: "1px" }}
    >
      <Grid item>
        <Typography variant="body1" color={reviewBodyColor}>
          {label} star
        </Typography>
      </Grid>

      <Grid item xs>
        <Box sx={styles.progressBG}>
          <Box sx={[styles.progress, { width: `${progressValue}%` }]}>
            <Typography variant="body2" color={whiteColor}>
              {progressValue > 0 ? `${progressValue}%` : ""}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    mt:2
  },

  progressBG: {
    width: "100%",
    height: "16px",
    backgroundColor: progressBgColor,
    borderRadius: "40px",
  },

  progress: {
    height: "16px",
    backgroundColor: secondaryColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "40px",
    transition: "width 6s",
  },
};
