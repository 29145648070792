import styled from '@emotion/styled'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import FormActionButtons from '../../components/Button/FormActionButtons'
import StyledTextInput from '../../components/Input/StyledTextInput'
import {
  greenTextColor,
  primaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function AffiliateCodeForm({
  name,
  setName,
  affiliateCode,
  setAffiliateCode,
  refferedBy,
  setRefferedBy,
  setCurrentPage,
  onValidateCode,
  nameError,
  codeError,
  refferedByError,
  isCodeValid,
  handleNext,
  isReadOnly,
  loading,
}) {
  return (
    <Box p={2}>
      <from>
        <StyledTextInput
          label='Name'
          placeholder='Enter Name'
          value={name}
          onChange={({ target }) => {
            setName(target.value)
          }}
          required
          errorMsg={nameError}
          disabled={isReadOnly}
          isReadOnly={isReadOnly}
        />

        <StyledTextInput
          label='Unique Affiliates'
          placeholder='(6 to 30 characters, Upper Case)'
          value={affiliateCode}
          onChange={({ target }) => {
            const value = target.value.replace(/[^a-zA-Z0-9]/g, '')
            setAffiliateCode(value.toUpperCase())
          }}
          maxLength={30}
          required
          errorMsg={codeError}
          disabled={isReadOnly}
          isReadOnly={isReadOnly}
        />

        {!isReadOnly && (
          <Typography variant='body2' sx={{ m:"-6px 0 10px !important" }}>
            Ex: DEALS2023
          </Typography>
        )}

        {isCodeValid && (
          <Typography
            variant='body2'
            fontSize={13}
            fontWeight={500}
            color={greenTextColor}
            mt='-6px !important'
          >
            Code Available
          </Typography>
        )}

        {/* {!isReadOnly && (
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <StyledButton onClick={onValidateCode}>VALIDATE</StyledButton>
          </Box>
        )} */}

        <StyledTextInput
          label='Who referred you?'
          placeholder='Tell us who reffered you'
          value={refferedBy}
          onChange={({ target }) => {
            const value = target.value.replace(/[^0-9]/g, '')
            setRefferedBy(value)
          }}
          errorMsg={refferedByError}
          disabled={isReadOnly}
          isReadOnly={isReadOnly}
          maxLength={10}
        />
        
        {!isReadOnly && (
          <Typography variant='body2' sx={{ m:"-6px 0 10px !important" }}>
            Ex: 9876543210
          </Typography>
        )}

        {!isReadOnly && (
          <StyledButton
            fullWidth
            onClick={handleNext}
            mt={1}
            disabled={loading}
          >
            {loading && (
              <CircularProgress color='inherit' size={18} sx={{ mr: 1.5 }} />
            )}{' '}
            ENROLL AS AN AFFILIATE
          </StyledButton>
        )}

        {/* <FormActionButtons
            hideSaveBtn
            hidePreviousBtn
            onNext={handleNext}
            onSave={() => {}}
          /> */}
      </from>
    </Box>
  )
}

export default AffiliateCodeForm

const StyledButton = styled(Button)(({ disabled }) => ({
  color: whiteColor,
  backgroundColor: disabled ? 'rgba(0,0,0,0.1)' : primaryColor,
  border: disabled ? '1px solid rgba(0,0,0,0.1)' : '1px solid ' + primaryColor,
  padding: '6px 25px',
  margin: '0 0 15px',

  '&:hover': {
    color: primaryColor,
    backgroundColor: whiteColor,
  },
}))
