import { Button, Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import GetAppModalCard from 'components/GetAppModalCard'
import ModalCard from 'components/ModalCard'
import { useState } from 'react'
import { getUserName } from 'utils/authHelpers'
import {
  filterMobileColor,
  headingColor,
  offerTextColor,
  savingTextColor,
  topbarColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import CartQuantityPicker from '../Cart/CartQuantityPicker'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'

export default function ProductDescriptionBody({
  productOriginalPrice,
  productSalePrice,
  percentageOff,
  min,
  max,
  isOutOfStock,
  onQtyUpdate,
  productQty,
  onAddProduct,
  onRemoveProduct,
  subscribe,
  availability,
  subscription,
  handleChatClick,
}) {
  const commerceInfo = JSON.parse(sessionStorage.getItem('commerceInfo'))
  const commerceEnable = commerceInfo
    ? commerceInfo?.info?.commerceEnable
    : true

  const [loggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  const [isModal, setIsModal] = useState(false)

  return (
    <Box>
      <Box>
        <Box display='flex'>
          {percentageOff ? (
            <Typography
              variant='h4'
              color={offerTextColor}
              fontWeight={400}
              display='inline'
              sx={{ textDecorationLine: 'line-through' }}
              mr={1.2}
            >
              ₹ {productOriginalPrice}
            </Typography>
          ) : null}

          <Typography
            variant='h2'
            color={headingColor}
            display='inline'
            component='p'
            mr={1.2}
          >
            ₹ {productSalePrice}
          </Typography>
          {percentageOff ? (
            <Box
              sx={{ backgroundColor: 'primary.main' }}
              px={2}
              display='flex'
              alignItems='center'
              borderRadius='5px'
            >
              <Typography color={whiteColor} fontWeight={600}>
                {percentageOff}% OFF
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Typography
          variant='body1'
          fontSize={13}
          pt='10px'
          color={savingTextColor}
          lineHeight='25px'
        >
          (Inclusive of all taxes)
        </Typography>

        <Box
          display='flex'
          gap={1}
          mt={{ xs: 1, md: 1.5 }}
          alignItems='center'
          sx={{ cursor: 'pointer' }}
          onClick={handleChatClick}
        >
          <Typography
            // component='a'
            // href={

            // }
            target='_blank'
            textAlign='center'
            fontSize={{ md: 14, xs: 13 }}
            fontWeight={500}
            sx={{
              color: topbarColor,
            }}
          >
            Inquire about this product
          </Typography>

          <QuestionAnswerOutlinedIcon
            sx={{
              cursor: 'pointer',
              fontSize: { md: 18, xs: 18 },
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ my: '20px !important' }} />

      {!subscribe ? (
        isOutOfStock || !commerceEnable ? (
          <Typography
            variant='body1'
            color={
              isOutOfStock ? 'rgb(226 62 29 / 85%)' : 'rgb(15 190 83 / 85%)'
            }
            fontSize='18px'
            fontWeight={500}
            textAlign='left'
          >
            {isOutOfStock ? 'Out of Stock' : 'Coming Soon'}
          </Typography>
        ) : (
          <Box>
            {productQty > 0 ? (
              <CartQuantityPicker
                onIncrement={() => {
                  if (productQty < max && productQty >= min) {
                    onQtyUpdate(productQty + 1)
                  }
                }}
                onDecrement={() => {
                  if (productQty > min && productQty <= max) {
                    onQtyUpdate(productQty - 1)
                  } else {
                    onRemoveProduct()
                  }
                }}
                minQty={min}
                maxQty={max}
                count={productQty}
                removeEnabled
              />
            ) : (
              <Button
                fullWidth
                variant='contained'
                sx={{
                  backgroundColor: filterMobileColor,
                  py: 1.3,
                  width: '250px',
                }}
                onClick={() => {
                  onAddProduct()
                }}
              >
                Add to Cart
              </Button>
            )}
          </Box>
        )
      ) : null}

      {subscribe ? (
        !availability ? (
          <Typography
            variant='body1'
            color={'rgb(226 62 29 / 85%)'}
            fontSize='18px'
            fontWeight={500}
            textAlign='left'
          >
            Subscribe Only
          </Typography>
        ) : (
          <Box>
            {productQty > 0 ? (
              <CartQuantityPicker
                onIncrement={() => {
                  if (productQty < max && productQty >= min) {
                    onQtyUpdate(productQty + 1)
                  }
                }}
                onDecrement={() => {
                  if (productQty > min && productQty <= max) {
                    onQtyUpdate(productQty - 1)
                  } else {
                    onRemoveProduct()
                  }
                }}
                minQty={min}
                maxQty={max}
                count={productQty}
                removeEnabled
              />
            ) : (
              <Button
                fullWidth
                variant='contained'
                sx={{
                  backgroundColor: filterMobileColor,
                  py: 1.3,
                  width: '250px',
                }}
                onClick={() => {
                  onAddProduct()
                }}
              >
                Add to Cart
              </Button>
            )}
          </Box>
        )
      ) : null}

      {subscribe ? (
        <Typography
          color={topbarColor}
          fontSize={16}
          fontWeight={500}
          sx={{ cursor: 'pointer', mt: { md: 3, xs: 1.8 } }}
          onClick={() => setIsModal(true)}
        >
          Click to subscribe for ₹ {subscription?.offerValue}
        </Typography>
      ) : null}
      {/* <Grid container spacing={2}>
        <Grid item xs={5}>
          <Grid container>
            <Grid item xs>
              <Typography mt={1.5} variant='subtitle1' color={offerTextColor}>
                Qty
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Box>
                <Select
                  fullWidth
                  variant='standard'
                  sx={{
                    border: borderTextbox,
                    borderRadius: '6px',
                    fontSize: '13px',
                    padding: '8px 10px',
                    textAlign: 'center !important',
                  }}
                  defaultValue={1}
                  disableUnderline
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                </Select>
              </Box>
              <Box display='flex' justifyContent='space-between'>
                <Typography color={headingColor} fontWeight={600} fontSize={13}>
                  Min {min}
                </Typography>
                <Typography color={headingColor} fontWeight={600} fontSize={13}>
                  Min {max}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          
        </Grid>
      </Grid> */}
      <Divider sx={{ my: '20px !important' }} />
      {/* <Typography variant='h6' fontWeight={500} color={offerTextColor} pb={1}>
        Share on:
      </Typography>

      {businessAffiliateData?.businessResellers?.length > 0 &&
      rellerProfileData ? (
        <AffiliateShareMenu
          url={shortShareLink}
          onClick={(sharemedium) => {
            onAffiliateShareClick(sharemedium)
          }}
          hideLabel
          quote={`${productName} at ₹${productSalePrice}\n\nGET additional ${businessAffiliateData?.businessResellers?.[0]?.affiliatePercentage}% OFF on all products \nUse below link for instant discount!`}
          whatsappShareRef={whatsappShareRef}
          faceBookShareRef={faceBookShareRef}
          twitterShareRef={twitterShareRef}
          linkedInShareRef={linkedInShareRef}
          telegramShareRef={telegramShareRef}
        />
      ) : (
        <SocialShareVertical
          url={url}
          quote='My product'
          back={() => {
            if (loggedIn)
              addTimelineShare({
                shareType: 'timelineshare',
                latitude: 77.65563062,
                longitude: 12.92352506,
              })
          }}
        />
      )} */}

      <ModalCard
        handleClose={() => setIsModal(false)}
        open={isModal}
        width='40%'
      >
        <GetAppModalCard />
      </ModalCard>
    </Box>
  )
}
