import { getApiInstance } from '../../ApiInstance'

export const getPayCartApi = async (data) => {
  try {
    const instance = getApiInstance()

    const response = await instance.post(
      `cart-query-side/api/v2/cart/consumer/getPayCart`,
      {
        all: false,
        businessId: [`${data?.businessId}`],
        cartType: ['businesspaycart'],
      },
      {
        headers: {
          Cartid: data.cartId,
        },
      }
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
