import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllConnections,
  getAllProfileImages,
  getAllReviewCount,
  getAllReviewDetails,
} from '../../redux/reviews/cusomerReviewSlice'

import { Box } from '@mui/material'
import CustomerReviewSubComponent from './customerReviewSubComponent'
import 'react-loading-skeleton/dist/skeleton.css'
import Loader from 'components/Loader'

function CustomerReview() {
  const [textData, SetTextData] = useState('Most Recent')

  const dispatch = useDispatch()
  const { allReviews, connectionCount, reviewCount, profileImages, loading } =
    useSelector((state) => state.reviewCustomer)

  useEffect(() => {
    // dispatch(getAllReviewDetails({date:true, isHelpful:false}));
    dispatch(getAllReviewDetails({ option: 'Most Recent' }))
  }, [dispatch])

  useEffect(() => {
    let formdata = ''
    allReviews?.allReview?.forEach((item) => {
      formdata = formdata + `params=${item.profileId}&`
    })
    if (formdata !== '') {
      dispatch(getAllConnections(formdata))
      dispatch(getAllReviewCount(formdata))
      dispatch(getAllProfileImages(formdata))
    }
  }, [allReviews])

  return (
    <Box>
      {loading ? (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Loader />
        </Box>
      ) : (
        <>
          <CustomerReviewSubComponent
            data={allReviews}
            reviewCount={reviewCount}
            connectionCount={connectionCount}
            profileImages={profileImages}
            getAllReviewDetails={(option, pageNo) => {
              SetTextData(option)

              dispatch(getAllReviewDetails({ option: option, pageNo: pageNo }))
            }}
            optionData={textData}
          ></CustomerReviewSubComponent>
        </>
      )}
    </Box>
  )
}
export default CustomerReview
