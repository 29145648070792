import { AddRounded, RemoveRounded } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import {
  arrowBgColor,
  breadcrumbBgColor,
  catalogSubHeaderColor,
  headingColor,
  quantityIconBgColor,
  quatityIconColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function CartQuantityPicker({
  count,
  onIncrement,
  onDecrement,
  minQty,
  maxQty,
  removeEnabled
}) {
  return (
    <Box sx={styles.quantityPicker}>
      <Grid container direction='row' alignItems='center'>
        <Grid item>
          <Box
            sx={[
              styles.qtBtn,
              {
                backgroundColor:
                  count <= minQty ? breadcrumbBgColor : quantityIconBgColor,
              },
            ]}
            borderRadius='6px 0 0 6px'
            onClick={onDecrement}
          >
            <RemoveRounded
              sx={{
                color: count <= minQty ? removeEnabled ? quatityIconColor : arrowBgColor : quatityIconColor,
              }}
              //  sx={{ color: quatityIconColor }}
            />
          </Box>
        </Grid>

        <Grid item>
          <Typography
            variant='body1'
            color={headingColor}
            fontSize='15px'
            width='45px'
            fontWeight={600}
            textAlign='center'
          >
            {count}
          </Typography>
        </Grid>

        <Grid item>
          <Box
            sx={[
              styles.qtBtn,
              {
                backgroundColor:
                  count >= maxQty ? breadcrumbBgColor : quantityIconBgColor,
              },
            ]}
            borderRadius='0 6px 6px 0'
            onClick={onIncrement}
          >
            <AddRounded
              sx={{
                color: count >= maxQty ? arrowBgColor : quatityIconColor,
              }}
              // sx={{ color: quatityIconColor }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        mt={0.6}
      >
        <Typography
          variant='body2'
          fontSize='10px'
          fontWeight={600}
          color={catalogSubHeaderColor}
          display='inline'
        >
          Min {minQty}
        </Typography>

        <Typography
          variant='body2'
          fontSize='10px'
          fontWeight={600}
          color={catalogSubHeaderColor}
          display='inline'
        >
          Max {maxQty}
        </Typography>
      </Box>
    </Box>
  )
}

export default CartQuantityPicker

const styles = {
  quantityPicker: {
    height: {xs:'32px',md: '38px'},
    display: 'inline-block',
    border: '1px solid #ededed',
    borderRadius: '6px',
    backgroundColor: whiteColor,
  },

  qtBtn: {
    width: {xs:'30px',md: '36px'},
    height: {xs:'30px',md: '36px'},
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: quantityIconBgColor,
  },
}
