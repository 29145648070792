import { Box, Button, Grid, Typography } from '@mui/material'
import {
  borderLineColor,
  breadcrumbBgColor,
  completedColor,
  headingColor,
  orderDateColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import OrderStep from './OrderStep'
import { LocalShippingRounded } from '@mui/icons-material'

import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { getCartOrderDetails } from 'services/api_calls/orderdetails_api/OrderDetailsApi'
import ModalCard from 'components/ModalCard'
import TrackCard from './TrackCard'
import { useDispatch, useSelector } from 'react-redux'
import { getCartDetailsById } from 'redux/addToCart/AddToCartSlice'
import {
  resetDelhiveryStatus,
  resetOrderDetails,
} from 'redux/orderDetails/OrderDetailsSlice'
import {
  orders_OrderIDLabel,
  orders_Quantity,
  orders_TotalPriceLabel,
  orders_TrackLabel,
  orders_ViewLabel,
} from 'configs/Constants'

function OrderCard({
  cartId,
  canceled,
  completed,
  orderId,
  date,
  quantity,
  totalPrice,
  orderStatus,
  cartDate,
  orderData,
  fromPage,
}) {
  const dispatch = useDispatch()
  const isOrderCreated =
    orderStatus == 'ordercreated' ||
    orderStatus == 'orderapproved' ||
    orderStatus == 'ordershipped' ||
    orderStatus == 'orderdelivered'
  // || orderStatus == 'paycartcreated'

  const isOrderApproved =
    orderStatus == 'orderapproved' ||
    orderStatus == 'ordershipped' ||
    orderStatus == 'orderdelivered'

  const isOrderShipped =
    orderStatus == 'ordershipped' || orderStatus == 'orderdelivered'

  const isOrderDelivered = orderStatus == 'orderdelivered'
  const isCancelled = orderStatus == 'bordercanceled'

  const [cartData, setCartData] = useState(null)

  const businessName = sessionStorage.getItem('businessName')

  let navigate = useNavigate()

  const [isModal, setIsModal] = useState(false)

  const handleModalClose = () => {
    setIsModal(false)
    dispatch(resetOrderDetails())
    dispatch(resetDelhiveryStatus())
  }

  return (
    <Box
      boxShadow='0 4px 8px rgb(0 0 0 / 6%)'
      p={{ md: 1.5, xs: 1 }}
      my={2}
      sx={{ border: '1px solid' + borderLineColor }}
      borderRadius={2}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        flexWrap='wrap'
        gap={1}
      >
        <Box display='flex' alignItems='flex-start'>
          <Box
            // p={{xs:0.8, md:1.7}}
            width={{ xs: '35px', md: '50px' }}
            height={{ xs: '35px', md: '50px' }}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='50%'
            sx={{ backgroundColor: breadcrumbBgColor }}
            mr={1.5}
          >
            <LocalShippingRounded
              sx={{ fontSize: { xs: '18px', md: '24px' } }}
            />
          </Box>
          <Box>
            <Typography
              variant='subtitle1'
              fontSize={{ xs: 13, md: 15 }}
              fontWeight={600}
              lineHeight='24px'
              color={headingColor}
            >
              {orders_OrderIDLabel}
              {orderId}
            </Typography>
            <Typography
              variant='subtitle1'
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={500}
              color={orderDateColor}
              mt={0.5}
            >
              Date:{' '}
              {date
                ? moment(date).format('MMM DD, YYYY')
                : cartDate
                ? moment(cartDate).format('MMM DD, YYYY')
                : ''}
            </Typography>
            <Typography
              variant='subtitle1'
              fontSize='13px'
              fontWeight={400}
              color='#7e808c'
              mt={0.25}
            >
              {orders_Quantity} {quantity} | {orders_TotalPriceLabel}{' '}
              {totalPrice}
            </Typography>
            <Button
              sx={{
                textDecoration: 'underline',
                px: 0,
                fontSize: { xs: 12, md: 13 },
              }}
              onClick={() => {
                navigate(`/${businessName}/OrderDetails/${cartId}`, {
                  state: { cartDate, fromPage },
                })
              }}
            >
              {orders_ViewLabel}
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography
            variant='subtitle1'
            fontSize={{ xs: 13, md: 14 }}
            fontWeight={500}
            lineHeight='24px'
            color={completedColor}
            mt={0.5}
          >
            {getOrderStatus(orderStatus)}
          </Typography>
          {(orderStatus == 'ordershipped' ||
            orderStatus == 'orderdelivered' ||
            orderStatus == 'orderapproved') &&
          (orderData?.deliveryType?.type?.toLowerCase() == 'business' ||
            orderData?.deliveryType?.type?.toLowerCase() == 'delhivery' ||
            orderData?.deliveryType?.type?.toLowerCase() == 'delhiveryb2b' ||
            orderData?.deliveryType?.type?.toLowerCase() == 'telyport') ? (
            <Button
              sx={{ textDecoration: 'underline' }}
              onClick={() => setIsModal(true)}
            >
              {orders_TrackLabel}
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box px={{ md: 3, xs: 0 }}>
        <Grid container>
          <Grid item xs={3}>
            <OrderStep
              completed={isOrderCreated || orderStatus == 'bordercanceled'}
              active={
                orderStatus == 'ordercreated' || orderStatus == 'paycartcreated'
              }
              label='Created'
              // time={
              //   cartData
              //     ? cartData?.cart?.orderCreatedOn ? moment(cartData?.cart?.orderCreatedOn).format('MMM DD, YYYY') : ''
              //     : ''
              // }
              time={
                orderData?.orderCreatedOn
                  ? moment(orderData?.orderCreatedOn).format('MMM DD, YYYY')
                  : ''
              }
            />
          </Grid>

          {orderStatus == 'bordercanceled' ? (
            <Grid item xs={3}>
              <OrderStep
                completed={isCancelled}
                active={isCancelled}
                label='Cancelled'
                time=''
                isCancelled={isCancelled}
              />
            </Grid>
          ) : (
            <Grid item xs={3}>
              <OrderStep
                completed={isOrderApproved}
                active={orderStatus == 'orderapproved'}
                label='Approved'
                // time={
                //   isOrderApproved
                //     ? cartData?.cart?.orderApprovedOn ? moment(cartData?.cart?.orderApprovedOn).format('MMM DD, YYYY') : ''
                //     : 'pending'
                // }
                time={
                  orderData?.orderApprovedOn
                    ? moment(orderData?.orderApprovedOn).format('MMM DD, YYYY')
                    : ''
                }
                hideDate={isCancelled}
              />
            </Grid>
          )}

          <Grid item xs={3}>
            <OrderStep
              completed={isOrderShipped}
              active={orderStatus == 'ordershipped'}
              label='Shipped'
              // time={
              //   isOrderShipped
              //     ? cartData?.cart?.orderShippedOn ?  moment(cartData?.cart?.orderShippedOn).format('MMM DD, YYYY') : ''
              //     : 'pending'
              // }
              time={
                orderData?.orderShippedOn
                  ? moment(orderData?.orderShippedOn).format('MMM DD, YYYY')
                  : ''
              }
              hideDate={isCancelled}
            />
          </Grid>
          {console.log('orderDataorderData', orderData)}
          <Grid item xs={3}>
            <OrderStep
              completed={isOrderDelivered}
              label='Delivered'
              // time={
              //   isOrderDelivered
              //     ? cartData?.cart?.orderDeliveredOn ? moment(cartData?.cart?.orderDeliveredOn).format('MMM DD, YYYY') : ''
              //     : 'pending'
              // }
              time={
                orderData?.orderDeliveredOn
                  ? moment(orderData?.orderDeliveredOn).format('MMM DD, YYYY')
                  : ''
              }
              hideDate={isCancelled}
            />
          </Grid>
        </Grid>
      </Box>
      <ModalCard handleClose={handleModalClose} open={isModal} width='30%'>
        <TrackCard
          orderId={orderId}
          orderStatus={getOrderStatus(orderStatus)}
          orderData={orderData}
          cartId={cartId}
          itemCount={orderData?.totalQuantity}
          totalCost={orderData?.orderTotalCost}
          deliveryType={orderData?.deliveryType?.type}
        />
      </ModalCard>
    </Box>
  )
}

export default OrderCard

const getOrderStatus = (status) => {
  switch (status) {
    case 'ordercreated':
      return 'Created'

    case 'orderapproved':
      return 'Approved'

    case 'ordershipped':
      return 'Shipped'

    case 'orderdelivered':
      return 'Completed'

    case 'bordercanceled':
      return 'Canceled'

    case 'paycartcreated':
      return 'Easy Cart Created'

    case 'paycartcreated':
      return 'Easy Cart Created'

    default:
      return ''
  }
}
