import { StarRounded } from '@mui/icons-material'
import { Box, Grid, Rating, Typography } from '@mui/material'
import {
  arrowBgColor,
  headingColor,
  reviewBodyColor,
  whiteColor,
} from 'configs/styles/muiThemes'

function HomeReviewCard({ review }) {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      sx={{
        boxShadow: '0px 3px 7px rgb(0 0 0 / 8%)',
        borderRadius: '12px',
        backgroundColor: whiteColor,
        p: 1.8,
        mx: { xs: 0.5, md: 1.8 },
        mb: 1,
        height: '150px',
      }}
    >
      <Grid container>
        <Grid item>
          <Box
            sx={{ width: 50, height: 50, borderRadius: '50%' }}
            component='img'
            alt={businessFullName + ' CostBo'}
            src={review?.profilePic}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = 'https://storage.googleapis.com/bodefaults/shopweb/review_avatar.png'
            }}
          />
        </Grid>

        <Grid item pl={1.8}>
          <Typography variant='h5' color={headingColor} fontSize={16} mt={0.5}>
            {review?.name}
          </Typography>

          <Rating
            readOnly
            icon={
              <StarRounded sx={{ color: 'primary.main', fontSize: '16px' }} />
            }
            emptyIcon={
              <StarRounded sx={{ color: arrowBgColor, fontSize: '16px' }} />
            }
            precision={0.1}
            value={review?.reviewRating}
            sx={{ mt: 0.5 }}
            size='small'
          />
        </Grid>
      </Grid>

      <Typography
        variant='subtitle1'
        color={headingColor}
        lineHeight='25px'
        mt={0.8}
      >
        {/* {review?.reviewTitle} */}
        {review?.reviewTitle.slice(0, 43)}
        {review?.reviewTitle?.length > 43 ? '...' : ''}
      </Typography>
      <Typography
        variant='body1'
        color={reviewBodyColor}
        // sx={{ overflow: 'hidden', textOverflow: 'ellipsis',height:"30px" }}
      >
        {review?.message.slice(0, 90)}
        {review?.message?.length > 90 ? '...' : ''}
      </Typography>
    </Box>
  )
}

export default HomeReviewCard
