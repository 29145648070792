import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import { getProfileDetails } from 'redux/authRedux/authSlice'
import { useDispatch } from 'react-redux'

function UserAuth() {
  const { businessName } = useParams()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const data = searchParams.get('id')
  // console.log('QUERY_DATA', data)

  const decryptData = () => {
    const d_data = data
      .replace(/p1L2u3S/g, '+')
      .replace(/s1L2a3S4h/g, '/')
      .replace(/e1Q2u3A4l/g, '=')
    const bytes = CryptoJS.AES.decrypt(d_data, 'kZcpaSt3Tby@XkhZG4fW2t2W')
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    console.log('ORIGINAL_DATA', decryptedData)
    return decryptedData
  }

  const getProfileData = () => {
    const tokenData = decryptData()

    if (tokenData?.id && tokenData?.accessToken) {
      sessionStorage.setItem('token', tokenData?.accessToken)
      sessionStorage.setItem('profileId', tokenData?.id)
      sessionStorage.setItem('businessName', businessName)

      dispatch(getProfileDetails()).then((response) => {
        // console.log('PROFILE_DATA', response)
        if (tokenData?.path) {
          navigate(`/${businessName}${tokenData?.path}`)
        } else {
          navigate(`/${businessName}`)
        }
      })
    }
  }

  useEffect(() => {
    getProfileData()
  }, [])

  return <div> Redirecting...</div>
}

export default UserAuth
