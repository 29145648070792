import { useEffect, useState } from 'react'
import { getGraphData, summary } from '../utils/utilities'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOwnReviewDetails,
  getReviewSummaryDetails,
} from '../redux/reviews/reviewsSlice'
import ReviewPageNew from './Review/ReviewPageNew'
import FooterMui from '../components/FooterMui'
import TopComponent from './TopComponent'
import { Box } from '@mui/material'
import { getUserName, removeSessionInfo } from 'utils/authHelpers'
import Loader from 'components/Loader'
import { useParams } from 'react-router-dom'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import { getCouponsData } from 'services/api_calls/dealsCoupons_api/DealCouponsApi'
import { getCoupons } from 'redux/dealsCoupons/dealsCouponsSlice'
import { getCategoryDataFinder, getDataForSideBar } from 'redux/subCatergory/subCategorySlice'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { getCartDetails } from 'redux/addToCart/AddToCartSlice'

function Review() {
  const { businessName } = useParams()
  const [graphData, setGraphData] = useState([])
  const [textData, SetTextData] = useState('Most Recent')
  const [showLogin, setShowLogin] = useState(false)

  const dispatch = useDispatch()
  const {
    reviewSummary,
    allReviews,
    ownReview,
    connectionCount,
    reviewCount,
    profileImages,
    loading,
  } = useSelector((state) => state.review)

   

  const { loginDetails } = useSelector((state) => state.auth)

   const { businessId } = useSelector((state) => state.rootSlice)
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  const { commercialDetails } = useSelector((state) => state.product)
  const { cartDetails } = useSelector((state) => state.cart)
  const businessIdData = sessionStorage.getItem('businessId')

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem('businessName')
    if (businessName !== localBusinessName) {
      console.log('BUSINESS_NAME_CHANGED')
      removeSessionInfo()
      // sessionStorage.setItem('cartData', 'null')
    }

    dispatch(getBusinessIdDetails(businessName))
    sessionStorage.setItem('businessName', businessName)
  }

  useEffect(() => {
    getBusinessData()
  }, [businessName])

  useEffect(() => {
    if (businessIdData !== null) {
      dispatch(getDataForSideBar())
      dispatch(getCategoryDataFinder())
      if (!commercialDetails) {
        dispatch(getCommercialDetails())
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId))
        }
      }
    }

    return () => {
      // dispatch(clearSearchData())
    }
  }, [businessId])

  useEffect(() => {
    if (businessId?.validate) {
      console.log('Valid_BusinessID')
      dispatch(getCoupons())
    }
  }, [businessId])
  
  // useEffect(() => {
  //   dispatch(getReviewSummaryDetails())
  //   // dispatch(getAllReviewDetails({date:true, isHelpful:false}));

  //   if (isLoggedIn) {
  //     dispatch(getOwnReviewDetails())
  //   }
  // }, [])

  useEffect(() => {
    dispatch(getReviewSummaryDetails())
    
    if (loginDetails || isLoggedIn) {
      dispatch(getOwnReviewDetails())
      setIsLoggedIn(true)
    }
  }, [loginDetails])

  useEffect(() => {
    setGraphData(getGraphData(reviewSummary?.summary || [], summary))
  }, [reviewSummary])

  return (
    <Box>
      <TopComponent value={'reviews'} showLoginForm={showLogin} bannerHide />
      {/* <Loader /> */}
      {loading ? (
        <Box
          size={25}
          display='flex'
          alignItems='center'
          justifyContent='center'
          mt={2}
        >
          {/* <CircularProgress /> */}
          <Loader />
        </Box>
      ) : (
        <>
          <ReviewPageNew
            reviewSummary={reviewSummary}
            ownReview={ownReview}
            graphData={graphData}
            getOwnReviewDetails={() => dispatch(getOwnReviewDetails())}
            getSummary={() => dispatch(getReviewSummaryDetails())}
            data={allReviews}
            reviewCount={reviewCount}
            connectionCount={connectionCount}
            profileImages={profileImages}
            getAllReviewDetails={(option, pageNo) => {
              SetTextData(option)
              // dispatch(getAllReviewDetails({sort:sort,
              //   order:order, pageNo:pageNo}))
              // dispatch(getAllReviewDetails({ option: option, pageNo: pageNo }))
            }}
            optionData={textData}
            isLoggedIn={isLoggedIn}
            setShowLogin={() => setShowLogin(!showLogin)}
          />
          <FooterMui />
        </>
      )}
    </Box>
  )
}
export default Review
