import { Box } from '@mui/system'
import React from 'react'
import { keyframes } from '@emotion/react'

const load7 = keyframes`
0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`

function Loader() {
  return <div class='loader'></div>
}

export default Loader

const styles = {
  loader: {
    color: '#2598af',
    fontSize: '7px',
    margin: '10px auto 60px',
    position: 'relative',
    textIndent: '-9999em',
    '-webkit-transform': 'translateZ(0)',
    '-ms-transform': 'translateZ(0)',
    transform: 'translateZ(0)',
    borderRadius: '50%',
    width: '14px',
    height: '14px',
    '-webkit-animation-fill-mode': 'both',
    'animation-fill-mode': 'both',
    '-webkit-animation': `${load7} 1.8s infinite ease-in-out`,
    animation: `${load7} 1.8s infinite ease-in-out`,
    '-webkit-animation-delay': '-0.16s',
    'animation-delay': '-0.16s',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: '-3.5em',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      '-webkit-animation-fill-mode': 'both',
      'animation-fill-mode': 'both',
      '-webkit-animation': `${load7} 1.8s infinite ease-in-out`,
      animation: `${load7} 1.8s infinite ease-in-out`,
      '-webkit-animation-delay': '-0.32s',
      'animation-delay': '-0.32s',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: '3.5em',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      '-webkit-animation-fill-mode': 'both',
      'animation-fill-mode': 'both',
      '-webkit-animation': `${load7} 1.8s infinite ease-in-out`,
      animation: `${load7} 1.8s infinite ease-in-out`,
    },
  },
}
