import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { couponColor, whiteColor } from "../../configs/styles/muiThemes";

function MainProductCard({ products }) {
  if (products.length === 0) return null;

  return (
    <Box sx={styles.mainProductCard}>
      {products.map((item, index) => {
        return (
          <ListItem sx={{ py: 0 }}>
            <ListItemText
              sx={{
                display: "list-item",
              }}
              key={index + "main_product"}
              primary={
                <Typography variant="subtitle1" fontSize='13px' color={couponColor}>
                  {item.productName}
                </Typography>
              }
            />
          </ListItem>
        );
      })}
    </Box>
  );
}

export default MainProductCard;

const styles = {
  mainProductCard: {
    backgroundColor: whiteColor,
    boxShadow: "0px 2px 8px rgb(94 94 94 / 16%)",
    padding: "8px 18px",
    borderRadius: "12px",
  },
};
