import { Box } from '@mui/material'
import FullHeightLoader from 'components/FullHeightLoader'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getCartDetails } from 'redux/addToCart/AddToCartSlice'
import { getCoupons } from 'redux/dealsCoupons/dealsCouponsSlice'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import {
  getCategoryDataFinder,
  getDataForSideBar,
} from 'redux/subCatergory/subCategorySlice'
import { getUserName, removeSessionInfo } from 'utils/authHelpers'
import FooterMui from '../components/FooterMui'
import { getOverviewDetails } from '../redux/overview/overviewSlice'
import OverviewContentNew from './Overview/OverviewContentNew'
import TopComponent from './TopComponent'

function Overview() {
  const { businessName } = useParams()
  const dispatch = useDispatch()
  const { overviewDetails, loading } = useSelector((state) => state.overview)
  const businessIdData = sessionStorage.getItem('businessId')

  const { businessId } = useSelector((state) => state.rootSlice)
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  const { commercialDetails } = useSelector((state) => state.product)
  const { cartDetails } = useSelector((state) => state.cart)

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem('businessName')
    if (businessName !== localBusinessName) {
      console.log('BUSINESS_NAME_CHANGED')
      removeSessionInfo()
      // sessionStorage.setItem('cartData', 'null')
    }

    dispatch(getBusinessIdDetails(businessName))
    sessionStorage.setItem('businessName', businessName)
  }

  useEffect(() => {
    getBusinessData()
  }, [businessName])

  useEffect(() => {
    if (businessIdData !== null) {
      dispatch(getDataForSideBar())
      dispatch(getCategoryDataFinder())
      dispatch(getOverviewDetails())
      if (!commercialDetails) {
        dispatch(getCommercialDetails())
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId))
        }
      }
    }

    return () => {
      // dispatch(clearSearchData())
    }
  }, [businessId])

  useEffect(() => {
    if (businessId?.validate) {
      console.log('Valid_BusinessID')
      dispatch(getCoupons())
    }
  }, [businessId])
  return (
    <Box>
      {/* businessId : {JSON.stringify(businessId !== null)} <br />
      overviewDetails : {JSON.stringify(overviewDetails !== null)} <br />
      businessIdData : {JSON.stringify(businessIdData !== null)} <br /> */}
      {businessId !== null &&
      overviewDetails !== null &&
      businessIdData !== null ? (
        <>
          <TopComponent value={'overview'} bannerHide />
          {!loading && (
            <>
              <OverviewContentNew data={overviewDetails} loading={loading} />
              <FooterMui />
            </>
          )}
        </>
      ) : (
        <FullHeightLoader />
      )}
    </Box>
  )
}
export default Overview
