import { getProfileId } from 'utils/authHelpers'
import { getApiInstance } from '../../ApiInstance'

export const getAccountData = async () => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      `account-query-side/api/v1/account/getAccount/?profileId=${profileId}`,
      {
        _pId: profileId,
      }
    )
    console.log('GET_ACCOUNT_DATA:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
    return error?.response
  }
}

export const getOrdersCountAPI = async (businessId) => {
  const profileId = getProfileId()
  const instance = getApiInstance({
    headers: {
      profileID: profileId,
    },
  })
  try {
    const response = await instance.get(
      `cart-query-side/api/v2/cart/consumer/${businessId}/orders-count`
    )
    console.log('GET_ORDERS_COUNT:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getAllOrderByProductIdBusinessDm = async (params) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  const businessId = params ? params.businessId : 0
  const pageNo = params ? params.pageNo : 0

  try {
    const response = await instance(
      `cart-query-side/api/v2/cart/consumer/viewAllOrderByProfileIdBusinessIdDm/?pageNo=${pageNo}&filter=1000`,
      {
        headers: {
          businessId: businessId,
          profileId: profileId,
        },
      }
    )
    console.log('GET_ALL_ORDER_DATA:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getAllOpenOrders = async (params) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  const businessId = params ? params.businessId : 0
  const pageNo = params ? params.pageNo : 0
  try {
    const response = await instance(
      `cart-query-side/api/v2/cart/consumer/viewOpenOrdersByProfileIdBusinessIdDm/?pageNo=${pageNo}&filter=1000`,
      {
        headers: {
          businessId: businessId,
          profileId: profileId,
        },
      }
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getAllCompletedOrders = async (params) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  const businessId = params ? params.businessId : 0
  const pageNo = params ? params.pageNo : 0
  try {
    const response = await instance(
      `cart-query-side/api/v2/cart/consumer/viewCompletedOrderByProfileIdBusinessIdDm/?pageNo=${pageNo}&filter=1000`,
      {
        headers: {
          businessId: businessId,
          profileId: profileId,
        },
      }
    )
    // console.log('GET_ALL_COMPLETED_ORDERS:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getAllCancelledOrders = async (params) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  const businessId = params ? params.businessId : 0
  const pageNo = params ? params.pageNo : 0
  try {
    const response = await instance(
      `cart-query-side/api/v2/cart/consumer/viewcancelledOrdersByProfileIdBusinessIdDm/?pageNo=${pageNo}&filter=1000`,
      {
        headers: {
          businessId: businessId,
          profileId: profileId,
        },
      }
    )
    // console.log('GET_ALL_CANCELLED_ORDERS:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getAvailabelCashback = async (businessId) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    if (profileId) {
      const response = await instance(
        `reward-query-side/api/v1/rewardpoint/getRewardPoints`,
        {
          headers: {
            businessId: businessId,
            profileId: profileId,
          },
        }
      )
      // console.log('GET_ALL_AVAILABEL_CASH:', response.data)
      return response.data
    } else return null
  } catch (error) {
    console.log(error)
  }
}

export const getUserAddress = async () => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    const response = await instance(
      `account-query-side/api/v1/account/getAddress`,
      {
        headers: {
          profileId: profileId,
        },
      }
    )
    // console.log('GET_ALL_ADDRESS:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
