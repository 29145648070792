import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBUBmdsxBAlfSaOZtbK9VZ-jH5d7MTaj4E",
  authDomain: "costbo-prod.firebaseapp.com",
  databaseURL: "https://costbo-prod.firebaseio.com",
  projectId: "costbo-prod",
  storageBucket: "costbo-prod.appspot.com",
  messagingSenderId: "659800586407",
  appId: "1:659800586407:web:9ca50231b1355f92b58382",
  measurementId: "G-3F2J02PD6X"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export default firebase
