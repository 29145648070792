import { InputAdornment, TextField, Typography, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import {
  authInfoColor,
  backgroundColor,
  borderTextbox,
  descriptionColor,
  errorTextColor,
  primaryColor,
  secondaryColor,
} from '../configs/styles/muiThemes'
import {
  inputError_PasswordRequirementNotMet,
  inputLabel_MinChar,
  inputLabel_OneNum,
  inputLabel_OneSpecialChar,
  inputLabel_OneUpperChar,
} from 'configs/Constants'
import { allowOnlyEnglish } from 'utils/utilities'

const lowerCaseLetters = /[a-z]/g

function AuthPasswordBox({
  placeholder,
  name,
  value,
  onChange,
  icon,
  onIconClick,
  showPassword,
  maxLength,
  inputType,
  isError,
  error,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  lower,
  numberFormat,
  upper,
  symbol,
  isPasswordError,
}) {
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = allowOnlyEnglish(inputValue)

    if(onChange){
      onChange({ target: { value: sanitizedValue } });
    }
  };

  return (
    <Box>
      <TextField
        variant='standard'
        required={true}
        fullWidth
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        inputRef={inputRef}
        sx={{ fontSize: 14, my: '6px !important', borderBottom: borderTextbox }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
              {icon}
            </InputAdornment>
          ),
          style: { padding: '8px' },
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength, autocomplete: 'new-password' }}
        type={inputType}

        // {...(error && { error: true, helperText: error })}
      />

      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box>
              <CircleIcon
                style={{ height: '7px', padding: 0 }}
                color={upper ? '#228B22' : authInfoColor}
              />{' '}
            </Box>
            <Box>
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 11, md: 11.5 }}
                fontWeight={400}
                color={upper ? '#228B22' : authInfoColor}
              >
                {inputLabel_OneUpperChar}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box>
              <CircleIcon
                style={{ height: '7px', padding: 0 }}
                color={symbol ? '#228B22' : authInfoColor}
              />{' '}
            </Box>
            <Box>
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 11, md: 11.5 }}
                fontWeight={400}
                color={symbol ? '#228B22' : authInfoColor}
              >
                {inputLabel_OneSpecialChar}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box>
              <CircleIcon
                style={{ height: '7px', padding: 0 }}
                color={lower ? '#228B22' : authInfoColor}
              />{' '}
            </Box>
            <Box>
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 11, md: 11.5 }}
                fontWeight={400}
                color={lower ? '#228B22' : authInfoColor}
              >
                {inputLabel_MinChar}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box>
              <CircleIcon
                style={{ height: '7px', padding: 0 }}
                color={numberFormat ? '#228B22' : authInfoColor}
              />{' '}
            </Box>
            <Box>
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 11, md: 11.5 }}
                fontWeight={400}
                color={numberFormat ? '#228B22' : authInfoColor}
              >
                {inputLabel_OneNum}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {isPasswordError ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={{ xs: 13, md: 13 }}
          color={errorTextColor}
        >
          {inputError_PasswordRequirementNotMet}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant='body1'
          component='p'
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  )
}

export default AuthPasswordBox
