import { NavigateNextRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Typography,
  Breadcrumbs,
  useMediaQuery,
} from '@mui/material'
import { breadcrum_ShopMore } from 'configs/Constants'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getActiveBusiness } from 'redux/header/headerSlice'
import {
  headingColor,
  primaryColor,
  sidebarHeadingColor,
} from '../configs/styles/muiThemes'

function BreadcrumbsSection({ links }) {
  const dispatch = useDispatch()
  const { businessDetails } = useSelector((state) => state.header)
  const businessName = sessionStorage.getItem('businessName')

  const [businessTitle, setBusinessTitle] = useState('')

  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    const businessInfo = sessionStorage.getItem('businessInfo')
    if (!businessInfo) {
      dispatch(getActiveBusiness())
    } else {
      const data = JSON.parse(businessInfo)
      setBusinessTitle(data?.[0]?.businessName)
    }
  }, [businessDetails])

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          // display: { xs: 'none', md: 'flex' },
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '39px',
        }}
        px={2}
        py='5px'
        borderRadius='6px'
        mb={{ xs: 1, md: 1.5 }}
      >
        <Breadcrumbs separator={<NavigateNextRounded fontSize='small' />}>
          <Typography
            key='1'
            color={headingColor}
            fontSize='14px'
            component={Link}
            to={`/${businessName}`}
          >
            {businessTitle?.split(' ').slice(0, 2).join(' ')}
          </Typography>

          {matches
            ? links?.map((link, index) => {
                return (
                  <Typography
                    key='2'
                    color={
                      index == links?.length - 1 ? primaryColor : headingColor
                    }
                    fontSize='14px'
                    component={index == links?.length - 1 ? 'p' : Link}
                    to={link?.disabled ? '' : `/${businessName}${link.to}`}
                  >
                    {link.label}
                  </Typography>
                )
              })
            : null}
        </Breadcrumbs>

        <Button
          onClick={() => {}}
          sx={{
            textTransform: 'none',
            color: 'primary',
            textDecorationLine: 'underline',
            fontSize: '14px',
            fontWeight: 600,
          }}
          endIcon={<NavigateNextRounded />}
          component={Link}
          to={`/${businessName}/catalog`}
        >
          {breadcrum_ShopMore}
        </Button>
      </Box>

      {/* <Box mb={3} sx={{ display: { xs: 'block', md: 'none' } }}>
        <Typography
          variant='subtitle2'
          component='h4'
          fontSize='16px'
          color={headingColor}
          ml={1}
          textAlign='center'
        >
          Michelin Auto Accessories Online Store India
        </Typography>

        <Button
          onClick={() => {}}
          fullWidth
          sx={{
            textTransform: 'none',
            color: 'primary',
            textDecorationLine: 'underline',
            fontSize: '15px',
          }}
        >
          Shop More
        </Button>
      </Box> */}
    </>
  )
}

export default BreadcrumbsSection
