import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  headingColor,
  primaryColor,
  topbarColor,
} from 'configs/styles/muiThemes'

export default function DescriptionSpecificationTabs({
  value,
  handleChange,
  descriptionData,
  specificationData,
  refLink,
}) {
  return (
    <Grid container>
      <Grid xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={(event, newValue) => handleChange(event, newValue)}
            aria-label='basic tabs example'
          >
            <Tab label='Description' />
            <Tab label='Specification' />
          </Tabs>
        </Box>
      </Grid>
      {value === 0 ? (
        <Grid md={12}>
          <Typography variant='h5' pt={3}>
            Description
          </Typography>
          {descriptionData?.split('\n')?.map((item, i) => {
            return item.length > 0 ? (
              item?.charAt(0) === '*' ? (
                <ListItem sx={{ py: 0, ml: 3 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    key={i}
                    primary={
                      <Typography variant='body1' pt={2}>
                        {item?.substring(1)}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : (
                <Typography variant='body1' pt={2}>
                  {item}
                </Typography>
              )
            ) : null
          })}
        </Grid>
      ) : null}

      {value === 1 ? (
        <Grid xs={12}>
          <Typography variant='h5' pt={3}>
            Specification
          </Typography>
          <Stack pt={2} spacing={1}>
            <Grid container>
              <Grid item xs={6} md={4}>
                <Typography variant='body1' fontWeight={600}>
                  Country of Origin
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant='body1'>India</Typography>
              </Grid>
            </Grid>
            {specificationData?.map((spec, i) => {
              return (
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant='body1' fontWeight={600}>
                      {spec?.code}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    {spec?.value?.map((item, index) => {
                      return item.length > 0 ? (
                        <ListItem sx={{ py: 0 }}>
                          <ListItemText
                            sx={{
                              display: 'list-item',
                            }}
                            key={index}
                            primary={
                              <Typography variant='body1' color={headingColor}>
                                {item}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ) : null
                    })}
                  </Grid>
                </Grid>
              )
            })}
            {refLink?.map((ref, i) => {
              return (
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant='body1' fontWeight={600}>
                      {ref?.code}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant='body1'
                      color={topbarColor}
                      fontWeight={500}
                      sx={{ cursor: 'pointer', '&:hover': {textDecoration:"underline"} }}
                      onClick={() => {
                        window.open(ref?.link, '_blank')
                      }}
                    >
                      {ref?.link}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })}
          </Stack>
        </Grid>
      ) : null}
    </Grid>
  )
}
