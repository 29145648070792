import { Box, Button, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import CouponCard from './DiscountCards/CouponCard'
import CashbackCard from './DiscountCards/CashbackCard'
import FirstTimeBuyerCard from './DiscountCards/FirstTimeBuyerCard'
import InstantCashCard from './DiscountCards/InstantCashCard'
import {
  minOrderTextColor,
  primaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import moment from 'moment'
import ResellerCouponCard from './DiscountCards/ResellerCouponCard'
import {
  availabelDiscount_AlertInfo,
  availabelDiscount_Info,
} from 'configs/Constants'

function AvailableDiscount({
  rewardpoints,
  dealsCouponsDetails,
  cartDetails,
  commercialDetails,
  firstTimeDiscountData,
  discountAmount,
  setDiscountAmount,
  appliedDiscountDetails,
  setAppliedDiscountDetails,
  checkResellerData,
  instantDiscounts,
  resetDelivery,
  // Auto Coupon Apply Loading
  isCouponValidating,
  setIsCouponValidating,
}) {
  const [selectedDiscount, setSelectedDiscount] = useState('')
  // const [showDiscounts, setShowDiscounts] = useState(false)

  const affiliateDiscountData = JSON.parse(
    sessionStorage.getItem('specialOfferData')
  )
  const showOnlyResellerCoupon = affiliateDiscountData ? true : false

  return (
    <Box pb={1.1}>
      <Typography
        component='p'
        fontSize={{ xs: 11, md: 14 }}
        fontWeight={500}
        color={minOrderTextColor}
        sx={{
          lineHeight: {
            xs: '16px !important',
            md: '20px !important',
          },
          mt: 0.8,
        }}
      >
        {availabelDiscount_Info}
      </Typography>
      {/* {selectedDiscount != '' ? (
        <DiscountHeader
          icon={<LocalOfferRounded sx={{ color: headingColor }} />}
          title='AVAILABLE DISCOUNTS (OPTIONAL)'
          discountType={selectedDiscount != ''}
          onCancelDiscount={() => setSelectedDiscount('')}
        />
      ) : null} */}

      <Box sx={{ mt: 1 }}>
        <Grid container spacing={{ xs: 1.3, md: 2 }}>
          {!showOnlyResellerCoupon && (
            <>
              {dealsCouponsDetails?.length > 0 ? (
                <Grid item xs={12} md={6}>
                  <CouponCard
                    isSelected={selectedDiscount === 'coupon-code'}
                    dealsCouponsDetails={dealsCouponsDetails}
                    onSelect={() => {
                      setSelectedDiscount('coupon-code')
                    }}
                    rewardPoints={rewardpoints}
                    commercialDetails={commercialDetails}
                    cartDetails={cartDetails}
                    discountAmount={discountAmount}
                    setDiscountAmount={(data) => setDiscountAmount(data)}
                    appliedDiscountDetails={appliedDiscountDetails}
                    setAppliedDiscountDetails={(data) =>
                      setAppliedDiscountDetails(data)
                    }
                  />
                </Grid>
              ) : null}

              {rewardpoints?.details?.[0]?.availablePointValue?.toFixed() > 0 &&
              moment().isBefore(rewardpoints?.details?.[0]?.expiryDate) ? (
                <Grid item xs={12} md={6}>
                  <CashbackCard
                    isSelected={selectedDiscount === 'cashback'}
                    onSelect={() => {
                      console.log('cashback')
                      setSelectedDiscount('cashback')
                    }}
                    rewardPoints={rewardpoints}
                    commercialDetails={commercialDetails}
                    cartDetails={cartDetails}
                    discountAmount={discountAmount}
                    setDiscountAmount={(data) => setDiscountAmount(data)}
                    setAppliedDiscountDetails={(data) =>
                      setAppliedDiscountDetails(data)
                    }
                  />
                </Grid>
              ) : null}

              {firstTimeDiscountData?.details ? (
                firstTimeDiscountData?.details?.availableAmount >
                firstTimeDiscountData?.details?.walletGiftLimit ? (
                  //   &&
                  // moment().isBetween(
                  //   firstTimeDiscountData?.details?.walletStartDate,
                  //   firstTimeDiscountData?.details?.walletEndDate
                  // )
                  <Grid item xs={12} md={6}>
                    <FirstTimeBuyerCard
                      isSelected={selectedDiscount === 'first-time'}
                      onSelect={() => {
                        console.log('first')
                        setSelectedDiscount('first-time')
                      }}
                      firstTimeData={firstTimeDiscountData?.details}
                      commercialDetails={commercialDetails}
                      cartDetails={cartDetails}
                      discountAmount={discountAmount}
                      setDiscountAmount={(data) => setDiscountAmount(data)}
                      setAppliedDiscountDetails={(data) =>
                        setAppliedDiscountDetails(data)
                      }
                    />
                  </Grid>
                ) : null
              ) : null}

              {instantDiscounts?.length > 0 ? (
                <Grid item xs={12} md={6}>
                  <InstantCashCard
                    isSelected={selectedDiscount === 'instant-cash'}
                    onSelect={() => {
                      console.log('instant')
                      setSelectedDiscount('instant-cash')
                    }}
                    instantDiscounts={instantDiscounts}
                    cartDetails={cartDetails}
                    setDiscountAmount={(data) => setDiscountAmount(data)}
                    setAppliedDiscountDetails={(data) =>
                      setAppliedDiscountDetails(data)
                    }
                    // onViewMoreDiscount={() => {
                    //   setShowDiscounts(true)
                    // }}
                  />
                </Grid>
              ) : null}
            </>
          )}

          {checkResellerData?.isPresent ? (
            <Grid item xs={12} md={6}>
              <ResellerCouponCard
                isSelected={selectedDiscount === 'reseller-coupon-code'}
                dealsCouponsDetails={dealsCouponsDetails}
                onSelect={() => {
                  console.log('coupon')
                  setSelectedDiscount('reseller-coupon-code')
                }}
                rewardPoints={rewardpoints}
                commercialDetails={commercialDetails}
                cartDetails={cartDetails}
                discountAmount={discountAmount}
                setDiscountAmount={(data) => setDiscountAmount(data)}
                appliedDiscountDetails={appliedDiscountDetails}
                setAppliedDiscountDetails={(data) =>
                  setAppliedDiscountDetails(data)
                }
                // Loader
                setIsCouponValidating={setIsCouponValidating}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>

      <Typography
        component='p'
        fontSize={{ xs: 11, ms: 13 }}
        color={minOrderTextColor}
        sx={{
          lineHeight: {
            xs: '16px !important',
            md: '20px !important',
          },
          mt: 0.5,
        }}
      >
        {availabelDiscount_AlertInfo}
      </Typography>

      {!showOnlyResellerCoupon ? (
        selectedDiscount !== '' ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            mt={1.5}
          >
            <Button
              variant='outlined'
              sx={styles.actionBtn}
              onClick={() => {
                setSelectedDiscount('')
                // resetDelivery()
              }}
              size='small'
            >
              Cancel Discount
            </Button>
          </Box>
        ) : null
      ) : null}
    </Box>
  )
}

export default AvailableDiscount

const styles = {
  applyBtn: {
    width: '25%',
    fontSize: '15px',
    fontWeight: 600,
    color: whiteColor,
    border: '2px solid ' + primaryColor,
    '&:hover': {
      backgroundColor: whiteColor,
      color: 'primary.main',
    },
  },

  actionBtn: {
    fontSize: { xs: '11px', md: '13px' },
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: 'primary.main',
      color: whiteColor,
    },
  },
}
