import { configureStore } from '@reduxjs/toolkit'
import { dealsCouponsReducer } from './dealsCoupons/dealsCouponsSlice'
import { overviewDetailsReducer } from './overview/overviewSlice'
import { reviewReducer } from './reviews/reviewsSlice'
import { headerReducer } from './header/headerSlice'
import { subCategoryReducer } from './subCatergory/subCategorySlice'
import { catalogReducer } from './catalog/catalogSlice'
import { dashboardReducer } from './dashboard/dashboardSlice'
import { orderDetailsReducer } from './orderDetails/OrderDetailsSlice'
import { productDetailsReducer } from './productDescription/ProductDescriptionSlice'
import { cartDetailsReducer } from './addToCart/AddToCartSlice'
import { authReducer } from './authRedux/authSlice'
import { addActivityReducer } from './addActivity/AddActivitySlice'
import { cartReducer } from './cart/CartSlice'
import { checkoutDetailsReducer } from './checkout/CheckoutSlice'
import { homeReducer } from './home/HomeSlice'
import { rootDetailsReducer } from './rootSlice/RootSlice'
import { searchProductReducer } from './searchProduct/SearchProductSlice'
import { customerReviewReducer } from './reviews/cusomerReviewSlice'
import { affiliateSliceReducer } from './affiliate/AffiliateSlice'

const store = configureStore({
  reducer: {
    coupons: dealsCouponsReducer,
    overview: overviewDetailsReducer,
    review: reviewReducer,
    header: headerReducer,
    subCategory: subCategoryReducer,
    catalogCategory: catalogReducer,
    dashboard: dashboardReducer,
    orderdetails: orderDetailsReducer,
    product: productDetailsReducer,
    cart: cartDetailsReducer,
    addactivity: addActivityReducer,
    cartupdateadd: cartReducer,
    checkout: checkoutDetailsReducer,
    home: homeReducer,
    auth: authReducer,
    rootSlice: rootDetailsReducer,
    search: searchProductReducer,
    reviewCustomer:customerReviewReducer,
    affiliate: affiliateSliceReducer
  },
})
export default store
