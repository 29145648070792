import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getCartOrderDetails,
  getDelhiveryStatusDetails,
  getTelyportStatusDetails,
} from 'services/api_calls/orderdetails_api/OrderDetailsApi'

const initialState = {
  cartOrderDetails: null,
  dehiveryStatusData: null,
  telyportStatusData: null,
  loading: false,
  cartOrderLoading: false,
}

//Fetching Cart Order Details
export const getOrderDetails = createAsyncThunk(
  'getOrderDetails',
  async (cartId) => {
    const res = await getCartOrderDetails(cartId)
    return res
  }
)

//Fetching Cart Order Details
export const getDelhiveryStatus = createAsyncThunk(
  'getDelhiveryStatus',
  async (cartId) => {
    const res = await getDelhiveryStatusDetails(cartId)
    return res
  }
)
export const getTelyportStatus = createAsyncThunk(
  'getTelyportStatus',
  async (orderId) => {
    const res = await getTelyportStatusDetails(orderId)
    return res
  }
)
export const resetDelhiveryStatus = createAsyncThunk(
  'resetDelhiveryStatus',
  async () => {
    return null
  }
)
//Fetching Cart Order Details
export const resetOrderDetails = createAsyncThunk(
  'resetOrderDetails',
  async () => {
    const res = null
    return res
  }
)

export const orderDetailsSlice = createSlice({
  name: 'orderdetails',
  initialState,
  reducers: {},
  extraReducers: {
    //Fetch User Account Details
    [getOrderDetails.pending]: (state) => {
      state.loading = true
      state.cartOrderLoading = true
    },
    [getOrderDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.cartOrderLoading = false
      state.cartOrderDetails = payload
    },
    [getOrderDetails.rejected]: (state) => {
      state.loading = false
      state.cartOrderLoading = false
    },
    //Fetch User Account Details
    [getDelhiveryStatus.pending]: (state) => {
      state.loading = true
    },
    [getDelhiveryStatus.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.dehiveryStatusData = payload
    },
    [getDelhiveryStatus.rejected]: (state) => {
      state.loading = false
    },
    //Fetch User Account Details
    [getTelyportStatus.pending]: (state) => {
      state.loading = true
    },
    [getTelyportStatus.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.telyportStatusData = payload
    },
    [getTelyportStatus.rejected]: (state) => {
      state.loading = false
    },
    //Fetch User Account Details
    [resetDelhiveryStatus.pending]: (state) => {
      state.loading = true
    },
    [resetDelhiveryStatus.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.dehiveryStatusData = payload
      state.telyportStatusData=payload
    },
    [resetDelhiveryStatus.rejected]: (state) => {
      state.loading = false
    },
    //Fetch User Account Details
    [resetOrderDetails.pending]: (state) => {
      state.loading = true
    },
    [resetOrderDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.cartOrderDetails = payload
    },
    [resetOrderDetails.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const orderDetailsReducer = orderDetailsSlice.reducer
