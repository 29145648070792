import { useEffect, useRef, useState } from 'react'
import DiscountHeader from '../DiscountHeader'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { Box, Button, Grid } from '@mui/material'
import { headingColor, whiteColor } from '../../../configs/styles/muiThemes'
import AddressCard from './AddressCard'
import ModalCard from '../../../components/ModalCard'
import AddressForm from './AddressForm'
import Slider from 'react-slick/lib/slider'
import { addressData } from '../../../utils/demoData'
import {
  getAddressDetails,
  modifyAddressDetails,
} from 'redux/checkout/CheckoutSlice'
import { useDispatch } from 'react-redux'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import EditAddressForm from './EditAddressForm'
import AddressSearchContent from '../AddressSearch/AddressSearchContent'

function AddressSection({
  addressDetails,
  accountDetails,
  setSeletedAddress,
  setErrorMessage,
  seletedAddress,
  resetDelivery,
}) {
  const dispatch = useDispatch()
  const [openAddAddress, setOpenAddAddress] = useState(false)
  const [openUpdateAddress, setOpenUpdateAddress] = useState(false)
  const slider = useRef(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [isAdd, setIsAdd] = useState(true)
  const [editAddress, setEditAddress] = useState(null)
  const [makeDefault, setMakeDefault] = useState(true)

  const [openAddressSearch, setOpenAddressSearch] = useState(false)

  useEffect(() => {
    // if (makeDefault) {
    if (!seletedAddress) {
      const deliveryAddress =
        getDefaultAddress()?.length > 0 ? getDefaultAddress()?.[0] : null

      console.log('deliveryAddress__:', deliveryAddress)
      setErrorMessage("")
      setSeletedAddress(deliveryAddress)
      setIsAdd(getDefaultAddress()?.length ? false : true)
    } else {
      setIsAdd(false)
    }
    // }
  }, [addressDetails])

  const getDefaultAddress = () => {
    let data = addressDetails?.filter((address) => {
      if (address?.selected) return address
    })
    if (data?.length === 0) {
      if (addressDetails?.length > 0) {
        data.push(addressDetails?.[0])
      }
    }
    return data
  }

  const handleAddAddressClose = () => {
    setOpenAddAddress(false)
    setMakeDefault(false)
  }
  const handleUpdateAddressClose = () => {
    setOpenUpdateAddress(false)
    setEditAddress(null)
    setMakeDefault(false)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.08,
          slidesToScroll: 1,
          // variableWidth: true,
        },
      },
    ],
  }
  const onSlideNext = () => {
    if (currentSlideIndex < addressDetails?.length - 2) {
      slider.current.slickNext()
    }
  }

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev()
    }
  }

  const handleCloseAddressSearch = () => {
    setOpenAddressSearch(false)
  }

  return (
    <Box mb={2}>
      <ModalCard
        handleClose={handleAddAddressClose}
        open={openAddAddress}
        width='40vw'
      >
        <AddressForm
          title='Add Address'
          accountDetails={accountDetails}
          addressDetails={addressDetails}
          handleAddAddressClose={handleAddAddressClose}
          setSeletedAddress={setSeletedAddress}
          setErrorMessage={setErrorMessage}
        />
      </ModalCard>

      <ModalCard
        handleClose={handleUpdateAddressClose}
        open={openUpdateAddress}
        width='40vw'
      >
        <EditAddressForm
          title='Edit Address'
          accountDetails={accountDetails}
          addressDetails={addressDetails}
          editAddress={editAddress}
          handleUpdateAddressClose={handleUpdateAddressClose}
          setSeletedAddress={setSeletedAddress}
          setErrorMessage={setErrorMessage}
        />
      </ModalCard>

      <ModalCard
        handleClose={handleCloseAddressSearch}
        open={openAddressSearch}
        width='33vw'
      >
        <AddressSearchContent
          addressDetails={addressDetails}
          setOpenUpdateAddress={setOpenUpdateAddress}
          onSelectMethod={(address) => {
            setErrorMessage("")
            setSeletedAddress(address)
            setIsAdd(false)
          }}
          onEditAddress={(address) => {
            setEditAddress(address)
            setOpenUpdateAddress(true)
          }}
          onDeleteAddress={(address) => {
            let newAddress = addressDetails?.filter((item) => {
              return item !== address
            })
            // newAddress.splice(i, 1)
            console.log('addressDetailsHIt', newAddress)
            setMakeDefault(false)
            dispatch(modifyAddressDetails({ addressDetails: newAddress })).then(
              (res) => {
                dispatch(getAddressDetails())
              }
            )
          }}
          seletedAddress={seletedAddress}
          handleClose={handleCloseAddressSearch}
        />
      </ModalCard>

      {/* <Typography
        variant='subtitle2'
        component='p'
        fontSize={{ xs: '16px', md: '20px' }}
        color={headingColor}
        mt={1.6}
        textAlign='center'
      >
        Address and Delivery
      </Typography> */}

      <DiscountHeader
        title='DELIVERY ADDRESS'
        icon={
          <LocationOnRoundedIcon
            sx={{
              fontSize: { xs: '18px', md: '19px' },
              color: headingColor,
              mb: '-4px',
            }}
          />
        }
        onAddAddress={() => setOpenAddAddress(true)}
        onNext={onSlideNext}
        onPrev={onSlidePrev}
        slideIndex={currentSlideIndex}
        addressCount={addressDetails?.length}
        address={true}
        add={isAdd}
        onChangeAddress={() => {
          setErrorMessage("")
          setSeletedAddress(null)
          setIsAdd(true)
          resetDelivery()
        }}
        onSearchClick={() => {
          setOpenAddressSearch(true)
        }}
      />

      {!seletedAddress ? (
        <Box mt={{ xs: 1, md: 0 }}>
          {addressDetails?.length > 1 ? (
            <Slider
              ref={slider}
              {...settings}
              beforeChange={(oldIndex, newIndex) => {
                setCurrentSlideIndex(newIndex)
              }}
            >
              {addressDetails?.map((address, i) => {
                return (
                  <AddressCard
                    onEdit={() => setOpenUpdateAddress(true)}
                    address={address}
                    onSelectMethod={() => {
                      setErrorMessage("")
                      setSeletedAddress(address)
                      setIsAdd(false)
                    }}
                    onEditAddress={() => {
                      setEditAddress(address)
                      setOpenUpdateAddress(true)
                    }}
                    onDeleteAddress={() => {
                      let newAddress = addressDetails?.filter((item) => {
                        return item !== address
                      })
                      // newAddress.splice(i, 1)
                      console.log('addressDetailsHIt', newAddress)
                      setMakeDefault(false)
                      dispatch(
                        modifyAddressDetails({ addressDetails: newAddress })
                      ).then((res) => {
                        dispatch(getAddressDetails())
                      })
                    }}
                    recipientPhInline
                  />
                )
              })}
            </Slider>
          ) : (
            <Box sx={{ width: { md: '50%', xs: '100%' } }}>
              {addressDetails?.map((address, i) => {
                return (
                  <AddressCard
                    onEdit={() => setOpenUpdateAddress(true)}
                    address={address}
                    onSelectMethod={() => {
                      setErrorMessage("")
                      setSeletedAddress(address)
                      setIsAdd(false)
                    }}
                    onEditAddress={() => {
                      setEditAddress(address)
                      setOpenUpdateAddress(true)
                    }}
                    onDeleteAddress={() => {
                      let newAddress = addressDetails?.filter((item) => {
                        return item !== address
                      })
                      // newAddress.splice(i, 1)
                      console.log('addressDetailsHIt', newAddress)
                      setMakeDefault(false)
                      dispatch(
                        modifyAddressDetails({ addressDetails: newAddress })
                      ).then((res) => {
                        dispatch(getAddressDetails())
                      })
                    }}
                    recipientPhInline
                  />
                )
              })}
            </Box>
          )}
        </Box>
      ) : null}
      {seletedAddress ? (
        <Box mt={{ xs: 1, md: 0 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <AddressCard
                address={seletedAddress}
                onEditAddress={() => {
                  setEditAddress(seletedAddress)
                  setOpenUpdateAddress(true)
                }}
                showAlternateNo
                readOnly={true}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
      {!isAdd ? (
        <Box
          display={{ xs: 'flex', md: 'none' }}
          alignItems='center'
          justifyContent='center'
          mt={1.5}
        >
          <Button
            variant='outlined'
            sx={styles.actionBtn}
            startIcon={<EditRoundedIcon />}
            onClick={() => {
              setErrorMessage("")
              setSeletedAddress(null)
              setIsAdd(true)
            }}
            size='small'
          >
            Change Address
          </Button>
        </Box>
      ) : (
        <Box
          display={{ xs: 'flex', md: 'none' }}
          alignItems='center'
          justifyContent='center'
          mt={1.5}
        >
          <Button
            variant='outlined'
            sx={styles.actionBtn}
            startIcon={<AddRoundedIcon />}
            onClick={() => setOpenAddAddress(true)}
            size='small'
          >
            Add New Address
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default AddressSection

const styles = {
  actionBtn: {
    fontSize: { xs: '11px', md: '13px' },
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: 'primary.main',
      color: whiteColor,
    },
  },
}
