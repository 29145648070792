import { Box, Container, Typography } from '@mui/material'
import {
  headingColor,
  minOrderTextColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getCartDetails,
  getCommercialDetails,
} from 'redux/addToCart/AddToCartSlice'
import {
  checkBusinessResellerDetails,
  firstTimeDiscountDetails,
  getAddressDetails,
  getRewardPointsDetails,
  viewActiveBusinessDetails,
} from 'redux/checkout/CheckoutSlice'
import { getAccountDetails } from 'redux/dashboard/dashboardSlice'
import {
  getAllInstantDiscount,
  getCoupons,
} from 'redux/dealsCoupons/dealsCouponsSlice'
import { getOverviewDetails } from 'redux/overview/overviewSlice'
import { getUserName } from 'utils/authHelpers'
import FooterMui from '../components/FooterMui'
import DiscountDeliveryBreadcrum from './DiscountDelivery/DiscountDeliveryBreadcrum'
import DiscountDeliveryContent from './DiscountDelivery/DiscountDeliveryContent'
import TopComponent from './TopComponent'

function DiscountDelivery() {
  const dispatch = useDispatch()
  const { businessName } = useParams()
  let navigation = useNavigate()

  const [currentPage, setCurrentPage] = useState('discount')

  const {
    addressDetails,
    rewardpoints,
    firstTimeDiscountData,
    checkResellerData,
    activeBusinessData,
  } = useSelector((state) => state.checkout)
  const { accountDetails } = useSelector((state) => state.dashboard)
  const { dealsCouponsDetails, instantDiscount, couponsLoading } = useSelector(
    (state) => state.coupons
  )
  const { cartDetails, commercialDetails } = useSelector((state) => state.cart)
  const { overviewDetails } = useSelector((state) => state.overview)

  const isLoggedIn = getUserName().length === 0 ? false : true

  useEffect(() => {
    if (!isLoggedIn) {
      navigation(`/${businessName}`)
    }
  }, [])

  useEffect(() => {
    dispatch(getAccountDetails())
    dispatch(getRewardPointsDetails())
    dispatch(getCoupons())
    dispatch(getCartDetails())
    dispatch(getCommercialDetails())
    dispatch(firstTimeDiscountDetails())
    dispatch(checkBusinessResellerDetails())
    dispatch(getAllInstantDiscount())
    dispatch(viewActiveBusinessDetails())
    dispatch(getOverviewDetails())
  }, [])

  useEffect(() => {
    if (activeBusinessData) {
      dispatch(getAddressDetails())
    }
  }, [activeBusinessData])

  return (
    <Box>
      <Box
        sx={{
          // position: { md: 'sticky', xs: 'static' },
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 10,
          backgroundColor: whiteColor,
          pb: 1.5,
        }}
      >
        {/* <Navbar value='' /> */}
        <TopComponent value='' disableBanners />
        <Box display={{ xs: 'none', md: 'block' }}>
          <DiscountHeaderBreadcrumbs
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
      </Box>

      <Box display={{ xs: 'block', md: 'none' }} pb={1.5}>
        <DiscountHeaderBreadcrumbs
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>

      <DiscountDeliveryContent
        addressDetails={addressDetails}
        accountDetails={accountDetails}
        rewardpoints={rewardpoints}
        dealsCouponsDetails={dealsCouponsDetails}
        cartDetails={cartDetails}
        commercialDetails={commercialDetails}
        firstTimeDiscountData={firstTimeDiscountData}
        checkResellerData={checkResellerData}
        instantDiscounts={instantDiscount?.details}
        activeBusinessData={activeBusinessData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        couponsLoading={couponsLoading}
        overviewDetails={overviewDetails}
      />
      <FooterMui />
    </Box>
  )
}

export default DiscountDelivery

const DiscountHeaderBreadcrumbs = ({ currentPage, setCurrentPage }) => {
  return (
    <Container sx={{ maxWidth: '1300px !important', mt: { xs: 0.5, md: 2.5 } }}>
      <DiscountDeliveryBreadcrum
        links={[
          { label: 'Catalog', to: `/catalog`, hidden: false },
          { label: 'Cart', to: `/cart`, hidden: false },
        ]}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <Typography
        variant='subtitle1'
        display={'inline'}
        component='h5'
        fontSize={{ xs: 15, md: 19 }}
        fontWeight={600}
        color={headingColor}
        mt={0.3}
        ml={0.1}
        py={0.3}
      >
        {currentPage === 'discount'
          ? 'Discounts/Coupons'
          : 'Address & Delivery'}{' '}
        <Typography
          display={'inline'}
          variant='subtitle1'
          component='h5'
          fontSize={{ xs: 13, md: 15 }}
          fontWeight={500}
          color={minOrderTextColor}
        >
          {currentPage === 'discount' ? '(Optional)' : ''}
        </Typography>
      </Typography>
    </Container>
  )
}
