import { Box, Typography } from '@mui/material'
import { headingColor, tncColor } from 'configs/styles/muiThemes'
import React from 'react'

export default function ParagraphWithTitleSubTitle({
  title,
  subTitle,
  paragraph,
}) {
  return (
    <Box pb={1}>
      <Typography
        color={headingColor}
        variant='h5'
        fontSize={18}
        fontWeight={500}
        component='h4'
        pb={1.6}
      >
        {title}
      </Typography>
      <Typography
        color={headingColor}
        variant='h5'
        fontSize={14}
        fontWeight={500}
        component='h4'
        pb={1.6}
      >
        {subTitle}
      </Typography>
      {paragraph?.map((item, i) => {
        return (
          <Typography
            color={tncColor}
            fontSize={14}
            fontWeight={400}
            pb={2}
            lineHeight='25px'
            textAlign='justify'
          >
            {item}
          </Typography>
        )
      })}
    </Box>
  )
}
