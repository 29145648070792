import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getOwnReviewData,
  getReviewSummaryData,
  helpFulReview,
} from '../../services/api_calls/review_api/reviewApi'

const initialState = {
  reviewSummary: [],
  allReviews: null,
  ownReview: null,
  connectionCount: [],
  reviewCount: [],
  profileImages: [],
  loading: false,

  // Loaders
  reviewSummaryDetailsLoading: false,
}

//Fetching Review Summary
export const getReviewSummaryDetails = createAsyncThunk(
  'getReviewSummaryDetails',
  async (thunkAPI) => {
    const res = await getReviewSummaryData()
    return res
  }
)

//Fetching Own reviews
export const getOwnReviewDetails = createAsyncThunk(
  'getOwnReviewDetails',
  async (thunkAPI) => {
    const res = await getOwnReviewData()
    return res
  }
)

//Fetching All Connection Count

//Update helpfull review count
export const helpFullReviewPost = createAsyncThunk(
  'helpFullReviewPost',
  async (reviewId, profileId) => {
    const res = await helpFulReview(reviewId, profileId)
    return res
  }
)

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    clearOwnReviewData: (state) => {
      state.ownReview = null
    },
  },
  extraReducers: {
    //Review Summary Reducer
    [getReviewSummaryDetails.pending]: (state) => {
      state.reviewSummaryDetailsLoading = true
      state.loading = true
    },
    [getReviewSummaryDetails.fulfilled]: (state, { payload }) => {
      state.reviewSummaryDetailsLoading = false
      state.loading = false
      state.reviewSummary = payload?.reviewSummary[0]
    },
    [getReviewSummaryDetails.rejected]: (state) => {
      state.reviewSummaryDetailsLoading = false
      state.loading = false
    },

    //Own reviews reducer
    [getOwnReviewDetails.pending]: (state) => {
      state.loading = true
    },
    [getOwnReviewDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.ownReview = payload?.data?.ownReview
    },
    [getOwnReviewDetails.rejected]: (state) => {
      state.loading = false
    },

    //Update isHelpfull count
    [helpFullReviewPost.pending]: (state) => {
      state.loading = true
    },
    [helpFullReviewPost.fulfilled]: (state, { payload }) => {
      state.loading = false
    },
    [helpFullReviewPost.rejected]: (state) => {
      state.loading = false
    },
  },
})


export const { clearOwnReviewData } = reviewSlice.actions

export const reviewReducer = reviewSlice.reducer
