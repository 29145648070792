import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addActicity } from 'services/api_calls/addActivity/AddActivity'

const initialState = {
  data: null,
  loading: false,
}

//Fetching user account details
export const addActivityWithLogin = createAsyncThunk(
  'addActivityWithLogin',
  async (param) => {
    const res = await addActicity(param)
    return res
  }
)
export const addActivityWithOutLogin = createAsyncThunk(
  'addActivityWithOutLogin',
  async (param) => {
    const res = await addActicity(param)
    return res
  }
)

export const addActivitySlice = createSlice({
  name: 'addactivity',
  initialState,
  reducers: {},
  extraReducers: {
    //Add Activity With Login
    [addActivityWithLogin.pending]: (state) => {
      state.loading = true
    },
    [addActivityWithLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.data = payload
    },
    [addActivityWithLogin.rejected]: (state) => {
      state.loading = false
    },

    //Add Activity With Out Login
    [addActivityWithOutLogin.pending]: (state) => {
      state.loading = true
    },
    [addActivityWithOutLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.data = payload
    },
    [addActivityWithOutLogin.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const addActivityReducer = addActivitySlice.reducer
