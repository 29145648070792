import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Typography, Grid, Box, Button, Container } from '@mui/material'
import {
  blackColor,
  breadcrumbBgColor,
  headingColor,
  tncColor,
  whiteColor,
} from 'configs/styles/muiThemes'

function InfoSection() {
  return (
    <Container
      sx={{
        maxWidth: '1300px !important',
      }}
    >
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <InfoCard
            icon='https://storage.googleapis.com/bodefaults/shopweb/pm_stores_lower_prices.png'
            title='Buy Directly from Brands & Stores at lower prices!'
            description='When you buy from brands or stores directly your prices are much lower
              than the market places!'
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InfoCard
            icon='https://storage.googleapis.com/bodefaults/shopweb/pm_best_choices_prices.png'
            title='Shop Socially for best choices and prices!'
            description='Get to know about friends’ ratings and recommendations about brands and products.'
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InfoCard
            icon='https://storage.googleapis.com/bodefaults/shopweb/pm_multiple_brands.png'
            title='Connect and chat with multiple brands simultaneously!'
            description='For queries, instantly chat with brands and refer back to your queries whenever you want.'
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InfoCard
            icon='https://storage.googleapis.com/bodefaults/shopweb/pm_shopping_deliveries.png'
            title='Reliable shopping and deliveries!'
            description='At costBo every business is verified. Your money is safe and of course "WE STAND BY YOU"'
          />
        </Grid>
      </Grid>

      <Box display='flex' alignItems='center' justifyContent='center' mb='20px'>
        <Button
          sx={styles.actionBtn}
          variant='contained'
          disableElevation
          onClick={() => {}}
          component='a'
          href='https://www.costbo.com/'
          endIcon={<ArrowForwardRoundedIcon fontSize='small' />}
        >
          shop@costbo.com
        </Button>
      </Box>
    </Container>
  )
}

export default InfoSection

const InfoCard = ({ icon, title, description }) => {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      display='flex'
      alignItems='center'
      borderBottom={'1px solid ' + breadcrumbBgColor}
      pb={{ xs: '20px', md: '30px' }}
    >
      <Box
        component='img'
        src={icon}
        alt={businessFullName + ' CostBo'}
        width='61px'
        height='61px'
      />

      <Box ml={2}>
        <Typography
          variant='body2'
          color={headingColor}
          fontWeight={600}
          fontSize={{ xs: 15, md: 18 }}
          lineHeight={{ xs: '24px', md: '32px' }}
        >
          {title}
        </Typography>

        <Typography
          variant='body2'
          color={tncColor}
          fontSize={{ xs: 13, md: 15 }}
          lineHeight={{ xs: '20px', md: '24px' }}
          mt={{ xs: '5px', md: '0px' }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

const styles = {
  headerSection: {
    height: '690px',
    background: 'url(https://storage.googleapis.com/bodefaults/shopweb/home-bg.png) no-repeat center',
    backgroundSize: '100% 100%',
    backgroundColor: 'transparent',
    paddingTop: '100px',
    marginBottom: '30px',
  },

  actionBtn: {
    fontSize: '15px',
    fontWeight: 600,
    color: whiteColor,
    p: '8px 25px',
    border: '1px solid ' + blackColor,
    textTransform: 'none',
    borderRadius: '5px',
    backgroundColor: blackColor,
    marginTop: '24px',
    '&:hover': {
      backgroundColor: whiteColor,
      color: blackColor,
    },
  },
}
