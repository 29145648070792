import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Mail, WhatsappOutlined, Logout, Dashboard } from '@mui/icons-material'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import {
  productLabelColor,
  sidebarHeadingColor,
  topbarColor,
  whiteColor,
  offerTextColor,
  trackColor,
  whatsappIconColor,
  greyShade32,
  navHeaderColor,
} from '../../configs/styles/muiThemes'
import { useDispatch, useSelector } from 'react-redux'
import { getDataForSideBar } from '../../redux/subCatergory/subCategorySlice'

import { Link, useNavigate } from 'react-router-dom'
import { getAccountDetails } from 'redux/dashboard/dashboardSlice'
import { geProfilePhoto, getPhoneNumber, getUserName } from 'utils/authHelpers'
import {
  navBar_MenuCounpons,
  navBar_MenuLoginSignup,
  navBar_MenuOverview,
  navBar_MenuReview,
  sideBar_MainMenu,
  navBar_MenuHome,
  navBar_MenuCatalog,
  sideBar_CategoryHeading,
  contactLabel,
  costBoEmailText,
  costBoContactNumberText,
  sideBar_Signout,
} from 'configs/Constants'
import ProfileCharAvatar from 'components/ProfileCharAvatar'
import styled from '@emotion/styled'

export default function Sidebar({
  onCloseClick,
  isLoggedIn,
  onLoginClick,
  onSubCatSelected,
  onLogOut,
  productCount,
  isBusinessClosed,
}) {
  const dispatch = useDispatch()

  const { commercialDetails } = useSelector((state) => state.product)
  const { businessDetails } = useSelector((state) => state.header)
  const businessName = sessionStorage.getItem('businessName')
  const { accountDetails } = useSelector((state) => state.dashboard)
  const { productsByCategory, loading } = useSelector(
    (state) => state.subCategory
  )

  const whatsAppNumber =
    commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace('+', '')

  const emailAddress =
    commercialDetails?.info?.costboPowered?.supportEmail || 'support@costbo.com'

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore`,
        '_blank'
      )
    } else {
      window.fcWidget.open()
      window.fcWidget.show()
    }
  }

  useEffect(() => {
    dispatch(getDataForSideBar())
    if (!accountDetails && isLoggedIn) {
      dispatch(getAccountDetails())
    }
  }, [])

  let navigate = useNavigate()

  return (
    <Box
      sx={{
        width: '280px',
        backgroundColor: '#fff',
        height: '100vh !important',
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: '30px',
          left: '280px',
          zIndex: '9999 !important',
        }}
        onClick={() => onCloseClick()}
      >
        <CloseRoundedIcon sx={{ color: '#fff' }} />
      </IconButton>

      <Box>
        <Grid
          container
          padding={1.5}
          sx={{ background: topbarColor }}
          alignItems='center'
        >
          <Grid item>
            {isLoggedIn ? (
              geProfilePhoto() === '' ? (
                <ProfileCharAvatar username={getUserName()} />
              ) : (
                <Avatar alt='User' src={geProfilePhoto()} />
              )
            ) : (
              <Avatar alt='User' src={geProfilePhoto()} />
            )}
          </Grid>
          <Grid item pl={2}>
            <Stack>
              <Typography variant='h6' color={whiteColor}>
                {isLoggedIn ? getUserName() : 'Welcome!'}
              </Typography>

              {isLoggedIn ? (
                <Typography variant='body2' color={whiteColor}>
                  {getPhoneNumber()}
                </Typography>
              ) : (
                <Typography
                  variant='body2'
                  color={whiteColor}
                  sx={{ cursor: 'pointer' }}
                  onClick={onLoginClick}
                >
                  {navBar_MenuLoginSignup}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Box paddingX={1.5} paddingY={0.5}>
          <List component='nav'>
            <ListItem
              sx={{ p: 0, pt: 0.3 }}
              component={Link}
              to={isLoggedIn ? `/${businessName}/Dashboard` : '#'}
              onClick={() => {
                onCloseClick()
              }}
            >
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <Dashboard
                  sx={{
                    fontSize: '20px',
                    color: isLoggedIn ? productLabelColor : offerTextColor,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={styles.listText(isLoggedIn)}
                primary='Dashboard'
              />
            </ListItem>

            <ListItem
              sx={{ p: 0 }}
              component={Link}
              to={isLoggedIn ? `/${businessName}/AllOrders` : '#'}
              onClick={() => {
                onCloseClick()
              }}
            >
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <ShoppingCartIcon
                  sx={{
                    fontSize: '20px',
                    color: isLoggedIn ? productLabelColor : offerTextColor,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={styles.listText(isLoggedIn)}
                primary='My Orders'
              />
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Box>
      <Box
        sx={{ height: '60vh', overflowY: 'auto', pb: { xs: 22, md: 0 } }}
        // paddingY={1}
      >
        {productCount == 0 || isBusinessClosed ? null : (
          <Box paddingX={2} pb={0.5}>
            <Typography variant='h6' color={sidebarHeadingColor} pt={0.8}>
              {sideBar_CategoryHeading}
            </Typography>
            {loading === false &&
              productsByCategory &&
              productsByCategory.length > 0 && (
                <Box>
                  <List component='nav'>
                    {productsByCategory.map((item, index) => {
                      return (
                        <ListItem
                          sx={{ p: 0, pt: 0.5, cursor: 'pointer' }}
                          onClick={() => {
                            // onSubCatSelected(item)
                            navigate(`/${businessName}/catalog`, {
                              state: { categorySideBar: item },
                            })
                            onCloseClick()
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={styles.listTextStyle}
                            primary={item.subCatValue}
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>
              )}

            <Divider />
          </Box>
        )}

        <Box paddingX={2} paddingY={0.5}>
          <Typography variant='h6' color={sidebarHeadingColor} pt={0.8}>
            {contactLabel}
          </Typography>
          <List component='nav'>
            <Box
              display='flex'
              sx={{ p: 0 }}
              component='a'
              href={`https://mail.google.com/mail/u/0/?fs=1&to=${emailAddress}&tf=cm`}
              target={'_blank'}
              onClick={() => {
                onCloseClick()
              }}
            >
              <Mail sx={{ fontSize: '20px', color: topbarColor, mr: 1 }} />
              <Typography sx={styles.listTextStyle}>
                {costBoEmailText}
              </Typography>
            </Box>
            <Box
              display='flex'
              sx={{ p: 0, pt: 1.7, cursor: 'pointer' }}
              component='a'
              // href='https://api.whatsapp.com/send?phone=918088422611'
              target={'_blank'}
              onClick={() => {
                handleChatClick()
                // onCloseClick()
              }}
            >
              <QuestionAnswerOutlinedIcon
                sx={{ fontSize: '20px', color: topbarColor, mr: 1 }}
              />
              <Typography sx={styles.listTextStyle}>
                {costBoContactNumberText}
              </Typography>
            </Box>
          </List>
        </Box>
        <Box paddingX={2}>
          <Divider />
        </Box>
        <Box paddingX={2} paddingY={0.5} display={{ md: 'none', xs: 'block' }}>
          <Typography variant='h6' color={sidebarHeadingColor} pt={0.8}>
            Information
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              p: '0 0 0 5px',
              mb: 1.5,
            }}
          >
            <PageLink
              linkText='Terms & Condtions'
              onClick={() => {
                navigate('/termsAndConditions')
              }}
            />
            <PageLink
              linkText='Privacy & Policy'
              onClick={() => {
                navigate('/privacyPolicy')
              }}
            />
            <PageLink
              linkText='Return Policy'
              onClick={() => {
                navigate('/return-policy')
              }}
            />
            <PageLink
              linkText='Cancellation Policy'
              onClick={() => {
                navigate('/cancellation-policy')
              }}
            />
          </Box>
          <Divider />
          <Typography
            mt={1.5}
            textAlign='center'
            fontSize={14}
            color={navHeaderColor}
            fontWeight={500}
          >
            Secure E-Commerce powered by
          </Typography>
          <Box
            sx={{
              // background: 'red',
              display: 'flex',
              justifyContent: 'center',
              mt: 1,
            }}
          >
            <Box
              component='img'
              src='https://storage.googleapis.com/bodefaults/shopweb/costBo-logo.svg'
              width='50%'
            />
          </Box>

          {/* <List component='nav'>
            <Box
              display='flex'
              sx={{ p: 0 }}
              component='a'
              href='https://mail.google.com/mail/u/0/?fs=1&to=support@costbo.com&tf=cm'
              target={'_blank'}
              onClick={() => {
                onCloseClick()
              }}
            >
              <Mail sx={{ fontSize: '20px', color: trackColor, mr: 1 }} />
              <Typography sx={styles.listTextStyle}>
                {costBoEmailText}
              </Typography>
            </Box>
            <Box
              display='flex'
              sx={{ p: 0, pt: 1.7 }}
              component='a'
              href='https://api.whatsapp.com/send?phone=918088422611'
              target={'_blank'}
              onClick={() => {
                onCloseClick()
              }}
            >
              <WhatsappOutlined
                sx={{ fontSize: '20px', color: whatsappIconColor, mr: 1 }}
              />
              <Typography sx={styles.listTextStyle}>
                {costBoContactNumberText}
              </Typography>
            </Box>
          </List> */}
        </Box>

        {isLoggedIn && (
          <Box>
            <Divider />
            <Box paddingX={2} paddingY={0.5}>
              <List
                component='nav'
                disablePadding
                onClick={onLogOut}
                sx={{ cursor: 'pointer' }}
              >
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon sx={{ minWidth: '30px' }}>
                    <Logout
                      sx={{ fontSize: '20px', color: productLabelColor }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={styles.listTextStyle}
                    primary={sideBar_Signout}
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const styles = {
  listText: (isLoggedIn) => ({
    fontSize: '14px',
    fontWeight: 500,
    color: isLoggedIn ? productLabelColor : offerTextColor,
    display: 'block',
  }),
  listTextStyle: {
    fontSize: '14px',
    fontWeight: 500,
    color: productLabelColor,
    display: 'block',
  },
}

const PageLink = ({ linkText, onClick }) => {
  return (
    <StyledLink
      variant='body1'
      fontSize={12}
      fontWeight={400}
      lineHeight='25px'
      color={greyShade32}
      sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
      onClick={onClick}
    >
      {linkText}
    </StyledLink>
  )
}
const StyledLink = styled(Typography)({
  position: 'relative',
  margin: '0 0 0 15px',

  '&:hover': {
    textDecoration: 'underline',
  },

  '&:before': {
    content: '" "',
    width: '3px',
    height: '3px',
    borderRadius: '5px',
    backgroundColor: greyShade32 + '80',
    position: 'absolute',
    top: '10px',
    left: '-8px',
  },
})
