import { Box, Typography } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'
import React from 'react'
import { useSelector } from 'react-redux'

function FinvervNotRegistered({ onBannerClick }) {
  const { commercialDetails } = useSelector((state) => state.product)
  return (
    <Box
      sx={{
        background: `url("https://storage.googleapis.com/bodefaults/shopweb/finverv-banner.jpg")`,
        backgroundSize: '100% 100%',
        width: '80%',
        height: '230px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 4,
        mt: 2,
        mx: 'auto',
      }}
      onClick={onBannerClick}
    >
      <Box sx={{ width: '40%' }}>
        <Typography
          color={whiteColor}
          fontSize={24}
          fontWeight={600}
          textAlign='center'
          lineHeight={1.5}
          sx={{ mb: '20px !important' }}
        >
          Apply for a Credit Line
        </Typography>
        <Typography
          color={whiteColor}
          fontSize={15}
          fontWeight={400}
          textAlign='center'
          lineHeight={1.5}
        >
          Get qualified for upto 10 Lakhs in few minutes
        </Typography>
        <Typography
          color={whiteColor}
          fontSize={15}
          fontWeight={400}
          textAlign='center'
          lineHeight={1.5}
          mt={1}
        >
          *Eligible for orders above ₹{' '}
          {commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit}
        </Typography>
      </Box>
    </Box>
  )
}

export default FinvervNotRegistered
