export const colors = [
    {
      background: "linear-gradient(166deg, #e57194, #df6288)",
      border: "1px solid #e36d91",
    },
    {
      background: "linear-gradient(166deg, #018ba9, #00bedd)",
      border: "1px solid #00a4c3",
    },
    {
      background: "linear-gradient(166deg, #86b92c, #a1cb43)",
      border: "1px solid #91c035",
    },
    {
      background: "linear-gradient(166deg, #fca81f, #fabf07)",
      border: "1px solid #fbad19",
    },
    {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
      {
        background: "linear-gradient(166deg, #e57194, #df6288)",
        border: "1px solid #e36d91",
      },
      {
        background: "linear-gradient(166deg, #018ba9, #00bedd)",
        border: "1px solid #00a4c3",
      },
      {
        background: "linear-gradient(166deg, #86b92c, #a1cb43)",
        border: "1px solid #91c035",
      },
      {
        background: "linear-gradient(166deg, #fca81f, #fabf07)",
        border: "1px solid #fbad19",
      },
  ];