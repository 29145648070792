import {
  Box,
  Button,
  Chip,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CategoryGroupHeader from './CategoryGroupHeader'
import ProductCard from './ProductCard'
import { automobileProducts } from '../../utils/demoData'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { getOutOfStock } from 'utils/productHelper'
import Slider from 'react-slick/lib/slider'

function FilteredSubCategory({
  allProducts,
  filteredSubCat,
  onRemoveSubCatFilter,
  cartData,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
}) {
  const [products, setProducts] = useState([])

  useEffect(() => {
    let productList = []

    filteredSubCat.forEach((item) => {
      allProducts?.productList?.forEach((product) => {
        if (item.code == product.productSubCategory2?.[0]?.code) {
          productList.push(product)
        }
      })
    })

    setProducts(productList)
  }, [filteredSubCat])

  return (
    <Box>
      {/* <CategoryGroupHeader
        title={selectedCategory?.[0]?.subCatValue}
        totalProduct={selectedCategory?.[0]?.count}
      /> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          mt: 2,
        }}
      >
        {filteredSubCat.map((item, index) => {
          return (
            <Chip
              label={item.value}
              //   onClick={handleClick}
              variant='outlined'
              onDelete={() => {
                onRemoveSubCatFilter(item)
              }}
              sx={{ mr: 1.5, mb: 1.5 }}
            />
          )
        })}
      </Box>

      <Grid container columnSpacing={1} rowSpacing={2.5} pt={1.5} pl={1}>
        {products?.map((item, index) => {
          return (
            <Grid item xs={6} sm={4} md={2.4}>
              <ProductCard
                img={item.productPhoto[0].docURL}
                price={item.productSalePrice}
                oldPrice={item.productOriginalPrice}
                description={item.productName}
                discount={item.percentageOff}
                style={{ my: 1.5 }}
                isOutOfStock={getOutOfStock(item)}
                isComingSoon={item.commerceEnabled === 'NO'}
                minQty={item.minOrderQuantity}
                maxQty={item.maxOrderQuantity}
                currencySymbol={item.currencySymbol ? '₹' : item.currencySymbol}
                cartData={cartData}
                product={item}
                cartId={cartId}
                onQtyUpdate={(data) => {
                  onQtyUpdate(data)
                }}
                onAddProduct={(data) => {
                  onAddProduct(data)
                }}
                onRemoveProduct={onRemoveProduct}
                subscribe={item?.subscription?.status === 'active'}
                availability={item?.availability?.[0]?.toLowerCase() === 'yes'}
                subscription={item?.subscription}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default FilteredSubCategory

const styles = {
  btnHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}
