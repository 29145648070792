import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import {
  addressForm_AlternateNumber,
  addressForm_AlternateNumberError,
  addressForm_CityNameError,
  addressForm_EnterMail,
  addressForm_EnterName,
  addressForm_EnterNumber,
  addressForm_HouseNo,
  addressForm_HouseNoError,
  addressForm_Landmark,
  addressForm_MailError,
  addressForm_NameError,
  addressForm_NumberError,
  addressForm_Pincode,
  addressForm_PincodeError,
  addressForm_SelectAddressType,
} from 'configs/Constants'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileDetails } from 'redux/authRedux/authSlice'
import {
  getAddressDetails,
  getCoordinatesDetails,
  getStateCityDetails,
  modifyAddressDetails,
} from 'redux/checkout/CheckoutSlice'
import { getAccountDetails } from 'redux/dashboard/dashboardSlice'
import { updateEmail } from 'services/api_calls/checkout/checkoutApi'
import {
  validateAddressEmail,
  validateMobileNumber,
} from 'utils/validationUtils'
import TextboxWithLabel from '../../../components/TextboxWithLabel'
import {
  blackColor,
  errorTextColor,
  headingColor,
  primaryColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'
import PickAddressFromMapModal from 'components/PickAddress/PickAddressFromMapModal'
import NearMeIcon from '@mui/icons-material/NearMe'
import { allowOnlyEnglish } from 'utils/utilities'

function AddressForm({
  title,
  accountDetails,
  addressDetails,
  handleAddAddressClose,
  handleUpdateAddressClose,
  editAddress,
  setSeletedAddress,
  setErrorMessage
}) {
  const [name, setName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [alternateNumber, setAlternateNumber] = useState('')
  const [pincode, setPincode] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [landmark, setLandmark] = useState('')
  const [type, setType] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [selected, setDefault] = useState(false)

  const [latLong, setLatLong] = useState({
    latitude: '',
    longitude: '',
  })

  const [isAddressError, setIsAddressError] = useState(false)
  const [isEmailError, setIsEmailError] = useState(false)
  const [isNameError, setIsNameError] = useState(false)
  const [isPincodeError, setIsPincodeError] = useState(false)
  const [isCityError, setIsCityError] = useState(false)
  const [isStateError, setIsStateError] = useState(false)
  const [isTypeError, setIsTypeError] = useState(false)
  const [isAlternateError, setIsAlternateError] = useState(false)

  const [loading, setLoading] = useState(false)

  const [guestEmail, setGuestEmail] = useState(false)

  const [openMap, setOpenMap] = useState({
    show: false,
    userLat: '',
    userLong: '',
  })

  const { stateCityDetails } = useSelector((state) => state.checkout)

  const dispatch = useDispatch()

  useEffect(() => {
    if (editAddress) {
      setName(editAddress.name)
      setContactNumber(editAddress.phoneNo)
      setAlternateNumber(editAddress.alternatePhoneNo)
      setLandmark(editAddress.landMark)
      setType(editAddress.addressType)
      setAddress(editAddress.addressLine1)
      setCity(editAddress.city)
      setState(editAddress.state)
      setEmail(editAddress.email)
      setDefault(editAddress.selected)
      setPincode(editAddress.zip)
    }
  }, [editAddress])

  const reset = () => {
    setName('')
    setContactNumber('')
    setAlternateNumber('')
    setLandmark('')
    setType('')
    setAddress('')
    setCity('')
    setState('')
    setEmail('')
    setDefault('')
    setPincode('')
  }

  useEffect(() => {
    if (!editAddress) {
      setContactNumber(accountDetails?.account?.phonenumber)
    }
    // 'guest-+91' + phoneNumber + '@costbo.com'
    if (
      'guest-' + accountDetails?.account?.phonenumber + '@costbo.com' !=
      accountDetails?.account?.email
    ) {
      setEmail(accountDetails?.account?.email)
    } else {
      setGuestEmail(true)
    }

    // setEmail(accountDetails?.account?.email)
  }, [accountDetails])

  useEffect(() => {
    if (stateCityDetails) {
      if (pincode.length === 6) {
        const filterState =
          stateCityDetails?.results?.[0]?.address_components?.filter((item) =>
            item?.types?.includes('administrative_area_level_1')
          )
        const filterCity1 =
          stateCityDetails?.results?.[0]?.address_components?.filter((item) =>
            item?.types?.includes('administrative_area_level_2')
          )
        const filterCity2 =
          stateCityDetails?.results?.[0]?.address_components?.filter((item) =>
            item?.types?.includes('administrative_area_level_3')
          )
        const filterCity3 =
          stateCityDetails?.results?.[0]?.address_components?.filter((item) =>
            item?.types?.includes('locality')
          )

        setCity(
          filterCity1?.length > 0
            ? filterCity1?.[0]?.long_name
            : filterCity2?.length > 0
            ? filterCity2?.[0]?.long_name
            : filterCity3?.length > 0
            ? filterCity3?.[0]?.long_name
            : ''
        )
        console.log('filterCity', filterCity1, filterCity2, filterCity3)
        console.log('filterState', filterState)
        setState(filterState?.length > 0 ? filterState?.[0]?.long_name : '')

        setOpenMap({
          show: true,
          userLat:
            stateCityDetails?.results?.[0]?.geometry?.location?.lat || '',
          userLong:
            stateCityDetails?.results?.[0]?.geometry?.location?.lng || '',
        })
      }

      // setCity(
      //   pincode.length === 6
      //     ? stateCityDetails?.results?.[0]?.address_components?.[
      //         stateCityDetails?.results?.[0]?.address_components?.length - 3
      //       ]?.long_name
      //       ? stateCityDetails?.results?.[0]?.address_components?.[
      //           stateCityDetails?.results?.[0]?.address_components?.length - 3
      //         ]?.long_name
      //       : ''
      //     : ''
      // )
      setIsStateError(false)
      setIsCityError(false)
    }
  }, [stateCityDetails])

  useEffect(() => {
    if (pincode?.length === 6) {
      console.log(pincode)
    }
  }, [pincode])

  const addAddress = () => {
    if (loading) return
    setLoading(true)
    let error = false

    if (alternateNumber) {
      let alterError = validateMobileNumber(alternateNumber)
      console.log('hiytdf', alterError)
      if (alterError) {
        setIsAlternateError(true)
        error = true
      }
    }
    if (!address) {
      setIsAddressError(true)
      error = true
    }
    if (!name) {
      setIsNameError(true)
      error = true
    }
    if (!email) {
      setIsEmailError(true)
      error = true
    }
    if (validateAddressEmail(email)) {
      setIsEmailError(true)
      error = true
    }

    if (pincode.length !== 6) {
      setIsPincodeError(true)
      error = true
    }
    if (!city) {
      setIsCityError(true)
      error = true
    }
    if (!state) {
      setIsStateError(true)
      error = true
    }
    if (!type) {
      setIsTypeError(true)
      error = true
    }
    if (error) {
      setLoading(false)
      return
    }

    let newAddObj = null

    dispatch(
      getCoordinatesDetails({ address: pincode, city, state, pincode })
    ).then((res) => {
      if (res?.payload?.results?.length > 0) {
        console.log('getCoordinatesDetails')
        let newAddress = []
        if (selected) {
          console.log('Default selected')
          let filteredSelectedAddress = addressDetails?.filter(
            (address) => address.selected === true
          )
          console.log('filteredSelectedAddress', filteredSelectedAddress)
          let addressWithoutSelected = addressDetails?.filter(
            (address) => address.selected !== true
          )
          console.log('addressWithoutSelected', addressWithoutSelected)
          newAddress = [...addressWithoutSelected]
          if (filteredSelectedAddress.length > 0) {
            let obj = { ...filteredSelectedAddress[0], selected: false }
            newAddress = [...newAddress, obj]
          }

          newAddObj = {
            name: name,
            addressLine1: address,
            addressLine2: '',
            addressLine3: '',
            houseNo: '',
            city: city,
            zip: pincode,
            state: state,
            country: 'India',
            landMark: landmark,
            phoneNo: contactNumber,
            alternatePhoneNo: alternateNumber,
            addressType: type,
            latitude:
              latLong.latitude ||
              res?.payload?.results?.[0]?.geometry?.location?.lat,
            longitude:
              latLong.longitude ||
              res?.payload?.results?.[0]?.geometry?.location?.lng,
            selected: selected,
            addedOn: moment().format('YYYY-MM-DDTHH:MM:SS'),
            updatedOn: moment().valueOf(),
          }

          newAddress = [...newAddress, newAddObj]
          console.log('newAddress', newAddress)
        } else {
          newAddObj = {
            name: name,
            addressLine1: address,
            addressLine2: '',
            addressLine3: '',
            houseNo: '',
            city: city,
            zip: pincode,
            state: state,
            country: 'India',
            landMark: landmark,
            phoneNo: contactNumber,
            alternatePhoneNo: alternateNumber,
            addressType: type,
            latitude:
              latLong.latitude ||
              res?.payload?.results?.[0]?.geometry?.location?.lat,
            longitude:
              latLong.longitude ||
              res?.payload?.results?.[0]?.geometry?.location?.lng,
            selected: selected,
            addedOn: moment().format('YYYY-MM-DDTHH:MM:SS'),
            updatedOn: moment().valueOf(),
          }

          newAddress = [...addressDetails, newAddObj]
        }

        dispatch(
          modifyAddressDetails({
            addressDetails: newAddress,
          })
        ).then((res) => {
          setErrorMessage("")
          setSeletedAddress(newAddObj)
          handleAddAddressClose()
          dispatch(getAddressDetails())
          reset()
          setTimeout(() => {
            setLoading(false)
          }, 3000)
        })
      } else {
        console.log('address line havind no coordinates')
        setIsPincodeError(true)
        setLoading(false)
        return
      }
    })
    if (guestEmail) {
      updateEmail({ phone: contactNumber, newEmail: email }).then((res) => {
        dispatch(getAccountDetails())
        dispatch(getProfileDetails())
      })
    }
  }

  // autoFill user selected address from map
  const handlePickLocationConfirm = (userAddress) => {
    if (userAddress) {
      console.log('userAddress:', userAddress)
      setCity((prevState) => allowOnlyEnglish(userAddress?.city))
      setState((prevState) => allowOnlyEnglish(userAddress?.state))
      setPincode((prevState) => allowOnlyEnglish(userAddress?.postalCode))
      setAddress('')
      // setAddress((prevState) => userAddress?.address || prevState)
      setLandmark((prevState) => allowOnlyEnglish(userAddress?.locality))

      setLatLong((prevState) => ({
        latitude: userAddress?.lat,
        longitude: userAddress?.lng,
      }))
    }

    setOpenMap((prevState) => ({
      ...prevState,
      show: false,
      userLat: userAddress?.lat,
      userLong: userAddress?.lng,
    }))
  }

  const onChooseLocation = () => {
    const lat = localStorage.getItem('lat')
    const long = localStorage.getItem('long')

    setOpenMap((prevState) => ({
      ...prevState,
      show: true,
      userLat: prevState?.userLat || lat,
      userLong: prevState?.userLong || long,
    }))
  }

  return (
    <Box
      px={{ md: 4, xs: 1.5 }}
      py={2}
      sx={{
        height: { xs: '68vh', md: '88vh' },
        overflowY: 'auto',
        background: whiteColor,
      }}
      borderRadius={1}
    >
      <Typography
        variant='h2'
        textAlign='center'
        fontSize={17}
        color={headingColor}
        pb={{ md: 1.5, xs: 0.7 }}
      >
        {title}
      </Typography>

      <PickAddressFromMapModal
        isOpen={openMap.show}
        setIsOpen={setOpenMap}
        onConfirm={handlePickLocationConfirm}
        userLat={openMap.userLat}
        userLong={openMap.userLong}
      />

      <Box>
        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label='Name'
              value={name}
              onValueChange={(text) => {
                if (isNameError) {
                  setIsNameError(false)
                }
                setName(text)
              }}
              placeholder={addressForm_EnterName}
              required={true}
              isError={isNameError}
              errorMsg={addressForm_NameError}
            />
          </Grid>
          {/* {'guest-' + accountDetails?.account?.phonenumber + '@costbo.com'} */}
          <Grid item xs={12} md={6}>
            {!guestEmail ? (
              <TextboxWithLabel
                label='Email-Id'
                value={email}
                placeholder={addressForm_EnterMail}
                required={true}
                disabled
                isError={isEmailError}
                errorMsg={addressForm_MailError}
              />
            ) : (
              // <>sjxbsj</>
              <TextboxWithLabel
                label='Email-Id'
                value={email}
                onValueChange={(text) => {
                  if (isEmailError) {
                    setIsEmailError(false)
                  }
                  setEmail(text)
                }}
                placeholder={addressForm_EnterMail}
                required={true}
                isError={isEmailError}
                errorMsg={addressForm_MailError}
              />
            )}
            {/* <TextboxWithLabel
              label='Email-Id'
              value={email}
              placeholder={addressForm_EnterMail}
              required={true}
              disabled
              isError={isEmailError}
              errorMsg={addressForm_MailError}
            /> */}
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label={addressForm_EnterNumber}
              value={contactNumber?.replace('+91', '+91  ')}
              placeholder={addressForm_NumberError}
              required={true}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label={addressForm_AlternateNumber}
              value={alternateNumber}
              maxLength={10}
              onValueChange={(text) => {
                if (isAlternateError) {
                  setIsAlternateError(false)
                }
                const value = text
                  .replace(/[^0-9.]/g, '')
                  .replace(/(\..*)\./g, '$1')
                setAlternateNumber(value)
              }}
              placeholder={addressForm_AlternateNumberError}
              isError={isAlternateError}
              errorMsg={'Please enter a valid Mobile number'}
              startIcon={
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={blackColor}
                  sx={{ mt: -0.1 }}
                >
                  +91
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Typography
          component='p'
          fontSize={12}
          fontWeight={400}
          color={blackColor}
          lineHeight='20px'
          mt={0.5}
        >
          Please update the recipient number, if you are booking the order on
          behalf of someone else.
        </Typography>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={4}>
            <TextboxWithLabel
              label='Pincode'
              value={pincode}
              onValueChange={(text) => {
                // if (pincode.length === 5) {
                //   dispatch(getStateCityDetails(text))
                //   setIsPincodeError(false)
                // }

                if (text.length === 6) {
                  dispatch(getStateCityDetails(text))
                  setIsPincodeError(false)
                }
                setPincode(text)
              }}
              maxLength={6}
              placeholder={addressForm_Pincode}
              required={true}
              isError={isPincodeError}
              errorMsg={addressForm_PincodeError}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextboxWithLabel
              label='City'
              placeholder=''
              required={true}
              value={city}
              onValueChange={(text) => {
                setIsCityError(false)
                setCity(text)
              }}
              // disabled
              isError={isCityError}
              errorMsg={addressForm_CityNameError}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextboxWithLabel
              label='State'
              placeholder=''
              required={true}
              value={state}
              onValueChange={(text) => {
                setIsStateError(false)
                setState(text)
              }}
              // disabled
              isError={isStateError}
              errorMsg={'Enter State Name'}
            />
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={12}>
            <Button
              sx={styles.chooseLocationBtn}
              variant='contained'
              disableElevation
              onClick={onChooseLocation}
              startIcon={<NearMeIcon sx={{ fontSize: '40px' }} />}
            >
              Update Current Location
            </Button>

            <Typography
              component='p'
              fontSize={12.2}
              fontWeight={500}
              color={primaryColor}
              lineHeight='20px'
              mt={0.5}
            >
              Please choose the exact location of the building from the map, for
              a smooth delivery
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={12}>
            <TextboxWithLabel
              label={addressForm_HouseNo}
              placeholder=''
              value={address}
              onValueChange={(text) => {
                if (isAddressError) {
                  setIsAddressError(false)
                }
                setAddress(text)
              }}
              required={true}
              isError={isAddressError}
              errorMsg={addressForm_HouseNoError}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={6}>
            <TextboxWithLabel
              label='Landmark'
              placeholder={addressForm_Landmark}
              value={landmark}
              onValueChange={(text) => setLandmark(text)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 1.5, xs: 0.5 }}>
          <Grid item xs={12} md={6}>
            <>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={type}
                onChange={({ target }) => {
                  if (isTypeError) {
                    setIsTypeError(false)
                  }
                  setType(target.value)
                }}
              >
                <FormControlLabel
                  value='Home'
                  control={<Radio />}
                  label='Home'
                />
                <FormControlLabel
                  value='Office/Commercial'
                  control={<Radio />}
                  label='Office/Commercial'
                />
              </RadioGroup>
              {isTypeError ? (
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize={13}
                  color={errorTextColor}
                >
                  {addressForm_SelectAddressType}
                </Typography>
              ) : null}
            </>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={<Checkbox />}
              label='Default address'
              value={selected}
              onChange={() => {
                setDefault(!selected)
              }}
            />
          </Grid>
        </Grid>

        {/* {selected.toString()} */}
      </Box>
      <Box display='flex' justifyContent='center' mt={2}>
        <Button
          variant='contained'
          sx={{ px: 10, ...styles.submitBtn }}
          size='large'
          onClick={() => {
            // title === 'Edit Address' ? editAddressClicked() : addAddress()
            addAddress()
          }}
        >
          {loading ? (
            <CircularProgress sx={{ color: whiteColor, mr: 1 }} size={16} />
          ) : null}{' '}
          {title}
        </Button>
      </Box>
    </Box>
  )
}

export default AddressForm

const styles = {
  submitBtn: {
    fontSize: '14px',
    fontWeight: 600,
    color: whiteColor,
    border: '2px solid ' + primaryColor,
    '&:hover': {
      backgroundColor: whiteColor,
      color: 'primary.main',
    },
  },

  chooseLocationBtn: {
    background: whiteColor,
    p: '0px 0px',
    fontSize: '13px',
    color: primaryColor,
    fontWeight: 600,
    textTransform: 'unset',
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: whiteColor,
      color: primaryColor,
      textDecoration: 'none',
    },
  },
}
