import { Box, Grid, Typography, Snackbar } from '@mui/material'
import { useState } from 'react'
import moment from 'moment'
import { colors } from '../../utils/colorConstants'
import {
  couponColor,
  labelColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

const DisCountCard = ({ data, index }) => {
  const [success, setSuccess] = useState(false)

  return (
    <Box sx={{ ...styles.card, ...colors[index % 4] }}>
      <Snackbar
        open={success}
        onClose={() => setSuccess(false)}
        message='Coupon code copied'
        autoHideDuration={3000}
      />

      <Box sx={styles.dealExpiryContainer}>
        <Typography variant='subtitle1' fontSize='13px' color={couponColor}>
          Expiry
        </Typography>
        <Typography variant='subtitle1' fontSize='13px' color={couponColor}>
          {' '}
          {moment(data.codeValidityEnd).format('MMM DD, yyyy')}
        </Typography>
      </Box>
      <Typography
        variant='subtitle1'
        component='h6'
        color={whiteColor}
        marginBottom='5px !important'
      >
        Deals of this week
      </Typography>
      <Typography
        color={whiteColor}
        variant='h2'
        component='h6'
        fontSize='17px'
        marginTop='15px'
        marginBottom='15px !important'
      >
        EXTRA {data.discountPercentage}% OFF
      </Typography>
      <Grid container>
        <Grid xs sx={styles.copyContainer}>
          <Typography color={whiteColor} variant='subtitle1' fontWeight={600}>
            {data.resellerCode}
          </Typography>
        </Grid>
        {/* <Grid xs='auto'>
          <CopyToClipboard
            text={data.resellerCode}
            onCopy={() => setSuccess(true)}
          >
            <Button sx={styles.copyBtn}>Copy</Button>
          </CopyToClipboard>
        </Grid> */}
      </Grid>
    </Box>
  )
}
export default DisCountCard

const styles = {
  card: {
    overflow: 'hidden',
    position: 'relative',
    padding: '14px',
    borderRadius: '12px',
    minHeight: '100px',
    mr:"15px",
  },
  dealExpiryContainer: {
    width: '100px',
    // height: '115px',
    display: 'inline-block',
    textAlign: 'center',
    background: '#ffffff',
    border: '1px solid #eeeeee',
    position: 'absolute',
    // borderRadius: '50%',
    borderRadius: '0 0 0 10px',
    right: '0px',
    top: '0px',
    // paddingTop: '30px',
    // paddingRight: "10px",
    padding: '5px 0 5px 0',
  },
  expiryText: {
    color: '#545454',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: 500,
  },
  dealsName: {
    fontSize: '15px',
    lineHeight: '16px',
    fontWeight: 500,
    pb: '5px !important',
    color: '#fff',
  },
  copyBtn: {
    padding: 'auto 11px',
    backgroundColor: whiteColor,
    color: labelColor,
    borderRadius: '0px 10px 10px 0px',
    fontWeight: 600,
    fontSize: '13px',
    '&:hover': {
      backgroundColor: whiteColor,
    },
  },
  copyContainer: {
    borderRadius: '10px',
    border: '1.5px dashed #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
