import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import {
  blackColor,
  borderPaymentInfo,
  greyShade35,
  greyShade36,
  whiteColor,
} from '../../configs/styles/muiThemes'

function PaymentInfoCard({ icon, label, value }) {
  return (
    <Box sx={styles.card}>
      <Box
        component='img'
        src={icon}
        alt='costbo icon'
        sx={{
          width: '25px',
          height: '25px',
          objectFit: 'contain',
          display: { xs: 'none', md: 'inline-block' },
        }}
      />

      <Box>
        <Typography
          variant='body2'
          fontSize={12}
          fontWeight={400}
          lineHeight='18px'
          color={greyShade35}
        >
          {label}
        </Typography>

        <Typography
          variant='body2'
          fontSize={14}
          fontWeight={600}
          lineHeight={1.2}
          color={blackColor}
          mt='7px !important'
        >
          {value == undefined ? (
            <Skeleton variant='text' width={60} height={18} />
          ) : (
            '₹ ' + value
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default PaymentInfoCard

const styles = {
  card: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: { xs: '0', md: '10px' },
    borderRadius: '7px',
    backgroundColor: { xs: whiteColor, md: greyShade36 },
    border: { xs: 'none', md: borderPaymentInfo },
    boxShadow: { xs: 'none', md: '0 1px 2px rgb(0 0 0 / 5%)' },
  },
}
