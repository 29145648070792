var MOBILE_REGEX$1 =
  /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/
var PASSWORD_REGX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$/
var validEmailRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function validateMobileNumber(mobileNumber, isMandatory) {
  console.log(mobileNumber)
  var res = isMandatory ? 'Please enter a valid Mobile number' : ''
  if (mobileNumber && mobileNumber.length > 0) {
    var isValid = MOBILE_REGEX$1.test(mobileNumber)
    if (!isValid) {
      res = 'Please enter a valid Mobile number'
    } else {
      res = ''
    }
  }
  return res
}
export function validatePhoneNumber(mobileNumber) {
  let res = false
  if (mobileNumber && mobileNumber.length > 0) {
    var isValid = MOBILE_REGEX$1.test(mobileNumber)
    if (!isValid) {
      res = false
    } else {
      res = true
    }
  }
  return res
}

export function validatePassword(password) {
  if (password.length > 0) password = ''
  else password = 'Please enter Password'
  return password
}

export function validateName(name) {
  if (name.length > 0) name = ''
  else name = 'Please enter name'
  return name
}

export function validateEmail(email) {
  if (email.length > 0) {
    var isValid = validEmailRegEx.test(email.trim())
    if (!isValid) {
      email = 'Please enter a valid Email'
    } else {
      email = ''
    }
  } else email = 'Please enter email'
  return email
}
export function validateAddressEmail(email) {
  let result = false
  if (email.length > 0) {
    var isValid = validEmailRegEx.test(email.trim())
    if (!isValid) {
      result = true
    }
  }
  return result
}

export function validateRenterEmail(email) {
  if (email.length > 0) {
    var isValid = validEmailRegEx.test(email.trim())
    if (!isValid) {
      email = 'Please enter a valid Email'
    } else {
      email = ''
    }
  } else email = 'Please re-enter email'
  return email
}

export function validatePasswordSave(password) {
  if (password.length > 0) {
    var isValid = PASSWORD_REGX.test(password)
    if (!isValid) {
      password = 'Min 8 char, 1 Alphabet Caps, 1 Special char, & 1 number'
    } else {
      password = ''
    }
  } else {
    password = 'Please enter Password'
    console.log('hit', password.length)
  }

  return password
}

export function validateKey(key) {
  if (key.length > 0) key = ''
  else key = 'Please enter password reset key'
  return key
}
