import { Box, Container, Typography } from '@mui/material'
import FooterMui from 'components/FooterMui'
import { headingColor, tncColor } from 'configs/styles/muiThemes'
import ParagraphWithHeader from './TermsAndConditions/ParagraphWithHeader'
import ParagraphWithTitleSubTitle from './TermsAndConditions/ParagraphWithTitleSubTitle'
import TopComponent from './TopComponent'

export default function TermsAndConditions() {
  return (
    <div>
      {/* <Navbar /> */}
      <TopComponent value='' disableBanners />
      
      <Container sx={{ maxWidth: '1300px !important', mt: 3.5 }}>
        <Typography variant='h2' align='left' color={headingColor} pb={3}>
          Terms And Conditions
        </Typography>
        <ParagraphWithHeader
          title='Acceptance of Terms of Service'
          paragraph={[
            `This is an agreement between COSTBO SERVICES PRIVATE LIMITED ("Company"), the owner and operator of [WEBSITE URL] (the "Site"), and you ("you" or "You"), a user of the Site. By using the Site you acknowledge and agree to these Terms of Service and also the Privacy Policy, which can be found at [PRIVACY POLICY URL] and is incorporated by reference. If you choose to not agree with any of these terms, you may not use the Site.`,
            'All associated sites and applications linked with the same (the “Application”) are operated by the Company. The Company, through its Application offers an [e-Commerce marketplace for selling products across listed categories, and associated marketing, payment, order management, enquiry management tools and other incidental services to enable the transactions as available from time to time, on the Application (the “Services”). Through its application costBo enables business listing, product listing and campaigns ( including sale, announcements, events and Ads) for conducting business activities, selling or buying products across the listed categories. Also enable share of the above details by business or customer to their connections inside outside application. Application also tracks and stores the business and users activities/ trends for improving application usage and other marketing activities. Business information could be used for marketing and analytical needs.',
            'Application by nature enables business to user communications and user to user communication along with view of activities, sharing of messages, interests and etc. Location of the application users always tracked and stored for getting accurate results and social networking by the application.',
            'Application can enable social networking between users to businesses and users to users by posting each other’s activities.',
            'Communication and other media information created, shared and viewed are stored in costBo servers. costBo is not responsible for loss of messages stored in the server. Any illegal or in appropriate messages and information will be removed by costBo with or without any notification to the users. costBo will not read, sell and rent customer message contents .',
            'Communication and application availability time may not be guaranteed all the time which could be influenced by system outage, failures and other network related issues.',
            'These terms and conditions (the “Terms of Service”) shall apply to all users of the Application, including without limitation users who are browsers or contributors of content (collectively, the “Users”). By visiting or accessing any part of the Application or utilising the Services, the Users agree to be bound by these terms and conditions, including any additional terms and conditions and policies referenced herein.',
            'These Terms Of Service govern the use of the Application, by installing, downloading or even merely using the Application, the Users shall be contracting with the Company and signify their acceptance to the Terms of Service and other policies of the Company (the “Policies”) as posted on the Application from time to time, which takes effect on the date on which the Application is downloaded and creates a legally binding arrangement to abide by the same.',
          ]}
        />
        <ParagraphWithHeader
          title='Eligibility'
          paragraph={[
            'Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Application. Only individuals who are eighteen (18) years of age or older may use the Application and avail Services. The Company reserves the right to terminate the Users account and / or deny access to the Application if it is brought to the Company’s notice that the Users are under the age of eighteen (18) years.',
          ]}
        />
        <Box pb={1}>
          <Typography
            color={headingColor}
            variant='h5'
            fontSize={18}
            fontWeight={500}
            component='h4'
            pb={1.6}
          >
            Your Account
          </Typography>
          <Box pb={2}>
            <Typography
              color={headingColor}
              fontSize={14}
              fontWeight={500}
              pb={2}
              lineHeight='25px'
              textAlign='justify'
              display='inline'
            >
              1. Account Creation{' '}
              <Typography
                color={tncColor}
                fontSize={14}
                fontWeight={400}
                pb={2}
                lineHeight='25px'
                textAlign='justify'
                display='inline'
              >
                You must complete the registration process by providing the
                Company with current, complete, and accurate information as
                prompted by the applicable registration form. You also will
                choose a password and a user name (for businesses).
              </Typography>
            </Typography>
          </Box>
          <Box pb={2}>
            <Typography
              color={headingColor}
              fontSize={14}
              fontWeight={500}
              pb={2}
              lineHeight='25px'
              textAlign='justify'
              display='inline'
            >
              2. Responsibility for Account{' '}
              <Typography
                color={tncColor}
                fontSize={14}
                fontWeight={400}
                pb={2}
                lineHeight='25px'
                textAlign='justify'
                display='inline'
              >
                You are entirely responsible for maintaining the confidentiality
                of your password and account. Furthermore, you are entirely
                responsible for any and all activities that occur under your
                account. You agree to notify the Company immediately of any
                unauthorized use of your account or any other breach of
                security.
              </Typography>
            </Typography>
          </Box>
          <Box pb={2}>
            <Typography
              color={headingColor}
              fontSize={14}
              fontWeight={500}
              pb={2}
              lineHeight='25px'
              textAlign='justify'
              display='inline'
            >
              3. Liability for Account Misuse{' '}
              <Typography
                color={tncColor}
                fontSize={14}
                fontWeight={400}
                pb={2}
                lineHeight='25px'
                textAlign='justify'
                display='inline'
              >
                The Company will not be liable for any loss that you may incur
                as a result of someone else using your password or account,
                either with or without your knowledge. You could be held liable
                for losses incurred by the Company or another party due to
                someone else using your account or password.
              </Typography>
            </Typography>
          </Box>

          <Box pb={2}>
            <Typography
              color={headingColor}
              fontSize={14}
              fontWeight={500}
              pb={2}
              lineHeight='25px'
              textAlign='justify'
              display='inline'
            >
              4. Use of Other Accounts{' '}
              <Typography
                color={tncColor}
                fontSize={14}
                fontWeight={400}
                pb={2}
                lineHeight='25px'
                textAlign='justify'
                display='inline'
              >
                You may not use anyone else's account at any time, without the
                permission of the account holder.
              </Typography>
            </Typography>
          </Box>

          <Box pb={2}>
            <Typography
              color={headingColor}
              fontSize={14}
              fontWeight={500}
              pb={2}
              lineHeight='25px'
              textAlign='justify'
              display='inline'
            >
              5. Account Security{' '}
              <Typography
                color={tncColor}
                fontSize={14}
                fontWeight={400}
                pb={2}
                lineHeight='25px'
                textAlign='justify'
                display='inline'
              >
                The Company cares about the integrity and security of your
                personal information. However, the Company cannot guarantee that
                unauthorized third parties will never be able to defeat the
                Site's security measures or use any personal information you
                provide to us for improper purposes. You acknowledge that you
                provide your personal information at your own risk.
              </Typography>
            </Typography>
          </Box>
        </Box>
        <ParagraphWithHeader
          title='Orders and Financial Terms'
          paragraph={[
            'The Application allows the Users to place orders for the products sold or view detailed provided by various Vendors or Users and the Company will, subject to the terms and conditions set out herein, facilitate the placement of orders for the products to the Users. The Company does not own, sell, resell products on its own and/or does not control the Vendors or Users. The Company reserves the right to delist any vendor/ user/product from the Application.',
            'The Users understand that any order that they place shall be subject to the terms and conditions set out in these Terms of Service, and any terms and conditions imposed by the Vendor or Reseller concerned.',
            'All commercial/contractual terms are offered by and agreed to between Vendors or Users and the Users alone. The commercial/contractual terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products, etc.',
            'The Company does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Vendors or Users and the Users. All discounts and offers are by the Vendors and Users and not by the Company.',
            'In connection with any order, information such as name, billing address and credit card information will have to be provided by the Vendor or the third party payment processor. If the Users are directed to the third party payment processor, they may be subject to terms and conditions governing use of that third party’s service and that third party’s personal information collection practices. Users are requested to review such terms and conditions and privacy policy before using the Application services.',
            'Any payment on behalf of the user to Vendor will be the sole responsibility of the user. All applicable taxes and levies, the rates thereof and the manner of applicability of such taxes on the bill are being charged and determined by the Vendor. The Company holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole liability with respect to any legal issue arising on the taxes payable shall be with the Vendor.',
            'The transaction is bilateral between the Vendor and the Users and therefore, the Company is not liable to charge or deposit any taxes applicable on such transaction.',
            "Any money transactions between vendor and user or user and another user is a sole response between them. The company holds no responsibility for loss of money or any illegal activities during this transaction. The transaction records may be kept with the company for any audit trails by the company to support any official or governmental claims. Both Sending the receiving party should take due intelligence to know each other before initiating the transactions. The company can only provide limited details to users or vendors of this kind of transactions since most the payments are captured or executed outside the platform by the third party providers. The company only provides way of initiating and limited monitoring of the transaction in the platform. Original transaction confirmation may not accurately provided with the company's interface. Vendors and users double check the details with the payment processing platform to confirm the transaction through the outside interface.",
          ]}
        />
        <ParagraphWithTitleSubTitle
          title='User Conduct'
          subTitle='You may not engage in any of the following prohibited activities'
          paragraph={[
            '1. Copying, distributing, or disclosing any part of the Site in any medium, including without limitation by any automated or non-automated "scraping",',
            '2. Using any automated system, including without limitation "robots," "spiders," "offline readers," etc., to access the Site,',
            '3. Transmitting spam, chain letters, or other unsolicited email,',
            '4. Attempting to interfere with, compromise the system integrity or security of, or decipher any transmissions to or from the servers running the Site,',
            '5. Taking any action that imposes or may impose, at our sole discretion, an unreasonable or disproportionately large load on Site infrastructure,',
            '6. Uploading invalid data, viruses, worms, or other software agents through the Site,',
            '7. Collecting or harvesting any personally identifiable information, including account names, from the Site,',
            '8. Using the Site for any commercial solicitation purposes,',
            '9. Impersonating another person or otherwise misrepresenting your affiliation with a person or entity, conducting fraud, hiding or attempting to hide your identity,',
            '10. Interfering with the proper working of the Site,',
            '11. Accessing any content on the Site through any technology or means other than those provided or authorized by the Site, or',
            '12. Bypassing the measures we may use to prevent or restrict access to the Site, including without limitation, features that prevent or restrict use or copying of any content or enforce limitations on use of the Service or the content',
          ]}
        />
        <ParagraphWithHeader
          title='Disclaimers of Warranties and Limitation of Liability'
          paragraph={[
            'The Company endeavours to make the Application available during the Company’s working hours. However, the Company does not represent that access to the Application will be uninterrupted, timely, error free; free of viruses or other harmful components or that such defects will be corrected.',
            'The Company does not warrant that the Application will be compatible with all hardware and software, which is used by the Users. The Company shall not be liable for damage to, or viruses or other code that may affect, any equipment, software, data or other property as a result of downloading and installing the Application.',
            'The Company does not represent or warranty that the information available on the Application will be correct, accurate or otherwise reliable.',
            'The Vendors and Users shall be solely responsible about the details pertaining to specifics (such as quality, value, sale ability, etc) of the products proposed to be sold or offered to be sold or purchased on the Application. The Company does not implicitly or explicitly support or endorse the sale or purchase of any products nor provide any warrantee/guarantee of the products sold to the Users, and in no event shall such products be the responsibility of the Company.',
            'The Company is not responsible for any non-performance or breach of any contract entered into between the Vendors or Users and the Users. The Company cannot and does not guarantee that the concerned Users will perform any transaction concluded on the Application. The Company shall not and is not required to mediate or resolve any dispute or disagreement between the Users concerned.',
            'The Company does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users.',
            'The Company does not at any point of time during any transaction between any Vendor or Reseller and a User take possession of any of the products offered nor does it at any point gain title to or have any rights or claims over such products. At no time shall the Company hold any right, title or interest over the products nor shall the Company have any obligations or liabilities in respect of such contract entered into between the Users. The Company is not responsible for damages or delays as a result of products which are out of stock, unavailable or back ordered.',
            'The Company only provides a platform for communication and it is agreed that the contract for sale of any of the products shall be a strictly bipartite contract between the Users concerned.',
            'The Application may be under constant upgrades, and some functions and features may not be fully operational.',
            'The Application is provided on an “as is” and “as available” basis. The Company expressly disclaims all warranties of any kind, whether express or implied with respect to the records and other data that is made available by it to the Users.',
            'The Company makes no representation or warranty that: (i) the Application will be accurate or reliable; (ii) the Application will be uninterrupted, timely, secure, or error-free; (iii) any information that may be obtained from the use of the Application will be accurate, timely or complete; or (iv) any errors in any software used on the site or in connection with the Application will be corrected.',
            'The Company does not represent any of the users, and disclaims any liability with respect to any error or inconsistency with respect to any information relating to such users displayed on the site or with respect to the services provided. any information provided with respect to the users and fees payable is subject to change without notice. any trademark, word mark or intellectual property of any users belongs to such users alone, and the company has no right or claim over the same.',
            'The Company is not responsible and will have no liability for: (i) any content or products provided by any persons or entities other than the Company; (ii) damages of any kind that result from the downloading of any data or any other materials on the site or through the Application; or (iii) the failures of the internet or any data or telecommunications equipment, system or network used in connection with the Application.',
            'The Company shall not be liable for: any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation any financial losses, loss of data, replacement costs, or any similar damages, whether based in contract, tort, strict liability or otherwise, arising from the use of the Application, or for any other claim related in any way to the use of the Application, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Application or any content posted, transmitted, or otherwise made available via the Application, even if advised of their possibility.',
            'The Company shall not be liable to anyone else for any losses or injury arising out of or relating to the information provided on the Application. In no event will the Company or its employees, affiliates, authors or agents be liable to the Users or any third party for any decision made or action taken by the Users.',
          ]}
        />
        <ParagraphWithHeader
          title='Selling:'
          paragraph={[
            'Users and Vendors are permitted to list products for sale on the Application in accordance with the Policies, which are incorporated by way of reference in this Terms of Service. The Users and Vendors represent and warrant that they are legally able to sell or list the products on the Application; and the listed items do not infringe upon the intellectual property, trade secret or other proprietary rights or rights of publicity or privacy rights of third parties.',
          ]}
        />
        <ParagraphWithHeader
          title='Privacy'
          paragraph={[
            'All information about Users that are collected, stored or transmitted in any way on the Application, including any registration information, is subject to our Privacy Policy (the “Privacy Policy”), located at www.costbo.com',
          ]}
        />
        <ParagraphWithHeader
          title='Optional Tools'
          paragraph={[
            'The Company may provide you with access to third-party tools over which the Company neither monitors nor has any control or input.',
            'The Users acknowledge and agree that access to such tools is in an “as is” and “as available” basis, without any warranties, representations or conditions of any kind and without any endorsement. The Company shall have no liability whatsoever arising from or relating to your use of optional third-party tools.',
            'Any use by the Users of the optional tools offered through the Application is entirely at their own risk and discretion and it is the responsibility of the Users that they ensure that they are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).',
            'The Company may also, in the future, offer new features through the Application (including, the release of new tools and resources). Such new features shall also be subject to these Terms of Service.',
          ]}
        />
        <ParagraphWithHeader
          title='Third Party Links'
          paragraph={[
            'Certain content or products available via the Application may include materials from third-parties.',
            'Third-party links on the Application may direct you to third-party websites that are not affiliated with the Company. The Company is not responsible for examining or evaluating the content or accuracy and does not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.',
            'The Company is not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices and make sure to understand them before engaging in any transactions. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.',
          ]}
        />
        <ParagraphWithHeader
          title='Intellectual property'
          paragraph={[
            'The Company is either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual property, on the Application, and on the material published on it.',
            'The Users must not modify the paper or digital copies of any materials printed or downloaded in any way, and they must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. The Users must not use any part of the materials on the Application for commercial purposes without obtaining a license to do so from the Company.',
          ]}
        />
        <ParagraphWithHeader
          title='Indemnification'
          paragraph={[
            'The Users agree to indemnify, defend and hold harmless the Company, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of a breach of these Terms of Service or the documents they incorporate by reference, or violation of any law or the rights of a third-party.',
          ]}
        />
        <ParagraphWithHeader
          title='Severability'
          paragraph={[
            'In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.',
            'Termination on Notice. Either party may terminate this agreement for any reason on 60 business days’ notice to the other party.',
          ]}
        />
        <ParagraphWithHeader
          title='Termination for Material Breach'
          paragraph={[
            `Each party may terminate this agreement with immediate effect by delivering notice of the termination to the other party, if the other party fails to perform, has made, or makes any inaccuracy in, or otherwise materially breaches, any of its obligations, covenants, or representations, and the failure, inaccuracy, or breach continues for a period of 60 Business Days' after the injured party delivers notice to the breaching party reasonably detailing the breach. Termination for Failure of Condition. Either party may terminate this agreement with immediate effect by delivering notice of the termination to the other party, if either any of the conditions precedent set out in conditions on obligations of all parties, have not been, or it becomes apparent that any such conditions will not be, fulfilled by 60days, and such non-fulfillment was not due to the failure of the injured party to perform or comply with any of its representations, warranties, covenants, or conditions to be performed or complied with, or any of the conditions specifically applicable to the other party have not have been, or it becomes apparent that any such conditions will not be, fulfilled by 60 days.`,
          ]}
        />
        <ParagraphWithHeader
          title='Termination for Insolvency'
          paragraph={[
            'If either party becomes insolvent, bankrupt, or enters receivership, dissolution, or liquidation, the other party may terminate this agreement with immediate effect. Termination Because of Law or Order. Either party may terminate this agreement with immediate effect if there is or becomes any Law that makes the performance of the terms of this agreement illegal or otherwise prohibited, or any Governmental Authority issues an Order restraining or enjoining the transactions under this agreement.',
          ]}
        />
        <ParagraphWithHeader
          title='Entire Agreement'
          paragraph={[
            'These Terms of Service and any policies or operating rules posted by the Company on the Application constitute the entire agreement and understanding between the Users and the Company with respect to the Application, and supersede any prior or contemporaneous agreements, communications and proposals, whether oral or written, between the Users and the Company (including, but not limited to, any prior versions of the Terms of Service).',
            'Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.',
          ]}
        />
        <ParagraphWithHeader
          title='Waiver'
          paragraph={[
            'The failure of the Company to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.',
          ]}
        />
        <ParagraphWithHeader
          title='Governing law and dispute resolution'
          paragraph={[
            'These Terms of Service are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to this Application, shall be subject to the jurisdiction of the courts at Bangalore, India.',
          ]}
        />
        <ParagraphWithHeader
          title='Contact Information'
          paragraph={[
            'Questions about the Terms of Service should be sent to us at hello@costbo.com.',
          ]}
        />
        <ParagraphWithHeader
          title='Disclaimers'
          paragraph={[
            'The Site is provided "as is," without any warranties of any kind. To the fullest extent permissible under applicable Law, the Company disclaims all such warranties, express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, non-infringement, accuracy, freedom from errors, suitability of content, or availability.',
          ]}
        />
        <ParagraphWithHeader
          title='Feedback'
          paragraph={[
            'We welcome any comment, question, and communication at www.costbo.com',
          ]}
        />
      </Container>
      <FooterMui />
    </div>
  )
}
