import { PrivacyTip, Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Typography, Button } from '@mui/material'
import {
  noProductImgUrl,
  productNotAvailabelMsg,
  productPriceChangeMsg,
} from 'configs/Constants'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  borderLineColor,
  categoryLabelColor,
  delectIconInactiveColor,
  headingColor,
  navLinkColor,
  offerTextColor,
  outOfStockTextColor,
  primaryColor,
  savingTextColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import QuantityPicker from '../CatalogMui/QuantityPicker'
import CartQuantityPicker from './CartQuantityPicker'

function CartProductCard({
  image,
  productName,
  caterogy,
  oldUnitPrice,
  unitprice,
  discount,
  price,
  saving,
  quantity,
  isDiscount,
  isOutofStock,
  onDeleteProduct,
  readOnly,
  minQty,
  maxQty,
  cartData,
  cartId,
  product,
  orderProduct,
  onQtyUpdate,
  isPriceChange,
  isEcommerceDisabled,
  minQtyCheck,
  maxQtyCheck,
  validateCart,
  onRemoveProduct,
  onPriceUpdate,
  weightZeroError,
  isOfferOrderPresent,
  productId,
}) {
  //For Ui count update
  const [qtyCount, setQtyCount] = useState(quantity)
  const businessName = sessionStorage.getItem('businessName')
  let businessFullName = sessionStorage.getItem('businessFullName')

  const [isImgFailed, setIsImgFailed] = useState(false)

  useEffect(() => {
    setQtyCount(quantity)
  }, [productName])

  const getDeleteIconColor = () => {
    if (!validateCart) {
      return false
    }

    return (
      isEcommerceDisabled ||
      isPriceChange ||
      minQtyCheck ||
      maxQtyCheck ||
      weightZeroError ||
      isOfferOrderPresent
    )
  }

  return (
    <Box py={1.8} borderTop={'1px solid' + borderLineColor}>
      <Grid
        container
        alignItems={{ xs: 'flex-start', md: 'center' }}
        spacing={{ md: 3, xs: 1 }}
      >
        <Grid item xs={12} md={readOnly ? 5 : 4.5}>
          <Grid container spacing={2} alignItems='flex-start'>
            <Grid item>
              <Box
                component={Link}
                to={`/${businessName}/singleProduct/${productId}`}
              >
                <Box
                  component='img'
                  src={image}
                  alt={businessFullName + ' CostBo'}
                  backgroundColor={whiteColor}
                  sx={styles.productImg}
                  onError={({ currentTarget }) => {
                    if (!isImgFailed) {
                      currentTarget.src = noProductImgUrl
                      setIsImgFailed(true)
                    }else{
                      // Both Images are failing to Load
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Stack>
                {/* <Typography
                  variant='subtitle1'
                  fontSize='13px'
                  color={categoryLabelColor}
                >
                  {JSON.stringify(validateCart)}
                </Typography> */}
                <Typography
                  variant='h6'
                  component='h4'
                  fontSize={{ xs: 13, md: 14 }}
                  fontWeight={500}
                  lineHeight='24px'
                  color={headingColor}
                  mt={0.7}
                >
                  {productName}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={5} md={2}>
          <Box>
            {discount ? (
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 13, md: 13 }}
                fontWeight={400}
                color={offerTextColor}
                display='inline'
                sx={{ textDecorationLine: 'line-through' }}
              >
                ₹{Number(oldUnitPrice).toFixed(2)}
              </Typography>
            ) : null}

            <Typography
              variant='subtitle1'
              component='h4'
              fontSize={{ xs: 13, md: 14 }}
              color={headingColor}
              display='inline'
              ml={1}
            >
              ₹{Number(unitprice).toFixed(2)}
            </Typography>

            {discount ? (
              <Typography
                variant='subtitle2'
                component='p'
                color={primaryColor}
                mt={0.3}
                // ml={{xs:0.7, md:0}}
                // display={{ xs: 'inline', md: 'block' }}
                display='block'
              >
                {discount}% OFF
              </Typography>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={5} md={2.3}>
          {readOnly ? (
            <>
              <Typography
                variant='subtitle1'
                fontSize='13px'
                color={offerTextColor}
                display={{ xs: 'block', md: 'none' }}
              >
                Qty
              </Typography>
              <Typography
                variant='body1'
                color={headingColor}
                fontSize='13px'
                ml={{ md: 4, xs: 1 }}
                // width="45px"
                fontWeight={600}
                display={'inline'}
              >
                {qtyCount}
              </Typography>
            </>
          ) : (
            <CartQuantityPicker
              onIncrement={() => {
                if (quantity < maxQty && quantity >= minQty) {
                  onQtyUpdate({
                    cartId: cartId,
                    productId: product?.productId,
                    quantity: quantity + 1,
                  })
                }
              }}
              onDecrement={() => {
                if (quantity > minQty && quantity <= maxQty) {
                  onQtyUpdate({
                    cartId: cartId,
                    productId: product?.productId,
                    quantity: quantity - 1,
                  })
                }
              }}
              minQty={minQty}
              maxQty={maxQty}
              count={quantity}
            />
          )}
        </Grid>

        <Grid item xs={0} md={1.2} display={{ xs: 'none', md: 'inline-flex' }}>
          <Typography
            variant='subtitle1'
            component='h4'
            lineHeight='24px'
            fontSize={14}
            color={headingColor}
          >
            ₹{Number(price).toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={2} md={readOnly ? 1 : 2}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            mr={0.6}
          >
            <Typography
              variant='subtitle1'
              component='h4'
              lineHeight='24px'
              fontSize={14}
              color={savingTextColor}
              display={{ xs: 'none', md: 'inline' }}
            >
              ₹{Number(saving).toFixed(2)}
            </Typography>
            {!readOnly ? (
              <IconButton
                sx={{
                  backgroundColor: whiteColor,
                  border: '1px solid' + borderLineColor,
                }}
                onClick={onDeleteProduct}
              >
                <Delete
                  fontSize='small'
                  sx={{
                    color: getDeleteIconColor()
                      ? outOfStockTextColor
                      : delectIconInactiveColor,
                  }}
                />
              </IconButton>
            ) : null}
          </Box>
        </Grid>
      </Grid>

      {/* Price Change Check */}
      {validateCart ? (
        isPriceChange ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            textAlign={{ xs: 'left', md: 'right' }}
          >
            <Typography
              variant='subtitle1'
              component='p'
              fontSize='12.5px'
              color={outOfStockTextColor}
              display='inline'
              mt={1.6}
            >
              <PrivacyTip
                sx={{ fontSize: '20px', color: navLinkColor, mr: 0.7 }}
              />
              {productPriceChangeMsg}{' '}
              {Number(orderProduct?.productSalePrice).toFixed(2)}
              <Button
                sx={{ mx: 1.5, p: 0.3 }}
                variant='contained'
                disableElevation
                onClick={() => {
                  onPriceUpdate()
                }}
              >
                Apply
              </Button>
            </Typography>
          </Box>
        ) : null
      ) : null}

      {/* Ecommerce Enable Check */}
      {validateCart ? (
        isEcommerceDisabled || weightZeroError ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            textAlign={{ xs: 'left', md: 'right' }}
          >
            <Typography
              variant='subtitle1'
              component='p'
              fontSize='12.5px'
              color={outOfStockTextColor}
              display='inline'
              mt={1.6}
            >
              <PrivacyTip
                sx={{ fontSize: '20px', color: navLinkColor, mr: 0.7 }}
              />
              {productNotAvailabelMsg}
            </Typography>
          </Box>
        ) : null
      ) : null}

      {/* Offer Order Already Purchased Check */}
      {validateCart ? (
        isOfferOrderPresent ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            textAlign={{ xs: 'left', md: 'right' }}
          >
            <Typography
              variant='subtitle1'
              component='p'
              fontSize='12.5px'
              color={outOfStockTextColor}
              display='inline'
              mt={1.6}
            >
              <PrivacyTip
                sx={{ fontSize: '20px', color: navLinkColor, mr: 0.7 }}
              />
              Promotional Products are in limited quantity per person, you could
              try other products in cart
            </Typography>
          </Box>
        ) : null
      ) : null}

      {/* Min Order Qty Check */}
      {validateCart ? (
        !isEcommerceDisabled ? (
          minQtyCheck ? (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              textAlign={{ xs: 'left', md: 'right' }}
            >
              <Typography
                variant='subtitle1'
                component='p'
                fontSize='12.5px'
                color={outOfStockTextColor}
                display='inline'
                mt={1.6}
              >
                <PrivacyTip
                  sx={{ fontSize: '20px', color: navLinkColor, mr: 0.7 }}
                />
                Minimum order quantity is {orderProduct?.minOrderQuantity}.
                Please update the quantity.
                <Button
                  sx={{ mx: 1.5, p: 0.3 }}
                  variant='contained'
                  disableElevation
                  onClick={() => {
                    onQtyUpdate({
                      cartId: cartId,
                      productId: product?.productId,
                      quantity: orderProduct?.minOrderQuantity,
                    })
                  }}
                >
                  Apply
                </Button>
              </Typography>
            </Box>
          ) : null
        ) : null
      ) : null}

      {/* Max Order Qty Check */}
      {validateCart ? (
        !isEcommerceDisabled ? (
          maxQtyCheck ? (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              textAlign={{ xs: 'left', md: 'right' }}
            >
              <Typography
                variant='subtitle1'
                component='p'
                fontSize='12.5px'
                color={outOfStockTextColor}
                display='inline'
                mt={1.6}
              >
                <PrivacyTip
                  sx={{ fontSize: '20px', color: navLinkColor, mr: 0.7 }}
                />
                Maximum order quantity is {orderProduct?.maxOrderQuantity}.
                Please update the quantity.
                <Button
                  sx={{ mx: 1.5, p: 0.3 }}
                  variant='contained'
                  disableElevation
                  onClick={() => {
                    onQtyUpdate({
                      cartId: cartId,
                      productId: product?.productId,
                      quantity: orderProduct?.maxOrderQuantity,
                    })
                  }}
                >
                  Apply
                </Button>
              </Typography>
            </Box>
          ) : null
        ) : null
      ) : null}
    </Box>
  )
}

export default CartProductCard

const styles = {
  productImg: {
    width: '100px',
    height: '75px',
    border: '1px solid' + borderLineColor,
    borderRadius: '10px',
    p: 0.5,
    objectFit: 'contain',
  },

  offTag: {
    display: 'inline-block',
    backgroundColor: 'primary.main',
    borderRadius: '0 6px 0 0',
    p: '4px 15px',
    borderRadius: '5px',
  },
}
