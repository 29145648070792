import { Box, Divider, Typography } from '@mui/material'
import { AdditionalSaving_CashbackDescription, AdditionalSaving_CashbackTitle, FutureSaving_Description, FutureSaving_SubTitle, FutureSaving_Title } from 'configs/Constants'
import React from 'react'
import {
  borderLineColor,
  couponColor,
  headingColor,
  primaryColor,
  savingTextColor,
} from '../../configs/styles/muiThemes'

function AdditionalSavingCard({
  cashback,
  savingAmount,
  orderTotal,
  cashbackData,
}) {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      border={'1px solid ' + borderLineColor}
      borderRadius='6px'
      p={1.5}
      my={{ md: 3, xs: 1.5 }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        pb={1.5}
        borderBottom={'1px solid ' + borderLineColor}
      >
        <Box
          component='img'
          src='https://storage.googleapis.com/bodefaults/shopweb/cart-saving.png'
          alt={businessFullName + ' CostBo'}
          width='34px'
          height='34px'
          mr={1.5}
        />
        <Typography variant='h5' fontSize={15} color={headingColor}>
          Savings
        </Typography>
      </Box>
      <Box py={1.2}>
        <Typography
          variant='h6'
          component='h3'
          color={headingColor}
          textAlign='right'
          display='inline'
          lineHeight='25px'
        >
          Product Discounts{'  '}
          <Typography
            display='inline'
            variant='h5'
            fontSize={14}
            lineHeight='25px'
            color={savingTextColor}
          >
            ₹{Number(savingAmount).toFixed(2)}
          </Typography>
        </Typography>
      </Box>

      {cashbackData !== undefined && cashbackData?.details?.length > 0 ? (
        <Box
          py={1.5}
          borderBottom={'1px solid ' + borderLineColor}
          borderTop={'1px solid ' + borderLineColor}
        >
          <Typography
            variant='h6'
            component='h3'
            fontSize={13}
            color={headingColor}
            textAlign='right'
            display='inline'
          >
            {AdditionalSaving_CashbackTitle}{'  '}
            {cashbackData !== undefined && cashbackData?.details?.length > 0 ? (
              <Typography
                display='inline'
                variant='h5'
                color={savingTextColor}
                fontSize={13}
              >
                ₹{cashbackData?.details?.[0].availablePointValue?.toFixed(2)}{' '}
              </Typography>
            ) : null}
          </Typography>

          <Typography
            variant='body2'
            fontSize='13px'
            fontWeight={500}
            color={couponColor}
            textAlign='justify'
            mt={1}
          >
            {AdditionalSaving_CashbackDescription}
          </Typography>
        </Box>
      ) : null}

      {cashback ? (
        <>
          <Typography
            variant='h6'
            component='h3'
            color={headingColor}
            textAlign='start'
            pt='10px'
            borderTop={'1px solid ' + borderLineColor}
          >
            {FutureSaving_Title}
          </Typography>

          <Typography
            variant='h6'
            color={primaryColor}
            lineHeight='40px'
            textAlign='justify'
          >
            {cashback}{FutureSaving_SubTitle}
          </Typography>

          <Typography
            variant='body2'
            fontSize='13px'
            fontWeight={500}
            color={couponColor}
            textAlign='justify'
          >
            You will earn{' '}
            <Typography
              fontWeight={600}
              color={savingTextColor}
              display='inline'
            >
              ₹{(orderTotal * (cashback / 100))?.toFixed(2)}
            </Typography>{' '}
            for your next purchase.
          </Typography>

          <Typography
            variant='body2'
            fontSize='13px'
            fontWeight={500}
            color={couponColor}
            textAlign='justify'
            mt={1}
          >
            {FutureSaving_Description}
          </Typography>
        </>
      ) : null}
    </Box>
  )
}

export default AdditionalSavingCard
