import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import ModalCard from 'components/ModalCard'
import {
  discountPercentageColor,
  headingColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { validateCoupon } from 'services/api_calls/checkout/checkoutApi'

function OfferAppliedPopupModal({ data, open, onClose }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { businessDetails } = useSelector((state) => state.header)

  return (
    <ModalCard
      handleClose={() => {
        onClose()
      }}
      open={open}
      width={matches ? '28%' : '80%'}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: whiteColor,
          borderRadius: '15px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background:
              'url(https://storage.googleapis.com/bodefaults/shopweb/special-offer-wave-bg.jpg) no-repeat',
            padding: '15px',
            borderRadius: '15px 15px 0 0',
          }}
        >
          <Box
            component='img'
            src={businessDetails?.businessInfo?.[0]?.logoURL}
            alt='business logo'
            style={styles.brandLogo}
          />
          <Typography
            variant='h4'
            component='h4'
            fontSize={{ xs: 15, md: 21 }}
            color={headingColor}
          >
            Congrats!
          </Typography>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px',
          }}
        >
          <Typography
            variant='h4'
            component='h2'
            fontSize={{ xs: 24, md: 24 }}
            fontWeight='600'
            color={headingColor}
            lineHeight='32px'
            margin='14px !important'
          >
            You saved ₹{Number(data?.discountAmt).toFixed(2)}
          </Typography>

          <Typography
            variant='body1'
            component='p'
            fontSize={{ xs: 15, md: 15.5 }}
            fontWeight='500'
            lineHeight='25px'
            color='#ff6e3b'
            sx={{ textDecoration: 'underline' }}
          >
            on this order!
          </Typography>

          <Typography
            variant='h4'
            component='h6'
            fontSize={{ xs: 15, md: 15 }}
            fontWeight='600'
            color={headingColor}
            m='8px 0 10px 0 !important'
            textAlign='center'
            lineHeight='32px'
          >
            Special discount of{' '}
            <span style={{ fontSize:'13px', color: discountPercentageColor }}>
              {data?.discountPercentage}% applied
            </span>
          </Typography>

          {/* <Typography
            variant='h4'
            component='h5'
            fontSize={{ xs: 13, md: 13.5 }}
            color='#909090'
            fontWeight='500'
            lineHeight='20px'
            textAlign='center'
            m='0 0 5px 0 !important'
          >
            Offer promoted by our star affiliate{' '}
            <span style={{fontWeight:'600'}}>{data?.resellerName?.slice(0, 1)?.toUpperCase() +
              data?.resellerName?.slice(1)}</span>
          </Typography> */}

          <Button
            variant='contained'
            disableElevation
            sx={{ m: '15px 0 15px', p: '6px 45px' }}
            onClick={() => {
              onClose()
            }}
          >
            CONTINUE
          </Button>
        </Box>
      </Box>
    </ModalCard>
  )
}

export default OfferAppliedPopupModal

const styles = {
  brandLogo: {
    width: '55px',
    height: 'auto',
    borderRadius: '8px',
    border: '1px solid #e7eaef',
    marginBottom: '10px',
  },

  couponCode: {
    border: '1.5px dashed #01a4b5',
    borderRadius: '5px',
    background: '#f9feff',
    padding: '3px 12px',
    color: '#01a4b5',
    fontSize: '13px',
    fontWeight: '600',
    margin: '0px 8px',
  },
}
