import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { blackColor } from 'configs/styles/muiThemes'
import React from 'react'

function CODConfirmationAlert({
  codCost,
  content,
  onConfirm,
  onClose,
  isOpen,
  orderCost
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Alert</DialogTitle>
      <DialogContent>
        <DialogContentText
          id='alert-dialog-description'
          sx={{ fontSize: 14, fontWeight: 500, textAlign:'center' }}
        >
          {content} <br />{' '}
          {/* <span style={{ fontWeight: 500, fontSize: 14, mt:{xs:'0px', md:'8px'} }}>
            COD Cost:{' '}
            <span style={{ color:blackColor }}>
              ₹{Number(codCost).toFixed(2)}
            </span>
          </span> */}
          
          <span
            style={{
              fontWeight: 600,
              fontSize: 18,
              pt: { xs: '0px', md: '8px !important' },
              lineHeight:"40px",
              textAlign: 'center',
            }}
          >
            ₹{orderCost}
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined' sx={{ px: 2, mr: '15px' }}>
          CANCEL
        </Button>

        <Button onClick={onConfirm} variant='contained' sx={{ px: 2 }}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CODConfirmationAlert
