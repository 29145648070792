import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  blackColor,
  borderTextbox,
  greyColor3,
  greyShade36,
  primaryColor,
  textBtnColor,
  textBtnColorLight,
} from '../../configs/styles/muiThemes'
import ShareEarnHeader from '../ShareAndEarn/ShareEarnHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AffliatePaymentsContent from './AffliatePaymentsContent'
import GuestRegisterInstructions from './GuestRegisterInstructions'
import { getAffiliateValidityApi } from 'services/api_calls/affiliate/AffiliateApi'
import { getRefRatesByBusinessID } from 'redux/affiliate/AffiliateSlice'

function AffiliateProgramsContent({
  matches,
  fetchPendingPayments,
  fetchSettledPayments,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { businessName } = useParams()

  const { rellerProfileData, businessAffiliateData, affiliateValidity } =
    useSelector((state) => state.affiliate)
  const { pendingPayments, settledPayments } = useSelector(
    (state) => state.affiliate
  )

  const businessId = sessionStorage.getItem('businessId')
  const userType = sessionStorage.getItem('userType')
  const [showRegisterInstr, setShowRegisterInstr] = useState(false)

  const handleBack = () => {
    navigate(`/${businessName}`)
  }

  const [commissionPercentage, setCommissionPercentage] = useState(0)

  const getAffiliatePercentage = async () => {
    const affiliateCommissionPer = affiliateValidity?.commissionPercentage || 0
    const businessCommissionPer =
      businessAffiliateData?.businessResellers?.[0]?.affiliatePercentage || 0

    if (affiliateCommissionPer > 0) {
      setCommissionPercentage(affiliateCommissionPer)
    } else {
      setCommissionPercentage(businessCommissionPer)
    }

    console.log(
      'getAffiliatePercentage_affiliateCommissionPer',
      affiliateCommissionPer
    )
    console.log(
      'getAffiliatePercentage_businessCommissionPer',
      businessCommissionPer
    )

    console.log('getAffiliatePercentage_VALID', affiliateValidity)
    console.log('getAffiliatePercentage_B_PERCENTAGE', businessAffiliateData)
  }

  useEffect(() => {
    getAffiliatePercentage()
  }, [businessAffiliateData, affiliateValidity])

  return (
    <Box>
      <ShareEarnHeader
        title='Brand Affiliate - Share & Earn'
        handleBackClick={handleBack}
        handleSettingClick={() => {}}
        handleSearchClick={() => {}}
      />

      <Box p={2}>
        <Box>
          <Typography
            variant='h6'
            fontSize={15}
            fontWeight={600}
            lineHeight='18px'
            color={blackColor}
            mb='10px !important'
          >
            Share product links with followers
          </Typography>

          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Typography
              variant='body2'
              fontSize={13}
              fontWeight={500}
              lineHeight='20px'
              color={greyColor3}
            >
              Earn {commissionPercentage}% commission on each sale
            </Typography>

            <Typography
              variant='body2'
              fontSize={13}
              fontWeight={500}
              sx={styles.costBoText}
            >
              {rellerProfileData?.details?.resellerCode}
            </Typography>
          </Box>
        </Box>

        <Box mt={2}>
          <Typography
            variant='body2'
            fontSize={13}
            fontWeight={500}
            lineHeight='24px'
            color={greyColor3}
          >
            Simple 3 ways to Share
          </Typography>

          <Typography
            variant='body2'
            fontSize={13}
            fontWeight={500}
            lineHeight='25px'
            color={greyColor3}
            mt='8px !important'
          >
            {/* Browse our catalog, go to the product details page and share using
            multiple social media options */}
            1. Browse our catalog, Share products
            <br />
            2. Search for a product & Share
            <br />
            3. Share our store link
          </Typography>

          <Button
            variant='contained'
            sx={styles.exploreBtn}
            onClick={() => {
              navigate(`/${businessName}/catalog`)
            }}
          >
            EXPLORE & SHARE - CLICK HERE
          </Button>
        </Box>
      </Box>
      <Box
        sx={styles.commissionCard}
        onClick={() => {
          // navigate('/affiliatePayments')
        }}
      >
        <Box display='flex' alignItems='center' gap='6px'>
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/commission-earnings.svg'
            alt='costbo'
            sx={{ width: '28px', height: '28px', objectFit: 'contain' }}
          />

          <Typography
            variant='h6'
            fontSize={15}
            fontWeight={600}
            lineHeight='18px'
            color={blackColor}
          >
            Total Commission Earnings
          </Typography>
        </Box>

        <Typography
          variant='h6'
          fontSize={16}
          fontWeight={600}
          lineHeight='18px'
          color={primaryColor}
        >
          ₹{' '}
          {pendingPayments && settledPayments
            ? Number(pendingPayments?.total + settledPayments?.total).toFixed(2)
            : '0'}
        </Typography>
      </Box>
      {rellerProfileData && !rellerProfileData?.details?.bankInfo?.accountNo && (
        <Box m='0 16px 10px'>
          <Typography
            variant='body2'
            fontSize={12}
            fontWeight={500}
            lineHeight='22px'
            color={blackColor}
          >
            {userType === 'guest' ? `Please ` : ''}
            {userType === 'guest' && (
              <Typography
                variant='body2'
                component='a'
                fontSize={12}
                fontWeight={600}
                color={primaryColor}
                sx={{
                  display: 'inline',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowRegisterInstr(true)
                }}
              >
                Register
              </Typography>
            )}
            {userType === 'guest' ? (
              ` your account and Update your Banking details`
            ) : (
              <>
                Update your{' '}
                <Typography
                  variant='body2'
                  component='a'
                  fontSize={12}
                  fontWeight={600}
                  color={primaryColor}
                  sx={{
                    display: 'inline',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate(`/${businessName}/affiliateBankDetails`)
                  }}
                >
                  Banking details
                </Typography>
              </>
            )}{' '}
            for direct settlement in your account
          </Typography>
        </Box>
      )}
      <AffliatePaymentsContent
        fetchPendingPayments={fetchPendingPayments}
        fetchSettledPayments={fetchSettledPayments}
        affiliateCommissionPercentage={commissionPercentage}
      />
      <GuestRegisterInstructions
        open={showRegisterInstr}
        setOpen={setShowRegisterInstr}
      />
    </Box>
  )
}

export default AffiliateProgramsContent

const styles = {
  costBoText: {
    color: textBtnColor,
    backgroundColor: textBtnColorLight,
    borderRadius: '5px',
    padding: '5px 10px',
    border: '1.5px dashed ' + textBtnColor,
  },

  commissionCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: greyShade36,
    padding: '14px 10px',
    margin: '14px 16px 16px',
    border: borderTextbox,
    borderRadius: '6px',
    cursor: 'pointer',
  },

  exploreBtn: {
    fontSize: '13px',
    padding: '4px 12px',
    mt: 1.8,
  },
}
