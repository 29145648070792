import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAccountDetails,
  getAllCancelledOrderDetails,
  getOrdersCount,
} from 'redux/dashboard/dashboardSlice'
import FooterMui from '../components/FooterMui'
import CanceledOrdersContent from './CanceledOrders/CanceledOrdersContent'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserName } from 'utils/authHelpers'
import TopComponent from './TopComponent'
import BreadcrumbsSection from 'components/Breadcrumbs'
import { Box, Container } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'

function CanceledOrders() {
  const { businessName } = useParams()
  // const { businessId } = useParams()
  const businessId = sessionStorage.getItem('businessId')

  const dispatch = useDispatch()
  const { accountDetails, cancelledOrderDetails, loading, ordersCountData } =
    useSelector((state) => state.dashboard)

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  let navigate = useNavigate()

  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    if (isLoggedIn) {
      if (accountDetails == null) {
        dispatch(getAccountDetails())
      }

      if (cancelledOrderDetails == null) {
        dispatch(getAllCancelledOrderDetails({ businessId, pageNo: 1 }))
      }

      dispatch(getOrdersCount(businessId))
    } else {
      navigate(`/${businessName}`)
    }
  }, [])

  const handleOrderPagination = (pageNumber) => {
    dispatch(getAllCancelledOrderDetails({ businessId, pageNo: pageNumber }))
    setPageNo(pageNumber)
  }

  return (
    <div>
      {/* <Navbar value='' /> */}
      {/* <TopComponent value='' disableBanners/> */}

      <Box
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 100,
          backgroundColor: whiteColor,
        }}
      >
        <TopComponent value='' disableBanners />

        <Container
          sx={{
            maxWidth: '1300px !important',
            mt: { xs: 1.8, md: 2.5 },
          }}
        >
          <BreadcrumbsSection
            links={[
              { label: 'All Orders', to: `/AllOrders` },
              { label: 'Canceled Orders', to: `/CanceledOrders` },
            ]}
          />
        </Container>
      </Box>

      <CanceledOrdersContent
        accountDetails={accountDetails}
        cancelledOrderDetails={cancelledOrderDetails}
        loading={loading}
        businessId={businessId}
        ordersCountData={ordersCountData}
        handleOrderPagination={handleOrderPagination}
        pageNo={pageNo}
      />
      <FooterMui />
    </div>
  )
}

export default CanceledOrders
