import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'
import {
  backgroundColor,
  businessBodyColor,
  closeColor,
  headingColor,
  openColor,
  primaryColor,
  secondaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import { checkIsStoreOpen, getDayOfWeek } from '../../utils/utilities'

import PersonIcon from '@mui/icons-material/Person';

function WorkingHoursCard(props) {
  return (
    <Box sx={styles.card}>
      <Grid
        container
        direction='row'
        alignItems='center'
        spacing={2}
        sx={{ pb: 2 }}
      >
        <Grid item>
          <Box sx={styles.iconCircle}>
            <PersonIcon sx={{ fontSize: '20px' }} />
          </Box>
        </Grid>

        <Grid item xs>
          <Typography
            variant='h5'
            align='left'
            color={headingColor}
            marginBottom='8px !important'
          >
            {props.title}
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          borderTop: '1px solid #e9eaed',
          p: '10px 5px',
        }}
      >
        {props.workigHourData.map((item, index) => {
          const dayOfWeek = getDayOfWeek(item.dayOfWeek)
          const currentDay = moment().format('dddd')
          const isOpen = checkIsStoreOpen(item.from, item.to)
          const isToday = dayOfWeek === currentDay
          return (
            <Grid container sx={{ m: '15px 0' }} key={index + '_workingHour'}>
              <Grid item xs={4}>
                <Typography
                  variant='body1'
                  color={isToday ? primaryColor : businessBodyColor}
                  style={{ fontWeight: isOpen ? 600 : 400 }}
                >
                  {isToday ? 'Today' : dayOfWeek}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Grid container>
                  <Typography
                    variant='body1'
                    color={isToday ? primaryColor : businessBodyColor}
                    style={{ fontWeight: isOpen ? 600 : 400 }}
                  >
                    {`${item.from} - ${item.to}`}
                  </Typography>

                  {currentDay === dayOfWeek ? (
                    isOpen ? (
                      <Typography
                        variant='body1'
                        color={openColor}
                        marginLeft={1.5}
                        sx={{ fontWeight: 600 }}
                      >
                        Open
                      </Typography>
                    ) : (
                      <Typography
                        variant='body1'
                        color={closeColor}
                        marginLeft={1.5}
                      >
                        Closed
                      </Typography>
                    )
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Box>
    </Box>
  )
}

export default WorkingHoursCard

const styles = {
  card: {
    borderRadius: '12px',
    padding: '20px 15px',
    background: backgroundColor,
    border: '1px solid #eceef2',
  },

  iconCircle: {
    width: 40,
    height: 40,
    fontSize: '17px',
    borderRadius: '50%',
    backgroundColor: secondaryColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: whiteColor,
  },
}
