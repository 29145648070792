import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import {
  blackShade21,
  borderLineColor,
  delectIconInactiveColor,
  greenColor,
  greenColor02,
  greyShade45,
  headingColor,
  lightGreen01,
  lightOrange01,
  orangeColor,
  orangeColor02,
  orangeThickColor,
  primaryColor,
  savingTextColor,
  searchLabelColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import TableProductCard from 'components/TableProductCard'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import moment from 'moment'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { useState } from 'react'
import DeliveryAddress from 'pages/Confirmation/DeliveryAddress'
import DeliveryMethod from 'pages/Confirmation/DeliveryMethod'
import SpecialNotes from 'pages/Confirmation/SpecialNotes'
import PaycartStatusStepper from './PaycartStatusStepper'

function PaycartDetailsContent({ businessName, paycartData, onTrack }) {
  let totalQty = 0
  let totalSavings = 0

  const STEPS = [
    {
      label: 'Payment Requested',
    },
    {
      label: 'Created',
    },
    {
      label: 'Approved',
    },
    {
      label: 'Shipped',
    },
    {
      label: 'Delivered',
    },
  ]

  const getOrderStatusStep = (status) => {
    switch (status) {
      case 'paycartcreated':
        return 0
      case 'ordercreated':
        return 1
      case 'orderapproved':
        return 2
      case 'ordershipped':
        return 3
      case 'orderdelivered':
        return 4

      default:
        return 0
    }
  }

  const formatOrderDate = (date) => {
    return date ? moment(date).format('MMM DD, YYYY') : ''
  }

  const getDateByOrderStatus = (status) => {
    switch (status) {
      case 0:
        return formatOrderDate(paycartData?.orderRequestedOn)
      case 1:
        return formatOrderDate(paycartData?.orderCreatedOn)
      case 2:
        return formatOrderDate(paycartData?.orderApprovedOn)
      case 3:
        return formatOrderDate(paycartData?.orderShippedOn)
      case 4:
        return formatOrderDate(paycartData?.orderDeliveredOn)

      default:
        return ''
    }
  }

  const getOrderStatusText = (status) => {
    switch (status) {
      case 'paycartcreated':
        return 'Yet to be Paid'

      case 'ordercreated':
        return 'Order Created'

      case 'orderapproved':
        return 'Ready to Ship'

      case 'ordershipped':
        return 'Shipped'

      case 'orderdelivered':
        return 'Delivered'

      case 'bordercanceled':
        return 'Cancelled'

      case 'cordercanceled':
        return 'Cancelled'

      default:
        return 'Yet to be Paid'
    }
  }

  return (
    <Box>
      <Grid container spacing={3.5}>
        <Grid item xs={12} md={9}>
          <Box sx={styles.cartContainer} mt={1}>
            <Box py={1.5} borderBottom={'1px solid ' + borderLineColor} mb={1}>
              <Box
                display='flex'
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start' }}
                justifyContent={{ xs: 'flex-start', md: 'space-between' }}
              >
                <Box>
                  <Typography component='h6' sx={styles.labelText}>
                    Order ID:
                    <Typography variant='subtitle1' sx={styles.valueText}>
                      {paycartData?.orderId}
                    </Typography>
                  </Typography>

                  <Typography
                    component='h6'
                    sx={{ ...styles.labelText, mt: { xs: 0.5, md: 0 } }}
                  >
                    Date:
                    <Typography variant='subtitle1' sx={styles.valueText}>
                      {paycartData?.createdOn
                        ? moment(paycartData?.createdOn).format('MMM Do, YYYY')
                        : ''}
                    </Typography>
                  </Typography>
                </Box>

                <Box
                  width={{ xs: '100%', md: 'auto' }}
                  display='flex'
                  alignItems='center'
                  justifyContent={{ xs: 'space-between', md: 'flex-start' }}
                  gap={5}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                    mt={{ xs: 0.5, md: 0 }}
                  >
                    <Typography component='h6' sx={styles.labelText}>
                      Order Status
                    </Typography>

                    <Box
                      sx={{
                        backgroundColor:
                          paycartData?.orderStatus === 'paycartcreated' ||
                          paycartData?.orderStatus === 'bordercanceled' ||
                          paycartData?.orderStatus === 'cordercanceled'
                            ? lightOrange01
                            : lightGreen01,
                        p: '4px 12px',
                        borderRadius: '20px',
                        mt: '4px',
                      }}
                    >
                      <Typography
                        component='p'
                        sx={{
                          fontSize: 12,
                          fontWeight: 500,
                          lineHeight: '18px',
                          color:
                            paycartData?.orderStatus === 'paycartcreated' ||
                            paycartData?.orderStatus === 'bordercanceled' ||
                            paycartData?.orderStatus === 'cordercanceled'
                              ? orangeColor02
                              : greenColor02,
                        }}
                      >
                        {getOrderStatusText(paycartData?.orderStatus)}
                      </Typography>
                    </Box>
                  </Box>

                  {(paycartData?.orderStatus === 'ordershipped' ||
                    paycartData?.orderStatus === 'orderdelivered' ||
                    paycartData?.orderStatus === 'orderapproved') && (
                    <Button
                      disableElevation
                      sx={{
                        fontSize: 12,
                        fontWeight: 600,
                        padding: '4px 20px',
                        color: whiteColor,
                        backgroundColor: blackShade21,
                        border: `1px solid ${blackShade21}`,
                        mr: 3,

                        '&:hover': {
                          color: blackShade21,
                          backgroundColor: whiteColor,
                        },
                      }}
                      onClick={() => {
                        onTrack()
                      }}
                    >
                      TRACK
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>

            <Grid container py={1.5} spacing={2}>
              <Grid item xs={12} md={5}>
                <Box display='flex'>
                  <Box flex={1} style={styles.circleDot}>
                    <CurrencyRupeeIcon sx={{ fontSize: '18px' }} />
                  </Box>
                  <Box flex={1} ml={5}>
                    <ContentBox
                      label='Payment Link:'
                      value={paycartData?.paymentLink}
                      icon={
                        <ContentCopyOutlinedIcon
                          sx={{ fontSize: '18px', color: orangeThickColor }}
                        />
                      }
                      isPaid={paycartData?.orderStatus !== 'paycartcreated'}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={7}>
                <Box display={'flex'}>
                  <Box flex={1} style={styles.circleDot}>
                    <ShoppingCartOutlinedIcon sx={{ fontSize: '18px' }} />
                  </Box>
                  <Box ml={5}>
                    <ContentBox
                      label='Order Details:'
                      value={`https://shop.costbo.com/${businessName}/easycart/${paycartData?.id}`}
                      icon={
                        <ContentCopyOutlinedIcon
                          sx={{ fontSize: '18px', color: orangeColor }}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ borderTop: `1px solid ${borderLineColor}` }}>
              <Grid container py={1.5}>
                <Grid item xs={12} md={10}>
                  <Typography
                    variant='h5'
                    fontSize={{ xs: 14, md: 15 }}
                    color={headingColor}
                    mb='15px !important'
                    ml={2}
                  >
                    Order Status
                  </Typography>

                  <PaycartStatusStepper
                    steps={STEPS}
                    activeStep={getOrderStatusStep(paycartData?.orderStatus)}
                    getDateByOrderStatus={getDateByOrderStatus}
                    width='auto'
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={styles.cartContainer} mt={2}>
            {/* <DiscountHeader
              icon={<CategoryRoundedIcon sx={{ color: headingColor }} />}
              title="Items"
            /> */}
            {/* <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 16 }}
              color={headingColor}
              ml={1}
              mt={1.5}
              mb='6px !important'
            >
              Items in Order
            </Typography> */}

            <Grid
              container
              py={1}
              display={{ xs: 'none', md: 'inline-flex' }}
              spacing={{ md: 1.5 }}
            >
              <Grid item xs={5.3}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={2}
                >
                  PRODUCT
                </Typography>
              </Grid>

              <Grid item xs={2.1}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>

              <Grid item xs={1.9}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>

              <Grid item xs={1.3}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid>

            {paycartData?.productList?.map((item, index) => {
              const savings =
                Number(item?.productOriginalPrice) *
                  Number(item?.currentQuantity) -
                Number(item?.productSalePrice) * Number(item?.currentQuantity)
              totalQty = totalQty + item.currentQuantity
              totalSavings = totalSavings + savings
              return (
                <TableProductCard
                  key={'cart_product_' + index}
                  image={item?.productPicURL}
                  productName={item?.productName}
                  oldUnitPrice={item?.productOriginalPrice}
                  unitprice={item?.productSalePrice}
                  discount={item?.percentageOff}
                  price={item?.productTotalPrice}
                  saving={savings}
                  quantity={item.currentQuantity}
                  isOutofStock={item.isOutOfStock}
                  cartData={paycartData?.productList}
                  cartId={paycartData?.cartId}
                  product={item}
                  readOnly={true}
                />
              )
            })}
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Box
            sx={{ backgroundColor: whiteColor }}
            border={'1px solid ' + borderLineColor}
            borderRadius='10px'
            p={1.5}
            mt={0.5}
          >
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={1.5}
              borderBottom={'1px solid ' + borderLineColor}
              textAlign='right'
            >
              SUMMARY
            </Typography>

            <Box py={1}>
              <SummaryDataRow
                label='Subtotal:'
                value={paycartData?.orderTotalProductCost}
              />
              <SummaryDataRow
                label='Total Quantity:'
                value={paycartData?.totalQuantity}
              />
              <SummaryDataRow label='Taxes:' value='Included' isValueGreen />
              <SummaryDataRow
                label='Shipping & Handling:'
                value={paycartData?.shippingCost}
              />

              {paycartData?.codCost ? (
                <SummaryDataRow
                  label='COD Cost:'
                  value={`₹${paycartData?.codCost?.codCharges?.toFixed(2)}`}
                />
              ) : null}

              <SummaryDataRow
                label='Discount:'
                value={paycartData?.claimedPoint}
              />
            </Box>

            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              pt={0.7}
              borderTop={'1px solid ' + borderLineColor}
            >
              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={headingColor}
                  display='inline'
                >
                  Total
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant='h5'
                  component='p'
                  fontSize={14}
                  color={primaryColor}
                  display='inline'
                >
                  ₹{paycartData?.orderTotalCost}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mt={{ xs: '24px', md: '0px' }}>
            <DeliveryAddress address={paycartData?.customerAddress} isPayCart />
          </Box>

          <DeliveryMethod
            delivery={paycartData?.deliveryDateRange}
            shipType={paycartData?.deliveryType?.type}
          />

          {/* <PaymentType
            paymentType={
              cartDetails?.paymentInfo?.paymentDetail?.paymentType || 'Not Paid'
            }
          /> */}

          {paycartData ? (
            paycartData?.notesConsumer ? (
              <SpecialNotes note={paycartData?.notesConsumer} />
            ) : null
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}

export default PaycartDetailsContent

const ContentBox = ({ label, value, icon, isPaid }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(value)
    setIsCopied(() => true)

    setTimeout(() => {
      setIsCopied(() => false)
    }, 2000)
  }

  const openLink = () => {
    window.open(value, '_blank')
  }

  return (
    <Box display={'flex'} flexDirection='column'>
      <Typography
        variant='subtitle1'
        fontSize='14px'
        fontWeight={600}
        color={headingColor}
        display='inline'
      >
        {label}
      </Typography>

      <Box display={'flex'} alignItems='center' gap={1} sx={{ mt: -0.5 }}>
        <Typography
          variant='h6'
          fontSize='13px'
          fontWeight={500}
          color={searchLabelColor}
          display='inline'
          sx={{
            lineBreak: 'anywhere',
            '&:hover': {
              textDecoration: 'underline',
              color: primaryColor,
              cursor: 'pointer',
            },
          }}
          onClick={openLink}
        >
          {value}
        </Typography>

        {isPaid ? (
          <Box
            sx={{
              backgroundColor: lightGreen01,
              p: '0px 10px',
              borderRadius: '10px',
              border: `1px solid ${greenColor}`,
            }}
          >
            <Typography
              fontSize={11}
              fontWeight={500}
              color={greenColor02}
              lineHeight='20px'
            >
              Paid
            </Typography>
          </Box>
        ) : (
          <IconButton onClick={handleCopy}>
            {!isCopied ? (
              icon
            ) : (
              <CheckCircleOutlineRoundedIcon
                sx={{ fontSize: '20px', color: greenColor }}
              />
            )}
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

const SummaryDataRow = ({ label, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      py={0.2}
    >
      <Grid item>
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='13.5px'
          color={headingColor}
          display='inline'
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='h6'
          component='p'
          fontSize='13.5px'
          color={isValueGreen ? savingTextColor : headingColor}
          display='inline'
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = {
  cartContainer: {
    backgroundColor: whiteColor,
    // boxShadow: '0 4px 8px rgb(0 0 0 / 6%)',
    px: 1.5,
    py: 0.5,
    borderRadius: 2.2,
    border: '1px solid' + greyShade45,
  },
  actionBtn: (outline) => ({
    fontSize: '15px',
    fontWeight: 500,
    color: outline ? primaryColor : whiteColor,
    p: '7px 24px',
    mb: '10px !important',
    border: '1px solid ' + primaryColor,
    backgroundColor: outline ? whiteColor : primaryColor,
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: outline ? primaryColor : whiteColor,
      color: outline ? whiteColor : primaryColor,
    },
  }),

  circleDot: {
    position: 'absolute',
    width: '32px',
    height: '32px',
    color: whiteColor,
    backgroundColor: orangeThickColor,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // cursor: 'pointer',
  },

  labelText: {
    fontSize: { xs: 14, md: 15 },
    fontWeight: 500,
    lineHeight: '27px',
    color: headingColor,
    ml: 0.3,
  },

  valueText: {
    fontSize: '15px',
    fontWeight: 600,
    color: primaryColor,
    ml: 0.7,
    display: 'inline',
  },
}
