import { Box, Button, Grid, Radio, Typography } from '@mui/material'
import { couponCard_CongratsMsg, firstTimeBuyerCard_DiscountExceedOrderAmtErr, firstTimeBuyerCard_MinOrderAmtErr } from 'configs/Constants'
import React, { useEffect, useState } from 'react'
import { firstTimeDiscountDataHelper } from 'utils/checkoutHelpers'
import {
  borderLineColor,
  discountBgColor,
  errorTextColor,
  headingColor,
  minOrderTextColor,
  savingTextColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'

function FirstTimeBuyerCard({
  isSelected,
  onSelect,
  firstTimeData,
  cartDetails,
  commercialDetails,
  discountAmount,
  setDiscountAmount,
  setAppliedDiscountDetails,
}) {
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  let businessFullName = sessionStorage.getItem('businessFullName')

  useEffect(() => {
    if (!isSelected) {
      setDiscountAmount(0)
      setAppliedDiscountDetails(null)
      setIsError(false)
      setIsSuccess(false)
      setErrorMessage('')
    }
  }, [isSelected])

  const applyDiscount = () => {
    if (cartDetails.orderTotalProductCost < firstTimeData?.walletGiftLimit) {
      setIsError(true)
      setErrorMessage(
        firstTimeBuyerCard_MinOrderAmtErr + firstTimeData?.walletGiftLimit
      )
    } else if (
      cartDetails.orderTotalProductCost < firstTimeData?.walletGiftAmount
    ) {
      setIsError(true)
      setErrorMessage(firstTimeBuyerCard_DiscountExceedOrderAmtErr)
    } else {
      setIsError(false)
      setIsSuccess(true)
      setDiscountAmount(firstTimeData?.walletGiftAmount)
      setAppliedDiscountDetails(
        firstTimeDiscountDataHelper(
          firstTimeData,
          firstTimeData?.walletGiftAmount,
          firstTimeData?.totalClaimed,
          cartDetails
        )
      )
    }
  }
  return (
    <Box height='100%' sx={styles.discountCard(isSelected)}>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <Radio
            checked={isSelected}
            onChange={() => {
              onSelect()
            }}
            value='a'
            name='radio-buttons'
            inputProps={{ 'aria-label': 'A' }}
            size='small'
            sx={{ width: 35, height: 30 }}
          />
          <Typography
            variant='h6'
            component='p'
            fontSize={{ xs: 13.5, ms: 14 }}
            color={headingColor}
            display='inline'
          >
            First time Buyer Discount
          </Typography>
        </Grid>

        <Grid item>
          <Box
            component='img'
            src='https://storage.googleapis.com/bodefaults/shopweb/discounts_buyer_discount.svg'
            alt={businessFullName + ' CostBo'}
            width={{ xs: '48px', md: '46px' }}
            height={{ xs: '32px', md: '30px' }}
          />
        </Grid>
      </Grid>

      <Grid container alignItems='flex-end' justifyContent='space-between'>
        <Grid item>
          <Box mx={1.4} mt={0.5}>
            <Typography
              variant='subtitle1'
              component='p'
              fontSize={{ xs: 12, ms: 13 }}
              color={headingColor}
              display='inline'
            >
              Discount Amount{' '}
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 12, ms: 13 }}
                fontWeight={600}
                color={savingTextColor}
                display='inline'
              >
                ₹{firstTimeData?.walletGiftAmount}
              </Typography>
            </Typography>

            <Box sx={{ lineHeight: '18px' }}>
              <Typography
                variant='subtitle1'
                component='p'
                fontSize={{ xs: 12, ms: 13 }}
                color={minOrderTextColor}
                display='inline'
              >
                On Minimum order of{' '}
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize={{ xs: 12, ms: 13 }}
                  color={savingTextColor}
                  display='inline'
                >
                  ₹{firstTimeData?.walletGiftLimit}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item pr={1.4}>
          {isSelected ? (
            <Box textAlign='right' mr={1.4}>
              <Button
                sx={styles.actionBtn}
                variant='contained'
                disableElevation
                onClick={() => applyDiscount()}
              >
                Apply
              </Button>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      {isError ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={{ xs: '11px', md: '12px' }}
          color={errorTextColor}
          mx={1.4}
        >
          {errorMessage}
        </Typography>
      ) : null}
      {isSuccess ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={{ xs: '11px', md: '12px' }}
          color={savingTextColor}
          mx={1.4}
        >
          {couponCard_CongratsMsg}{firstTimeData?.walletGiftAmount}
        </Typography>
      ) : null}
    </Box>
  )
}

export default FirstTimeBuyerCard

const styles = {
  discountCard: (isSelected) => ({
    backgroundColor: isSelected ? discountBgColor : whiteColor,
    borderRadius: '6px',
    border: '1px solid' + borderLineColor,
    px: 0.5,
    py: 1,
    height: { xs: '109px', md: '125px' },
  }),

  actionBtn: {
    height: { xs: '33px', md: '36px' },
    fontSize: { xs: '13px', md: '14px' },
    fontWeight: 600,
    color: savingTextColor,
    backgroundColor: whiteColor,
    px: { xs: '20px', md: '28px' },
    border: '1px solid ' + savingTextColor,
    borderRadius: { xs: '4px', md: '6px' },
    '&:hover': {
      backgroundColor: savingTextColor,
      color: whiteColor,
    },
  },
}
