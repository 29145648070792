import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader'
import { orders_AvailableCashback, orders_PendingOrders, orders_TotalOrder } from 'configs/Constants'
import BreadcrumbsSection from '../../components/Breadcrumbs'
import {
  dashboardDescriptionColor,
  headingColor,
  primaryColor,
} from '../../configs/styles/muiThemes'
import DasboardInfoCard from './DasboardInfoCard'
import DashboardCard from './DashboardCard'
import DashboardNavbar from './DashboardNavbar'

function DashboardContent({
  accountDetails,
  orderDetails,
  openOrderDetails,
  cashbackData,
  loading,
  businessId,
  address,
  ordersCountData,
}) {
  // const orderList = orderDetails?.details?.filter((item) => {
  //   return (
  //     item.orderStatus !== 'paycartcreated' &&
  //     item.orderStatus !== 'businesscartcreated'
  //   )
  // })

  const businessName = sessionStorage.getItem('businessName')

  return (
    <Container sx={{ maxWidth: '1300px !important', mt: { xs: 1.8, md: 2.5 } }}>
      {/* <BreadcrumbsSection links={[{ label: 'Dashboard', to: `/Dashboard` }]} /> */}
      <Box mt={{ md: '20px !important', xs: 0 }}>
        <Grid
          container
          rowSpacing={{ md: 2, xs: 0 }}
          columnSpacing={{ md: 5, xs: 0 }}
        >
          <Grid item md={3} xs={12}>
            <DashboardNavbar active='dashboard' businessId={businessId} />
          </Grid>

          {loading ? (
            <Grid item md={9} xs={12}>
              <Box display='flex' flexDirection='row' justifyContent='center'>
                <Loader />
              </Box>
            </Grid>
          ) : (
            <Grid item md={9} xs={12}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mb={2.0}
                mt={{ md: 0, xs: 2.3 }}
              >
                <Typography
                  variant='h4'
                  component='h2'
                  fontSize={{ xs: 15, md: 17 }}
                  color={headingColor}
                >
                  My Dashboard
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize={14}
                  lineHeight='24px'
                  color={dashboardDescriptionColor}
                  display='inline'
                >
                  Hello,{' '}
                  <Typography
                    variant='subtitle1'
                    component='p'
                    fontSize='14px'
                    fontWeight={600}
                    color={primaryColor}
                    display='inline'
                  >
                    {/* Show only first name */}
                    {/* {(accountDetails?.account?.name).split(' ')[0]} ! */}
                    {accountDetails?.account?.name} !
                  </Typography>
                </Typography>
              </Box>
              <Typography
                variant='body1'
                fontSize={{ xs: 13, md: 14 }}
                color={dashboardDescriptionColor}
                mb='15px !important'
              >
                From your My Dashboard you have the ability to view a snapshot
                of your account information and recent order activity
              </Typography>
              <Grid container spacing={{ xs: 1.25, md: 2 }} mt={0}>
                <Grid item md={4} xs={12}>
                  <DashboardCard
                    icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_total_orders.png'
                    title={orders_TotalOrder}
                    // value={orderList?.length}
                    value={ordersCountData?.details?.allOrder}
                    linkPath={`/${businessName}/allOrders`}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <DashboardCard
                    icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_pending_orders.png'
                    title={orders_PendingOrders}
                    // value={openOrderDetails?.details?.length}
                    value={ordersCountData?.details?.openOrder}
                    linkPath={`/${businessName}/openOrders`}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <DashboardCard
                    icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_available_cashback.png'
                    title={orders_AvailableCashback}
                    value={`₹${
                      cashbackData?.details.length > 0
                        ? cashbackData.details[0].availablePointValue.toFixed(2)
                        : 0
                    }`}
                  />
                </Grid>
              </Grid>
              <Typography
                variant='h5'
                color={headingColor}
                fontSize='15px'
                mt={3}
                mb='16px !important'
              >
                Account Information
              </Typography>
              <Grid container spacing={{ md: 2, xs: 1 }}>
                <Grid item md={6} xs={12}>
                  <DasboardInfoCard
                    title='Name'
                    label=''
                    value={accountDetails?.account?.name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DasboardInfoCard
                    title='Contact Number'
                    label=''
                    value={accountDetails?.account?.phonenumber}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <DasboardInfoCard
                    title='E-Mail Address'
                    label=''
                    value={accountDetails?.account?.email}
                  />
                </Grid>

                {/* <Grid item md={6} xs={12}>
                <DasboardInfoCard
                  title='Default Address'
                  label=''
                  value={`${address?.name}, ${address?.houseNo} ${address?.addressLine1}, ${address?.city},  ${address?.state}`}
                />
              </Grid> */}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  )
}

export default DashboardContent
