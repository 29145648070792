import { Box, Grid, Typography } from '@mui/material'
import ProductCard from './ProductCard'
import { getOutOfStock } from 'utils/productHelper'
import { headingColor, searchLabelColor } from 'configs/styles/muiThemes'
import { useSelector } from 'react-redux'
import EmptyCard from 'components/EmptyCard'
import { searchEmptyImgUrl, searchEmptyMsg } from 'configs/Constants'

function SearchResult({
  searchProducts,
  allProducts,
  cartData,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
}) {
  const { searchQuery } = useSelector((state) => state.search)

  return (
    <Box pt={1}>
      {/* <CategoryGroupHeader
          title={selectedCategory?.title}
          totalProduct={selectedCategory?.products?.length}
          noAction
        /> */}

      <Box my={1}>
        <Typography
          variant='h6'
          color={searchLabelColor}
          fontWeight={500}
          fontSize={15}
          display='inline'
        >
          Showing {searchProducts?.length} results for{' '}
          <Typography
            variant='h6'
            color={headingColor}
            fontSize={15}
            display='inline'
          >
            “{searchQuery}”
          </Typography>
        </Typography>
      </Box>

      <Grid container columnSpacing={1} rowSpacing={2.5} pt={1.5} pl={1}>
        {allProducts?.length > 0
          ? searchProducts?.map((item, index) => {
              const productData = allProducts?.filter((data) => {
                return item.id === data.id
              })

              console.log("SEARCH_ITEM_DATA", productData?.[0])

              // if(!productData?.[0]) return null

              return (
                <Grid item xs={6} sm={3} md={2.4}>
                  <ProductCard
                    img={productData?.[0]?.productPhoto[0].docURL}
                    price={productData?.[0]?.productSalePrice}
                    oldPrice={productData?.[0]?.productOriginalPrice}
                    description={productData?.[0]?.productName}
                    discount={productData?.[0]?.percentageOff}
                    style={{ my: 1.5 }}
                    isOutOfStock={getOutOfStock(productData?.[0])}
                    isComingSoon={productData?.[0]?.commerceEnabled === 'NO'}
                    minQty={productData?.[0]?.minOrderQuantity}
                    maxQty={productData?.[0]?.maxOrderQuantity}
                    currencySymbol={
                      productData?.[0]?.currencySymbol
                        ? '₹'
                        : productData?.[0]?.currencySymbol
                    }
                    cartData={cartData}
                    product={productData?.[0]}
                    cartId={cartId}
                    onQtyUpdate={(data) => {
                      onQtyUpdate(data)
                    }}
                    onAddProduct={(data) => {
                      onAddProduct(data)
                    }}
                    onRemoveProduct={onRemoveProduct}
                    subscribe={
                      productData?.[0]?.subscription?.status === 'active'
                    }
                    availability={
                      productData[0]?.availability?.[0]?.toLowerCase() === 'yes'
                    }
                    subscription={productData[0]?.subscription}
                  />
                </Grid>
              )
            })
          : null}

        {searchProducts?.length === 0 ? (
          <EmptyCard
            message={searchEmptyMsg + `"${searchQuery}"`}
            imageUrl={searchEmptyImgUrl}
          />
        ) : null}
      </Grid>
    </Box>
  )
}

export default SearchResult
