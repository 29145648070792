import { ADD_SEARCH_INFO_URL, SEARCH_PRODUCTS_API } from 'services/constant'
import { getApiInstance } from '../../ApiInstance'

export const getSearchProducts = async (data) => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()
  const key = data?.key
  const pageNo = data?.pageNo ? data?.pageNo : 0

  try {
    const response = await instance.get(
      `${SEARCH_PRODUCTS_API}/?key=${key}&businessId=${businessId}&page=${pageNo}`
    )

    console.log('SEARCH_PRODUCTS:', response?.data)

    return response?.data
  } catch (error) {
    console.log(error)
  }
}
export const addSearchInfo = async (data) => {
  try {
    const instance = getApiInstance()
    const response = await instance.post(ADD_SEARCH_INFO_URL, data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
