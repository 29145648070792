import { getApiInstance } from '../../ApiInstance'

export const getSubCategoryData = async (param) => {
  const businessId = sessionStorage.getItem('businessId')
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    })

    const response = await instance.get(
      `product-query-side/api/v1/product/group/subCategoryProducts`
    )
    const detailedArr = response ? response.data.details : []
    console.log("Detailed",detailedArr)
    return detailedArr
  } catch (error) {
    console.log(error)
  }
}

export const getProductsBySubCat = async (param) => {
  const businessId = sessionStorage.getItem('businessId')
  const catCode = param ? param.catCode : ''
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    })

    const response = await instance.get(
      `product-query-side/api/v1/product/productsBySubcategory?subcatcodes=${catCode}`
    )
    const detailedArr = response ? response.data.details : []
    console.log(detailedArr)
    return detailedArr
  } catch (error) {
    console.log(error)
  }
}

export const getAllProducts = async (param) => {
  const businessId = sessionStorage.getItem('businessId')
  const pageNo = param ? param.pageNo : 1
  const discount = param ? param.discountHightToLow : false
  const lowPrice = param ? param.priceLowoHig : false
  const highPrice = param ? param.highPrice : false
  const business = param ? param.business : false

  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    })

    const response = await instance.get(
      `product-query-side/api/v1/open/product/productsByBusiness/${businessId}?business=${business}&percentageOff=${discount}&highToLow=${highPrice}&lowToHigh=${lowPrice}&pageSize=500&pageNo=${pageNo}`
    )
    console.log('ALL_PRODUCTS:', response?.data)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const getCategoryDetails = async () => {
  const businessId = sessionStorage.getItem('businessId')
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    })

    const response = await instance.get(
      `https://stagej.costbo.com/product-query-side/api/v1/open/product/category`
    )

    return response.data.details
  } catch (error) {
    console.log(error)
  }
}
