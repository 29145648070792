import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  footerListColor,
  stepperIconColor,
  stepperInactiveColor,
  stepperTextInactiveColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { Box, Typography } from "@mui/material";
import {
  CheckRounded,
  LocalMallRounded,
  LocalShippingRounded,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: footerListColor,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: footerListColor,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 6,
    border: 0,
    backgroundColor: stepperInactiveColor,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: stepperInactiveColor,
  zIndex: 1,
  color: stepperIconColor,
  width: 41,
  height: 41,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: footerListColor,
    // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: whiteColor,
  }),
  ...(ownerState.completed && {
    backgroundColor: footerListColor,
    color: whiteColor,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: (
      <Box component={Link} to={props.route}>
        <LocalMallRounded
          sx={{ color: completed || active ? whiteColor : footerListColor, fontSize:'20px' }}
        />
      </Box>
    ),
    2: (
      <Box component={Link} to={props.route}>
        <LocalShippingRounded
          sx={{ color: completed || active ? whiteColor : footerListColor, fontSize:'20px' }}
        />
      </Box>
    ),
    3: (
      <Box component={Link} to={props.route}>
        <CheckRounded
          sx={{ color: completed || active ? whiteColor : footerListColor, fontSize:'20px' }}
        />
      </Box>
    ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function CartStepper({ steps, activeStep }) {
  return (
    <Stack sx={{ width: "100%" }} mb={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((item, index) => (
          <Step key={item.label} onClick={() => {}}>
            <StepLabel
              StepIconComponent={(props) =>
                ColorlibStepIcon({ ...props, route: item.route })
              }
            >
              <Typography
                variant="h5"
                component="p"
                fontSize={{ xs: "12px", md: "14px" }}
                fontWeight={activeStep === index ? 600 : 500}
                color={
                  activeStep === index
                    ? footerListColor
                    : stepperTextInactiveColor
                }
                display={{ xs: "none", md: "block" }}
              >
                {item.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
