import { Box, Typography, useMediaQuery } from '@mui/material'
import ModalCard from 'components/ModalCard'
import { blackColor, greyColor3, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

function GuestRegisterInstructions({open, setOpen}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleModalClose = () => {
    setOpen(false)
  }
  return (
    <ModalCard
      handleClose={handleModalClose}
      open={open}
      width={matches ? '25%' : '70%'}
    >
      <Box sx={styles.card}>
        <Typography
          variant='h6'
          fontSize={15}
          fontWeight={500}
          lineHeight='25px'
          color={blackColor}
          textAlign='center'
        >
          You have logged in as a Guest user using OTP
        </Typography>

        <Typography
          variant='body2'
          fontSize={13}
          fontWeight={500}
          lineHeight='25px'
          color={greyColor3}
          mt='8px !important'
        >
          Please follow the steps below
        </Typography>

        <Typography
          variant='body2'
          fontSize={13}
          fontWeight={500}
          lineHeight='25px'
          color={greyColor3}
          mt='8px !important'
        >
          1. Log off from the current account <br />
          2. Goto login screen <br />
          3. Enter phone number and OTP <br />
          4. Click on 'Create Account' <br />
        </Typography>
      </Box>
    </ModalCard>
  )
}

export default GuestRegisterInstructions

const styles = {
  card: {
    backgroundColor: whiteColor,
    padding: '15px',
    borderRadius: '8px',
  },
}
