import { Box, Radio, Typography } from '@mui/material'
import React from 'react'
import {
  borderLineColor,
  headingColor,
  minOrderTextColor,
  offerTextColor,
  savingTextColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'

function DeliveryMethodCard({
  icon,
  title,
  subtitle,
  subtitle1,
  oldPrice,
  price,
  isSelect,
  onSelectMethod,
  additionalDiscount,
  isFreeShipping,
  error,
}) {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      backgroundColor={whiteColor}
      border={'1px solid ' + borderLineColor}
      borderRadius={1.8}
      display='flex'
      flexDirection={'column'}
      alignItems='center'
      justifyContent={'center'}
      textAlign='center'
      p={{ md: 1, xs: 0.7 }}
      position='relative'
      sx={{
        cursor: (price || price === 0) && price !== '' ? 'cursor' : '',
        opacity: error ? 0.5 : 1,
      }}
      width={{ xs: '123px', md: '156px' }}
      height={'140px'}
      onClick={() => {
        if ((price || price === 0) && price !== '' && !error) {
          onSelectMethod(price?.toFixed(2), subtitle)
        }
      }}
    >
      <Box
        component='img'
        src={icon}
        alt={businessFullName + ' CostBo'}
        width='40px'
        height='35px'
      />

      <Typography
        variant='subtitle2'
        component='h5'
        fontSize='13px'
        color={headingColor}
        mt={0.5}
      >
        {title}
      </Typography>

      <Typography
        variant='subtitle1'
        component='p'
        fontSize='11px'
        lineHeight='18px'
        width='100%'
        color={offerTextColor}
      >
        {subtitle1}
      </Typography>

      <Typography
        variant='subtitle1'
        component='p'
        fontSize='12px'
        lineHeight='26px'
        width='100%'
        color={minOrderTextColor}
      >
        {subtitle}
      </Typography>

      {error ? (
        title == 'Rush' || title == 'Express' ? (
          <Typography
            variant='subtitle1'
            component='p'
            fontSize='13px'
            color={savingTextColor}
          >
            {oldPrice ? (
              <Typography
                variant='subtitle1'
                component='p'
                fontSize='12px'
                color={offerTextColor}
                sx={{ textDecoration: 'line-through' }}
                display='inline'
                mr={0.8}
              >
                ₹{oldPrice?.toFixed(2)}
              </Typography>
            ) : null}
            ₹{price?.toFixed(2)}
          </Typography>
        ) : (
          <Typography
            variant='subtitle1'
            component='p'
            fontSize='13px'
            lineHeight='26px'
            color={minOrderTextColor}
          >
            Not Available
          </Typography>
        )
      ) : (price || price === 0) && price !== '' ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='13px'
          color={savingTextColor}
        >
          {oldPrice ? (
            <Typography
              variant='subtitle1'
              component='p'
              fontSize='12px'
              color={offerTextColor}
              sx={{ textDecoration: 'line-through' }}
              display='inline'
              mr={0.8}
            >
              ₹{oldPrice?.toFixed(2)}
            </Typography>
          ) : null}
          ₹{price?.toFixed(2)}
        </Typography>
      ) : (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='14px'
          color={savingTextColor}
        >
          Loading...
        </Typography>
      )}

      {}

      {/* {price || price == 0 ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='14px'
          color={savingTextColor}
        >
          {isFreeShipping || additionalDiscount ? (
            <Typography
              variant='subtitle1'
              component='p'
              fontSize='12px'
              color={offerTextColor}
              sx={{ textDecoration: 'line-through' }}
              display='inline'
              mr={0.8}
            >
              {price}
            </Typography>
          ) : null}
          {isFreeShipping
            ? 0
            : additionalDiscount
            ? price - (additionalDiscount * price) / 100
            : price}
        </Typography>
      ) : (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize='14px'
          color={savingTextColor}
        >
          Loading...
        </Typography>
      )} */}

      <Box sx={styles.radioBtn}>
        <Radio
          checked={isSelect}
          // onChange={() => {
          //   onSelectMethod(price, subtitle)
          // }}
          value='a'
          size='small'
          name='radio-buttons'
          inputProps={{ 'aria-label': 'A' }}
          disabled={!((price || price === 0) && price !== '')}
        />
      </Box>
    </Box>
  )
}

export default DeliveryMethodCard

const styles = {
  radioBtn: {
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
}
