import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  headingColor,
  labelColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function ReviewSectionHeader(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [text, SetText] = useState(props.preselectedOption);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    SetText(event.target.innerText)
    props.onClickMore(event.target.innerText ? event.target.innerText : 'Most Recent')
  };

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={styles.reviewHeader}
    >
      <Grid item>
        <Typography
          variant="h4"
          component="h2"
          fontSize={{md:'17px',xs:'15px'}}
          color={headingColor}
          align="left"
        >
          Customer Reviews
        </Typography>
      </Grid>

      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1" align="left" color={labelColor}>
          Sort by:
        </Typography>

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={styles.dropdownBtn}
          endIcon={<KeyboardArrowDownOutlined />}
        >
          {text}
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          variant="selectedMenu"
        >
          <MenuItem onClick={handleClose} value="Profile">
            Most Recent
          </MenuItem>
          <MenuItem onClick={handleClose} value="My account">
            Highest Rating
          </MenuItem>
          <MenuItem onClick={handleClose} value="Logout">
            Lowest Rating
          </MenuItem>
          {/* <MenuItem onClick={handleClose} value="Logout">
            Most Helpful
          </MenuItem> */}
        </Menu>
      </Grid>
    </Grid>
  );
}

export default ReviewSectionHeader;

const styles = {
  reviewHeader: {
    py: 2,
    borderTop: "1px solid #eff1f2",
    mt: 3,
  },

  dropdownBtn: {
    fontSize: "15px",
    fontWeight: 600,
    textTransform: "none",
    color: headingColor,
    "&:hover": {
      backgroundColor: whiteColor,
    },
  },
};
