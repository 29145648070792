import {
  AppBar,
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  keyframes,
  Menu,
  Modal,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
// import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import SellIcon from '@mui/icons-material/Sell'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ReviewsIcon from '@mui/icons-material/Reviews'
import SearchIcon from '@mui/icons-material/Search'
import { Suspense, useEffect, useState } from 'react'
import {
  backgroundColor,
  navHeaderColor,
  navLinkColor,
  primaryColor,
  productLabelColor,
  topbarColor,
  headingColor,
  whiteColor,
  offerTextColor,
  breadcrumbBgColor,
  blackColor,
} from '../../configs/styles/muiThemes'
import Sidebar from './Sidebar'
import { NavLink, Link, useNavigate, useParams } from 'react-router-dom'
import ModalCard from '../ModalCard'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkExistingUser,
  checkUserLogin,
  clearLoginData,
  getProfileDetails,
  setLoginData,
  updateIsLogin,
} from 'redux/authRedux/authSlice'

import {
  geProfilePhoto,
  getAccessToken,
  getPhoneNumber,
  getProfileId,
  getUserName,
  removeSessionInfo,
} from 'utils/authHelpers'
import { getAccountDetails } from 'redux/dashboard/dashboardSlice'
import { clearCartData, getCartDetails } from 'redux/addToCart/AddToCartSlice'
import {
  addActivityWithLogin,
  addActivityWithOutLogin,
} from 'redux/addActivity/AddActivitySlice'
import { createCart, updateCartDetails } from 'redux/cart/CartSlice'
import { createCartHelper, updateMergeCartHelper } from 'utils/cartHelper'
import {
  activeMsg,
  applyImg,
  applyMsg,
  approvedImg,
  approvedMsg,
  dueImg,
  dueMsg,
  incompleteImg,
  incompleteMsg,
  LATITUDE,
  LONGITUDE,
  navBar_BuyOnlineMsg,
  navBar_Menu,
  navBar_MenuCatalog,
  navBar_MenuCounpons,
  navBar_MenuDealsCounpons,
  navBar_MenuHome,
  navBar_MenuLoginSignup,
  navBar_MenuOverview,
  navBar_MenuReview,
  overDueImg,
  overDueMsg,
  rejectedImg,
  rejectedMsg,
} from 'configs/Constants'
import { getCatalogAllProducts } from 'redux/subCatergory/subCategorySlice'
import {
  addSearchInfoAPI,
  clearSearchData,
  searchProducts,
  setSearchQuery,
} from 'redux/searchProduct/SearchProductSlice'
import { PersonOutlineRounded, ShoppingCartOutlined } from '@mui/icons-material'
import { skeletonBox } from 'utils/styleUtility'
import { getIpAddress } from 'services/api_calls/header/HeaderApi'
import {
  allowOnlyEnglish,
  getBrowser,
  getOperatingSystem,
} from 'utils/utilities'
import ProfileCharAvatar from 'components/ProfileCharAvatar'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AuthLayoutNew from 'pages/Auth/newUi/AuthLayoutNew'
import MobileNumberScreen from 'pages/Auth/newUi/MobileNumberScreen'
import PasswordScreen from 'pages/Auth/newUi/PasswordScreen'
import ResetPasswordNew from 'pages/Auth/newUi/ResetPasswordNew'
import OTPScreen from 'pages/Auth/newUi/OTPScreen'
import CreateAccountNew from 'pages/Auth/newUi/CreateAccountNew'
import {
  clearRewardPointsData,
  fintechStatusChange,
} from 'redux/checkout/CheckoutSlice'
import { clearOwnReviewData } from 'redux/reviews/reviewsSlice'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import FinboxStatusCard from 'components/FinboxStatusCard'
import {
  checkFinboxUserExists,
  checkFinvervUserExists,
  finboxPartnerPush,
  getFinboxBanner,
  getFinboxCreditLine,
  getFinboxEligibility,
  getFinboxTransactions,
  getFinboxUrl,
  getFinvervApplicationStatus,
  getFinvervLines,
  getFinvervUrl,
  registeFinboxUser,
  registeFinvervUser,
} from 'services/api_calls/checkout/checkoutApi'
import LoginAlertCard from 'components/LoginAlertCard'
import moment from 'moment'
import ModalCard1 from 'components/ModalCard1'
import { clearResellerDetails } from 'redux/affiliate/AffiliateSlice'
import CloseIcon from '@mui/icons-material/Close'
import { navBarBorderColor } from '../../configs/styles/muiThemes'

const businessId = sessionStorage.getItem('businessId')

const marqueX = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(40%);
  }`

const MobileNavbar = ({
  value,
  onMenuClick,
  onLoginClick,
  cartCount,
  searchQuery,
  onChangeText,
  onSearch,
  logoUrl,
  isLoggedIn,
  businessName,
  allProducts,
  commercialDetails,
  isBusinessClosed,
  onLogOut,
  rewardpoints,
  dealsCouponsDetails,
  handleChatClick,
  setFinvervModal,
  finboxOverdueDetails,
  isFinvervAvailable,
  accountDetails,
  setLoginPopUp,
  setFinvervModal1,
  businessAffiliateData,
  rellerProfileData,
}) => {
  const navigate = useNavigate()
  const [searchVisible, setSearchVisible] = useState(false)
  let businessFullName = sessionStorage.getItem('businessFullName')
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = (event) => {
    if (window.scrollY > 100) {
      setSearchVisible(false)
    }
  }

  return (
    <>
      <AppBar
        sx={{
          boxShadow: 2,
          background: whiteColor,
          boxShadow: 'none !important',
          borderBottom: '1px solid ' + breadcrumbBgColor,
        }}
        position='static'
      >
        <Box sx={styles.topBar}>
          <Typography
            component='p'
            textAlign='center'
            color={whiteColor}
            sx={{
              // display: 'inline-block',
              whiteSpace: 'nowrap',
              fontSize: 12,
              fontWeight: 600,
              animation: `${marqueX} 30s linear infinite`,
              animationDirection: 'reverse',
            }}
          >
            {navBar_BuyOnlineMsg}
            {commercialDetails?.info?.rewardPointPercent
              ? ` • ${commercialDetails?.info?.rewardPointPercent}% Cashback on
                    all orders!`
              : ''}{' '}
            {commercialDetails?.info?.immediateDiscount
              ? ` • Free Shipping Above ₹${commercialDetails?.info?.immediateDiscount}!`
              : ''}
            {dealsCouponsDetails?.length > 0
              ? ` • Coupons ${dealsCouponsDetails?.map(
                  (item) => item.resellerCode
                )}`
              : ''}
            {/* {commercialDetails?.info?.fintech?.active ? (
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/new.png'
                width='30px'
                height='auto'
                alt={businessFullName + ' CostBo'}
                sx={{ mr: 1, ml: 2.5 }}
              />
            ) : null}
            {commercialDetails?.info?.fintech?.active
              ? `Instant credit for orders above ₹ ${commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit}`
              : null} */}
          </Typography>
        </Box>
        <Grid container spacing={1} px={1.3} pt={1.3}>
          <Grid xs={12} container justifyContent='space-between'>
            <Stack direction='row'>
              <IconButton
                size='large'
                edge='start'
                color='inherit'
                aria-label='menu'
                sx={{ ml: 0.6 }}
                onClick={() => onMenuClick()}
              >
                <MenuIcon sx={{ color: navHeaderColor, fontSize: '24px' }} />
              </IconButton>

              {logoUrl ? (
                <IconButton
                  onClick={() => {
                    if (commercialDetails?.info?.costboPowered?.domainURL) {
                      window.open(
                        commercialDetails?.info?.costboPowered?.domainURL,
                        '_self'
                      )
                    } else {
                      navigate(`/${businessName}`)
                    }
                  }}
                >
                  <img
                    src={logoUrl}
                    alt={businessFullName + ' CostBo'}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '6px',
                    }}
                  />
                </IconButton>
              ) : (
                <Box
                  sx={{
                    width: '40px',
                    height: '40px',
                    ...skeletonBox.boxAnim,
                    backgroundColor: '#e8e8e8',
                  }}
                />
              )}
            </Stack>
            <Stack direction='row' spacing={1.5} alignItems='center'>
              {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
                businessAffiliateData?.businessResellers?.length > 0 && (
                  <AffiliateButton
                    handleClick={() => {
                      navigate(
                        rellerProfileData?.details
                          ? `/${businessName}/affiliateHome`
                          : `/${businessName}/affiliates`,
                        {
                          state: { disableAutoNav: true },
                        }
                      )
                    }}
                    businessName={businessName}
                  />
                )}

              {/* <ChatIconButton
                handleClick={handleChatClick}
                businessName={businessName}
              /> */}

              {!searchVisible ? (
                <IconButton
                  sx={{
                    background: backgroundColor,
                    p: 1,
                    height: '40px',
                    weight: '40px',
                    '&.Mui-disabled': {
                      bgcolor: `${backgroundColor} !important`,
                      border: 'none !important',
                    },
                  }}
                  onClick={() => setSearchVisible(true)}
                  disabled={allProducts?.productList?.length === 0}
                >
                  <SearchIcon
                    sx={{
                      color:
                        allProducts?.productList?.length === 0
                          ? offerTextColor
                          : headingColor,
                    }}
                  />
                </IconButton>
              ) : null}

              <Cart
                cartCount={cartCount}
                businessName={businessName}
                disabled={
                  commercialDetails?.info?.commerceEnable == false ||
                  allProducts?.productList?.length == 0 ||
                  isBusinessClosed
                }
              />
              <Box
                onClick={() => (isLoggedIn ? () => {} : onLoginClick())}
                sx={{ height: '40px', weight: '40px' }}
              >
                {/* <Avatar
                  alt='User'
                  src={
                    isLoggedIn
                      ? geProfilePhoto()
                      : 'https://storage.googleapis.com/bodefaults/shopweb/user.png'
                  }
                /> */}
                {isLoggedIn ? (
                  <Profile
                    onLoginClick={onLoginClick}
                    isLoggedIn={isLoggedIn}
                    hideName
                    businessName={businessName}
                    onLogOut={onLogOut}
                  />
                ) : (
                  <IconButton sx={{ background: backgroundColor, p: 1.1 }}>
                    <PersonOutlineRounded
                      sx={{
                        fontSize: '23px',
                        color: primaryColor,
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            </Stack>
          </Grid>
          <Grid xs={12} item>
            {searchVisible ? (
              <Grid xs={12} item>
                <SearchBar
                  value={searchQuery}
                  onChangeText={(text) => {
                    onChangeText(text)
                  }}
                  onSearch={onSearch}
                  // disabled={
                  //   commercialDetails?.info?.commerceEnable == false ||
                  //   allProducts?.productList?.length == 0
                  // }
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {!isLoggedIn && commercialDetails?.info?.fintech?.active ? (
          <FinboxStatusCard
            msg={applyMsg}
            img={applyImg}
            btnName='Apply Here'
            onBtnClick={() => setLoginPopUp(true)}
          />
        ) : null}
        {isLoggedIn ? (
          <>
            {isFinvervAvailable === 'APPLY' ? (
              <FinboxStatusCard
                msg={applyMsg}
                img={applyImg}
                btnName='Apply Here'
                onBtnClick={() => {
                  getFinboxUrl({
                    redirectURL: window.location.href,
                  }).then((resp) => {
                    console.log('resprespresp', resp)
                    if (resp?.data?.url) {
                      setFinvervModal({
                        show: true,
                        url: resp?.data?.url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'notRegisteredFinbox' ? (
              <FinboxStatusCard
                msg={applyMsg}
                img={applyImg}
                btnName='Apply Here'
                onBtnClick={() => {
                  const phoneNumber = getPhoneNumber()
                  const customerID = getProfileId()
                  const name = accountDetails?.account?.name
                  const email = accountDetails?.account?.email
                  console.log('Fintech', phoneNumber?.replace('+91', ''))
                  registeFinboxUser({
                    customerID,
                    mobile: phoneNumber?.replace('+91', ''),
                  }).then((res) => {
                    finboxPartnerPush({ customerID, name, email }).then(() => {
                      getFinboxEligibility().then(() => {
                        getFinboxUrl({
                          redirectURL: window.location.href,
                        }).then((resp) => {
                          console.log('resprespresp', resp)
                          if (resp?.data?.url) {
                            setFinvervModal({
                              show: true,
                              url: resp?.data?.url,
                            })
                          }
                        })
                      })
                    })
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'INCOMPLETE' ? (
              <FinboxStatusCard
                msg={incompleteMsg}
                img={incompleteImg}
                btnName='Continue here'
                onBtnClick={() => {
                  getFinboxUrl({
                    redirectURL: window.location.href,
                  }).then((resp) => {
                    console.log('resprespresp', resp)
                    if (resp?.data?.url) {
                      setFinvervModal({
                        show: true,
                        url: resp?.data?.url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'APPROVED' ? (
              <FinboxStatusCard
                msg={approvedMsg}
                img={approvedImg}
                btnName='Continue here'
                onBtnClick={() => {
                  getFinboxUrl({
                    redirectURL: window.location.href,
                  }).then((resp) => {
                    console.log('resprespresp', resp)
                    if (resp?.data?.url) {
                      setFinvervModal({
                        show: true,
                        url: resp?.data?.url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'REJECTED' ? (
              <FinboxStatusCard
                msg={rejectedMsg}
                img={rejectedImg}
                btnName='Click here for details'
                onBtnClick={() => {
                  getFinboxUrl({
                    redirectURL: window.location.href,
                  }).then((resp) => {
                    console.log('resprespresp', resp)
                    if (resp?.data?.url) {
                      setFinvervModal({
                        show: true,
                        url: resp?.data?.url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'ACTIVE' ? (
              <FinboxStatusCard
                msg={activeMsg}
                img={applyImg}
                btnName='Click here for details'
                onBtnClick={() => {
                  getFinboxUrl({
                    redirectURL: window.location.href,
                  }).then((resp) => {
                    console.log('resprespresp', resp)
                    if (resp?.data?.url) {
                      setFinvervModal({
                        show: true,
                        url: resp?.data?.url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'DUE' ? (
              <FinboxStatusCard
                msg={dueMsg}
                img={dueImg}
                btnName='Click here for details'
                onBtnClick={() => {
                  getFinboxUrl({
                    redirectURL: window.location.href,
                  }).then((resp) => {
                    console.log('resprespresp', resp)
                    if (resp?.data?.url) {
                      setFinvervModal({
                        show: true,
                        url: resp?.data?.url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'OVERDUE' && finboxOverdueDetails?.amt ? (
              <FinboxStatusCard
                msg={`${overDueMsg}. Amount:  ₹${
                  finboxOverdueDetails?.amt
                }    Due date: ${moment(finboxOverdueDetails?.date).format(
                  'DD-MM-YYYY'
                )}`}
                img={overDueImg}
                btnName='Click here for details'
                onBtnClick={() => {
                  getFinboxUrl({
                    redirectURL: window.location.href,
                  }).then((resp) => {
                    console.log('resprespresp', resp)
                    if (resp?.data?.url) {
                      setFinvervModal({
                        show: true,
                        url: resp?.data?.url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'notRegistered' ? (
              <FinboxStatusCard
                msg={applyMsg}
                img={applyImg}
                btnName='Apply Here'
                onBtnClick={() => {
                  const phoneNumber = getPhoneNumber()
                  console.log('Fintech', phoneNumber?.replace('+91', ''))
                  registeFinvervUser({
                    mobile: phoneNumber?.replace('+91', ''),
                  }).then((res) => {
                    getFinvervUrl().then((resp) => {
                      if (resp?.redirect_url) {
                        setFinvervModal1({
                          show: true,
                          url: resp?.redirect_url,
                        })
                      }
                    })
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'notCompleted' ? (
              <FinboxStatusCard
                msg={incompleteMsg}
                img={incompleteImg}
                btnName='Continue here'
                onBtnClick={() => {
                  getFinvervUrl().then((resp) => {
                    if (resp?.redirect_url) {
                      setFinvervModal1({
                        show: true,
                        url: resp?.redirect_url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'balanceAvailable' ? (
              <FinboxStatusCard
                msg={activeMsg}
                img={applyImg}
                btnName='Click here for details'
                onBtnClick={() => {
                  getFinvervUrl().then((resp) => {
                    if (resp?.redirect_url) {
                      setFinvervModal1({
                        show: true,
                        url: resp?.redirect_url,
                      })
                    }
                  })
                }}
              />
            ) : null}
            {isFinvervAvailable === 'rejected' ? (
              <FinboxStatusCard
                msg={rejectedMsg}
                img={rejectedImg}
                btnName='Click here for details'
                onBtnClick={() => {
                  getFinvervUrl().then((resp) => {
                    if (resp?.redirect_url) {
                      setFinvervModal1({
                        show: true,
                        url: resp?.redirect_url,
                      })
                    }
                  })
                }}
              />
            ) : null}
          </>
        ) : null}

        {/* {allProducts && commercialDetails ? (
          allProducts?.productList?.length == 0 ||
          commercialDetails?.info?.commerceEnable ? (
            commercialDetails?.info?.hyperlocalStore ? (
              !commercialDetails?.info?.hyperlocalStore?.storeOpenStatus ? (
                <Box
                  backgroundColor={primaryColor}
                  p={1}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Box
                    component='img'
                    src='https://storage.googleapis.com/bodefaults/shopweb/OrderTemporarily.svg'
                    width='30px'
                    height='auto'
                    alt={businessFullName + ' CostBo'}
                    sx={{ mr: 1.5 }}
                  />
                  <Typography
                    variant='subtitle1'
                    fontSize={13}
                    color={whiteColor}
                  >
                    {commercialDetails?.info?.hyperlocalStore?.reason}
                  </Typography>
                </Box>
              ) : null
            ) : null
          ) : (
            <Box
              backgroundColor={primaryColor}
              p={1}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/OrderTemporarily.svg'
                width='30px'
                height='auto'
                alt={businessFullName + ' CostBo'}
                sx={{ mr: 1.5 }}
              />
              <Typography variant='subtitle1' fontSize={13} color={whiteColor}>
                {onlineOrderClosedMsg}
              </Typography>
            </Box>
          )
        ) : null} */}
      </AppBar>
      <Box
        sx={{
          background: whiteColor,
          zIndex: 4,
          p: 0.5,
          width: '100%',
          position: 'fixed',
          bottom: 0,
          left: 0,
          boxShadow: '0 -5px 15px 0 rgba(0, 0, 0, 0.1)',
        }}
      >
        <BottomNavigation showLabels value={value} sx={styles.bottomTabBox}>
          <BottomNavigationAction
            component={Link}
            to={`/${businessName}`}
            label={
              <Typography
                variant='subtitle2'
                color={value === 'home' ? primaryColor : navLinkColor}
                fontSize='11px'
              >
                {navBar_MenuHome}
              </Typography>
            }
            value='home'
            icon={<StorefrontOutlinedIcon sx={styles.bottomTabIcon} />}
            sx={styles.bottomTabStyles}
          />
          <BottomNavigationAction
            component={Link}
            to={
              allProducts?.productList?.length !== 0
                ? `/${businessName}/catalog`
                : '#'
            }
            // to={`/${businessName}/catalog`}
            label={
              <Typography
                variant='subtitle2'
                color={
                  allProducts?.productList?.length == 0
                    ? offerTextColor
                    : value === 'catalog'
                    ? primaryColor
                    : navLinkColor
                }
                // color={value === 'catalog' ? primaryColor : navLinkColor}
                fontSize='11px'
              >
                {navBar_MenuCatalog}
              </Typography>
            }
            value='catalog'
            icon={
              <ShoppingCartIcon
                sx={{
                  color:
                    allProducts?.productList?.length == 0
                      ? offerTextColor
                      : value === 'catalog'
                      ? primaryColor
                      : navLinkColor,
                  // color: value === 'catalog' ? primaryColor : navLinkColor,
                  ...styles.bottomTabIcon,
                }}
              />
            }
            // disabled={allProducts?.productList?.length == 0 || isBusinessClosed}
            sx={styles.bottomTabStyles}
          />
          <BottomNavigationAction
            component={Link}
            // to={
            //   allProducts?.productList?.length !== 0 && !isBusinessClosed
            //     ? `/${businessName}/dealsCoupons`
            //     : '#'
            // }
            to={`/${businessName}/dealsCoupons`}
            label={
              <Typography
                variant='subtitle2'
                // color={
                //   allProducts?.productList?.length == 0 || isBusinessClosed
                //     ? offerTextColor
                //     : value === 'coupons'
                //     ? primaryColor
                //     : navLinkColor
                // }
                color={value === 'coupons' ? primaryColor : navLinkColor}
                fontSize='11px'
              >
                {navBar_MenuCounpons}
              </Typography>
            }
            value='coupons'
            icon={
              <SellIcon
                sx={{
                  // color:
                  //   allProducts?.productList?.length == 0 || isBusinessClosed
                  //     ? offerTextColor
                  //     : value === 'coupons'
                  //     ? primaryColor
                  //     : navLinkColor,
                  color: value === 'coupons' ? primaryColor : navLinkColor,
                  ...styles.bottomTabIcon,
                }}
              />
            }
            // disabled={allProducts?.productList?.length == 0 || isBusinessClosed}
            sx={styles.bottomTabStyles}
          />
          <BottomNavigationAction
            component={Link}
            to={`/${businessName}/overview`}
            label={
              <Typography
                variant='subtitle2'
                color={value === 'overview' ? primaryColor : navLinkColor}
                fontSize='11px'
              >
                {navBar_MenuOverview}
              </Typography>
            }
            value='overview'
            icon={<RemoveRedEyeIcon sx={styles.bottomTabIcon} />}
            sx={styles.bottomTabStyles}
          />
          <BottomNavigationAction
            component={Link}
            // to={
            //   allProducts?.productList?.length !== 0 && !isBusinessClosed
            //     ? `/${businessName}/review`
            //     : '#'
            // }
            to={`/${businessName}/review`}
            label={
              <Typography
                variant='subtitle2'
                // color={
                //   allProducts?.productList?.length == 0 || isBusinessClosed
                //     ? offerTextColor
                //     : value === 'reviews'
                //     ? primaryColor
                //     : navLinkColor
                // }
                color={value === 'reviews' ? primaryColor : navLinkColor}
                fontSize='11px'
              >
                {navBar_MenuReview}
              </Typography>
            }
            value='reviews'
            icon={
              <ReviewsIcon
                sx={{
                  // color:
                  //   allProducts?.productList?.length == 0 || isBusinessClosed
                  //     ? offerTextColor
                  //     : value === 'reviews'
                  //     ? primaryColor
                  //     : navLinkColor,
                  color: value === 'reviews' ? primaryColor : navLinkColor,
                  ...styles.bottomTabIcon,
                }}
              />
            }
            // disabled={allProducts?.productList?.length == 0 || isBusinessClosed}
            sx={styles.bottomTabStyles}
          />
        </BottomNavigation>
      </Box>
    </>
  )
}

function Navbar(props) {
  const { productId } = useParams()
  const dispatch = useDispatch()

  const [query, setQuery] = useState('')

  const { accountDetails } = useSelector((state) => state.dashboard)
  const { cartDetails } = useSelector((state) => state.cart)
  const { allProducts } = useSelector((state) => state.subCategory)
  const { businessId } = useSelector((state) => state.rootSlice)

  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [emailId, SetEmailId] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [idToken, setIdToken] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [currentPage, setCurrentPage] = useState('mobile-number')
  // const [currentPage, setCurrentPage] = useState('registerDetails')
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [showSearchBox, setShowSearchBox] = useState(false)

  const [userType, setUserType] = useState('')
  const name = getUserName()

  const { loading } = useSelector((state) => state.auth)

  const [totalQty, setTotalQty] = useState(0)
  const { updateQty, localCartData, createCartRes, updateCartIntRes } =
    useSelector((state) => state.cartupdateadd)
  const [commerceInfo, setCommerceInfo] = useState(null)

  const { profile, loginDetails } = useSelector((state) => state.auth)
  const { commercialDetails } = useSelector((state) => state.product)

  const { dealsCouponsDetails } = useSelector((state) => state.coupons)

  const { businessDetails } = useSelector((state) => state.header)
  const [finvervModal, setFinvervModal] = useState({ show: false, url: '' })

  const businessName = sessionStorage.getItem('businessName')
  let businessFullName = sessionStorage.getItem('businessFullName')

  const [isFinvervAvailable, setIsFinvervAvailable] = useState('')
  const [finAvailValue, setFinAvailValue] = useState(null)
  const [finPercentComplete, setFinPercentComplete] = useState(null)
  const [finboxAvlAmt, setFinboxAvlAmt] = useState(null)
  const [loginPopUp, setLoginPopUp] = useState(false)
  const [finboxOverdueDetails, setFinboxOverdueDetails] = useState(null)
  const [finvervModal1, setFinvervModal1] = useState({ show: false, url: '' })

  const [isBusinessClosed, setIsBusinessClosed] = useState(
    commercialDetails?.info?.hyperlocalStore
      ? !commercialDetails?.info?.hyperlocalStore?.storeOpenStatus
      : false
  )

  // const [fintechType]
  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  )

  // const [lat, setLat] = useState(null)
  // const [lng, setLng] = useState(null)
  // const [accuracy, setAccuracy] = useState(null)
  // const [area, setArea] = useState(null)
  // const [status, setStatus] = useState(null)

  // const getLocation = () => {
  //   if (!navigator.geolocation) {
  //     setStatus('Geolocation is not supported by your browser')
  //   } else {
  //     setStatus('Locating...')
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setStatus(null)
  //         setLat(position.coords.latitude)
  //         setLng(position.coords.longitude)
  //         setAccuracy(position.coords.accuracy)
  //         setArea(position.coords.speed)
  //       },
  //       () => {
  //         setStatus('Unable to retrieve your location')
  //       }
  //     )
  //   }
  // }
  // useEffect(() => {
  //   getLocation()
  // }, [])

  let navigate = useNavigate()

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 && getAccessToken() === null ? false : true
  )

  useEffect(() => {
    dispatch(checkUserLogin())
  }, [])

  const getFinboxBannerData = () => {
    getFinboxBanner().then((res) => {
      dispatch(fintechStatusChange(res))
      if (res?.data?.banner) {
        setIsFinvervAvailable(res?.data?.banner)
        if (res?.data?.banner === 'ACTIVE') {
          getFinboxCreditLine().then((resp) => {
            if (resp?.data?.availableLimit) {
              setFinboxAvlAmt(resp?.data?.availableLimit)
            }
          })
        }
        if (res?.data?.banner === 'OVERDUE') {
          getFinboxTransactions().then((resp) => {
            if (resp?.data?.transactions) {
              const overdueList = resp?.data?.transactions?.filter(
                (item) => item?.txnStatus === 'OVERDUE'
              )
              const overdueAmt = overdueList?.reduce(
                (total, cur) => total + cur?.emis?.[0]?.totalPayable,
                0
              )
              let overdueDate = []
              overdueList?.forEach((element) => {
                overdueDate.push(moment(element?.emis?.[0]?.dueDate))
              })
              const minDate = moment.min(overdueDate)
              console.log(
                'overdueAmt',
                overdueAmt,
                overdueList,
                minDate,
                overdueDate
              )
              setFinboxOverdueDetails({ amt: overdueAmt, date: minDate })
            }
          })
        }
      }
    })
  }

  const getFinvervLinesData = () => {
    getFinvervLines()
      .then((resp) => {
        dispatch(fintechStatusChange(resp))
        if (resp) {
          if (!resp?.line_id || !resp?.line_status) {
            // getFinvervApplicationStatus().then((res) => {
            //   console.log('fffff', res)
            //   setFinPercentComplete(res?.data?.percent_complete)
            // })
            setIsFinvervAvailable('notCompleted')
          }
        }

        if (resp?.line_status === 'Under Process' && resp?.line_id) {
          console.log('ssclcmslcmlc')
          setIsFinvervAvailable('notCompleted')
          setFinAvailValue(resp)
        }
        if (
          (resp?.line_status === 'Rejected' ||
            resp?.line_status === 'Blocked') &&
          resp?.line_id
        ) {
          console.log('ssclcmslcmlc')
          setIsFinvervAvailable('rejected')
          setFinAvailValue(resp)
        }
        if (resp?.line_status === 'Approved') {
          setIsFinvervAvailable('balanceAvailable')
          setFinAvailValue(resp)
        }
      })
      .catch(() => setIsFinvervAvailable(''))
  }

  useEffect(() => {
    if (props.showLoginForm) {
      if (!login) setLogin(true)
    }
  }, [props.showLoginForm])

  useEffect(() => {
    if (loginDetails) {
      dispatch(getAccountDetails())
    }
  }, [loginDetails])

  useEffect(() => {
    if (createCartRes) {
      dispatch(getCartDetails())
    }
  }, [createCartRes])

  useEffect(() => {
    const finboxRes = JSON.parse(sessionStorage.getItem('finboxStatus'))
    if (finboxRes?.data?.banner) {
      setIsFinvervAvailable(finboxRes?.data?.banner)
      if (finboxRes?.data?.banner === 'OVERDUE') {
        getFinboxTransactions().then((resp) => {
          if (resp?.data?.transactions) {
            const overdueList = resp?.data?.transactions?.filter(
              (item) => item?.txnStatus === 'OVERDUE'
            )
            const overdueAmt = overdueList?.reduce(
              (total, cur) => total + cur?.emis?.[0]?.totalPayable,
              0
            )
            let overdueDate = []
            overdueList?.forEach((element) => {
              overdueDate.push(moment(element?.emis?.[0]?.dueDate))
            })
            const minDate = moment.min(overdueDate)
            console.log(
              'overdueAmt',
              overdueAmt,
              overdueList,
              minDate,
              overdueDate
            )
            setFinboxOverdueDetails({ amt: overdueAmt, date: minDate })
          }
        })
      }
      // if (res?.data?.banner === 'ACTIVE') {
      //   getFinboxCreditLine().then((resp) => {
      //     if (resp?.data?.availableLimit) {
      //       setFinboxAvlAmt(resp?.data?.availableLimit)
      //     }
      //   })
      // }
    }
    const finbox = sessionStorage.getItem('finboxUser')
    if (finbox === 'notRegisteredFinbox') {
      setIsFinvervAvailable('notRegisteredFinbox')
    }
    const finvervRes = JSON.parse(sessionStorage.getItem('finvervStatus'))
    const finverv = sessionStorage.getItem('finvervUser')
    if (finverv === 'notRegistered') {
      setIsFinvervAvailable('notRegistered')
    }
    if (finverv === 'notCompleted') {
      setIsFinvervAvailable('notCompleted')
    }
    if (finvervRes) {
      if (!finvervRes?.line_id || !finvervRes?.line_status) {
        getFinvervApplicationStatus().then((res) => {
          console.log('fffff', res)
          setFinPercentComplete(res?.data?.percent_complete)
        })
        setIsFinvervAvailable('notCompleted')
      }
    }

    if (finvervRes?.line_status === 'Under Process' && finvervRes?.line_id) {
      console.log('ssclcmslcmlc')
      setIsFinvervAvailable('notCompleted')
      setFinAvailValue(finvervRes)
    }
    if (
      (finvervRes?.line_status === 'Rejected' ||
        finvervRes?.line_status === 'Blocked') &&
      finvervRes?.line_id
    ) {
      console.log('ssclcmslcmlc')
      setIsFinvervAvailable('rejected')
      setFinAvailValue(finvervRes)
    }
    if (finvervRes?.line_status === 'Approved') {
      setIsFinvervAvailable('balanceAvailable')
      setFinAvailValue(finvervRes)
    }
  }, [])
  useEffect(() => {
    window.addEventListener('storage', () => {
      const finboxRes = JSON.parse(sessionStorage.getItem('finboxStatus'))
      if (finboxRes?.data?.banner) {
        setIsFinvervAvailable(finboxRes?.data?.banner)
        if (finboxRes?.data?.banner === 'OVERDUE') {
          getFinboxTransactions().then((resp) => {
            if (resp?.data?.transactions) {
              const overdueList = resp?.data?.transactions?.filter(
                (item) => item?.txnStatus === 'OVERDUE'
              )
              const overdueAmt = overdueList?.reduce(
                (total, cur) => total + cur?.emis?.[0]?.totalPayable,
                0
              )
              let overdueDate = []
              overdueList?.forEach((element) => {
                overdueDate.push(moment(element?.emis?.[0]?.dueDate))
              })
              const minDate = moment.min(overdueDate)
              console.log(
                'overdueAmt',
                overdueAmt,
                overdueList,
                minDate,
                overdueDate
              )
              setFinboxOverdueDetails({ amt: overdueAmt, date: minDate })
            }
          })
        }
      }
      const finvervRes = JSON.parse(sessionStorage.getItem('finvervStatus'))
      if (finvervRes) {
        if (!finvervRes?.line_id || !finvervRes?.line_status) {
          getFinvervApplicationStatus().then((res) => {
            console.log('fffff', res)
            setFinPercentComplete(res?.data?.percent_complete)
          })
          setIsFinvervAvailable('notCompleted')
        }
      }
      if (finvervRes?.line_status === 'Under Process' && finvervRes?.line_id) {
        console.log('ssclcmslcmlc')
        setIsFinvervAvailable('notCompleted')
        setFinAvailValue(finvervRes)
      }
      if (
        (finvervRes?.line_status === 'Rejected' ||
          finvervRes?.line_status === 'Blocked') &&
        finvervRes?.line_id
      ) {
        console.log('ssclcmslcmlc')
        setIsFinvervAvailable('rejected')
        setFinAvailValue(finvervRes)
      }
      if (finvervRes?.line_status === 'Approved') {
        setIsFinvervAvailable('balanceAvailable')
        setFinAvailValue(finvervRes)
      }
    })
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setLoginData())
      dispatch(getCartDetails(businessId)).then((carRes) => {
        sessionStorage.setItem(
          'cartData',
          JSON.stringify(carRes?.payload?.detail)
        )
      })
    }
  }, [businessId])

  useEffect(() => {
    if (localCartData) {
      setTotalQty(localCartData?.totalQuantity)
    }
  }, [localCartData])

  useEffect(() => {
    const currentOS = getOperatingSystem(window)
    const currentBrowser = getBrowser(window)
    businessFullName = sessionStorage.getItem('businessFullName')
    const busId = sessionStorage.getItem('businessId')

    // const lat = 12.9634
    // const long = 77.5855

    const lat = localStorage.getItem('lat') || LATITUDE
    const long = localStorage.getItem('long') || LONGITUDE

    if (lat && long) {
      if (isLoggedIn) {
        const profileId = getProfileId()
        dispatch(getCartDetails(businessId))

        if (busId && !productId) {
          dispatch(
            addActivityWithLogin({
              profileId: profileId,
              businessId: busId,
              activityName: 'viewActiveBusiness',
              browserType: currentBrowser,
              deviceOs: currentOS,
              latitude: lat,
              longitude: long,
              productId: null,
              topicId: null,
              validActivity: 1,
            })
          )
        }
      } else {
        let bid = busId
        getIpAddress().then((res) => {
          if (bid && !productId) {
            dispatch(
              addActivityWithOutLogin({
                profileId: `WithoutLoginUser${res?.ip}${bid}`,
                businessId: bid,
                activityName: 'viewActiveBusiness',
                browserType: currentBrowser,
                deviceOs: currentOS,
                latitude: lat,
                longitude: long,
                productId: null,
                topicId: null,
                validActivity: 1,
              })
            )
          }
        })

        if (!allProducts) {
          dispatch(
            getCatalogAllProducts({
              pageNo: 1,
              discountHightToLow: false,
              priceLowoHig: false,
              highPrice: false,
              business: false,
            })
          )
        }
      }
    }

    // dispatch(getLocationDetails()).then((res) => {
    //   console.log('ljbnljb ljb l', res)

    //   const lat = res?.payload?.lat
    //   const long = res?.payload?.lon
    //   if (isLoggedIn) {
    //     const profileId = getProfileId()
    //     // dispatch(getAccountDetails())
    //     dispatch(getCartDetails(businessId))

    //     if (businessId?.businessId && !productId) {
    //       dispatch(
    //         addActivityWithLogin({
    //           profileId: profileId,
    //           businessId: businessId?.businessId,
    //           activityName: 'viewActiveBusiness',
    //           browserType: currentBrowser,
    //           deviceOs: currentOS,
    //           latitude: lat,
    //           longitude: long,
    //           productId: null,
    //           topicId: null,
    //           validActivity: 1,
    //         })
    //       )
    //     }
    //   } else {
    //     let bid = businessId?.businessId
    //     getIpAddress().then((res) => {
    //       if (bid && !productId) {
    //         dispatch(
    //           addActivityWithOutLogin({
    //             profileId: `WithoutLoginUser${res?.ip}${bid}`,
    //             businessId: businessId?.businessId,
    //             activityName: 'viewActiveBusiness',
    //             browserType: currentBrowser,
    //             deviceOs: currentOS,
    //             latitude: lat,
    //             longitude: long,
    //             productId: null,
    //             topicId: null,
    //             validActivity: 1,
    //           })
    //         )
    //       }

    //       console.log('uglikcicujtgdcut', res)
    //     })

    //     dispatch(
    //       getCatalogAllProducts({
    //         pageNo: 1,
    //         discountHightToLow: false,
    //         priceLowoHig: false,
    //         highPrice: false,
    //         business: false,
    //       })
    //     )
    //   }
    // })

    if (props.value !== 'catalog') {
      dispatch(clearSearchData())
    }
  }, [businessId])

  useEffect(() => {
    let count = 0

    const sessionCartData = sessionStorage.getItem('cartData')
    const cartProductData =
      sessionCartData === 'undefined' ? null : JSON.parse(sessionCartData)

    if (cartProductData && cartProductData.productList) {
      cartProductData.productList.forEach((item) => {
        count = count + item.currentQuantity
      })
    }
    setTotalQty(count)
  }, [cartDetails, updateQty])

  useEffect(() => {
    const commerceData = JSON.parse(sessionStorage.getItem('commerceInfo'))
    if (commerceData) {
      setCommerceInfo(commerceData)
      setIsBusinessClosed(
        commerceData?.info?.hyperlocalStore
          ? !commerceData?.info?.hyperlocalStore?.storeOpenStatus
          : false
      )
    }
  }, [commercialDetails])

  const handleSearchQuery = (value) => {
    setQuery(value)
    dispatch(setSearchQuery(value))
  }

  const handleSearch = () => {
    dispatch(
      searchProducts({
        key: query,
        pageNo: 0,
      })
    ).then(() => {
      const currentOS = getOperatingSystem(window)
      const currentBrowser = getBrowser(window)
      // const lat = 12.9634
      // const long = 77.5855

      const lat = localStorage.getItem('lat') || LATITUDE
      const long = localStorage.getItem('long') || LONGITUDE

      if (isLoggedIn) {
        const profileId = getProfileId()
        getIpAddress().then((res) => {
          dispatch(
            addSearchInfoAPI({
              profileId: profileId,
              deviceId: '44544646fgy54rthgr',
              ip: res?.ip,
              searchText: query,
              deviceOS: currentOS,
              deviceName: 'lap',
              sessionId: 'e4454',
              latitude: lat,
              longitude: long,
            })
          )
        })
      } else {
        getIpAddress().then((res) => {
          dispatch(
            addSearchInfoAPI({
              profileId: `WithoutLoginUser${res?.ip}`,
              deviceId: '44544646fgy54rthgr',
              ip: res?.ip,
              searchText: query,
              deviceOS: currentOS,
              deviceName: 'lap',
              sessionId: 'e4454',
              latitude: lat,
              longitude: long,
            })
          )
        })
      }
    })
    navigate(`/${businessName}/catalog`)
  }

  const handleChatClick = () => {
    if (!commercialDetails) return

    const whatsAppNumber =
      commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace('+', '')

    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore`,
        '_blank'
      )
    } else {
      window.fcWidget.open()
      window.fcWidget.show()
    }
  }

  return allProducts && commercialDetails ? (
    <Suspense fallback={<p></p>}>
      <Box
        sx={{ position: 'sticky', top: 0, background: whiteColor, zIndex: 10 }}
      >
        <Modal open={open} onClose={() => setOpen(false)} anchor='left'>
          <Sidebar
            onCloseClick={() => setOpen(false)}
            isLoggedIn={isLoggedIn}
            onLoginClick={() => {
              setLogin(true)
            }}
            onLogOut={() => {
              setLogin(false)
              setIsLoggedIn(false)
              setIsFinvervAvailable('')
              removeSessionInfo()

              dispatch(clearCartData())
              dispatch(clearRewardPointsData())
              dispatch(clearOwnReviewData())
              dispatch(clearLoginData())
              dispatch(updateIsLogin())
              dispatch(clearResellerDetails())
              navigate(`/${businessName}`)
              // window.location.replace(
              //   `${window.location.origin}/${businessName}`
              // )
              // navigate(0)
            }}
            // onSubCatSelected={props.onSubCatSelected}
            onSubCatSelected={props.onSubCatSelected}
            productCount={allProducts?.productList?.length}
            isBusinessClosed={isBusinessClosed}
          />
        </Modal>

        <ModalCard
          open={login}
          handleClose={() => {
            setLogin(false)
            setCurrentPage('mobile-number')
            setErrorMsg('')
          }}
          width='27%'
        >
          <AuthLayoutNew>
            {currentPage === 'mobile-number' ? (
              <MobileNumberScreen
                onContinue={(data) => {
                  setCurrentPage(data.page)
                  setPhoneNo(data.phone)
                }}
                setUserType={(data) => setUserType(data)}
              />
            ) : null}
            {currentPage === 'password' ? (
              <PasswordScreen
                mobileNumber={phoneNo}
                onChangeNumber={() => setCurrentPage('mobile-number')}
                onForgotClick={(email) => {
                  setCurrentPage('reset')
                  SetEmailId(email)
                }}
                onLoginSuccess={() => {
                  setIsLoggedIn(true)
                  dispatch(setLoginData())
                  dispatch(updateIsLogin())
                  dispatch(getProfileDetails()).then((response) => {
                    if (response?.payload?.status === 200) {
                      dispatch(getCartDetails(businessId)).then((res) => {
                        if (!res?.payload?.detail) {
                          let localCart = JSON.parse(
                            sessionStorage.getItem('cartData')
                          )
                          if (localCart) {
                            let newCartData = createCartHelper(localCart)
                            dispatch(createCart(newCartData))
                          }
                        } else {
                          let localCart = JSON.parse(
                            sessionStorage.getItem('cartData')
                          )
                          if (!localCart) {
                            sessionStorage.setItem(
                              'cartData',
                              JSON.stringify(res?.payload?.detail)
                            )
                          } else {
                            const updatedCart = updateMergeCartHelper(
                              res?.payload?.detail,
                              localCart
                            )
                            dispatch(updateCartDetails(updatedCart)).then(
                              (upRes) => {
                                if (upRes?.payload?.success) {
                                  dispatch(getCartDetails(businessId)).then(
                                    (carRes) => {
                                      sessionStorage.setItem(
                                        'cartData',
                                        JSON.stringify(carRes?.payload?.detail)
                                      )
                                    }
                                  )
                                }
                              }
                            )
                          }
                        }
                      })
                      if (
                        commercialDetails?.info?.fintech?.active &&
                        commercialDetails?.info?.fintech?.partners?.[0]
                          ?.name === 'finbox'
                      ) {
                        const phoneNumber = getPhoneNumber()
                        console.log('Fintech', phoneNumber?.replace('+91', ''))
                        dispatch(
                          checkExistingUser({
                            phone: phoneNumber?.replace('+91', ''),
                          })
                        ).then((response) => {
                          if (response.payload) {
                            if (response.payload.status === 200) {
                              if (response.payload.data.sr === false) {
                                checkFinboxUserExists().then((res) => {
                                  console.log('checkFinboxUserExists', res)
                                  if (res?.exist == false) {
                                    sessionStorage.setItem(
                                      'finboxUser',
                                      'notRegisteredFinbox'
                                    )
                                    console.log('checkFinboxUserExists1', res)
                                    setIsFinvervAvailable('notRegisteredFinbox')
                                    dispatch(fintechStatusChange(res))
                                  } else {
                                    getFinboxBannerData()
                                  }
                                })
                              }
                            }
                          }
                        })
                      }
                      if (
                        commercialDetails?.info?.fintech?.active &&
                        commercialDetails?.info?.fintech?.partners?.[0]
                          ?.name === 'finverv'
                      ) {
                        checkFinvervUserExists().then((res) => {
                          console.log('checkFinvervUserExists', res)
                          if (res?.exist == false) {
                            console.log('checkFinvervUserExists1', res)
                            setIsFinvervAvailable('notRegistered')
                            sessionStorage.setItem(
                              'finvervUser',
                              'notRegistered'
                            )
                            dispatch(fintechStatusChange(res))
                          } else {
                            getFinvervApplicationStatus().then((resp) => {
                              if (resp?.data?.percent_complete == 100) {
                                getFinvervLinesData()
                              } else {
                                setIsFinvervAvailable('notCompleted')
                                sessionStorage.setItem(
                                  'finvervUser',
                                  'notCompleted'
                                )
                                dispatch(fintechStatusChange(res))
                              }
                              // console.log('fffff', res)
                              // setFinPercentComplete(res?.data?.percent_complete)
                            })
                          }
                        })
                      }
                    }
                  })

                  setLogin(false)
                  setCurrentPage('mobile-number')
                  // props.handleNext()
                }}
              />
            ) : null}
            {currentPage === 'reset' ? (
              <ResetPasswordNew
                email={emailId}
                phone={phoneNo}
                onForgotpasswordSuccess={() => {
                  dispatch(getProfileDetails())
                  setLogin(false)
                  setIsLoggedIn(true)
                  setCurrentPage('mobile-number')
                  dispatch(setLoginData())
                }}
              />
            ) : null}
            {currentPage === 'otp' ? (
              <OTPScreen
                mobileNumber={phoneNo}
                userType={userType}
                onLoginSuccess={() => {
                  setIsLoggedIn(true)
                  dispatch(setLoginData())
                  dispatch(updateIsLogin())
                  dispatch(getProfileDetails()).then((response) => {
                    if (response?.payload?.status === 200) {
                      dispatch(getCartDetails(businessId)).then((res) => {
                        if (!res?.payload?.detail) {
                          let localCart = JSON.parse(
                            sessionStorage.getItem('cartData')
                          )
                          if (localCart) {
                            let newCartData = createCartHelper(localCart)
                            dispatch(createCart(newCartData))
                          }
                        } else {
                          let localCart = JSON.parse(
                            sessionStorage.getItem('cartData')
                          )
                          if (!localCart) {
                            sessionStorage.setItem(
                              'cartData',
                              JSON.stringify(res?.payload?.detail)
                            )
                          } else {
                            const updatedCart = updateMergeCartHelper(
                              res?.payload?.detail,
                              localCart
                            )
                            dispatch(updateCartDetails(updatedCart)).then(
                              (upRes) => {
                                if (upRes?.payload?.success) {
                                  dispatch(getCartDetails(businessId)).then(
                                    (carRes) => {
                                      sessionStorage.setItem(
                                        'cartData',
                                        JSON.stringify(carRes?.payload?.detail)
                                      )
                                    }
                                  )
                                }
                              }
                            )
                          }
                        }
                      })
                    }
                  })
                  setLogin(false)
                  setCurrentPage('mobile-number')
                  // props.handleNext()
                }}
                onChangeNumber={() => setCurrentPage('mobile-number')}
                onCreateAccount={(token) => {
                  setCurrentPage('create-account')
                  setIdToken(token)
                }}
              />
            ) : null}
            {currentPage === 'create-account' ? (
              <CreateAccountNew
                phone={phoneNo}
                token={idToken}
                userType={userType}
                onSuccess={() => {
                  dispatch(getProfileDetails()).then((response) => {
                    if (response?.payload?.status === 200) {
                      dispatch(getCartDetails(businessId)).then((res) => {
                        if (!res?.payload?.detail) {
                          let localCart = JSON.parse(
                            sessionStorage.getItem('cartData')
                          )
                          if (localCart) {
                            let newCartData = createCartHelper(localCart)
                            dispatch(createCart(newCartData))
                          }
                        } else {
                          let localCart = JSON.parse(
                            sessionStorage.getItem('cartData')
                          )
                          if (!localCart) {
                            sessionStorage.setItem(
                              'cartData',
                              JSON.stringify(res?.payload?.detail)
                            )
                          } else {
                            const updatedCart = updateMergeCartHelper(
                              res?.payload?.detail,
                              localCart
                            )
                            dispatch(updateCartDetails(updatedCart)).then(
                              (upRes) => {
                                if (upRes?.payload?.success) {
                                  dispatch(getCartDetails(businessId)).then(
                                    (carRes) => {
                                      sessionStorage.setItem(
                                        'cartData',
                                        JSON.stringify(carRes?.payload?.detail)
                                      )
                                    }
                                  )
                                }
                              }
                            )
                          }
                        }
                      })
                    }
                  })
                  dispatch(setLoginData())
                  setLogin(false)
                  setIsLoggedIn(true)
                  setCurrentPage('mobile-number')
                }}
              />
            ) : null}
          </AuthLayoutNew>
        </ModalCard>

        <ModalCard1
          handleClose={() => {
            getFinboxBannerData()
            setFinvervModal({ show: false, url: '' })
          }}
          open={finvervModal.show}
          width='30%'
          height='80vh'
        >
          <Box
            width='100%'
            minHeight='80vh'
            sx={{ borderRadius: '12px', backgroundColor: whiteColor }}
          >
            <iframe
              src={finvervModal.url.slice(0, -5) + 'true'}
              style={{
                border: 'none',
                borderRadius: '12px',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </ModalCard1>

        {/* Finverv Modal */}
        <ModalCard1
          handleClose={() => {
            getFinvervLinesData()
            setFinvervModal1({ show: false, url: '' })
          }}
          open={finvervModal1.show}
          width='30%'
          height='80vh'
        >
          <Box
            width='100%'
            minHeight='80vh'
            sx={{ borderRadius: '12px', backgroundColor: whiteColor }}
          >
            <iframe
              src={finvervModal1.url}
              style={{
                border: 'none',
                borderRadius: '12px',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </ModalCard1>

        <ModalCard
          open={loginPopUp}
          handleClose={() => {
            setLoginPopUp(false)
          }}
          width='23%'
        >
          <LoginAlertCard
            handleClose={() => {
              setLoginPopUp(false)
            }}
            onLoginClick={() => {
              setLoginPopUp(false)
              setLogin(true)
            }}
          />
        </ModalCard>

        {matches ? (
          <AppBar
            sx={{
              boxShadow: 1,
              background: whiteColor,
              boxShadow: 'none !important',
              borderBottom: `1px solid ${navBarBorderColor}`,
            }}
            position='static'
          >
            <Grid container>
              <Grid item xs={12}>
                <Box sx={styles.topBarStyles}>
                  <Container
                    sx={{
                      maxWidth: '1325px !important',
                    }}
                  >
                    <Box display='flex' alignItems='center'>
                      <Box
                        sx={{
                          width: '240px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          ml: '9px',
                        }}
                      >
                        <IconButton
                          size='large'
                          edge='start'
                          color='inherit'
                          aria-label='menu'
                          sx={{ p: '2px !important' }}
                          onClick={() => setOpen(true)}
                        >
                          <MenuIcon
                            sx={{ color: whiteColor, fontSize: '16px' }}
                          />
                        </IconButton>
                        <Typography
                          variant='h6'
                          color={whiteColor}
                          fontSize={13}
                          fontWeight={500}
                        >
                          {navBar_Menu}
                        </Typography>
                      </Box>

                      <Box sx={{ ...styles.topBar, flex: 1 }}>
                        <Typography
                          textAlign='center'
                          variant='h6'
                          fontSize='13px'
                          fontWeight={500}
                          color={whiteColor}
                          sx={{
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            animation: `${marqueX} 30s linear infinite`,
                            animationDirection: 'reverse',
                            transform: 'translateX(330px)',
                          }}
                        >
                          {navBar_BuyOnlineMsg}{' '}
                          {commercialDetails?.info?.rewardPointPercent ? (
                            <span style={{ marginLeft: '20px' }}>
                              • {commercialDetails?.info?.rewardPointPercent}%
                              Cashback on all orders!
                            </span>
                          ) : (
                            ''
                          )}
                          {commercialDetails?.info?.immediateDiscount ? (
                            <span style={{ marginLeft: '20px' }}>
                              • Free Shipping Above ₹
                              {commercialDetails?.info?.immediateDiscount}!
                            </span>
                          ) : (
                            ''
                          )}
                          {dealsCouponsDetails?.length > 0
                            ? dealsCouponsDetails?.map((item) => {
                                return (
                                  <span style={{ marginLeft: '20px' }}>
                                    • Get {item.discountPercentage}% Off, use
                                    coupon code {item.resellerCode}
                                  </span>
                                )
                              })
                            : ''}
                          {commercialDetails?.info?.fintech?.active ? (
                            <Box
                              component='img'
                              src='https://storage.googleapis.com/bodefaults/shopweb/new.png'
                              width='30px'
                              height='auto'
                              alt={businessFullName + ' CostBo'}
                              sx={{ mr: 1, ml: 2.5 }}
                            />
                          ) : null}
                          {commercialDetails?.info?.fintech?.active
                            ? `Instant credit for orders above ₹ ${commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit}`
                            : null}
                        </Typography>
                      </Box>
                    </Box>
                    {/* <Grid container>
                      <Grid item md={2}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <IconButton
                            size='large'
                            edge='start'
                            color='inherit'
                            aria-label='menu'
                            sx={{ p: '2px !important' }}
                            onClick={() => setOpen(true)}
                          >
                            <MenuIcon
                              sx={{ color: whiteColor, fontSize: '16px' }}
                            />
                          </IconButton>
                          <Typography
                            variant='h6'
                            color={whiteColor}
                            fontSize={13}
                            fontWeight={500}
                          >
                            {navBar_Menu}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item md={10}>
                        <Box sx={styles.topBar}>
                          <Typography
                            textAlign='center'
                            variant='h6'
                            fontSize='13px'
                            fontWeight={500}
                            color={whiteColor}
                            sx={{
                              display: 'inline-block',
                              whiteSpace: 'nowrap',
                              animation: `${marqueX} 30s linear infinite`,
                              animationDirection: 'reverse',
                              transform: 'translateX(330px)',
                            }}
                          >
                            {navBar_BuyOnlineMsg}{' '}
                            {commercialDetails?.info?.rewardPointPercent ? (
                              <span style={{ marginLeft: '20px' }}>
                                • {commercialDetails?.info?.rewardPointPercent}%
                                Cashback on all orders!
                              </span>
                            ) : (
                              ''
                            )}
                            {commercialDetails?.info?.immediateDiscount ? (
                              <span style={{ marginLeft: '20px' }}>
                                • Free Shipping Above ₹
                                {commercialDetails?.info?.immediateDiscount}!
                              </span>
                            ) : (
                              ''
                            )}
                            {dealsCouponsDetails?.length > 0
                              ? dealsCouponsDetails?.map((item) => {
                                  return (
                                    <span style={{ marginLeft: '20px' }}>
                                      • Get {item.discountPercentage}% Off, use
                                      coupon code {item.resellerCode}
                                    </span>
                                  )
                                })
                              : ''}
                            {commercialDetails?.info?.fintech?.active ? (
                              <Box
                                component='img'
                                src='https://storage.googleapis.com/bodefaults/shopweb/new.png'
                                width='30px'
                                height='auto'
                                alt={businessFullName + ' CostBo'}
                                sx={{ mr: 1, ml: 2.5 }}
                              />
                            ) : null}
                            {commercialDetails?.info?.fintech?.active
                              ? `Instant credit for orders above ₹ ${commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit}`
                              : null}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid> */}
                  </Container>
                </Box>
              </Grid>

              <Container
                sx={{
                  maxWidth: '1325px !important',
                  py: '10px',
                }}
              >
                <Grid container display='flex' alignItems='center'>
                  <Grid item>
                    <Box sx={{ width: '245px' }}>
                      {props.logoUrl ? (
                        <IconButton
                          // href={`/${businessName}`}
                          onClick={() => {
                            if (
                              commercialDetails?.info?.costboPowered?.domainURL
                            ) {
                              window.open(
                                commercialDetails?.info?.costboPowered
                                  ?.domainURL,
                                '_self'
                              )
                            } else {
                              navigate(`/${businessName}`)
                            }
                          }}
                          sx={{ p: 0 }}
                        >
                          <img
                            src={props.logoUrl}
                            alt={businessFullName + ' CostBo'}
                            style={{
                              width: '80px',
                              height: '80px',
                              borderRadius: '5px',
                              // aspectRatio: 4 / 3,
                            }}
                          />
                        </IconButton>
                      ) : (
                        <Box
                          sx={{
                            width: '80px',
                            height: '80px',
                            ...skeletonBox.boxAnim,
                            backgroundColor: '#e8e8e8',
                          }}
                        />
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs>
                    {showSearchBox ? (
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <SearchBar
                          value={query}
                          onChangeText={(text) => {
                            handleSearchQuery(text)
                          }}
                          onSearch={handleSearch}
                          // disabled={
                          //   commercialDetails?.info?.commerceEnable == false ||
                          //   allProducts?.productList?.length == 0
                          // }
                        />
                      </Box>
                    ) : (
                      <Box
                        display='flex'
                        alignItems='center'
                        // justifyContent='center'
                        gap='10px'
                      >
                        <NavLink
                          exact
                          activeClassName='active'
                          to={`/${businessName}`}
                        >
                          <Button
                            startIcon={
                              <StorefrontOutlinedIcon
                                sx={{ fontSize: '18px !important' }}
                              />
                            }
                            sx={{
                              ...styles.navBtn(false),
                              color:
                                props.value === 'home'
                                  ? primaryColor
                                  : navLinkColor,
                            }}
                          >
                            {navBar_MenuHome}
                          </Button>
                        </NavLink>

                        <NavLink
                          activeClassName='active'
                          to={
                            allProducts?.productList?.length == 0
                              ? '#'
                              : `/${businessName}/catalog`
                          }
                        >
                          <Button
                            startIcon={
                              <ShoppingCartIcon
                                sx={{ fontSize: '18px !important' }}
                              />
                            }
                            sx={{
                              ...styles.navBtn(false),
                              color:
                                allProducts?.productList?.length == 0
                                  ? offerTextColor
                                  : props.value === 'catalog'
                                  ? primaryColor
                                  : navLinkColor,
                            }}
                            variant='text'
                            disabled={allProducts?.productList?.length == 0}
                          >
                            {navBar_MenuCatalog}
                          </Button>
                        </NavLink>

                        <NavLink
                          activeClassName='active'
                          to={`/${businessName}/dealsCoupons`}
                        >
                          <Button
                            startIcon={
                              <SellIcon sx={{ fontSize: '18px !important' }} />
                            }
                            sx={{
                              ...styles.navBtn(false),
                              color:
                                props.value === 'coupons'
                                  ? primaryColor
                                  : navLinkColor,
                            }}
                          >
                            {navBar_MenuDealsCounpons}
                          </Button>
                        </NavLink>

                        <NavLink
                          activeClassName='active'
                          to={`/${businessName}/overview`}
                        >
                          <Button
                            startIcon={
                              <RemoveRedEyeIcon
                                sx={{ fontSize: '18px !important' }}
                              />
                            }
                            sx={{
                              ...styles.navBtn(),
                              color:
                                props.value === 'overview'
                                  ? primaryColor
                                  : // : allProducts?.productList?.length == 0
                                    // ? offerTextColor
                                    navLinkColor,
                            }}
                          >
                            {navBar_MenuOverview}
                          </Button>
                        </NavLink>

                        <NavLink
                          activeClassName='active'
                          to={`/${businessName}/review`}
                        >
                          <Button
                            startIcon={
                              <ReviewsIcon
                                sx={{ fontSize: '18px !important' }}
                              />
                            }
                            sx={{
                              ...styles.navBtn(false),
                              color:
                                props.value === 'reviews'
                                  ? primaryColor
                                  : navLinkColor,
                            }}
                          >
                            {navBar_MenuReview}
                          </Button>
                        </NavLink>
                      </Box>
                    )}
                  </Grid>

                  <Grid item>
                    <Box display='flex' alignItems='center' gap='10px'>
                      <SearchIconButton
                        handleClick={() => {
                          setShowSearchBox(!showSearchBox)
                        }}
                        isOpen={showSearchBox}
                        disabled={
                          commercialDetails?.info?.commerceEnable == false ||
                          allProducts?.productList?.length == 0
                        }
                      />

                      {commercialDetails?.info?.costboPowered
                        ?.affiliateEnabled &&
                        businessAffiliateData?.businessResellers?.length >
                          0 && (
                          <Tooltip title='Brand Affiliate' arrow>
                            <AffiliateButton
                              handleClick={() => {
                                navigate(
                                  rellerProfileData?.details
                                    ? `/${businessName}/affiliateHome`
                                    : `/${businessName}/affiliates`,
                                  {
                                    state: { disableAutoNav: true },
                                  }
                                )
                              }}
                              businessName={businessName}
                            />
                          </Tooltip>
                        )}

                      <ChatIconButton
                        handleClick={handleChatClick}
                        businessName={businessName}
                      />

                      <Cart
                        cartCount={totalQty}
                        businessName={businessName}
                        disabled={
                          commerceInfo?.info?.commerceEnable == false ||
                          allProducts?.productList?.length == 0 ||
                          isBusinessClosed
                        }
                      />
                      {loading && (
                        <Profile
                          onLoginClick={() => setLogin(true)}
                          name={name}
                          profilePhoto={geProfilePhoto()}
                          isLoggedIn={isLoggedIn}
                          onLogOut={() => {
                            setLogin(false)
                            setIsLoggedIn(false)
                            setIsFinvervAvailable('')
                            removeSessionInfo()
                            dispatch(clearCartData())
                            dispatch(clearRewardPointsData())
                            dispatch(clearOwnReviewData())
                            dispatch(clearLoginData())
                            dispatch(updateIsLogin())
                            dispatch(clearResellerDetails())
                            navigate(`/${businessName}`)
                          }}
                          businessName={businessName}
                        />
                      )}
                      {loading === false && (
                        <Profile
                          onLoginClick={() => setLogin(true)}
                          name={name}
                          profilePhoto={geProfilePhoto()}
                          isLoggedIn={isLoggedIn}
                          onLogOut={() => {
                            setLogin(false)
                            setIsLoggedIn(false)
                            setIsFinvervAvailable('')
                            removeSessionInfo()
                            dispatch(clearCartData())
                            dispatch(clearRewardPointsData())
                            dispatch(clearOwnReviewData())
                            dispatch(clearLoginData())
                            dispatch(updateIsLogin())
                            dispatch(clearResellerDetails())
                            navigate(`/${businessName}`)

                            // window.location.replace(
                            //   `${window.location.origin}/${businessName}`
                            // )
                            // navigate(0)
                          }}
                          businessName={businessName}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Container>

              <Grid item xs={12}>
                {!isLoggedIn && commercialDetails?.info?.fintech?.active ? (
                  <FinboxStatusCard
                    msg={applyMsg}
                    img={applyImg}
                    btnName='Apply Here'
                    onBtnClick={() => setLoginPopUp(true)}
                  />
                ) : null}
                {isLoggedIn ? (
                  <>
                    {/* Finbox */}
                    {isFinvervAvailable === 'APPLY' ? (
                      <FinboxStatusCard
                        msg={applyMsg}
                        img={applyImg}
                        btnName='Apply Here'
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log('resprespresp', resp)
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'notRegisteredFinbox' ? (
                      <FinboxStatusCard
                        msg={applyMsg}
                        img={applyImg}
                        btnName='Apply Here'
                        onBtnClick={() => {
                          const phoneNumber = getPhoneNumber()
                          const customerID = getProfileId()
                          const name = accountDetails?.account?.name
                          const email = accountDetails?.account?.email
                          console.log(
                            'Fintech',
                            phoneNumber?.replace('+91', '')
                          )
                          registeFinboxUser({
                            customerID,
                            mobile: phoneNumber?.replace('+91', ''),
                          }).then((res) => {
                            finboxPartnerPush({ customerID, name, email }).then(
                              () => {
                                getFinboxEligibility().then(() => {
                                  getFinboxUrl({
                                    redirectURL: window.location.href,
                                  }).then((resp) => {
                                    console.log('resprespresp', resp)
                                    if (resp?.data?.url) {
                                      setFinvervModal({
                                        show: true,
                                        url: resp?.data?.url,
                                      })
                                    }
                                  })
                                })
                              }
                            )
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'INCOMPLETE' ? (
                      <FinboxStatusCard
                        msg={incompleteMsg}
                        img={incompleteImg}
                        btnName='Continue here'
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log('resprespresp', resp)
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'APPROVED' ? (
                      <FinboxStatusCard
                        msg={approvedMsg}
                        img={approvedImg}
                        btnName='Continue here'
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log('resprespresp', resp)
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'REJECTED' ? (
                      <FinboxStatusCard
                        msg={rejectedMsg}
                        img={rejectedImg}
                        btnName='Click here for details'
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log('resprespresp', resp)
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'ACTIVE' ? (
                      <FinboxStatusCard
                        msg={activeMsg}
                        img={applyImg}
                        btnName='Click here for details'
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log('resprespresp', resp)
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'DUE' ? (
                      <FinboxStatusCard
                        msg={dueMsg}
                        img={dueImg}
                        btnName='Click here for details'
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log('resprespresp', resp)
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'OVERDUE' &&
                    finboxOverdueDetails?.amt ? (
                      <FinboxStatusCard
                        msg={`${overDueMsg}. Amount:  ₹${
                          finboxOverdueDetails?.amt
                        }    Due date: ${moment(
                          finboxOverdueDetails?.date
                        ).format('DD-MM-YYYY')}`}
                        img={overDueImg}
                        btnName='Click here for details'
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log('resprespresp', resp)
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'notRegistered' ? (
                      <FinboxStatusCard
                        msg={applyMsg}
                        img={applyImg}
                        btnName='Apply Here'
                        onBtnClick={() => {
                          const phoneNumber = getPhoneNumber()
                          console.log(
                            'Fintech',
                            phoneNumber?.replace('+91', '')
                          )
                          registeFinvervUser({
                            mobile: phoneNumber?.replace('+91', ''),
                          }).then((res) => {
                            getFinvervUrl().then((resp) => {
                              if (resp?.redirect_url) {
                                setFinvervModal1({
                                  show: true,
                                  url: resp?.redirect_url,
                                })
                              }
                            })
                          })
                        }}
                      />
                    ) : null}
                    {/* <Typography color='red'>{isFinvervAvailable}</Typography> */}
                    {isFinvervAvailable === 'notCompleted' ? (
                      <FinboxStatusCard
                        msg={incompleteMsg}
                        img={incompleteImg}
                        btnName='Continue here'
                        onBtnClick={() => {
                          getFinvervUrl().then((resp) => {
                            if (resp?.redirect_url) {
                              setFinvervModal1({
                                show: true,
                                url: resp?.redirect_url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'balanceAvailable' ? (
                      <FinboxStatusCard
                        msg={activeMsg}
                        img={applyImg}
                        btnName='Click here for details'
                        onBtnClick={() => {
                          getFinvervUrl().then((resp) => {
                            if (resp?.redirect_url) {
                              setFinvervModal1({
                                show: true,
                                url: resp?.redirect_url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === 'rejected' ? (
                      <FinboxStatusCard
                        msg={rejectedMsg}
                        img={rejectedImg}
                        btnName='Click here for details'
                        onBtnClick={() => {
                          getFinvervUrl().then((resp) => {
                            if (resp?.redirect_url) {
                              setFinvervModal1({
                                show: true,
                                url: resp?.redirect_url,
                              })
                            }
                          })
                        }}
                      />
                    ) : null}
                  </>
                ) : null}
              </Grid>
            </Grid>

            {/* {allProducts && commerceInfo ? (
              allProducts?.productList?.length == 0 ||
              commerceInfo?.info?.commerceEnable ? (
                commerceInfo?.info?.hyperlocalStore ? (
                  !commerceInfo?.info?.hyperlocalStore?.storeOpenStatus ? (
                    <Box
                      backgroundColor={primaryColor}
                      p={1}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Box
                        component='img'
                        src='https://storage.googleapis.com/bodefaults/shopweb/OrderTemporarily.svg'
                        width='30px'
                        height='auto'
                        alt={businessFullName + ' CostBo'}
                        sx={{ mr: 1.5 }}
                      />
                      <Typography
                        variant='subtitle1'
                        fontSize={16}
                        color={whiteColor}
                      >
                        {commerceInfo?.info?.hyperlocalStore?.reason}
                      </Typography>
                    </Box>
                  ) : null
                ) : null
              ) : (
                <Box
                  backgroundColor={primaryColor}
                  p={1}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Box
                    component='img'
                    src='https://storage.googleapis.com/bodefaults/shopweb/OrderTemporarily.svg'
                    width='30px'
                    height='auto'
                    alt={businessFullName + ' CostBo'}
                    sx={{ mr: 1.5 }}
                  />
                  <Typography
                    variant='subtitle1'
                    fontSize={16}
                    color={whiteColor}
                  >
                    {onlineOrderClosedMsg}
                  </Typography>
                </Box>
              )
            ) : null} */}
          </AppBar>
        ) : (
          <MobileNavbar
            value={props.value}
            onMenuClick={() => setOpen(true)}
            onLoginClick={() => {
              setLogin(true)
            }}
            searchQuery={query}
            onChangeText={(text) => {
              handleSearchQuery(text)
            }}
            logoUrl={
              props.logoUrl
                ? props.logoUrl
                : JSON.parse(sessionStorage.getItem('businessInfo'))?.[0]
                    ?.logoURL
            }
            cartCount={totalQty}
            businessName={businessName}
            onSearch={handleSearch}
            isLoggedIn={isLoggedIn}
            allProducts={allProducts}
            commercialDetails={commerceInfo}
            isBusinessClosed={isBusinessClosed}
            rewardpoints={props.rewardpoints}
            dealsCouponsDetails={dealsCouponsDetails}
            onLogOut={() => {
              setLogin(false)
              setIsLoggedIn(false)
              setIsFinvervAvailable('')
              removeSessionInfo()
              dispatch(clearCartData())
              dispatch(clearLoginData())
              dispatch(clearRewardPointsData())
              dispatch(clearOwnReviewData())
              dispatch(updateIsLogin())
              dispatch(clearResellerDetails())
              navigate(`/${businessName}`)
              // window.location.replace(
              //   `${window.location.origin}/${businessName}`
              // )
              // navigate(0)
            }}
            handleChatClick={handleChatClick}
            setFinvervModal={(data) => setFinvervModal(data)}
            finboxOverdueDetails={finboxOverdueDetails}
            isFinvervAvailable={isFinvervAvailable}
            accountDetails={accountDetails}
            setLoginPopUp={(data) => setLoginPopUp(data)}
            setFinvervModal1={(data) => setFinvervModal1(data)}
            businessAffiliateData={businessAffiliateData}
            rellerProfileData={rellerProfileData}
          />
        )}
      </Box>
    </Suspense>
  ) : null
}

const SearchBar = ({ value, onChangeText, onSearch, disabled }) => {
  return (
    <form>
      <Box
        sx={{
          background: whiteColor,
          borderRadius: '8px',
          border: { xs: '1.5px solid #4f4f4f50', md: '1px solid #f0f0f0' },
          display: 'flex',
          alignItems: 'center',
          pl: '20px',
          height: '35px',
          // minWidth: { lg: '450px', xs: '300px' },
          minWidth: { lg: '400px', xs: '300px' },
          mb: { xs: 1.5, md: 0 },
        }}
      >
        <TextField
          value={value}
          placeholder='Search Products'
          variant='standard'
          sx={{ flex: 1 }}
          InputProps={{
            disableUnderline: true,
          }}
          onChange={({ target }) => {
            const finalValue = allowOnlyEnglish(target.value)
            onChangeText(finalValue)
          }}
          disabled={disabled}
        />
        <IconButton
          onClick={(e) => {
            e.preventDefault()

            onSearch()
          }}
          disabled={disabled}
          type='submit'
          sx={{ width: 30, height: 30 }}
        >
          <SearchIcon sx={{ color: navHeaderColor }} />
        </IconButton>
      </Box>
    </form>
  )
}

const AffiliateButton = ({ handleClick, businessName, disabled }) => {
  return (
    <Tooltip title='Brand Affiliates' arrow>
      <IconButton
        sx={{
          background: backgroundColor,
          p: 1.3,
        }}
        onClick={handleClick}
      >
        <Box
          component='img'
          src='https://storage.googleapis.com/bodefaults/shopweb/share-earn.svg'
          alt='affilite'
          sx={{ width: '18px', height: '18px', objectFit: 'contain' }}
        />
      </IconButton>
    </Tooltip>
  )
}

const SearchIconButton = ({ handleClick, disabled, isOpen }) => {
  return (
    <IconButton
      sx={{
        background: backgroundColor,
        p: 1.3,
        '&.Mui-disabled': {
          color: offerTextColor,
          bgcolor: `${backgroundColor} !important`,
          border: 'none !important',
        },
      }}
      onClick={handleClick}
      disabled={disabled}
    >
      {isOpen ? (
        <CloseIcon
          sx={{
            fontSize: '18px',
            color: disabled ? offerTextColor : productLabelColor,
          }}
        />
      ) : (
        <SearchIcon
          sx={{
            fontSize: '18px',
            color: disabled ? offerTextColor : productLabelColor,
          }}
        />
      )}
    </IconButton>
  )
}

const ChatIconButton = ({ handleClick, businessName, disabled }) => {
  return (
    <Tooltip title='Contact Us' arrow>
      <IconButton
        sx={{
          background: backgroundColor,
          p: 1.3,
        }}
        onClick={handleClick}
      >
        <QuestionAnswerOutlinedIcon
          sx={{
            fontSize: '18px',
            color: disabled ? offerTextColor : productLabelColor,
          }}
        />
      </IconButton>
    </Tooltip>
  )
}

const Cart = ({ cartCount, businessName, disabled }) => {
  return (
    <IconButton
      sx={{
        background: backgroundColor,
        p: 1.3,
      }}
      component={Link}
      to={disabled ? '#' : `/${businessName}/cart`}
    >
      {cartCount !== 0 ? (
        <Badge badgeContent={cartCount} color='primary'>
          <ShoppingCartOutlined
            sx={{
              fontSize: '18px',
              color: disabled ? offerTextColor : productLabelColor,
            }}
          />
        </Badge>
      ) : (
        <ShoppingCartOutlined
          sx={{
            fontSize: '18px',
            color: disabled ? offerTextColor : productLabelColor,
          }}
        />
      )}
    </IconButton>
  )
}

// const Profile = ({ onLoginClick, profileImg, name, isLoggedIn, onLogOut }) => {
//   const isProfileImgAvailabel = geProfilePhoto()
//   console.log(
//     'isProfileImgAvailabelisProfileImgAvailabel',
//     isProfileImgAvailabel
//   )
//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//       {isLoggedIn ? (
//         <Box display='flex' flexDirection='row' alignItems='center'>
//           {isProfileImgAvailabel == '' ? (
//             // <IconButton sx={{ background: backgroundColor, p: 1.1 }}>
//             //   <PersonOutlineRounded
//             //     sx={{
//             //       fontSize: '23px',
//             //       color: primaryColor,
//             //     }}
//             //   />
//             // </IconButton>

//             <ProfileCharAvatar username={getUserName()} />
//           ) : (
//             <Avatar
//               alt='User'
//               src={geProfilePhoto()}
//               sx={{ width: '35px', height: '35px' }}
//             />
//           )}

//           <Box>
//             <Typography
//               variant='h6'
//               marginLeft='10px'
//               lineHeight={'20px'}
//               color={navHeaderColor}
//             >
//               {getUserName()}
//             </Typography>
//             <Box>
//               <Typography
//                 onClick={onLogOut}
//                 marginLeft='10px'
//                 color={primaryColor}
//                 fontSize={13}
//                 fontWeight={500}
//                 sx={{
//                   textTransform: 'none',
//                   textDecoration: 'underline',
//                   '&:hover': {
//                     textDecoration: 'none',
//                   },
//                   cursor: 'pointer',
//                   p: '0',
//                 }}
//               >
//                 {navBar_MenuLogout}
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//       ) : (
//         <Box display='flex' flexDirection='row' onClick={onLoginClick}>
//           {/* <Avatar
//             alt='User'
//             src={geProfilePhoto()}
//             sx={{ width: '35px', height: '35px' }}
//           /> */}

//           <IconButton sx={{ background: backgroundColor, p: 1.1 }}>
//             <PersonOutlineRounded
//               sx={{
//                 fontSize: '23px',
//                 color: productLabelColor,
//               }}
//             />
//           </IconButton>

//           <Box py={0.5}>
//             <Typography
//               variant='h6'
//               fontSize={14}
//               marginLeft='10px'
//               color={navHeaderColor}
//               sx={{ cursor: 'pointer' }}
//             >
//               {navBar_MenuLoginSignup}
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   )
// }

const Profile = ({
  onLoginClick,
  isLoggedIn,
  onLogOut,
  hideName,
  businessName,
}) => {
  const isProfileImgAvailabel = geProfilePhoto()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [text, SetText] = useState('')

  const handleClose = (event) => {
    setAnchorEl(null)
    SetText(event.target.innerText)
  }

  return isLoggedIn ? (
    <Box
      sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', ml: 2 }}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {isProfileImgAvailabel == '' ? (
          <ProfileCharAvatar username={getUserName()} />
        ) : (
          <Avatar
            alt='User'
            src={geProfilePhoto()}
            sx={{ width: '35px', height: '35px' }}
          />
        )}

        {!hideName ? (
          <Box>
            <Typography
              variant='h6'
              marginLeft='10px'
              lineHeight={1}
              color={blackColor}
            >
              {getUserName()}
            </Typography>
          </Box>
        ) : null}
        <ArrowDropDownIcon sx={{ color: blackColor }} />
      </Box>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        mt={5}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        variant='selectedMenu'
      >
        <Box px={2} width={150} display='flex' flexDirection={'column'}>
          <Typography
            fontSize={15}
            fontWeight={600}
            color={blackColor}
            lineHeight={1.5}
            mb={0.5}
            sx={{ textDecoration: 'none' }}
          >
            My Account
          </Typography>
          <Typography
            // component={Link}
            // to='/profileDetails'
            sx={{ textDecoration: 'none' }}
            color={blackColor}
          >
            {getPhoneNumber()}
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <Box px={2}>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={blackColor}
            lineHeight={1.5}
            sx={{ textDecoration: 'none', display: 'block' }}
            component={Link}
            to={`/${businessName}/Dashboard`}
            my={1}
          >
            Dashboard
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={blackColor}
            lineHeight={1.5}
            sx={{ textDecoration: 'none', display: 'block' }}
            component={Link}
            to={`/${businessName}/AllOrders`}
            my={1}
          >
            My Orders
          </Typography>

          <Typography
            fontSize={14}
            fontWeight={400}
            color={blackColor}
            lineHeight={1.5}
            my={1}
            sx={{ cursor: 'pointer' }}
            onClick={onLogOut}
          >
            Logout
          </Typography>
        </Box>
      </Menu>
    </Box>
  ) : (
    <Box display='flex' flexDirection='row' onClick={onLoginClick}>
      {/* <Avatar
               alt='User'
               src={geProfilePhoto()}
               sx={{ width: '35px', height: '35px' }}
             /> */}

      <IconButton sx={{ background: backgroundColor, p: 1.1 }}>
        <PersonOutlineRounded
          sx={{
            fontSize: '23px',
            color: productLabelColor,
          }}
        />
      </IconButton>

      <Box py={0.5}>
        <Typography
          variant='h6'
          fontSize={14}
          marginLeft='10px'
          color={navHeaderColor}
          sx={{ cursor: 'pointer' }}
        >
          {navBar_MenuLoginSignup}
        </Typography>
      </Box>
    </Box>
  )
}
export default Navbar

const styles = {
  topBarStyles: {
    background: topbarColor,
    p: '4px 0',
  },

  topBar: {
    background: topbarColor,
    overflowX: 'hidden',
  },
  navBtn: (disabled) => ({
    fontWeight: 500,
    fontSize: '14px',
    textTransform: 'none',
    backgroundColor: whiteColor,

    '&:hover': {
      color: disabled ? offerTextColor : primaryColor,
      background: whiteColor,
    },
    '&.Mui-disabled': {
      bgcolor: `${whiteColor} !important`,
      border: 'none !important',
      opacity: 0.6,
    },
  }),

  bottomTabBox: {
    justifyContent: 'space-around !important',
  },

  bottomTabStyles: {
    p: '0px',
    minWidth: '0',
    maxWidth: 'none',
  },

  bottomTabLabel: {
    '@media screen and (max-width: 320px)': {
      fontSize: '9px',
    },
  },

  bottomTabIcon: {
    '@media screen and (max-width: 320px)': {
      fontSize: '16px',
    },
  },
}
