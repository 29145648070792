import { Box, Typography } from '@mui/material'
import {
  blackColor,
  blackShade14,
  borderBottomLine2,
} from '../../configs/styles/muiThemes'

function WhyChooseCostBo({ businessAffiliateData, affiCommissionPercentage }) {
  const businessResellerData = businessAffiliateData?.businessResellers?.[0]

  return (
    <Box p='15px'>
      <Typography
        variant='h3'
        fontSize={17}
        fontWeight={600}
        lineHeight='24px'
        color={blackColor}
        textAlign='center'
        sx={{ mb: '6px !important' }}
      >
        Why choose our Brand affiliate program?
      </Typography>

      <Typography
        variant='body2'
        fontSize={13}
        fontWeight={400}
        lineHeight='24px'
        color={blackShade14}
        textAlign='center'
        sx={{ mb: '10px !important' }}
      >
        Promote authentic products from our own website. Better prices &
        Guaranteed, trackable shipments for your buyers. More sales for you
      </Typography>

      <Box>
        <InfoCard
          icon='https://storage.googleapis.com/bodefaults/shopweb/earn-upto-commissions.svg'
          // title={`Earn ${businessResellerData?.affiliatePercentage}% commissions`}
          title={`Earn ${affiCommissionPercentage}% commissions`}
          description='You will earn commissions on every sale made through your link or affiliate code'
        />

        <InfoCard
          icon='https://storage.googleapis.com/bodefaults/shopweb/bonus-savings-customers.svg'
          title='Bonus savings for your customers'
          description='Customers get special discounts when they purchase using your link/affilate code'
        />

        <InfoCard
          icon='https://storage.googleapis.com/bodefaults/shopweb/quick-trusted-payments.svg'
          title='Quick and Trusted payments'
          description='Automated payments settled to your bank accounts'
        />

        <InfoCard
          icon='https://storage.googleapis.com/bodefaults/shopweb/sales-earnings.svg'
          title='Monitor Sales and Earnings'
          description='Simple mobile dashbaord to monitor sales, commissions and settlements'
        />
      </Box>
    </Box>
  )
}

export default WhyChooseCostBo

const InfoCard = ({ title, description, icon }) => {
  return (
    <Box sx={styles.infoCardContainer}>
      <Box
        component='img'
        src={icon}
        sx={{ width: '55px', height: '55px', objectFit: 'contain' }}
      />

      <Box>
        <Typography
          variant='h6'
          fontSize={14}
          fontWeight={600}
          lineHeight='28px'
          color={blackColor}
        >
          {title}
        </Typography>

        <Typography
          variant='body2'
          fontSize={12.5}
          fontWeight={400}
          lineHeight='22px'
          color={blackShade14}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

const styles = {
  infoCardContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    padding: '10px',
    borderBottom: borderBottomLine2,
  },
}
