import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getBusinessIdData } from 'services/api_calls/root/RootApi'

const initialState = {
  businessId: null,
  loading:false,

  //Loaders
  businessIdLoading:false
}

//Fetching Product details
export const getBusinessIdDetails = createAsyncThunk(
  'getBusinessIdDetails',
  async (param, thunkAPI) => {
    const res = getBusinessIdData(param)
    return res
  }
)

export const rootDetailsSlice = createSlice({
  name: 'rootSlice',
  initialState,
  reducers: {},
  extraReducers: {
    //product details
    [getBusinessIdDetails.pending]: (state) => {
      state.businessIdLoading = true
      state.loading = true
    },
    [getBusinessIdDetails.fulfilled]: (state, { payload }) => {
      state.businessIdLoading = false
      state.loading = false
      state.businessId = payload
    },
    [getBusinessIdDetails.rejected]: (state) => {
      state.businessIdLoading = false
      state.loading = false
    },
  },
})

export const rootDetailsReducer = rootDetailsSlice.reducer
