import { LocalShipping } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import TextboxWithLabel from 'components/TextboxWithLabel'
import {
  borderTextbox,
  filterMobileColor,
  headingColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { updateGstIfo } from 'services/api_calls/checkout/checkoutApi'
import { getProfileData } from 'services/api_calls/auth/login_api'
import { getUserDetails } from 'utils/authHelpers'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import DiscountHeader from './DiscountHeader'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

function GSTDetialsSection({
  gstChecked,
  setGstChecked,
  selectedGst,
  setGstSelected,
  gstNumber,
  setGstNumber,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [gstError, setGstError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [gstErrorMsg, setGstErrorMsg] = useState('')
  const [collapseCartTabel, setCollapseCartTabel] = useState(true)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const userDetails = getUserDetails()

  const handleClose = (item) => {
    setAnchorEl(null)
    setGstSelected(item)
    // if (!item?.id) return

    // if (!item?.pointValue || item?.pointValue === 0) {
    //   if (item?.pointPercentage !== 0) {
    //     const orderTotal = cartDetails?.orderTotalProductCost
    //     const discountPercentage = item?.pointPercentage

    //     const discountAmt = (orderTotal / 100) * discountPercentage
    //     setSelectedDiscount({ ...item, pointValue: discountAmt })
    //   }
    // } else {
    //   setSelectedDiscount(item)
    // }
  }
  return (
    <Box my={1}>
      <DiscountHeader
        icon={
          // <Box
          //   component={'img'}
          //   src=''
          //   width='20px'
          //   sx={{ mb: '-5px' }}
          // />
          <ReceiptLongOutlinedIcon
            sx={{
              fontSize: { xs: '19px', md: '20px' },
              color: headingColor,
              mb: '-5px',
            }}
          />
        }
        title=' GST DETAILS'
        gst={false}
        disablePB
        collapseCartTabel={collapseCartTabel}
        onToggleCartItems={() => setCollapseCartTabel(!collapseCartTabel)}
      />
      <Box 
      // display={{ md: 'block', xs: collapseCartTabel ? 'none' : 'block' }} 
      m={{md:'8px 0 15px 0', xs:'-5px 0 15px 0'}}>
        <FormControlLabel
          control={<Checkbox size='small' />}
          label={
            <Typography fontSize={{ md: 14, xs: 11.5 }} color={headingColor}>
              Do you want to claim GST refund on this order?
            </Typography>
          }
          value={gstChecked}
          onChange={() => {
            setGstChecked(!gstChecked)
          }}
        />

        {gstChecked ? (
          <Box
            display='flex'
            alignItems={{ md: 'center', xs: 'flex-start' }}
            gap={{ md: 5, xs: 1 }}
            flexDirection={{ md: 'row', xs: 'column' }}
          >
            <Box width={{ xs: '100%', md: '32%' }}>
              <Typography
                variant='subtitle1'
                fontSize={{ md: '13px', xs: '13px' }}
                sx={{
                  color: filterMobileColor,
                  display: 'inline',
                }}
              >
                Enter the GST Number
              </Typography>
              <Box
                width={{ md: '100%%', xs: '100%' }}
                display='flex'
                alignItems={'flex-start'}
                gap={1}
              >
                <TextboxWithLabel
                  small
                  // label='Enter the GST Number'
                  value={gstNumber}
                  onValueChange={(text) => {
                    const value = text
                      .replace(/[^a-zA-Z0-9]/g, '')
                      .toUpperCase()
                    if (gstError) setGstError(false)
                    if (success) setSuccess(false)
                    setGstSelected('')
                    if (value.length == 15) {
                      const reg =
                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                          value
                        )
                      console.log('reg', reg)
                      if (reg) {
                        setGstNumber(value)
                        if (userDetails?.account?.gstDetails?.includes(value)) {
                          setGstError(true)
                          setGstErrorMsg(
                            'This GST number already exists in your account. Pick from the dropdown'
                          )
                        }
                      } else {
                        setGstNumber(value)
                        setGstError(true)
                        setGstErrorMsg('Enter a valid GST number')
                      }
                    } else {
                      setGstNumber(value)
                    }
                  }}
                  maxLength={15}
                  isError={gstError}
                  errorMsg={gstErrorMsg}
                />
                <Button
                  variant='contained'
                  sx={{ mt: 1 }}
                  onClick={() => {
                    if (gstNumber.length == 15) {
                      const reg =
                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                          gstNumber
                        )
                      console.log('reg', reg)
                      if (reg) {
                        // setGstNumber(value)
                        if (
                          userDetails?.account?.gstDetails?.includes(gstNumber)
                        ) {
                          setGstError(true)
                          setGstErrorMsg(
                            'This GST number already exists in your account. Pick from the dropdown'
                          )
                        } else {
                          userDetails?.account?.gstDetails?.length > 0
                            ? updateGstIfo([
                                gstNumber,
                                ...userDetails?.account?.gstDetails,
                              ]).then((res) => {
                                if (res.success) setSuccess(true)
                                getProfileData()
                              })
                            : updateGstIfo([gstNumber]).then((res) => {
                                if (res.success) setSuccess(true)
                                getProfileData()
                              })
                        }
                      } else {
                        setGstError(true)
                        setGstErrorMsg('Enter a valid GST number')
                      }
                    } else {
                      setGstError(true)
                      setGstErrorMsg('Enter a valid GST number')
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
            {userDetails?.account?.gstDetails?.length > 0 ? (
              <Box
                display={'flex'}
                justifyContent='center'
                width={{ xs: '100%', md: 'auto' }}
              >
                <Typography fontSize={14} color={headingColor}>
                  OR
                </Typography>
              </Box>
            ) : null}
            {userDetails?.account?.gstDetails?.length > 0 ? (
              <Box width={{ md: '35%', xs: '80%' }}>
                <Typography
                  variant='subtitle1'
                  fontSize={{ md: '13px', xs: '13px' }}
                  sx={{
                    color: filterMobileColor,
                    display: 'inline',
                  }}
                >
                  Pick from existing value
                </Typography>
                <Box
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{
                    border: borderTextbox,
                    borderRadius: '6px',
                    fontSize: '13px',
                    color: '#777',
                    background: whiteColor,
                    padding: { md: '9px 10px', xs: '7px 10px' },
                    width: '100%',
                    '& .MuiInputBase-root.Mui-disabled': {
                      color: 'red !important',
                    },
                    minHeight: '20px',
                    mt: 0.8,
                  }}
                  // sx={styles.inputBox(isSelected, isSuccess)}
                  fullWidth
                  disableRipple
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  // sx={styles.dropdownBtn}
                  // endIcon={<KeyboardArrowDownOutlined />}
                >
                  <Typography
                    variant='body1'
                    component='p'
                    fontSize='13px'
                    color={headingColor}
                    lineHeight='16px'
                  >
                    {selectedGst}
                  </Typography>

                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: '20px' }} />
                </Box>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  variant='selectedMenu'
                  PaperProps={{
                    style: {
                      width: 320,
                      maxHeight: 250,
                    },
                  }}
                >
                  {getUserDetails()?.account?.gstDetails?.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleClose(item)
                        setGstNumber('')
                        setGstError(false)
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Menu>
                {/* <TextboxWithLabel
            small
            label='Pick from existing value'
            // value={pincode}
            onValueChange={(text) => {
              // if (pincode.length === 5) {
              //   dispatch(getStateCityDetails(text))
              //   setIsPincodeError(false)
              // }
              // if (text.length === 6) {
              //   dispatch(getStateCityDetails(text))
              //   setIsPincodeError(false)
              // }
              // setPincode(text)
            }}
            // maxLength={6}
            // placeholder={addressForm_Pincode}
            // required={true}
            // isError={isPincodeError}
            // errorMsg={addressForm_PincodeError}
          /> */}
              </Box>
            ) : null}
          </Box>
        ) : null}
        {success ? (
          <Typography
            variant='subtitle1'
            component='p'
            fontSize={13}
            color={'green'}
          >
            GST Number Successfully added
          </Typography>
        ) : null}
      </Box>
    </Box>
  )
}

export default GSTDetialsSection
