import React from 'react'
import styled from '@emotion/styled'
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  blackColor,
  blackShade17,
  borderInputTextbox,
  errorTextColor,
  redColor,
} from '../../configs/styles/muiThemes'
import { allowOnlyEnglish } from 'utils/utilities'

function StyledSelectWithLabel({
  label,
  value,
  onValueChange,
  menuList,
  valueSelector,
  required,
  disabled,
  errorMsg,
  isReadOnly,
}) {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant='body2'
        fontSize={isReadOnly ? 12 : 13}
        fontWeight={400}
        color={blackShade17}
        mb='4px !important'
      >
        {label}
        {!isReadOnly
          ? required && <span style={{ color: redColor }}>*</span>
          : ''}
      </Typography>

      {!isReadOnly ? (
        <StyledFormControl>
          <Select
            value={value}
            onChange={(event) => {
              const finalValue = allowOnlyEnglish(event.target.value)
              onValueChange(finalValue)
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            fullWidth
            disabled={disabled}
          >
            {menuList?.map((item, index) => {
              return (
                <MenuItem
                  value={valueSelector ? item[valueSelector] : item.label}
                  key={'Menu_Item_' + index}
                >
                  {valueSelector ? item[valueSelector] : item.label}
                </MenuItem>
              )
            })}
          </Select>
        </StyledFormControl>
      ) : (
        <Typography
          variant='body2'
          fontSize={14}
          fontWeight={500}
          color={blackColor}
          mt='10px !important'
        >
          {value}
        </Typography>
      )}

      <Typography
        variant='body2'
        fontSize={12}
        fontWeight={500}
        color={errorTextColor}
        mt='10px !important'
      >
        {errorMsg}
      </Typography>
    </Box>
  )
}

export default StyledSelectWithLabel

const StyledFormControl = styled(FormControl)({
  width: '100%',
  height: '48px',
  border: borderInputTextbox,
  borderRadius: '5px',
  boxShadow: '0 2px 3px rgb(239 239 239)',

  '& .css-hdw1oc': {
    display: 'none',
  },

  '& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline':
    {
      border: 'none',
    },
})
