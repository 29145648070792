import { LocalShipping } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import {
  borderLineColor,
  discountBgColor,
  headingColor,
  minOrderTextColor,
  outOfStockTextColor,
} from '../../configs/styles/muiThemes'
import DeliveryMethodCard from './DiscountCards/DeliveryMethodCard'
import DiscountHeader from './DiscountHeader'

function DeliveryMethodSection({
  deliveryMethod,
  onDeliveryMethodChange,
  totalCartWeightKg,
  costboPremierDeliverETA,
  seletedAddress,
  businessDelivery,
  additionalDiscount,
  isFreeShipping,
  costboPremier,
  costboPremierHeavy,
  costboPremierDeliverETAHeavy,
  telyportStandardDelivery,
  telyportRushDelivery,
  telyportExpressDelivery,
  errorMessage,
}) {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box my={1}>
      <DiscountHeader
        icon={
          <LocalShipping
            sx={{
              fontSize: { xs: '19px', md: '20px' },
              color: headingColor,
              mb: '-5px',
            }}
          />
        }
        title=' DELIVERY METHOD'
      />

      <Box
        backgroundColor={discountBgColor}
        border={'1px solid ' + borderLineColor}
        px={{ md: 1.5, xs: 0.5 }}
        py={{ xs: 1, md: 1.8 }}
        borderRadius={1.8}
        mt={{ xs: 1, md: 0 }}
      >
        <Grid container alignItems='center' spacing={1.7}>
          <Grid item>
            <Box
              component='img'
              src='https://storage.googleapis.com/bodefaults/shopweb/package.svg'
              alt={businessFullName + ' CostBo'}
              width={{ xs: '40px', md: '35px' }}
              height={{ xs: '58px', md: '35px' }}
            />
          </Grid>

          <Grid item>
            <Typography
              variant='subtitle1'
              component='h5'
              fontSize={14}
              fontWeight={600}
              color={headingColor}
            >
              Standard Package
            </Typography>

            <Typography
              variant='subtitle1'
              component='h5'
              fontSize='13px'
              color={minOrderTextColor}
            >
              Cart Weight {totalCartWeightKg.toFixed(2)} kg
            </Typography>
          </Grid>
        </Grid>
        {/* {JSON.stringify(costboPremier)} */}
        {seletedAddress ? (
          <Grid container pt={1.5} spacing={{ md: 1.5, xs: 0.75 }}>
            {telyportRushDelivery?.show ? (
              <Grid item xs={6} sm={4} md={2.5}>
                <DeliveryMethodCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/delivery_rush.svg'
                  title='Rush'
                  subtitle={telyportRushDelivery?.deliveryETA}
                  subtitle1='(Tracking Available)'
                  error={telyportRushDelivery?.error}
                  // isFreeShipping={isFreeShipping}
                  // additionalDiscount={additionalDiscount}
                  // oldPrice={item.oldPrice}
                  // price={businessDelivery?.totalAmount}
                  oldPrice={telyportRushDelivery?.originalAmount}
                  price={telyportRushDelivery?.totalAmount}
                  isSelect={deliveryMethod === 'rush'}
                  onSelectMethod={(price, subtitle) => {
                    onDeliveryMethodChange({
                      type: 'rush',
                      amount: price,
                      eta: subtitle,
                      discountType: telyportRushDelivery.type,
                      originalAmount: telyportRushDelivery?.originalAmount,
                      shipmentTax: telyportRushDelivery?.shipmentTax,
                    })
                  }}
                />
              </Grid>
            ) : null}
            {telyportExpressDelivery?.show ? (
              <Grid item xs={6} sm={4} md={2.5}>
                <DeliveryMethodCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/delivery_express.svg'
                  title='Express'
                  subtitle={telyportExpressDelivery?.deliveryETA}
                  subtitle1='(Tracking Available)'
                  error={telyportExpressDelivery?.error}
                  // isFreeShipping={isFreeShipping}
                  // additionalDiscount={additionalDiscount}
                  // oldPrice={item.oldPrice}
                  // price={businessDelivery?.totalAmount}
                  oldPrice={telyportExpressDelivery?.originalAmount}
                  price={telyportExpressDelivery?.totalAmount}
                  isSelect={deliveryMethod === 'express'}
                  onSelectMethod={(price, subtitle) => {
                    onDeliveryMethodChange({
                      type: 'express',
                      amount: price,
                      eta: subtitle,
                      discountType: telyportExpressDelivery.type,
                      originalAmount: telyportExpressDelivery?.originalAmount,
                      shipmentTax: telyportExpressDelivery?.shipmentTax,
                    })
                  }}
                />
              </Grid>
            ) : null}
            {telyportStandardDelivery?.show ? (
              <Grid item xs={6} sm={4} md={2.5}>
                <DeliveryMethodCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/delivery_standard.svg'
                  title='Next day'
                  subtitle={telyportStandardDelivery?.deliveryETA}
                  subtitle1='(Tracking Available)'
                  error={telyportStandardDelivery?.error}
                  // isFreeShipping={isFreeShipping}
                  // additionalDiscount={additionalDiscount}
                  // oldPrice={item.oldPrice}
                  // price={businessDelivery?.totalAmount}
                  oldPrice={telyportStandardDelivery?.originalAmount}
                  price={telyportStandardDelivery?.totalAmount}
                  isSelect={deliveryMethod === 'standard'}
                  onSelectMethod={(price, subtitle) => {
                    onDeliveryMethodChange({
                      type: 'standard',
                      amount: price,
                      eta: subtitle,
                      discountType: telyportStandardDelivery?.type,
                      originalAmount: telyportStandardDelivery?.originalAmount,
                      shipmentTax: telyportStandardDelivery?.shipmentTax,
                    })
                  }}
                />
              </Grid>
            ) : null}
            {costboPremier?.show ? (
              <Grid item xs={6} sm={4} md={2.5}>
                <DeliveryMethodCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/delivery_costbo_premier.svg'
                  title='Premier'
                  subtitle1='(Tracking Available)'
                  subtitle={costboPremierDeliverETA}
                  error={costboPremier?.error}
                  // isFreeShipping={isFreeShipping}
                  oldPrice={costboPremier?.originalAmount}
                  price={costboPremier?.totalAmount}
                  isSelect={deliveryMethod === 'costbo-premier'}
                  onSelectMethod={(price, subtitle) => {
                    onDeliveryMethodChange({
                      type: 'costbo-premier',
                      amount: price,
                      eta: subtitle,
                      discountType: costboPremier.type,
                      originalAmount: costboPremier?.originalAmount,
                      shipmentTax: costboPremier?.shipmentTax,
                    })
                  }}
                />
              </Grid>
            ) : null}
            {costboPremierHeavy?.show ? (
              <Grid item xs={6} sm={4} md={2.5}>
                <DeliveryMethodCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/delivery_costbo_premier.svg'
                  title='Heavy'
                  subtitle={costboPremierDeliverETAHeavy}
                  subtitle1='(Tracking Available)'
                  oldPrice={costboPremierHeavy?.originalAmount}
                  error={costboPremierHeavy?.error}
                  price={costboPremierHeavy?.totalAmount}
                  isSelect={deliveryMethod === 'costbo-heavy'}
                  onSelectMethod={(price, subtitle) => {
                    onDeliveryMethodChange({
                      type: 'costbo-heavy',
                      amount: price,
                      eta: subtitle,
                      discountType: costboPremierHeavy.type,
                      originalAmount: costboPremierHeavy?.originalAmount,
                      shipmentTax: costboPremierHeavy?.shipmentTax,
                    })
                  }}
                />
              </Grid>
            ) : null}
            {businessDelivery?.show ? (
              <Grid item xs={6} sm={4} md={2.5}>
                <DeliveryMethodCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/delivery_regular_delivery.svg'
                  title='Standard'
                  subtitle={businessDelivery?.deliveryETA}
                  error={businessDelivery?.error}
                  // isFreeShipping={isFreeShipping}
                  // additionalDiscount={additionalDiscount}
                  // oldPrice={item.oldPrice}
                  // price={businessDelivery?.totalAmount}
                  oldPrice={businessDelivery?.originalAmount}
                  price={businessDelivery?.totalAmount}
                  isSelect={deliveryMethod === 'regular-delivery'}
                  onSelectMethod={(price, subtitle) => {
                    onDeliveryMethodChange({
                      type: 'regular-delivery',
                      amount: price,
                      eta: subtitle,
                      discountType: businessDelivery.type,
                      originalAmount: businessDelivery?.originalAmount,
                    })
                  }}
                />
              </Grid>
            ) : null}
            {businessDelivery?.show ||
            costboPremierHeavy?.show ||
            businessDelivery?.show ? null : (
              <Typography
                variant='subtitle1'
                component='p'
                fontSize='13px'
                color={outOfStockTextColor}
                display='inline'
                mt={1.6}
                px={2}
              >
                {errorMessage}
              </Typography>
            )}

            {/* {deliveryMethods.map((item, index) => {
              return (
                <Grid item xs={6} sm={4} md={2}>
                  <DeliveryMethodCard
                    icon={item.icon}
                    title={item.title}
                    subtitle={item.subtitle}
                    oldPrice={item.oldPrice}
                    price={item.price}
                    isSelect={deliveryMethod == item.value}
                    onSelectMethod={() => {
                      onDeliveryMethodChange(item.value)
                    }}
                  />
                </Grid>
              )
            })} */}
          </Grid>
        ) : null}
      </Box>
    </Box>
  )
}

export default DeliveryMethodSection
