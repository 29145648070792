import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCartDetailsById,
  getCommercialDetails,
} from 'redux/addToCart/AddToCartSlice'
import FooterMui from '../components/FooterMui'
import ConfirmationContent from './Confirmation/ConfirmationContent'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { headingColor, whiteColor } from 'configs/styles/muiThemes'
import { Box, Container, Typography } from '@mui/material'
import BreadcrumbsSection from 'components/Breadcrumbs'
import TopComponent from './TopComponent'
import Loader from 'components/Loader'

import { getUserName } from 'utils/authHelpers'

function Confirmation() {
  const location = useLocation()
  const [pageLoading, setPageLoading] = useState(true)
  const { businessName } = useParams()
  let navigation = useNavigate()

  const { cartDetails, commercialDetails } = useSelector((state) => state.cart)

  const dispatch = useDispatch()
  const isLoggedIn = getUserName().length === 0 ? false : true

  useEffect(() => {
    if (!isLoggedIn || !location?.state?.cartId) {
      navigation(`/${businessName}`)
    }
  }, [])

  useEffect(() => {
    dispatch(getCommercialDetails())
    setTimeout(() => {
      if (location?.state?.cartId) {
        dispatch(getCartDetailsById(location?.state?.cartId))
          .then(() => {
            setPageLoading(false)
          })
          .catch((err) => {
            setPageLoading(false)
          })
      }
    }, 5000)
  }, [])
  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 10,
          backgroundColor: whiteColor,
          pb: 1.5,
        }}
      >
        {/* <Navbar value='' /> */}
        <TopComponent value='' disableBanners />
        {pageLoading && <Loader />}
        {!pageLoading && (
          <>
            <Container
              sx={{ maxWidth: '1300px !important', mt: { xs: 1.8, md: 2.5 } }}
            >
              <BreadcrumbsSection
                links={[
                  { label: 'Catalog', to: `/catalog` },
                  { label: 'Cart', to: `/cart` },
                  { label: 'Discount/Delivery', to: `#`, disabled: true },
                  { label: 'Order Confirmation', to: `/confirmation` },
                ]}
              />

              <Typography
                variant='subtitle1'
                component='h5'
                fontSize={{ xs: 15, md: 19 }}
                fontWeight={600}
                color={headingColor}
                mt={0.3}
                ml={0.1}
                py={0.3}
              >
                Order Confirmation
              </Typography>
            </Container>
          </>
        )}
      </Box>
      {!pageLoading && (
        <>
          <ConfirmationContent
            cartDetails={cartDetails}
            commercialDetails={commercialDetails}
          />
          <FooterMui />
        </>
      )}
    </>
  )
}

export default Confirmation
