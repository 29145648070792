import { StarRounded } from '@mui/icons-material'
import { Box, Container, Typography } from '@mui/material'
import {
  bannerListColor,
  bannerstarColor,
  discountSectionBGColor,
  headingColor,
  primaryColor,
} from 'configs/styles/muiThemes'
import { Link } from 'react-router-dom'
import HomeReviewCard from './HomeReviewCard'

import Slider from 'react-slick/lib/slider'

function TopReviewsSection({ allReviews, reviewSummary }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const businessName = sessionStorage.getItem('businessName')

  return (
   
    <Box backgroundColor={discountSectionBGColor} pb={2.5}>
      <Container sx={{ maxWidth: '1300px !important' }}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <Typography
            variant='h2'
            color={headingColor}
            fontSize={{ xs: '17px', md: '21px' }}
            pt={3.5}
          >
            Top Reviews
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }} mt={1.8} mb={1.8}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StarRounded
                sx={{ color: bannerstarColor, fontSize: '20px', mb: 0.3 }}
              />
              <Typography
                variant='body1'
                color={bannerListColor}
                fontWeight={500}
              >
                {reviewSummary?.average || 0}
              </Typography>
            </Box>
            <Typography variant='body1' mx={1.5}>
              &#183;
            </Typography>

            <Typography
              variant='body1'
              component={Link}
              to={`/${businessName}/review`}
              color={primaryColor}
              fontWeight={500}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {reviewSummary?.totalRated || 0} Reviews
            </Typography>
          </Box>
        </Box>

        <Slider {...settings}>
          {allReviews?.allReview?.slice(0, 3)?.map((review, index) => {
            return <HomeReviewCard review={review} />
          })}
          {/* {allReviews?allReview?.map((item, index) => {
            return <HomeReviewCard />
          })} */}
          {/* // <HomeReviewCard />
          // <HomeReviewCard />
          // <HomeReviewCard /> */}
        </Slider>
      </Container>
    </Box>
  )
}

export default TopReviewsSection
