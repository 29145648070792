import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOverviewData } from "../../services/api_calls/overview_api/OverviewApi";

const initialState = {
    overviewDetails: null,
    loading: false,
    overviewLoading: false,
};

//Fetching overview details
export const getOverviewDetails = createAsyncThunk("getOverviewDetails", async (thunkAPI) => {
    const res = await getOverviewData();
    return res;
});

export const overviewDetailsSlice = createSlice({
    name: "overview",
    initialState,
    reducers: {},
    extraReducers: {
        [getOverviewDetails.pending]: (state) => {
            state.loading = true;
            state.overviewLoading = true;
        },
        [getOverviewDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.overviewLoading = false;
            state.overviewDetails = payload;
        },
        [getOverviewDetails.rejected]: (state) => {
            state.loading = false;
            state.overviewLoading = false;
        },
    },
});

export const overviewDetailsReducer = overviewDetailsSlice.reducer;
