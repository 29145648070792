import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getOrderDetails } from 'redux/orderDetails/OrderDetailsSlice'
import FooterMui from '../components/FooterMui'
import OrderDetailsContent from './OrderDetails/OrderDetailsContent'
import TopComponent from './TopComponent'
import BreadcrumbsSection from 'components/Breadcrumbs'
import { Box, Container } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'

function OrderDetails(props) {
  const { cartId } = useParams()

  let { state } = useLocation()
  const cartDate = state?.cartDate
  const fromPage = state?.fromPage

  const dispatch = useDispatch()
  const { cartOrderDetails } = useSelector((state) => state.orderdetails)
  useEffect(() => {
    dispatch(getOrderDetails(cartId))
  }, [])

  return (
    <div>
      {/* <Navbar value='' /> */}
      {/* <TopComponent value='' disableBanners /> */}

      <Box
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 100,
          backgroundColor: whiteColor,
        }}
      >
        <TopComponent value='' disableBanners />

        <Container
          sx={{
            maxWidth: '1300px !important',
            mt: { xs: 1.8, md: 2.5 },
          }}
        >
          <BreadcrumbsSection
            links={[
              { label: fromPage?.label, to: `/${fromPage?.to}` },
              { label: 'Order Details', to: `#` },
            ]}
          />
        </Container>
      </Box>

      <OrderDetailsContent
        // data={cartData}
        // cartDate={cartDate}
        // orderStatus={cartData?.cart?.orderStatus}

        data={cartOrderDetails}
        cartDate={cartDate}
        orderStatus={cartOrderDetails?.cart?.orderStatus}
        fromPage={fromPage}
      />
      <FooterMui />
    </div>
  )
}

export default OrderDetails
