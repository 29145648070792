import { Box } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import TelegramIcon from '@mui/icons-material/Telegram'

function SocialShare({ url, quote, back }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <WhatsappShareButton url={url} quote={quote} hashtag='' onClick={back}>
        <Box
          sx={{
            ...styles.iconBtn,
            backgroundColor: '#42be50',
          }}
        >
          <WhatsAppIcon />
        </Box>
      </WhatsappShareButton>

      <FacebookShareButton url={url} quote={quote} hashtag='' onClick={back}>
        <Box
          sx={{
            ...styles.iconBtn,
            backgroundColor: '#1977F3',
          }}
        >
          <FacebookOutlinedIcon />
        </Box>
      </FacebookShareButton>

      <LinkedinShareButton url={url} quote={quote} hashtag='' onClick={back}>
        <Box
          sx={{
            ...styles.iconBtn,
            backgroundColor: '#0077B5',
          }}
        >
          <LinkedInIcon />
        </Box>
      </LinkedinShareButton>

      <TwitterShareButton url={url} quote={quote} hashtag='' onClick={back}>
        <Box
          sx={{
            ...styles.iconBtn,
            backgroundColor: '#1DA1F2',
          }}
        >
          <TwitterIcon />
        </Box>
      </TwitterShareButton>

      <TelegramShareButton url={url} quote={quote} hashtag='' onClick={back}>
        <Box
          sx={{
            ...styles.iconBtn,
            backgroundColor: '#35AADC',
          }}
        >
          <TelegramIcon />
        </Box>
      </TelegramShareButton>
    </Box>
  )
}

export default SocialShare

const styles = {
  iconBtn: {
    width: '45px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    color: whiteColor,
    borderRadius: '50%',
  },
}
