import { Box, Grid } from '@mui/material'
import { noProductImgUrl } from 'configs/Constants'
import { primaryColor, whiteColor } from '../../configs/styles/muiThemes'

export default function ProductImages({
  onImageClick,
  productImages,
  activeImg,
  onActiveClick,
}) {
  // const [activeImg, setActiveImg] = useState(productImages?.[0]?.docURL)

  // useEffect(() => {
  //   setActiveImg(productImages?.[0]?.docURL)
  // }, [productImages])

  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          component='img'
          src={activeImg}
          alt={businessFullName + ' CostBo'}
          backgroundColor={whiteColor}
          sx={{
            width: '100%',
            height: { xs: '250px', md: '340px' },
            boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
            borderRadius: '10px',
            objectFit: 'contain',
            cursor: 'pointer',
            // objectFit: "cover",
            // py: 0.5,
            // px: 7,
          }}
          onClick={() => onImageClick()}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = noProductImgUrl
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {productImages?.map((item, i) => {
            return (
              <Grid item xs={3}>
                <Box
                  component='img'
                  src={item.docURL}
                  alt={businessFullName + ' CostBo'}
                  onClick={() => onActiveClick(item.docURL)}
                  backgroundColor={whiteColor}
                  sx={{
                    width: '100%',
                    height: { xs: '70px', md: '100px' },
                    border:
                      activeImg === item.docURL
                        ? '2px solid' + primaryColor
                        : '',
                    boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
                    borderRadius: '10px',
                    // p: 0.5,
                    objectFit: 'contain',
                    cursor: 'pointer',
                    // objectFit: "cover",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = noProductImgUrl
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}
