import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getBuyAgainData, getMetricData } from 'services/api_calls/home/homeApi'
// import { getCouponsData } from "../../services/api_calls/dealsCoupons_api/DealCouponsApi";

const initialState = {
  buyAgainDetails: null,
  metricDetails: null,
  homeLoader: false,

  //Loading
  metricDetailsLoading: false
}

//Fetching Buy Again Products
export const getBuyAgainDetails = createAsyncThunk(
  'getBuyAgainDetails',
  async (thunkAPI) => {
    const res = await getBuyAgainData()
    // console.log(res.details)
    return res
  }
)

//Fetching Metric Products
export const getMetricDetails = createAsyncThunk(
  'getMetricDetails',
  async (thunkAPI) => {
    const res = await getMetricData()
    // console.log(res.details)
    return res
  }
)

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: {
    [getBuyAgainDetails.pending]: (state) => {
      state.homeLoader = true
    },
    [getBuyAgainDetails.fulfilled]: (state, { payload }) => {
      state.homeLoader = false
      state.buyAgainDetails = payload?.details
    },
    [getBuyAgainDetails.rejected]: (state) => {
      state.homeLoader = false
    },

    [getMetricDetails.pending]: (state) => {
      state.metricDetailsLoading = true
      state.homeLoader = true
    },
    [getMetricDetails.fulfilled]: (state, { payload }) => {
      state.metricDetailsLoading = false
      state.homeLoader = false
      state.metricDetails = payload?.details
    },
    [getMetricDetails.rejected]: (state) => {
      state.metricDetailsLoading = false
      state.homeLoader = false
    },
  },
})

export const homeReducer = homeSlice.reducer
