import { getProfileId } from 'utils/authHelpers'
import { getApiInstance } from '../../ApiInstance'

// const businessId = sessionStorage.getItem('businessId')

export const getReviewSummaryData = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_REVIEW_VIEW}/getReviewSummary?params=${businessId}`
    )
    console.log("GET_REVIEW_SUMMARY:", response?.data);
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
//Old Review API
// export const getAllReviewData = async (param) => {
//   const dateData= param ?  param.date: false
//   const helpData=param? param.isHelpful :false
//   const pageNo = param ? param.pageNo || 1 : 1
//   console.log("PAGE_NO:", pageNo);
//   const instance = getApiInstance();
//   try {
//     const response = await instance.get(
//       `${process.env.REACT_APP_REVIEW_VIEW}/viewAllReview/${businessId}?date=${dateData ? dateData:false}&profileId=3e1539ace15744df85ff5a2f09189ee9&pageSize=6&pageNo=${pageNo}&isHelpful=${helpData?helpData:false}`
//     );
//     console.log("REVIEW_DATA: ", response?.data);
//     return response?.data;
//   } catch (error) {
//     console.log(error);
//   }
// };

//New Review API
export const getAllReviewData = async (param) => {
  const businessId = sessionStorage.getItem('businessId')

  const option = param ? param.option : 'Most Recent'
  let sort = 'date'
  let order = 'desc'
  const pageNo = param ? param.pageNo || 1 : 1

  if (option === 'Most Helpful') {
    sort = 'helpful'
    order = 'desc'
  } 
  
  else if (option === 'Highest Rating') {
    sort = 'rating'
    order = 'desc'
  } 
  
  else if (option === 'Lowest Rating') {
    sort = 'rating'
    order = 'asc'
  } 
  
  else if (option === 'Most Recent') {
    sort = 'date'
    order = 'desc'
  }

  // const profileId = '3e1539ace15744df85ff5a2f09189ee9'
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `review-query-side/api/v1/open/review/business-reviews/${businessId}?profileId=${profileId}&pageNo=${pageNo}&sort=${sort}&order=${order}`
    )
    console.log('REVIEW_DATA: ', response?.data)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const getOwnReviewData = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const profileId = getProfileId()
  
  const header = {
    profileId: profileId,
    // Authorization:
    //   'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIrOTE2MzYyMjUxNTI3Iiwic2NvcGUiOlsib3BlbmlkIl0sInJhbmRvbU5vIjoiOTNkMjdlNDg0MzQwNDMzMWI0M2FhY2E0MThkMzJjMjQiLCJleHAiOjE2NjMwMjM2OTQsImlhdCI6MTY0NzI0MzY5NCwiYXV0aG9yaXRpZXMiOlsiQ1VTVE9NRVJfUk9MRSJdLCJqdGkiOiI1YzBjMTE5ZS05ZjZlLTQ3NjEtYmVkZS1jYzgyYTJkMGIzMmEiLCJjbGllbnRfaWQiOiJ3ZWJfYXBwIn0.VoKmOxcObfsBPbJ8WWoghtduIPhVEi-f9W0bgeREJUiHQr7id-N_IDNMPW7tblTaEq1EDuJl6mU6NsvToRoU4mtGiQpCnjfv4Wb_bgPdWN5Iw818oFiE-KIVpg61a1EL1R3eAEv0xtBcQOnZRMmRo4IwfsyEFlJedfrCXfS7GANvCZUX72cZplpxaiFM7oAvWuPOAWm-3XooXEOZBU8oqBJddVuR8QNqN-cIWo1a2Pc2YD6-C5C7z-rJ46dPAr2g8b9kh0F8G0SmC6ucV8YF53cM5GICyVjaajUGl4bpEpdmqdI3Ucl-6LNRnbtauKnI0AIMfUG0hGl_IwmQ3EW9OA',
    // 'Content-Type': 'application/json',
  }
  const instance = getApiInstance(header)
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_REVIEW_VIEW}/viewOwnReview/${profileId}/${businessId}`
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const addReview = async (data) => {
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_MODIFY_QUERY}/addReview`,
      data
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const editReview = async (data, reviewId) => {
  const header = {
    reviewId: reviewId,
  }
  const instance = getApiInstance(header)
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_MODIFY_QUERY}/updateReview`,
      data,
      {
        headers: { reviewId: reviewId },
      }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const helpFulReview = async (reviewId, profileId) => {
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_MODIFY_QUERY}/isHelpful`,
      {},
      {
        headers: {
          reviewId: reviewId,
          profileId: profileId,
        },
      }
    )
    console.log('HELPFULL_HIT: ', response)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const reportAbuse = async (reviewId) => {
  const instance = getApiInstance()
  try {
    const response = await instance.post(
      `${process.env.REACT_APP_MODIFY_QUERY}/reportAbuse`,
      {},
      {
        headers: {
          reviewId: reviewId,
        },
      }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getTotalReviewCount = async (formdata) => {
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `${process.env.REACT_APP_REVIEW_VIEW}/getMyTotalReviewCount?${formdata}`
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getCostboContactCounts = async (formdata) => {
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `contact-query-side/api/v1/contact/getMyCostboContactCounts?${formdata}`
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getProfileImages = async (formdata) => {
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `account-query-side/api/v1/account/getAccountSummary?${formdata}`
    )
    return response
  } catch (error) {
    console.log(error)
  }
}
