import moment from 'moment'

export const getOutOfStock = (item) => {
  const startDate = moment
    .unix(item.commerceStartDate / 1000)
    .format('YYYY-MM-DD')
  const endDate = moment.unix(item.commerceEndDate / 1000).format('YYYY-MM-DD')
  const currentDate = moment().format('YYYY-MM-DD')
  var bool1 = moment(currentDate).isBetween(startDate, endDate, null, '[]')
  const mapCheck =
    (item?.subscription?.status !== 'active' ||
      (!bool1 && item.commerceEnabled?.toUpperCase() === 'YES') ||
      item.availableQuantity < item.minOrderQuantity) &&
    (item?.availability?.[0]?.toUpperCase() === 'NO' ||
      (!bool1 && item.commerceEnabled?.toUpperCase() === 'YES') ||
      item.availableQuantity < item.minOrderQuantity)
  return mapCheck
}

export const getCommingSoon = (item) => {
  const startDate = moment
    .unix(item.commerceStartDate / 1000)
    .format('YYYY-MM-DD')
  const currentDate = moment().format('YYYY-MM-DD')

  let bool = moment(currentDate).isBefore(startDate)
  const isCommingSoon =
    item.status === 'inactive' ||
    bool ||
    item.commerceEnabled?.toUpperCase() === 'NO'
  return isCommingSoon
}
