import { Box, Grid, Stack, Typography } from '@mui/material'
import { AddRounded, RemoveRounded } from '@mui/icons-material'
import {
  arrowBgColor,
  headingColor,
  primaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function QuantityPicker({ count, onIncrement, onDecrement, minQty, maxQty }) {
  return (
    <Stack>
      <Box sx={styles.quantityPicker}>
        <Grid container direction='row' alignItems='center'>
          <Grid item>
            <Box
              sx={[
                styles.qtBtn,
                {
                  borderRight: '1px solid ' + primaryColor,
                  border: '',
                },
              ]}
              onClick={onDecrement}
            >
              <RemoveRounded
                sx={{
                  // color: count <= minQty ? arrowBgColor : headingColor,
                  color: headingColor,
                  fontSize: '18px',
                }}
              />
            </Box>
          </Grid>

          {/* <Divider orientation='vertical' flexItem /> */}

          <Grid item>
            <Typography
              variant='body1'
              color={headingColor}
              fontSize='12px'
              width='30px'
              fontWeight={600}
              textAlign='center'
            >
              {count}
            </Typography>
          </Grid>

          {/* <Divider orientation='vertical' flexItem sx={{backgroundColor: primaryColor}} /> */}

          <Grid item onClick={onIncrement}>
            <Box
              sx={[
                styles.qtBtn,
                {
                  borderLeft: '1px solid ' + primaryColor,
                  border: '',
                },
              ]}
            >
              <AddRounded
                sx={{
                  color: count >= maxQty ? arrowBgColor : headingColor,
                  fontSize: '18px',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <Typography
            variant='body1'
            color={headingColor}
            fontSize='10px'
            fontWeight={500}
            textAlign='center'
          >
            Min {minQty}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant='body1'
            color={headingColor}
            fontSize='10px'
            fontWeight={500}
            textAlign='center'
          >
            Max {maxQty}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default QuantityPicker

const styles = {
  quantityPicker: {
    width:'90px',
    height: '28px',
    borderRadius: 1.2,
    backgroundColor: whiteColor,
    border: '1px solid ' + primaryColor,
    // boxShadow: '0 3px 4px rgb(0 0 0 / 15%)',
    mt: 1.5,
    overflow: 'hidden',
  },

  qtBtn: {
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}
