import styled from '@emotion/styled'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import FormActionButtons from '../../components/Button/FormActionButtons'
import StyledSelectWithLabel from '../../components/Input/StyledSelectWithLabel'
import StyledTextInput from '../../components/Input/StyledTextInput'
import {
  blackShade18,
  primaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function AffiliateBankDetailsForm({
  bankDetails,
  onValueChange,
  setCurrentPage,
  handleNext,
  isReadOnly,
  loading,
}) {
  const ACCOUNT_TYPES = [
    { label: 'Choose Account Type', value: 'Choose Account Type' },
    { label: 'Savings', value: 'savings' },
    { label: 'Current', value: 'current' },
  ]
  return (
    <Box p={2}>
      <from>
        <StyledTextInput
          label='Account Number'
          placeholder='Enter Account Number'
          value={bankDetails?.accountNo}
          onChange={({ target }) => {
            const accountNum = target.value
              .replace(/[^a-zA-Z0-9]/g, '')
              .toUpperCase()
            onValueChange('accountNo', accountNum)
          }}
          required
          errorMsg={bankDetails?.errors?.accountNo}
          disabled={isReadOnly}
          isReadOnly={isReadOnly}
        />

        <StyledTextInput
          label='IFSC Code'
          placeholder='Enter IFSC Code'
          value={bankDetails?.ifscCode}
          onChange={({ target }) => {
            const value = target.value
              .replace(/[^a-zA-Z0-9]/g, '')
              .toUpperCase()
            onValueChange('ifscCode', value)
          }}
          maxLength={11}
          errorMsg={bankDetails?.errors?.ifscCode}
          required
          disabled={isReadOnly}
          isReadOnly={isReadOnly}
        />

        <StyledTextInput
          label='Bank Name'
          placeholder='Enter Bank Name'
          value={bankDetails?.bankName}
          onChange={({ target }) => {
            onValueChange('bankName', target.value)
          }}
          errorMsg={bankDetails?.errors?.bankName}
          required
          disabled={isReadOnly}
          isReadOnly={isReadOnly}
        />

        <StyledSelectWithLabel
          label='Account Type'
          placeholder='Enter Account Type'
          value={bankDetails?.accountType}
          menuList={ACCOUNT_TYPES}
          onValueChange={(value) => {
            onValueChange('accountType', value)
          }}
          errorMsg={bankDetails?.errors?.accountType}
          required
          disabled={isReadOnly}
          isReadOnly={isReadOnly}
        />

        <StyledTextInput
          label='Pan Number'
          placeholder='Enter Pan Number'
          value={bankDetails?.panNumber}
          onChange={({ target }) => {
            const value = target.value
              .replace(/[^a-zA-Z0-9]/g, '')
              .toUpperCase()
            onValueChange('panNumber', value)
          }}
          maxLength={10}
          errorMsg={bankDetails?.errors?.panNumber}
          required
          disabled={isReadOnly}
          isReadOnly={isReadOnly}
        />

        <Box mb={2}>
          {isReadOnly && (
            <Typography
              variant='body2'
              fontSize={13}
              fontWeight={400}
              color={blackShade18}
              textAlign='center'
            >
              For Banking information update Please send Email to{' '}
              <span style={{ fontWeight: '600' }}>support@costbo.com</span>
            </Typography>
          )}
        </Box>

        {!isReadOnly && (
          <StyledButton
            fullWidth
            onClick={handleNext}
            mt={1}
            disabled={loading}
          >
            {loading && (
              <CircularProgress color='inherit' size={18} sx={{ mr: 1.5 }} />
            )}{' '}
            UPDATE DETAILS
          </StyledButton>
        )}

        {/* <FormActionButtons
          hideSaveBtn
          hideNextBtn
          onPrevious={() => {
            setCurrentPage((previousState) => previousState - 1)
          }}
          onNext={handleNext}
          onSave={() => {}}
        /> */}
      </from>
    </Box>
  )
}

export default AffiliateBankDetailsForm

const StyledButton = styled(Button)(({ disabled }) => ({
  color: whiteColor,
  backgroundColor: disabled ? 'rgba(0,0,0,0.1)' : primaryColor,
  border: disabled ? '1px solid rgba(0,0,0,0.1)' : '1px solid ' + primaryColor,
  padding: '6px 25px',
  margin: '0 0 15px',

  '&:hover': {
    color: primaryColor,
    backgroundColor: whiteColor,
  },
}))
