import { Box, Typography } from '@mui/material'
import {
  borderLineColor,
  couponColor,
  headingColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function DeliveryAddress({ address, isPayCart }) {
  return (
    <Box
      sx={{ backgroundColor: whiteColor }}
      border={'1px solid ' + borderLineColor}
      borderRadius={2.2}
      p={1.85}
      mb={3}
      mt={{ xs: 0, md: 3 }}
    >
      <Typography
        variant='h5'
        component='h3'
        fontSize={{ xs: 14, md: 15 }}
        color={headingColor}
        pb={1.8}
        borderBottom={'1px solid ' + borderLineColor}
        textAlign='right'
      >
        {isPayCart ? 'Customer Address' : 'Delivery Address'}
      </Typography>

      <Typography
        variant='h6'
        fontSize={{ xs: 15, md: 15 }}
        color={headingColor}
        lineHeight='42px'
        textAlign='start'
      >
        {address?.name} {address?.type ? `(${address?.type})` : ''}
      </Typography>

      <Typography
        variant='body2'
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={500}
        color={couponColor}
        textAlign='start'
      >
        {address?.addressLine1}
      </Typography>
      <Typography
        variant='body2'
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={500}
        color={couponColor}
        textAlign='start'
      >
        {address?.landMark}
      </Typography>
      <Typography
        variant='body2'
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={500}
        color={couponColor}
        mt={0.6}
        textAlign='start'
      >
        {address?.city} - {address?.zip}
      </Typography>
      <Typography
        variant='body2'
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={500}
        color={couponColor}
        textAlign='start'
      >
        {address?.state}, {address?.country}
      </Typography>

      <Typography
        variant='body2'
        fontSize={{ xs: 13, md: 14 }}
        fontWeight={500}
        color={couponColor}
        textAlign='justify'
        mt={1}
      >
        Ph: {isPayCart ? `+91 ${address?.alternatePhoneNo?.replace('+91', '')}` : address?.phoneNo}
      </Typography>
    </Box>
  )
}

export default DeliveryAddress
