import {
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  Pagination,
  PaginationItem,
} from '@mui/material'
import { useState, useRef } from 'react'
import OwnReview from './OwnReview'
import ReviewCard from './ReviewCard'
import ModalCard from '../../components/ModalCard'
import ReviewSummaryGraph from './ReviewSummaryGraph'
import EditReview from './ModelComponents/editReview'
import AddReview from './ModelComponents/addReview'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { headingColor } from '../../configs/styles/muiThemes'
import ReviewLoginAlert from './ReviewLoginAlert'
import CustomerReview from './CustomerReview'

const ReviewPageNew = ({
  reviewSummary,
  ownReview,
  graphData,
  getOwnReviewDetails,
  getSummary,
  data,
  getAllReviewDetails,
  optionData,
  isLoggedIn,
  setShowLogin,
}) => {
  const [openFullReview, setOpenFullReview] = useState(false)
  const [openUpdateReview, setOpenUpdateReview] = useState(false)
  const selectedData = useRef(
    data ? (data.allReview !== null ? data.allReview[0] : undefined) : undefined
  )

  const textData = optionData

  // TO close full review
  const handleFullReviewClose = () => {
    setOpenFullReview(false)
  }

  const handlePagination = (event, value) => {
    getAllReviewDetails(textData, value)
  }

  return (
    <Container sx={{ maxWidth: '1300px !important' }}>
      {selectedData.current && (
        <ModalCard handleClose={handleFullReviewClose} open={openFullReview}>
          <ReviewCard
            disableDate={true}
            showFullData={true}
            data={selectedData.current.data}
            connection={selectedData.current.connection}
            totalReviews={selectedData.current.totalReviews}
          />
        </ModalCard>
      )}

      <Typography
        variant='h2'
        fontSize={{ md: '19px', xs: '16px' }}
        color={headingColor}
        pt={3.5}
        align='left'
      >
        Review
      </Typography>
      <Grid container rowSpacing={4} columnSpacing={10}>
        <Grid item xs={12} sm={7}>
          {!isLoggedIn ? (
            <ReviewLoginAlert onLoginClick={setShowLogin} />
          ) : ownReview === null ? (
            <AddReview
              getOwnReviewDetails={() => getOwnReviewDetails()}
              getSummary={() => getSummary()}
            />
          ) : (
            <OwnReview
              onEditClick={() => setOpenUpdateReview(true)}
              reviews={ownReview}
            />
          )}
        </Grid>

        {/* --Review Graph--- */}
        {/* {ownReview !== null && ( */}
        <Grid item xs={12} sm={5}>
          {reviewSummary && graphData && (
            <ReviewSummaryGraph
              reviewSummary={reviewSummary}
              graphData={graphData}
              ownReview={ownReview}
            />
          )}
        </Grid>
      </Grid>
      {/* ---End--- */}

      <CustomerReview />
      {/* ---Customer Reviews--- */}

      {/* ---End--- */}

      {/* ---Edit Own Review--- */}
      {ownReview !== null}
      <EditReview
        ownReview={ownReview}
        open={openUpdateReview}
        getOwnReviewDetails={() => getOwnReviewDetails()}
        getSummary={() => getSummary()}
        onClose={() => setOpenUpdateReview(false)}
      />
      {/* ---End--- */}

      {/* ---Pagination--- */}
      {data &&
      data.allReview !== null &&
      data.allReview.length > 0 &&
      data?.totalReview / data?.size > 1 ? (
        <Stack
          spacing={2}
          my={4}
          // display={{ md: 'flex', xs: 'none' }}
          alignItems='center'
          justifyContent='center'
        >
          <Pagination
            // count={data ? Math.ceil(data?.totalReview / 6) : 0}
            // page={data ? data?.pageNo : 0}
            // count={data ? Math.ceil(data?.totalReview / 10) : 0}
            count={data ? Math.ceil(data?.totalReview / data?.size) : 0}
            page={data ? data?.pageNo : 0}
            onChange={handlePagination}
            color='primary'
            renderItem={(item) => (
              <PaginationItem
                components={{
                  next: NextBtn,
                  previous: PreviousBtn,
                }}
                {...item}
              />
            )}
          />
        </Stack>
      ) : null}
      {/* ---Pagination End--- */}
    </Container>
  )
}

export default ReviewPageNew

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  )
}

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  )
}

const styles = {
  btnHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}
