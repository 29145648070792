import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { Suspense, useEffect, useRef, useState } from 'react'
import {
  borderLineColor,
  filterBgColor,
  headingColor,
  iconInactiveColor,
  navLinkColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

import Slider from 'react-slick/lib/slider'
import ProductCard from 'pages/CatalogMui/ProductCard'
import { getOutOfStock } from 'utils/productHelper'
import ScrollIndicator from 'components/ScrollIndicator'

function HomeMetricGroup({ title, products, type, caption }) {
  const slider = useRef(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [isScrollEnd, setIsScrollEnd] = useState(false)
  const containerRef = useRef(null)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  }

  const onSlideNext = () => {
    if (currentSlideIndex < products?.length - 6) {
      slider.current.slickNext()
    }
  }

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev()
    }
  }

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      // Check if the horizontal scroll has reached the end
      const isEnd =
        container?.scrollLeft + container?.clientWidth + 200 >=
        container?.scrollWidth

      setIsScrollEnd(isEnd)
    }

    container?.addEventListener('scroll', handleScroll)

    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Suspense fallback={<p></p>}>
      <Box mt={1} mb='20px !important' position='relative'>
        {/* Header */}
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item>
            <Typography
              variant='h2'
              fontSize={{ md: '17px', xs: '15px' }}
              color={headingColor}
              display='inline'
            >
              {title}
            </Typography>
          </Grid>

          {products?.length > 6 ? (
            <Grid item>
              <Grid container spacing={1.5}>
                <Grid item display={{ xs: 'none', md: 'block' }}>
                  <Box
                    width='40px'
                    height='40px'
                    backgroundColor={
                      currentSlideIndex === 0 ? filterBgColor : whiteColor
                    }
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    borderRadius={2}
                    sx={{ cursor: 'pointer' }}
                    border={'1px solid ' + borderLineColor}
                    onClick={() => onSlidePrev()}
                  >
                    <ChevronLeft
                      fontSize='medium'
                      sx={{
                        color:
                          currentSlideIndex === 0
                            ? iconInactiveColor
                            : navLinkColor,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item display={{ xs: 'none', md: 'block' }}>
                  <Box
                    width='40px'
                    height='40px'
                    backgroundColor={
                      currentSlideIndex === products?.length - 6
                        ? filterBgColor
                        : whiteColor
                    }
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    borderRadius={2}
                    border={'1px solid ' + borderLineColor}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onSlideNext()}
                  >
                    <ChevronRight
                      fontSize='medium'
                      sx={{
                        color:
                          currentSlideIndex === products?.length - 6
                            ? iconInactiveColor
                            : navLinkColor,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        {/* Products List */}
        <Grid container mt={{ xs: 0, md: 0.3 }} spacing={1}>
          {matches ? (
            <Grid item xs={0} md={2}>
              <SectionInfoCard type={type} caption={caption} />
            </Grid>
          ) : null}

          <Grid item xs={12} md={10}>
            {products?.length > 6 ? (
              <>
                <Box display={{ xs: 'none', md: 'block' }}>
                  <Slider
                    ref={slider}
                    {...settings}
                    beforeChange={(oldIndex, newIndex) => {
                      setCurrentSlideIndex(newIndex)
                    }}
                  >
                    {products?.map((item, index) => {
                      return (
                        <Box py={0.5} ml={1} key={`${title}_${index}`}>
                          {
                            <ProductCard
                              img={item?.productPhoto?.[0]?.docURL}
                              price={item?.productSalePrice}
                              oldPrice={item?.productOriginalPrice}
                              description={item?.productName}
                              discount={item?.percentageOff}
                              style={{ my: 1.5 }}
                              // isOutOfStock={getOutOfStock(item)}
                              // isComingSoon={item?.commerceEnabled === 'NO'}
                              minQty={item?.minOrderQuantity}
                              maxQty={item?.maxOrderQuantity}
                              disableAddtoCart
                              product={item}
                            />
                          }
                        </Box>
                      )
                    })}
                  </Slider>
                </Box>

                <Box display={{ xs: 'block', md: 'none' }}>
                  <Box sx={styles.scrollBox} ref={containerRef}>
                    {products?.map((item, index) => {
                      return (
                        <Box py={0.5} ml={1} key={`${title}_${index}`}>
                          <ProductCard
                            img={item?.productPhoto?.[0]?.docURL}
                            price={item?.productSalePrice}
                            oldPrice={item?.productOriginalPrice}
                            description={item?.productName}
                            discount={item?.percentageOff}
                            style={{ my: 1.5 }}
                            isOutOfStock={getOutOfStock(item)}
                            isComingSoon={item?.commerceEnabled === 'NO'}
                            minQty={item?.minOrderQuantity}
                            maxQty={item?.maxOrderQuantity}
                            disableAddtoCart
                            product={item}
                          />
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </>
            ) : matches ? (
              <Box display='flex' flexDirection='row' pl={1}>
                {products?.map((item, index) => {
                  return (
                    <Box py={0.5} mr={2} key={`${title}_${index}`}>
                      {
                        <ProductCard
                          img={item?.productPhoto?.[0]?.docURL}
                          price={item?.productSalePrice}
                          oldPrice={item?.productOriginalPrice}
                          description={item?.productName}
                          discount={item?.percentageOff}
                          style={{ my: 1.5 }}
                          // isOutOfStock={getOutOfStock(item)}
                          // isComingSoon={item?.commerceEnabled === 'NO'}
                          minQty={item?.minOrderQuantity}
                          maxQty={item?.maxOrderQuantity}
                          disableAddtoCart
                          product={item}
                        />
                      }
                    </Box>
                  )
                })}
              </Box>
            ) : (
              <Box sx={styles.scrollBox}>
                {products?.map((item, index) => {
                  return (
                    <Box py={0.5} ml={1} key={`${title}_${index}`}>
                      <ProductCard
                        img={item?.productPhoto?.[0]?.docURL}
                        price={item?.productSalePrice}
                        oldPrice={item?.productOriginalPrice}
                        description={item?.productName}
                        discount={item?.percentageOff}
                        style={{ my: 1.5 }}
                        isOutOfStock={getOutOfStock(item)}
                        isComingSoon={item?.commerceEnabled === 'NO'}
                        minQty={item?.minOrderQuantity}
                        maxQty={item?.maxOrderQuantity}
                        disableAddtoCart
                        product={item}
                      />
                    </Box>
                  )
                })}
              </Box>
              // <Slider
              //   ref={slider}
              //   {...settings}
              //   beforeChange={(oldIndex, newIndex) => {
              //     setCurrentSlideIndex(newIndex)
              //   }}
              // >
              //   {products?.map((item, index) => {
              //     return (
              //       <Box py={0.5} ml={1}>
              //         {
              //           <ProductCard
              //             img={item?.productPhoto?.[0]?.docURL}
              //             price={item?.productSalePrice}
              //             oldPrice={item?.productOriginalPrice}
              //             description={item?.productName}
              //             discount={item?.percentageOff}
              //             style={{ my: 1.5 }}
              //             isOutOfStock={getOutOfStock(item)}
              //             isComingSoon={item?.commerceEnabled === 'NO'}
              //             minQty={item?.minOrderQuantity}
              //             maxQty={item?.maxOrderQuantity}
              //             disableAddtoCart
              //             product={item}
              //           />
              //         }
              //       </Box>
              //     )
              //   })}
              // </Slider>
            )}
          </Grid>
        </Grid>

        {!isScrollEnd && products?.length > 2 && (
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              position: 'absolute',
              right: '0px',
              top: '57%',
            }}
          >
            <ScrollIndicator />
          </Box>
        )}
      </Box>
    </Suspense>
  )
}

export default HomeMetricGroup

const styles = {
  scrollBox: {
    display: 'flex',
    alignItems: 'center',

    overflowX: 'scroll',
    overscrollBehaviorInline: 'contain',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '-webkit-overflow-scrolling': 'touch',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
}

const getImageColor = (type) => {
  switch (type) {
    case 'trending':
      return {
        img: 'https://storage.googleapis.com/bodefaults/shopweb/banner_trending_product.png',
        bgColor: '#ffe5f5',
      }
    case 'top-deals':
      return {
        img: 'https://storage.googleapis.com/bodefaults/shopweb/banner_top_deals.png',
        bgColor: '#f5e7cf',
      }
    case 'bestseller':
      return {
        img: 'https://storage.googleapis.com/bodefaults/shopweb/banner_best_sellers.png',
        bgColor: '#fbe6ff',
      }
    case 'new-store':
      return {
        img: 'https://storage.googleapis.com/bodefaults/shopweb/banner_new_in_our_store.png',
        bgColor: '#dde8ff',
      }
    case 'price-right':
      return {
        img: 'https://storage.googleapis.com/bodefaults/shopweb/banner_product-below-certain.png',
        bgColor: '#dde8ff',
      }
    default:
      return {
        img: 'https://storage.googleapis.com/bodefaults/shopweb/banner_top_deals.png',
        bgColor: '#e7f4db',
      }
  }
}

const SectionInfoCard = ({ type, caption }) => {
  const data = getImageColor(type)
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      height='210px'
      //   backgroundColor='#f5e7cf'
      backgroundColor={data.bgColor}
      p={1}
      borderRadius={1.5}
      //   display='flex'
      display={{ xs: 'none', md: 'flex' }}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      py={1.5}
    >
      <Box
        component='img'
        src={data.img}
        alt={businessFullName + ' CostBo'}
        width='180px'
        height='auto'
      />
      {/* <Typography
        variant='h5'
        color={headingColor}
        component='p'
        mt={1.4}
        textAlign='center'
      >
        {caption}
      </Typography> */}
    </Box>
  )
}
