import { InputAdornment, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import {
  borderTextbox,
  descriptionColor,
  errorTextColor,
} from '../configs/styles/muiThemes'
import { allowOnlyEnglish } from 'utils/utilities';

function AuthTextBox({
  placeholder,
  name,
  value,
  onChange,
  icon,
  onIconClick,
  showPassword,
  maxLength,
  inputType,
  isError,
  error,
  errorMsg,
  helperText,
  autoFocus,
  inputRef,
  onKeyUp,
  showAutoComplete,
}) {
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = allowOnlyEnglish(inputValue)

    if(onChange){
      onChange({ target: { value: sanitizedValue } });
    }
  };

  return (
    <Box>
      <TextField
        variant='standard'
        required={true}
        fullWidth
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        inputRef={inputRef}
        sx={{ fontSize: 13, my: '6px !important', borderBottom: borderTextbox }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
              {icon}
            </InputAdornment>
          ),
          style: { padding: '8px' },
          disableUnderline: true,
        }}
        inputProps={{
          maxLength: maxLength,
          autocomplete: showAutoComplete
            ? 'on'
            : inputType === 'password'
            ? 'new-password'
            : 'off',
        }}
        type={inputType}

        // {...(error && { error: true, helperText: error })}
      />

      {isError ? (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={13}
          color={errorTextColor}
        >
          {errorMsg}
        </Typography>
      ) : null}

      {helperText ? (
        <Typography
          variant='body1'
          component='p'
          fontSize={11}
          color={descriptionColor}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  )
}

export default AuthTextBox
