import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAccountData,
  getAllCancelledOrders,
  getAllCompletedOrders,
  getAllOpenOrders,
  getAllOrderByProductIdBusinessDm,
  getAvailabelCashback,
  getOrdersCountAPI,
  getUserAddress,
} from 'services/api_calls/dashboard_api/DashboardApi'

const initialState = {
  accountDetails: null,
  orderDetails: null,
  openOrderDetails: null,
  completedOrderDetails: null,
  cancelledOrderDetails: null,
  cashbackData: null,
  allAddress: null,
  ordersCountData: null,
  loading: false,

}

//Fetching user account details
export const getAccountDetails = createAsyncThunk(
  'getAccountDetails',
  async (thunkAPI) => {
    const res = await getAccountData()
    console.log('gscjgwcsjws', res)
    return res
  }
)

//Fetching Orders Count
export const getOrdersCount = createAsyncThunk(
  'getOrdersCount',
  async (businessId) => {
    const res = await getOrdersCountAPI(businessId)
    return res
  }
)

//Fetching All Order details
export const getAllOrderDetails = createAsyncThunk(
  'getAllOrderDetails',
  async (params) => {
    const res = await getAllOrderByProductIdBusinessDm(params)
    return res
  }
)

//Fetching All Open Order details (Pending Orders)
export const getAllOpenOrderDetails = createAsyncThunk(
  'getAllOpenOrderDetails',
  async (params) => {
    const res = await getAllOpenOrders(params)
    return res
  }
)

//Fetching All Completed Order details
export const getAllCompletedOrderDetails = createAsyncThunk(
  'getAllCompletedOrderDetails',
  async (businessId) => {
    const res = await getAllCompletedOrders(businessId)
    return res
  }
)

//Fetching All Cancelled Order details
export const getAllCancelledOrderDetails = createAsyncThunk(
  'getAllCancelledOrderDetails',
  async (businessId) => {
    const res = await getAllCancelledOrders(businessId)
    return res
  }
)

//Fetching Available Cashback
export const getCashbackAvailabel = createAsyncThunk(
  'getCashbackAvailabel',
  async (businessId) => {
    const res = await getAvailabelCashback(businessId)
    return res
  }
)

//Fetching Address
export const getAddress = createAsyncThunk('getAddress', async () => {
  const res = await getUserAddress()
  return res
})

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    //Fetch User Account Details
    [getAccountDetails.pending]: (state) => {
      state.loading = true
    },
    [getAccountDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.accountDetails = payload
    },
    [getAccountDetails.rejected]: (state) => {
      state.loading = false
    },

    //Fetch Orders Count
    [getOrdersCount.pending]: (state) => {
      state.loading = true
    },
    [getOrdersCount.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.ordersCountData = payload
    },
    [getOrdersCount.rejected]: (state) => {
      state.loading = false
    },

    //Fetch All Order Details
    [getAllOrderDetails.pending]: (state) => {
      state.loading = true
    },
    [getAllOrderDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.orderDetails = payload
    },
    [getAllOrderDetails.rejected]: (state) => {
      state.loading = false
    },

    //Fetch All Open Order Details (Pending Order)
    [getAllOpenOrderDetails.pending]: (state) => {
      state.loading = true
    },
    [getAllOpenOrderDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.openOrderDetails = payload
    },
    [getAllOpenOrderDetails.rejected]: (state) => {
      state.loading = false
    },

    //Fetch Available Cashback
    [getCashbackAvailabel.pending]: (state) => {
      state.loading = true
    },
    [getCashbackAvailabel.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.cashbackData = payload
    },
    [getCashbackAvailabel.rejected]: (state) => {
      state.loading = false
    },

    //Fetch Completed Orders
    [getAllCompletedOrderDetails.pending]: (state) => {
      state.loading = true
    },
    [getAllCompletedOrderDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.completedOrderDetails = payload
    },
    [getAllCompletedOrderDetails.rejected]: (state) => {
      state.loading = false
    },

    //Fetch Cancelled Orders
    [getAllCancelledOrderDetails.pending]: (state) => {
      state.loading = true
    },
    [getAllCancelledOrderDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.cancelledOrderDetails = payload
    },
    [getAllCancelledOrderDetails.rejected]: (state) => {
      state.loading = false
    },

    //Fetch All User Address
    [getAddress.pending]: (state) => {
      state.loading = true
    },
    [getAddress.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.allAddress = payload
    },
    [getAddress.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const dashboardReducer = dashboardSlice.reducer
