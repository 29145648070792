import {
  Box,
  Button,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from '@mui/material'
import React, { useRef, useState } from 'react'
import CategoryGroupHeader from './CategoryGroupHeader'
import ProductCard from './ProductCard'
import { automobileProducts } from '../../utils/demoData'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { getOutOfStock } from 'utils/productHelper'
import Slider from 'react-slick/lib/slider'

function FilteredCategory({
  selectedCategory,
  cartData,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
}) {
  return (
    <Box pt={1}>
      <CategoryGroupHeader
        title={selectedCategory?.title}
        totalProduct={selectedCategory?.products?.length}
        noAction
      />

      <Grid container columnSpacing={1} rowSpacing={2.5} pt={1.5} pl={1}>
        {selectedCategory?.products?.map((item, index) => {
          return (
            <Grid item xs={6} sm={3} md={2.4}>
              <ProductCard
                img={item.productPhoto[0].docURL}
                price={item.productSalePrice}
                oldPrice={item.productOriginalPrice}
                description={item.productName}
                discount={item.percentageOff}
                style={{ my: 1.5 }}
                isOutOfStock={getOutOfStock(item)}
                isComingSoon={item.commerceEnabled === 'NO'}
                minQty={item.minOrderQuantity}
                maxQty={item.maxOrderQuantity}
                currencySymbol={item.currencySymbol ? '₹' : item.currencySymbol}
                cartData={cartData}
                product={item}
                cartId={cartId}
                onQtyUpdate={(data) => {
                  onQtyUpdate(data)
                }}
                onAddProduct={(data) => {
                  onAddProduct(data)
                }}
                onRemoveProduct={onRemoveProduct}
                subscribe={
                  item?.subscription?.status === 'active' 
                }
                availability={item?.availability?.[0]?.toLowerCase() === 'yes'}
                subscription={item?.subscription}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default FilteredCategory
