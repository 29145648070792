import FooterMui from '../components/FooterMui'
import { Grid, Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCatalogAllProducts,
  getDataForSideBar,
} from 'redux/subCatergory/subCategorySlice'
import React, { useEffect, useState } from 'react'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { getCoupons } from 'redux/dealsCoupons/dealsCouponsSlice'
import { getReviewSummaryDetails } from 'redux/reviews/reviewsSlice'
import {
  finboxStatusChange,
  fintechStatusChange,
  getRewardPointsDetails,
} from 'redux/checkout/CheckoutSlice'
import { getBuyAgainDetails, getMetricDetails } from 'redux/home/HomeSlice'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import { getPhoneNumber, getUserName } from 'utils/authHelpers'
import HomeContent from './Home/HomeContent'
import BusinessClosed from './Home/BusinessClosed'
import CommingSoonCard from './Home/CommingSoonCard'
import { getAllReviewDetails } from 'redux/reviews/cusomerReviewSlice'
import Loader from 'components/Loader'
import { getActiveBusiness } from 'redux/header/headerSlice'

import { getCartDetails } from 'redux/addToCart/AddToCartSlice'
import {
  checkFinboxUserExists,
  checkFinvervUserExists,
  getFinboxBanner,
  getFinboxCreditLine,
  getFinboxTransactions,
  getFinvervApplicationStatus,
  getFinvervLines,
} from 'services/api_calls/checkout/checkoutApi'
import { getUserLocation } from 'utils/geoLocationHelper'
import { getBusinessIdData } from 'services/api_calls/root/RootApi'
import FullHeightLoader from 'components/FullHeightLoader'
import OfferPopupModal from 'components/OfferPopupModal/OfferPopupModal'
import { getAccountDetails } from 'redux/dashboard/dashboardSlice'
import { checkExistingUser } from 'redux/authRedux/authSlice'
import moment from 'moment'
import { removeSessionInfo } from 'utils/authHelpers'
import TopComponent from './TopComponent'

function Home() {
  const { businessName } = useParams()
  const [searchParams] = useSearchParams()
  const atag = searchParams?.get('atag')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { productsByCategory, allProducts, loader } = useSelector(
    (state) => state.subCategory
  )
  const { commercialDetails } = useSelector((state) => state.product)

  const { dealsCouponsDetails } = useSelector((state) => state.coupons)

  const { allReviews, reviewSummary } = useSelector((state) => state.review)

  const { rewardpoints, fintechStatus } = useSelector((state) => state.checkout)
  const { buyAgainDetails, metricDetails } = useSelector((state) => state.home)
  const { businessId, loading } = useSelector((state) => state.rootSlice)
  const { loginDetails } = useSelector((state) => state.auth)

  const { accountDetails } = useSelector((state) => state.dashboard)

  const loggedIn = getUserName().length === 0 ? false : true

  const businessInfo = JSON.parse(sessionStorage.getItem('businessInfo'))
  const businessIdData = sessionStorage.getItem('businessId')

  const [isBusinesValid, setIsBusinesValid] = useState(true)
  const [isFinvervAvailable, setIsFinvervAvailable] = useState('')
  const [finAvailValue, setFinAvailValue] = useState(null)
  const [finPercentComplete, setFinPercentComplete] = useState(null)
  const [finboxAvlAmt, setFinboxAvlAmt] = useState(null)
  const { isLoggedIn } = useSelector((state) => state.auth)
  const [showLogin, setShowLogin] = useState(false)
  const [finboxOverdueDetails, setFinboxOverdueDetails] = useState(null)

  const [pageLoader, setPageLoader] = useState(
    businessIdData !== null &&
      commercialDetails !== null &&
      allProducts !== null
  )

  // useEffect(() => {
  //   if (businessInfo === null) {
  //     const localBusinessName = sessionStorage.getItem('businessName')
  //     if (businessName !== localBusinessName) {
  //       sessionStorage.setItem('cartData', 'null')
  //     }
  //     console.log("HOME_USE_EFFECT_LNUM_69_HIT");
  //     dispatch(getBusinessIdDetails(businessName))
  //     sessionStorage.setItem('businessName', businessName)
  //   }
  // }, [businessName, businessInfo, dispatch])

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem('businessName')
    if (businessName !== localBusinessName) {
      console.log('BUSINESS_NAME_CHANGED')
      removeSessionInfo()
      window.location.replace(`/${businessName}`)
    }
    // console.log("HOME_USE_EFFECT_LNUM_80_HIT");
    dispatch(getBusinessIdDetails(businessName)).then((res) => {
      console.log('HOME_USE_EFFECT_WEB_URL_HIT', res?.payload)
      if (!res?.payload?.validate) {
        navigate('/')
      }
    })
    sessionStorage.setItem('businessName', businessName)
  }

  const validateBusinessURL = async () => {
    const response = await getBusinessIdData(businessName)
    if (!response?.validate) {
      navigate('/')
    }
  }

  useEffect(() => {
    getBusinessData()

    // if (businessName) {
    //   validateBusinessURL()
    // }
  }, [businessName])

  useEffect(() => {
    if (businessId?.validate && businessIdData !== null) {
      console.log('HOME_USE_EFFECT_MAIN_HIT')
      // Clear Login

      //Clear Login

      dispatch(getCatalogAllProducts())
      dispatch(getDataForSideBar())
      dispatch(getActiveBusiness())
      dispatch(getMetricDetails())
      dispatch(getCoupons())
      // dispatch(getAllReviewDetails({ option: 'Highest Rating' }))
      // dispatch(getReviewSummaryDetails())
      dispatch(getCommercialDetails())
      if (loggedIn) {
        dispatch(getRewardPointsDetails())
        dispatch(getBuyAgainDetails())
        dispatch(getCartDetails(businessId?.businessId))
        if (!accountDetails) {
          dispatch(getAccountDetails())
        }
      }
      setIsBusinesValid(true)
    } else {
      setIsBusinesValid(false)
    }
  }, [businessId])

  // useEffect(() => {
  //   window.addEventListener('storage', () => {
  //     const finboxRes = JSON.parse(sessionStorage.getItem('finboxStatus'))
  //     if (finboxRes?.data?.banner) {
  //       setIsFinvervAvailable(finboxRes?.data?.banner)
  //       if (finboxRes?.data?.banner === 'OVERDUE') {
  //         getFinboxTransactions().then((resp) => {
  //           if (resp?.data?.transactions) {
  //             const overdueList = resp?.data?.transactions?.filter(
  //               (item) => item?.txnStatus === 'OVERDUE'
  //             )
  //             const overdueAmt = overdueList?.reduce(
  //               (total, cur) => total + cur?.emis?.[0]?.totalPayable,
  //               0
  //             )
  //             let overdueDate = []
  //             overdueList?.forEach((element) => {
  //               overdueDate.push(moment(element?.emis?.[0]?.dueDate))
  //             })
  //             const minDate = moment.min(overdueDate)
  //             console.log(
  //               'overdueAmt',
  //               overdueAmt,
  //               overdueList,
  //               minDate,
  //               overdueDate
  //             )
  //             setFinboxOverdueDetails({ amt: overdueAmt, date: minDate })
  //           }
  //         })
  //       }
  //     }
  //     const finvervRes = JSON.parse(sessionStorage.getItem('finvervStatus'))
  //     if (finvervRes) {
  //       if (!finvervRes?.line_id || !finvervRes?.line_status) {
  //         getFinvervApplicationStatus().then((res) => {
  //           console.log('fffff', res)
  //           setFinPercentComplete(res?.data?.percent_complete)
  //         })
  //         setIsFinvervAvailable('notCompleted')
  //       }
  //       if (
  //         finvervRes?.line_status === 'Under Process' &&
  //         finvervRes?.line_id
  //       ) {
  //         console.log('ssclcmslcmlc')
  //         setIsFinvervAvailable('notCompleted')
  //         setFinAvailValue(finvervRes)
  //       }
  //       if (
  //         (finvervRes?.line_status === 'Rejected' ||
  //           finvervRes?.line_status === 'Blocked') &&
  //         finvervRes?.line_id
  //       ) {
  //         console.log('ssclcmslcmlc')
  //         setIsFinvervAvailable('rejected')
  //         setFinAvailValue(finvervRes)
  //       }
  //       if (finvervRes?.line_status === 'Approved') {
  //         setIsFinvervAvailable('balanceAvailable')
  //         setFinAvailValue(finvervRes)
  //       }
  //     }
  //   })
  // }, [])

  useEffect(() => {
    const finboxRes = JSON.parse(sessionStorage.getItem('finboxStatus'))
    if (finboxRes?.data?.banner) {
      setIsFinvervAvailable(finboxRes?.data?.banner)
      if (finboxRes?.data?.banner === 'ACTIVE') {
        getFinboxCreditLine().then((resp) => {
          if (resp?.data?.availableLimit) {
            setFinboxAvlAmt(resp?.data?.availableLimit)
          }
        })
      }
      if (finboxRes?.data?.banner === 'OVERDUE') {
        getFinboxTransactions().then((resp) => {
          if (resp?.data?.transactions) {
            const overdueList = resp?.data?.transactions?.filter(
              (item) => item?.txnStatus === 'OVERDUE'
            )
            const overdueAmt = overdueList?.reduce(
              (total, cur) => total + cur?.emis?.[0]?.totalPayable,
              0
            )
            let overdueDate = []
            overdueList?.forEach((element) => {
              overdueDate.push(moment(element?.emis?.[0]?.dueDate))
            })
            const minDate = moment.min(overdueDate)
            console.log(
              'overdueAmt',
              overdueAmt,
              overdueList,
              minDate,
              overdueDate
            )
            setFinboxOverdueDetails({ amt: overdueAmt, date: minDate })
          }
        })
      }
      // if (res?.data?.banner === 'ACTIVE') {
      //   getFinboxCreditLine().then((resp) => {
      //     if (resp?.data?.availableLimit) {
      //       setFinboxAvlAmt(resp?.data?.availableLimit)
      //     }
      //   })
      // }
    }
    const finbox = sessionStorage.getItem('finboxUser')
    if (finbox === 'notRegisteredFinbox') {
      setIsFinvervAvailable('notRegisteredFinbox')
    }
    const finvervRes = JSON.parse(sessionStorage.getItem('finvervStatus'))

    const finverv = sessionStorage.getItem('finvervUser')
    if (finverv === 'notRegistered') {
      setIsFinvervAvailable('notRegistered')
    }
    if (finverv === 'notCompleted') {
      setIsFinvervAvailable('notCompleted')
    }
    if (finvervRes) {
      if (!finvervRes?.line_id || !finvervRes?.line_status) {
        setIsFinvervAvailable('notCompleted')
        console.log('finvervRes', finvervRes, 'notCompleted')
        // getFinvervApplicationStatus().then((res) => {
        //   console.log('fffff', res)
        //   setFinPercentComplete(res?.data?.percent_complete)
        // })
      }
      if (finvervRes?.line_status === 'Under Process' && finvervRes?.line_id) {
        console.log('ssclcmslcmlc')
        setIsFinvervAvailable('notCompleted')
        setFinAvailValue(finvervRes)
      }
      if (
        (finvervRes?.line_status === 'Rejected' ||
          finvervRes?.line_status === 'Blocked') &&
        finvervRes?.line_id
      ) {
        console.log('ssclcmslcmlc')
        setIsFinvervAvailable('rejected')
        setFinAvailValue(finvervRes)
      }
      if (finvervRes?.line_status === 'Approved') {
        setIsFinvervAvailable('balanceAvailable')
        setFinAvailValue(finvervRes)
      }
    }
  }, [fintechStatus, isLoggedIn])

  useEffect(() => {
    // if (loggedIn) {
    //   if (
    //     commercialDetails?.info?.fintech?.active &&
    //     commercialDetails?.info?.fintech?.partners?.[0]?.name === 'finbox'
    //   ) {
    //     const phoneNumber = getPhoneNumber()
    //     console.log('Fintech', phoneNumber?.replace('+91', ''))
    //     dispatch(
    //       checkExistingUser({ phone: phoneNumber?.replace('+91', '') })
    //     ).then((response) => {
    //       if (response.payload) {
    //         if (response.payload.status === 200) {
    //           if (response.payload.data.sr === false) {
    //             checkFinboxUserExists().then((res) => {
    //               console.log('checkFinboxUserExists', res)
    //               if (res?.exist == false) {
    //                 console.log('checkFinboxUserExists1', res)
    //                 setIsFinvervAvailable('notRegisteredFinbox')
    //               } else {
    //                 getFinboxBannerData()
    //               }
    //             })
    //           }
    //         }
    //       }
    //     })
    //   }
    //   if (
    //     commercialDetails?.info?.fintech?.active &&
    //     commercialDetails?.info?.fintech?.partners?.[0]?.name === 'finverv'
    //   ) {
    //     checkFinvervUserExists().then((res) => {
    //       console.log('checkFinvervUserExists', res)
    //       if (res?.exist == false) {
    //         console.log('checkFinvervUserExists1', res)
    //         setIsFinvervAvailable('notRegistered')
    //       } else {
    //         getFinvervLinesData()
    //       }
    //     })
    //   }
    // }
  }, [businessId, commercialDetails])

  useEffect(() => {
    if (loginDetails) {
      dispatch(getRewardPointsDetails())
      dispatch(getBuyAgainDetails())
      // if (
      //   commercialDetails?.info?.fintech?.active &&
      //   commercialDetails?.info?.fintech?.partners?.[0]?.name === 'finbox'
      // ) {
      //   const phoneNumber = sessionStorage.getItem('phone')
      //   console.log('FintechphoneNumber', phoneNumber)
      //   if (sessionStorage.getItem('userType') != 'guest') {
      //     checkFinboxUserExists().then((res) => {
      //       console.log('checkFinboxUserExists', res)
      //       if (res?.exist == false) {
      //         console.log('checkFinboxUserExists1', res)
      //         setIsFinvervAvailable('notRegisteredFinbox')
      //       } else {
      //         getFinboxBannerData()
      //       }
      //     })
      //   }

      //   // setIsFinvervAvailable('notRegistered')
      // }
      // if (
      //   commercialDetails?.info?.fintech?.active &&
      //   commercialDetails?.info?.fintech?.partners?.[0]?.name === 'finverv'
      // ) {
      //   checkFinvervUserExists().then((res) => {
      //     console.log('checkFinvervUserExists', res)
      //     if (res?.exist == false) {
      //       console.log('checkFinvervUserExists1', res)
      //       setIsFinvervAvailable('notRegistered')
      //     } else {
      //       getFinvervLinesData()
      //     }
      //   })
      // }
      if (!accountDetails) {
        dispatch(getAccountDetails())
      }
    }
  }, [loginDetails])

  useEffect(() => {
    if (!isLoggedIn) {
      setIsFinvervAvailable('')
    }
  }, [isLoggedIn])

  const getFinboxBannerData = () => {
    getFinboxBanner().then((res) => {
      dispatch(fintechStatusChange(res))
      if (res?.data?.banner) {
        // dispatch(finboxStatusChange(res?.data?.banner))
        setIsFinvervAvailable(res?.data?.banner)
        if (res?.data?.banner === 'ACTIVE') {
          getFinboxCreditLine().then((resp) => {
            if (resp?.data?.availableLimit) {
              setFinboxAvlAmt(resp?.data?.availableLimit)
            }
          })
        }
        if (res?.data?.banner === 'OVERDUE') {
          getFinboxTransactions().then((resp) => {
            if (resp?.data?.transactions) {
              const overdueList = resp?.data?.transactions?.filter(
                (item) => item?.txnStatus === 'OVERDUE'
              )
              const overdueAmt = overdueList?.reduce(
                (total, cur) => total + cur?.emis?.[0]?.totalPayable,
                0
              )
              let overdueDate = []
              overdueList?.forEach((element) => {
                overdueDate.push(moment(element?.emis?.[0]?.dueDate))
              })
              const minDate = moment.min(overdueDate)
              console.log(
                'overdueAmt',
                overdueAmt,
                overdueList,
                minDate,
                overdueDate
              )
              setFinboxOverdueDetails({ amt: overdueAmt, date: minDate })
            }
          })
        }
      }
    })
  }

  // useEffect(() => {
  //   window.addEventListener('storage', () => {
  //     const finboxRes = JSON.parse(sessionStorage.getItem('finboxStatus'))
  //     if (finboxRes?.data?.banner) {
  //       setIsFinvervAvailable(finboxRes?.data?.banner)
  //     }
  //   })
  // }, [])

  const getFinvervLinesData = () => {
    getFinvervLines().then((resp) => {
      dispatch(fintechStatusChange(resp))
      if (!resp?.line_id || !resp?.line_status) {
        getFinvervApplicationStatus().then((res) => {
          console.log('fffff', res)
          setFinPercentComplete(res?.data?.percent_complete)
        })
        setIsFinvervAvailable('notCompleted')
      }
      if (resp?.line_status === 'Under Process' && resp?.line_id) {
        console.log('ssclcmslcmlc')
        setIsFinvervAvailable('notCompleted')
        setFinAvailValue(resp)
      }
      if (
        (resp?.line_status === 'Rejected' || resp?.line_status === 'Blocked') &&
        resp?.line_id
      ) {
        console.log('ssclcmslcmlc')
        setIsFinvervAvailable('rejected')
        setFinAvailValue(resp)
      }
      if (resp?.line_status === 'Approved') {
        setIsFinvervAvailable('balanceAvailable')
        setFinAvailValue(resp)
      }
    })
  }

  return (
    <Box>
      {/* Page Loader: {JSON.stringify(pageLoader)} */}
      {pageLoader ? (
        <>
          {businessId || businessIdData ? (
            <TopComponent value={'home'} showLoginForm={showLogin} />
          ) : null}
        </>
      ) : null}
      {/* businessIdData: {JSON.stringify(businessIdData !== null)} <br />
      commercialDetails:{JSON.stringify(commercialDetails !== null)} <br />
      allProducts: {JSON.stringify(allProducts !== null)} <br />
      productsByCategory: {JSON.stringify(productsByCategory?.length > 0)}{' '}
      <br />
      metricDetails: {JSON.stringify(metricDetails !== null)} <br /> */}
      {businessIdData !== null &&
      commercialDetails !== null &&
      allProducts !== null &&
      productsByCategory?.length > 0 &&
      metricDetails !== null ? (
        <>
          {!isBusinesValid ? (
            businessId?.validate === false ? (
              <Box>
                <BusinessClosed />
                <FooterMui commercialDetails={commercialDetails} />
              </Box>
            ) : null
          ) : (
            <>
              {!pageLoader ? (
                businessId || businessIdData ? (
                  <TopComponent value={'home'} showLoginForm={showLogin} />
                ) : null
              ) : null}

              {loader && (
                <Grid
                  item
                  container
                  direction='column'
                  xs={12}
                  justify='center'
                  alignContent='center'
                  alignItems='center'
                >
                  <Grid item>
                    <Loader />
                  </Grid>
                </Grid>
              )}
              {allProducts?.productList?.length === 0 ? (
                <Box>
                  <CommingSoonCard />
                  <FooterMui commercialDetails={commercialDetails} />
                </Box>
              ) : (
                productsByCategory &&
                productsByCategory.length > 0 &&
                commercialDetails &&
                allProducts &&
                allProducts.productList &&
                metricDetails !== null && (
                  <Box>
                    <HomeContent
                      productsGroup={productsByCategory}
                      commercialDetails={commercialDetails}
                      dealsCouponsDetails={dealsCouponsDetails}
                      allReviews={allReviews}
                      reviewSummary={reviewSummary}
                      rewardpoints={rewardpoints}
                      buyAgainDetails={buyAgainDetails}
                      metricDetails={metricDetails}
                      productLength={allProducts.productList.length}
                      isFinvervAvailable={isFinvervAvailable}
                      finAvailValue={finAvailValue}
                      getFinboxLinesData={() => getFinboxBannerData()}
                      setShowLogin={() => setShowLogin(!showLogin)}
                      finboxOverdueDetails={finboxOverdueDetails}
                      finboxAvlAmt={finboxAvlAmt}
                      finPercentComplete={finPercentComplete}
                      getFinvervLinesData={() => getFinvervLinesData()}
                    />
                    <FooterMui commercialDetails={commercialDetails} />
                    <OfferPopupModal couponCode={atag} />
                  </Box>
                )
              )}
            </>
          )}
        </>
      ) : businessIdData !== null &&
        commercialDetails !== null &&
        allProducts !== null &&
        metricDetails !== null ? (
        <Box>
          {allProducts?.productList?.length === 0 && (
            <>
              <Box>
                {!pageLoader ? (
                  businessId || businessIdData ? (
                    <TopComponent value={'home'} showLoginForm={showLogin} />
                  ) : null
                ) : null}
              </Box>

              <Box pb={{ xs: 8, md: 0 }}>
                <CommingSoonCard />
                <FooterMui commercialDetails={commercialDetails} />
              </Box>
            </>
          )}
        </Box>
      ) : (
        <FullHeightLoader />
      )}
    </Box>
  )
}
export default Home
