import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import EmptyCard from 'components/EmptyCard'
import {
  allOrdersEmptyImgUrl,
  allOrdersEmptyMessage,
  emptyOrderBtnText,
  OrderEmptyDescription,
  orders_AllOrder,
  orders_AvailableCashback,
  orders_PendingOrders,
  orders_TotalOrder,
} from 'configs/Constants'
import React from 'react'
import BreadcrumbsSection from '../../components/Breadcrumbs'
import {
  dashboardDescriptionColor,
  headingColor,
  primaryColor,
} from '../../configs/styles/muiThemes'
import DasboardInfoCard from '../Dashboard/DasboardInfoCard'
import DashboardCard from '../Dashboard/DashboardCard'
import DashboardNavbar from '../Dashboard/DashboardNavbar'
import OrderCard from './OrderCard'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Loader from 'components/Loader'

function AllOrdersContent({
  accountDetails,
  orderDetails,
  openOrderDetails,
  cashbackData,
  loading,
  businessId,
  ordersCountData,
  handleAllOrderPagination,
  pageNo,
}) {
  const handlePagination = (event, value) => {
    handleAllOrderPagination(value)
  }

  const businessName = sessionStorage.getItem('businessName')

  return (
    <Container sx={{ maxWidth: '1300px !important', mt: { xs: 1.8, md: 2.5 } }}>
      {/* <BreadcrumbsSection links={[{ label: 'All Orders', to: `/AllOrders` }]} /> */}
      <Box mt={{ md: '20px !important', xs: 0 }}>
      <Grid
        container
        rowSpacing={{ md: 2, xs: 0 }}
        columnSpacing={{ md: 5, xs: 0 }}
      >
        <Grid item md={3} xs={12}>
          <DashboardNavbar active='allOrders' businessId={businessId} />
        </Grid>

        {loading ? (
          <Grid item md={9} xs={12}>
            <Box display='flex' flexDirection='row' justifyContent='center'>
              <Loader />
            </Box>
          </Grid>
        ) : (
          <Grid item md={9} xs={12}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2.0}
              mt={{ md: 0, xs: 2.3 }}
              flexWrap='wrap'
              gap={1}
            >
              <Box>
                <Typography
                  variant='h4'
                  component='h2'
                  fontSize={{ xs: 15, md: 17 }}
                  color={headingColor}
                  display='inline'
                >
                  {orders_AllOrder}{' '}
                </Typography>
                <Typography
                  variant='subtitle1'
                  fontSize={{ xs: 14, md: 16 }}
                  color={dashboardDescriptionColor}
                  display='inline'
                >
                  {/* ({orderDetails?.length}) */}(
                  {ordersCountData?.details?.allOrder})
                </Typography>
              </Box>

              {/* <Typography
                variant='subtitle1'
                component='p'
                fontSize='14px'
                lineHeight='24px'
                color={dashboardDescriptionColor}
                display='inline'
              >
                Hello,{' '}
                <Typography
                  variant='subtitle1'
                  component='p'
                  fontSize='14px'
                  fontWeight={600}
                  color={primaryColor}
                  display='inline'
                >
                  {accountDetails?.account?.name} !
                </Typography>
              </Typography> */}
            </Box>

            <Grid container spacing={{ xs: 1.25, md: 3 }}>
              <Grid item md={4} xs={12}>
                <DashboardCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_total_orders.png'
                  title={orders_TotalOrder}
                  // value={orderDetails?.length}
                  value={ordersCountData?.details?.allOrder}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <DashboardCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_pending_orders.png'
                  title={orders_PendingOrders}
                  // value={openOrderDetails?.details?.length}
                  value={ordersCountData?.details?.openOrder}
                  linkPath={`/${businessName}/openOrders`}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <DashboardCard
                  icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_available_cashback.png'
                  title={orders_AvailableCashback}
                  value={`₹${
                    cashbackData?.details.length > 0
                      ? cashbackData.details[0].availablePointValue.toFixed(2)
                      : 0
                  }`}
                />
              </Grid>
            </Grid>

            {orderDetails?.map((order, index) => {
              return (
                <OrderCard
                  key={'allorders_' + index}
                  cartId={order.cartId}
                  orderId={order.orderId}
                  date={order.orderDate}
                  quantity={order.totalQuantity}
                  totalPrice={order.orderTotalCost}
                  orderStatus={order.orderStatus}
                  cartDate={order.cartDate}
                  orderData={order}
                  fromPage={{ label: 'All Orders', to: 'AllOrders' }}
                />
              )
            })}

            {ordersCountData ? (
              ordersCountData?.details?.allOrder > 20 ? (
                <Stack
                  spacing={2}
                  my={4}
                  display={{ md: 'flex', xs: 'none' }}
                  alignItems='center'
                  justifyContent='center'
                >
                  <Pagination
                    count={
                      ordersCountData
                        ? Math.ceil(ordersCountData?.details?.allOrder / 20)
                        : 0
                    }
                    page={pageNo}
                    onChange={handlePagination}
                    color='primary'
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          next: NextBtn,
                          previous: PreviousBtn,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              ) : null
            ) : null}

            {orderDetails?.length == 0 ? (
              <EmptyCard
                message={allOrdersEmptyMessage}
                description={OrderEmptyDescription}
                imageUrl={allOrdersEmptyImgUrl}
                actionBtnText={emptyOrderBtnText}
              />
            ) : null}
          </Grid>
        )}
      </Grid>
      </Box>
    </Container>
  )
}

export default AllOrdersContent

const styles = {
  btnHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  )
}

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  )
}
